import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getPinyaSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'disabled'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const Pinya = ({ disabled = false, ...rest }: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getPinyaSettingsItems);
    const { t } = useAppTranslation();

    return (
        <FieldsGenerator
            {...rest}
            name="pinya"
            fields={[
                {
                    type: 'switch',
                    props: {
                        name: 'include_working_times',
                        disabled,
                        label: t('label.includeWorkingTimes', 'Include Working Times'),
                        title: t(
                            'message.info.includeWorkingTimes',
                            'Defines how many days after last day of a month will be included'
                        ),
                        value: Boolean(data.find(({ key }) => key === 'include_working_times')?.value ?? false)
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: !disabled,
                        name: 'base_url',
                        disabled,
                        label: t('label.baseUrl', 'Base Url'),
                        value: data.find(({ key }) => key === 'base_url')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: !disabled,
                        name: 'endpoint_vacations',
                        disabled,
                        label: t('label.endpointVacationsRequest', 'Endpoint for Vacations Request'),
                        value: data.find(({ key }) => key === 'endpoint_vacations')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: !disabled,
                        name: 'endpoint_employees',
                        disabled,
                        label: t('label.endpointEmployeesRequest', 'Endpoint for Employees Request'),
                        value: data.find(({ key }) => key === 'endpoint_employees')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: !disabled,
                        name: 'tenant',
                        disabled,
                        label: t('label.tenant', 'Tenant'),
                        value: data.find(({ key }) => key === 'tenant')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: !disabled,
                        name: 'access_token',
                        disabled,
                        label: t('label.accessToken', 'Access Token'),
                        value: data.find(({ key }) => key === 'access_token')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'offset_date_from',
                        label: t('label.offsetDateFrom', 'Offset Date From'),
                        type: 'number',
                        title: t(
                            'message.info.offsetDateFrom',
                            'Defines how many days before first day of a month will be included'
                        ),
                        InputProps: {
                            inputProps: {
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        },
                        value: data.find(({ key }) => key === 'offset_date_from')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'offset_date_to',
                        label: t('label.offsetDateTo', 'Offset Date To'),
                        type: 'number',
                        title: t(
                            'message.info.offsetDateTo',
                            'Defines how many days after last day of a month will be included'
                        ),
                        InputProps: {
                            inputProps: {
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        },
                        value: data.find(({ key }) => key === 'offset_date_to')?.value
                    }
                }
            ]}
        />
    );
};

export default Pinya;
