import { IRoleModel } from '@/data/Roles/RoleModels';
import { ISkillModel } from '@/data/Skills/SkillModels';
import ChipStack from '@/wrappers/ChipStack';

type ISchedulerTableSimpleSkillsProps = {
    skills: ISkillModel[];
    roles: IRoleModel[];
    selectedRequirements: number[];
    onRemove: (id: number) => void;
};

export default function SchedulerTableSimpleSkills(props: ISchedulerTableSimpleSkillsProps) {
    const { selectedRequirements, skills, roles, onRemove } = props;

    const values = [
        ...roles.map(({ id, name }) => ({
            id: -id,
            name,
            color: undefined
        })),
        ...skills.map(({ id, name, color }) => ({
            id,
            name,
            color
        }))
    ];

    return (
        <ChipStack
            name="skills"
            direction="row"
            variant="outlined"
            values={values
                .filter((item) => selectedRequirements?.includes(item.id))
                .map((skill) => ({ id: skill.id.toString(), label: skill.name, color: skill.color }))}
            onRemove={(id) => onRemove(parseInt(id))}
        />
    );
}
