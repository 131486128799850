import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { AllSystemCSSProperties } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Ref, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';
import { FieldWrapper } from '@/base/FormGenerator';
import Tabs from '@/wrappers/Tabs';
import { ISupportedFieldType } from './FieldTypes';
import MultiRowGenerator from './MultiRowGenerator';
import SimpleFieldRenderer, { ISimpleFieldRendererProps } from './SimpleFieldRenderer';
import { getFullNameOfField, getValuesFromProps, ISupportedValueType } from './utils';

export const StyledBox = styled(Box)`
    margin: 0;
    display: flex;
    flex-wrap: wrap;
`;

const StyledTabsWrapper = styled(Box)(
    ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
    width: 100%;
    display: inline-flex;
    flex-direction: column;
`
);

type ICallableRef = {
    getValues: () => ISupportedValueType;
};

export type IFieldsGeneratorProps = Pick<
    ISimpleFieldRendererProps,
    | 'clearErrors'
    | 'control'
    | 'getValues'
    | 'getFieldState'
    | 'disabled'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'shouldUnregister'
    | 'watch'
    | 'fullWidth'
> & {
    innerRef?: Ref<ICallableRef>;
    name?: string;
    actions?: boolean;
    fields: (false | ISupportedFieldType)[];
    justifyContent?: AllSystemCSSProperties['justifyContent'];
};

const FieldsGenerator = ({ innerRef, name, actions = false, fields, ...rest }: IFieldsGeneratorProps) => {
    const fieldsToRender = fields.filter((field) => typeof field === 'object') as ISupportedFieldType[];

    useImperativeHandle(innerRef, () => ({
        getValues: () => {
            return getValuesFromProps(fieldsToRender);
        }
    }));

    const toRender = fieldsToRender.map((field) => {
        if (field.type === 'newLine') {
            return <Box key={field.props.name} sx={{ width: '100%' }} />;
        } else if (field.type === 'multiRowInputs') {
            return (
                <FieldWrapper
                    key={`multiRowInputs_${field.props.name}`}
                    type="multiRowInputs"
                    fullWidth={true}
                    isDisplayed={field.display ? field.display(rest.getValues()) : true}
                >
                    <MultiRowGenerator
                        {...rest}
                        maxNumberOfItems={field.props.maxNumberOfItems}
                        key={field.props.name}
                        fixedSize={field.props.fixedSize}
                        inputs={field.props.inputs}
                        name={field.props.name}
                        sx={field.props.sx}
                        disableButtons={field.props.disableButtons}
                        fullWidth={rest.fullWidth}
                    />
                </FieldWrapper>
            );
        } else if (field.type === 'tabs') {
            return (
                <Controller
                    key={field.props.name}
                    name={field.props.name}
                    control={rest.control}
                    rules={{
                        deps: field.deps
                    }}
                    render={({ field: fieldData }) => {
                        const defaultValue = field.props.value ?? rest.initialValues[field.props.name] ?? '';
                        const { name: fieldName } = rest.register(field.props.name, {
                            shouldUnregister: rest.shouldUnregister,
                            value: defaultValue
                        });
                        const hideTabs = !(typeof field.display === 'function'
                            ? field.display(rest.getValues())
                            : field.display !== false);

                        return (
                            <StyledTabsWrapper
                                sx={{
                                    ...(hideTabs ? { visibility: 'hidden' } : { visibility: 'visible' })
                                }}
                            >
                                <Tabs
                                    {...field.props}
                                    name={fieldName}
                                    value={(fieldData.value ?? defaultValue) as string}
                                    onChange={fieldData.onChange}
                                    tabs={field.props.tabs.map((tab) => ({
                                        id: tab.name,
                                        label: tab.label,
                                        display: tab.display ? tab.display(rest.getValues()) : true,
                                        content: (
                                            <FieldsGenerator
                                                {...rest}
                                                key={tab.name}
                                                fields={tab.inputs}
                                                name={tab.name}
                                                fullWidth={true}
                                            />
                                        )
                                    }))}
                                />
                            </StyledTabsWrapper>
                        );
                    }}
                ></Controller>
            );
        } else {
            if (actions) {
                return (
                    <Box key={field.props.name}>
                        <SimpleFieldRenderer {...rest} input={field} fullName={getFullNameOfField(field.props.name)} />
                    </Box>
                );
            } else {
                return (
                    <SimpleFieldRenderer
                        {...rest}
                        key={field.props.name}
                        input={field}
                        fullName={getFullNameOfField(field.props.name)}
                    />
                );
            }
        }
    });

    if (actions) {
        return <>{toRender}</>;
    } else {
        return (
            <StyledBox
                data-testid={name ?? 'fieldsGenerator'}
                sx={{
                    width: rest.fullWidth ? '100%' : undefined,
                    ...(rest.justifyContent ? { justifyContent: rest.justifyContent } : {})
                }}
            >
                {toRender}
            </StyledBox>
        );
    }
};

export default FieldsGenerator;
