import { useAppSelector } from '@/data/hooks';
import { skillById } from '@/data/Skills/SkillSlice';
import SkillIcon from '@/modules/Scheduler/components/SkillIcon';
import { StyledSkillContentItem } from '@/modules/Scheduler/Table/SchedulerTable';

type IProps = {
    id: number;
};

const SchedulerTableSideBarSkill = ({ id }: IProps) => {
    const skill = useAppSelector((state) => skillById(state, id));

    return (
        <StyledSkillContentItem>
            {skill?.name}
            {skill?.color && skill?.icon ? <SkillIcon color={skill?.color} icon={skill?.icon} /> : null}
        </StyledSkillContentItem>
    );
};

export default SchedulerTableSideBarSkill;
