import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldsGenerator, IFieldsGeneratorProps, IOutputValueType, ISupportedFieldType } from '@/base/FormGenerator';
import CuForm, { ICuProps } from '@/components/CuForm';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { getRequestApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRequestTypes } from '@/data/RequestTypes/RequestTypeActions';
import { IRequestTypeSelectModel } from '@/data/RequestTypes/RequestTypeModels';
import { requestTypeAll } from '@/data/RequestTypes/RequestTypeSlice';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { IShiftSelectModel } from '@/data/Shifts/ShiftModels';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import { fetchUserById, fetchUsersForSelect } from '@/data/Users/UserActions';
import { IUserSelectModel } from '@/data/Users/UserModels';
import { selectUserById, usersForSelect } from '@/data/Users/UserSlice';
import { createUserToRequest } from '@/data/UserToRequests/UserToRequestActions';
import { IUserToRequestCUModel, IUserToRequestModel } from '@/data/UserToRequests/UserToRequestModels';
import { userToRequestCreatingStatus, userToRequestUpdatingStatus } from '@/data/UserToRequests/UserToRequestSlice';
import { getUsersFundForInsertedVacation } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import {
    exceededFundByRequestTypeIdAndDateTimeStartAndEnd,
    getUserToVacationFundKey,
    isSomeVacationFundLoading
} from '@/data/UserToVacationFunds/UserToVacationFundSlice';
import { fetchWorkplaceById, fetchWorkplacesRecordList } from '@/data/Workplaces/WorkplaceActions';
import { IWorkplaceModel, IWorkplaceRecordModel } from '@/data/Workplaces/WorkplaceModels';
import { selectWorkplaceById, workplaceRecordList } from '@/data/Workplaces/WorkplaceSlice';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsRequestItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsRequestItemEnum';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import { serializeUser } from '@/utils/UserHelper';
import { message, regex } from '@/utils/validations';
import { IDatePickerProps } from '@/wrappers/DatePicker';
import { IDateTimePickerProps } from '@/wrappers/DateTimePicker';
import { IDateTimeRangePickerProps, IValueType } from '@/wrappers/DateTimeRangePicker/DateTimeRangePicker';

type IBaseProps = {
    user?: IUserSelectModel;
    onlyFields?: boolean;
    noRequired?: boolean;
    isFromSchedulePlanDayShiftForm?: boolean;
    fromDay?: Date;
    workplaceId?: number;
    dateTimePickerValues?: IValueType<IDateTimePickerProps['value']> | null;
    onIsValidChange?: (isValid: boolean) => void;
};
type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;
type IFullFormProps = IBaseProps &
    Omit<ICuProps<IUserToRequestModel>, 'resource'> & {
        onlyFields: false;
    } & Partial<RequiredByFieldsGenerator>;
type IOnlyFieldsProps = IBaseProps &
    Partial<Omit<IFullFormProps, 'onlyFields'>> & {
        onlyFields: true;
    } & RequiredByFieldsGenerator;
type IProps = IOnlyFieldsProps | IFullFormProps;

export const handleData = (
    values: IOutputValueType,
    requestTypes: IRequestTypeSelectModel[],
    userId: number,
    shiftsData: IShiftSelectModel[],
    workplacesListData?: IWorkplaceModel[],
    isUserAdmin?: boolean
): IUserToRequestCUModel => {
    const isShift =
        requestTypes.find((entity) => `${entity.id}` === `${values.request_type_id}`)?.type === RequestTypeEnum.shifts;

    const workplaceId =
        typeof values.workplace_id === 'string' && values.workplace_id.length > 0
            ? parseInt(values.workplace_id)
            : undefined;
    const workplaceData = workplacesListData?.find((item) => item.id === workplaceId);

    let validFromDate: DateTimeType | null = null;
    let validToDate: DateTimeType | null = null;

    if (isShift) {
        const shiftId = values.shift_id;
        const shift = shiftsData.find((entity) => `${entity.id}` === `${shiftId}`);

        const date = values.date as IDatePickerProps['value'];

        if (shift && date) {
            validFromDate = DateHelper.fromTimeStringAndLocalDate(
                shift?.time_start,
                date,
                workplaceData?.time_zone ?? null
            );
            validToDate = DateHelper.addHours(validFromDate, shift.duration);
        }
    } else {
        validFromDate = (values.dateTimeRange as IDateTimeRangePickerProps['value'])?.start ?? null;
        validToDate = (values.dateTimeRange as IDateTimeRangePickerProps['value'])?.end ?? null;
    }

    return {
        user_id: isUserAdmin ? parseInt(values.user_id as string) : userId,
        request_type_id: values.request_type_id as number,
        workplace_id: workplaceData?.id ?? (values.workplace_id as number | undefined),
        shift_id: isShift ? parseInt(values.shift_id as string) : undefined,
        description: values.description as string,
        datetime_from: validFromDate ? DateHelper.formatISO(validFromDate) : '',
        datetime_to: validToDate ? DateHelper.formatISO(validToDate) : ''
    };
};

const UserToRequestForm = ({
    onlyFields,
    id,
    fromDay,
    clearErrors,
    control,
    fullWidth,
    getFieldState,
    getValues,
    initialValues,
    readOnly,
    register,
    setValue,
    watch,
    workplaceId,
    user,
    onIsValidChange,
    dateTimePickerValues = null,
    noRequired = false,
    isFromSchedulePlanDayShiftForm = false,
    ...rest
}: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();

    const requestTypeList = useAppSelector(requestTypeAll) ?? [];
    const selectedWorkplaceData = useAppSelector((state) => selectWorkplaceById(state, workplaceId ?? null)) ?? null;
    const workplacesRecordListData = useAppSelector(workplaceRecordList) ?? [];

    const workplacesListData: IWorkplaceRecordModel[] = selectedWorkplaceData
        ? [selectedWorkplaceData]
        : workplacesRecordListData ?? [];
    const shiftsData = useAppSelector(shiftsForSelect) ?? [];
    const creatingStatus = useAppSelector(userToRequestCreatingStatus);
    const updatingStatus = useAppSelector(userToRequestUpdatingStatus);
    const userData = useAppSelector((state) => (user ? selectUserById(state, user.id) : null));
    const [requestType, setRequestType] = useState<IRequestTypeSelectModel | null>(null);
    const [vacationDatetime, setVacationDatetime] = useState<IValueType<IDateTimePickerProps['value']> | null>(null);
    const requestSettingsData = useAppSelector(getRequestApplicationsSettings);
    const usersList = useAppSelector(usersForSelect);
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const [userId, setUserId] = useState<number | null>(user?.id ?? null);

    const isAbleToExceedFund = getSettingsItemBooleanValueByKey(
        requestSettingsData,
        ApplicationSettingsRequestItemEnum.allowCreateNewRequestAfterExceedUsersRequestFund
    );

    const usersExceededFundList = useAppSelector((state) =>
        exceededFundByRequestTypeIdAndDateTimeStartAndEnd(
            state,
            userData?.id ?? null,
            requestType?.id ?? null,
            vacationDatetime?.start ?? null,
            vacationDatetime?.end ?? null
        )
    );
    const isFundsLoading = useAppSelector((state) =>
        isSomeVacationFundLoading(state, vacationDatetime?.start ?? undefined, vacationDatetime?.end ?? undefined)
    );

    const findRequestType = useCallback(
        (requestTypeId: string) => requestTypeList.find((entity) => `${entity.id}` === requestTypeId),
        [requestTypeList]
    );
    const isTimeOff = useCallback(
        (requestTypeId: string) => findRequestType(requestTypeId)?.type === RequestTypeEnum.times_off,
        [requestTypeList]
    );
    const isShift = useCallback(
        (requestTypeId: string) => findRequestType(requestTypeId)?.type === RequestTypeEnum.shifts,
        [requestTypeList]
    );
    const isAvailable = useCallback(
        (requestTypeId: string) => findRequestType(requestTypeId)?.type === RequestTypeEnum.available,
        [requestTypeList]
    );

    const isValid = useMemo(
        () => !isFundsLoading && !((usersExceededFundList?.length ?? 0) > 0 && !isAbleToExceedFund),
        [usersExceededFundList, isAbleToExceedFund, isFundsLoading]
    );

    const handleOpen = useCallback(() => {
        setRequestType(null);
        setVacationDatetime(null);
        dispatch(fetchApplicationsSettingsItems());
        dispatch(fetchRequestTypes({ noPaging: true }));
        dispatch(fetchShiftsForSelect({ search: '' }));
        if (!isFromSchedulePlanDayShiftForm) {
            dispatch(fetchWorkplacesRecordList());
        }

        setUserId(user?.id ?? null);
    }, [user]);

    const handleCreate = useCallback(
        (values: IOutputValueType) => {
            if (userId) {
                return createUserToRequest(
                    handleData(values, requestTypeList, userId, shiftsData, workplacesListData, isUserAdmin)
                );
            }
        },
        [requestTypeList, workplacesListData, shiftsData, userId]
    );

    useEffect(() => {
        if (!rest.displayAsModal && !rest.displayAsSidebar) {
            handleOpen();
        }
    }, [rest.displayAsModal, rest.displayAsSidebar]);

    useEffect(() => {
        if (onIsValidChange) {
            onIsValidChange(isValid);
        }
    }, [isValid]);

    useEffect(() => {
        if (workplaceId) {
            dispatch(fetchWorkplaceById(workplaceId));
        }
    }, [workplaceId]);

    useEffect(() => {
        if (user) {
            dispatch(fetchUserById(user.id));
            setUserId(user.id);
        }
    }, [user]);

    useEffect(() => {
        dispatch(fetchApplicationsSettingsItems());
        dispatch(fetchUsersForSelect({ search: '' }));
    }, []);

    useEffect(() => {
        if (
            userData &&
            requestType &&
            isTimeOff(`${requestType.id}`) &&
            vacationDatetime &&
            vacationDatetime.start &&
            vacationDatetime.end
        ) {
            dispatch(
                getUsersFundForInsertedVacation({
                    userId: userData.id,
                    requestTypeId: requestType.id,
                    vacationStart: DateHelper.formatISO(vacationDatetime.start),
                    vacationEnd: DateHelper.formatISO(vacationDatetime.end)
                })
            );
        }
    }, [userData, requestType, vacationDatetime?.start, vacationDatetime?.end]);

    useEffect(() => {
        if (dateTimePickerValues) {
            if (dateTimePickerValues?.start && dateTimePickerValues?.end) {
                setVacationDatetime(dateTimePickerValues);
            }
        }
    }, [dateTimePickerValues?.start, dateTimePickerValues?.end]);

    const getUsersOptionsList = useCallback(
        () =>
            selectedWorkplaceData
                ? selectedWorkplaceData.user_to_workplaces.map((userToWorkplace) => ({
                      id: `${userToWorkplace.user.id}`,
                      label: serializeUser(userToWorkplace.user)
                  }))
                : usersList.map((entity) => ({
                      id: `${entity.id}`,
                      label: serializeUser(entity)
                  })) ?? [],
        [usersList, selectedWorkplaceData]
    );

    const fields: ISupportedFieldType[] = [
        {
            type: 'select',
            display: () => !isFromSchedulePlanDayShiftForm && isUserAdmin,
            props: {
                name: 'user_id',
                required: true,
                label: t('label.user', 'User'),
                value: userId?.toString(),
                onChange: (newUserId) => setUserId(newUserId ? parseInt(newUserId) : null),
                options: getUsersOptionsList()
            }
        },
        {
            type: 'select',
            display: ({ user_id }) => !!user_id,
            props: {
                required: !noRequired,
                name: 'request_type_id',
                label: 'Request Type',
                value: '',
                options: requestTypeList
                    .filter((item) => (isFromSchedulePlanDayShiftForm ? item.type !== RequestTypeEnum.available : true))
                    .map((item) => ({
                        id: item.id.toString(),
                        label: item.name
                    })),
                onChange: (value) =>
                    setRequestType(value ? requestTypeList.find((item) => item.id == parseInt(value)) ?? null : null),
                width: 6,
                validation: {
                    deps: 'type'
                }
            }
        },
        {
            type: 'select',
            display: ({ request_type_id }) => !workplaceId && isShift(`${request_type_id}`),
            props: {
                name: 'workplace_id',
                required: ({ request_type_id }) => isShift(`${request_type_id}`),
                value: workplaceId?.toString(),
                label: t('label.workplace', 'Workplace'),
                options: workplacesListData
                    .filter((item) => item.user_to_workplaces.some((subItem) => subItem.user_id === userId))
                    .map((item) => ({
                        id: item.id.toString(),
                        label: item.name
                    })),
                width: 6,
                validation: {
                    deps: ['request_type_id', 'workplace_id', 'shift_id']
                }
            }
        },
        {
            type: 'select',
            display: () => false,
            props: {
                name: 'type',
                disabled: true,
                label: t('label.type', 'Type'),
                value: (values) => requestTypeList.find((entity) => `${entity.id}` === values?.request_type_id)?.type,
                options: [
                    {
                        id: RequestTypeEnum.shifts,
                        label: t('label.shifts', 'Shifts')
                    },
                    {
                        id: RequestTypeEnum.times_off,
                        label: t('label.timesOff', 'Times Off')
                    },
                    {
                        id: RequestTypeEnum.available,
                        label: t('label.available', 'Available')
                    }
                ],
                validation: {
                    deps: 'request_type_id'
                },
                width: 6
            }
        },
        {
            type: 'newLine',
            display: ({ request_type_id }) => !isTimeOff(`${request_type_id}`),
            props: {
                name: 'after_request_type',
                type: 'hidden',
                validation: {
                    deps: 'request_type_id'
                }
            }
        },
        {
            type: 'select',
            display: ({ request_type_id, workplace_id }) => isShift(`${request_type_id}`) && !!workplace_id,
            props: {
                required: ({ request_type_id }) => !noRequired && isShift(`${request_type_id}`),
                name: 'shift_id',
                value: '',
                label: t('label.shift', 'Shift'),
                width: 6,
                options: ({ workplace_id }) =>
                    workplacesListData
                        ?.find((workplaceEntity) => workplaceEntity.id == workplace_id)
                        ?.shift_to_workplaces.filter((shiftToWorkplace) => shiftToWorkplace.type === 'allowed_shift')
                        .map(({ shift }) => ({
                            id: shift.id.toString(),
                            label: shift.name
                        })) ?? [],
                validation: {
                    deps: ['request_type_id', 'workplace_id']
                }
            }
        },
        {
            type: 'date',
            display: ({ request_type_id, workplace_id }) => isShift(request_type_id as string) && !!workplace_id,
            props: {
                name: 'date',
                minDate: DateHelper.getStartOfLastYear(),
                required: ({ request_type_id }) => isShift(request_type_id as string) && !noRequired,
                label: t('label.date', 'Date'),
                value: null,
                width: 6,
                validation: {
                    deps: ['request_type_id', 'workplace_id']
                }
            }
        },
        {
            type: 'dateTimeRange',
            display: ({ request_type_id }) =>
                (isTimeOff(`${request_type_id}`) || isAvailable(`${request_type_id}`)) &&
                !isFromSchedulePlanDayShiftForm,
            props: {
                name: 'dateTimeRange',
                minDate: DateHelper.getStartOfLastYear(),
                required: ({ request_type_id }) =>
                    (isTimeOff(`${request_type_id}`) || isAvailable(`${request_type_id}`)) && !noRequired,
                onChange: (value) => {
                    setVacationDatetime(value);
                },
                minutesStep: 15,
                label: {
                    start: t('label.fromDate', 'From Date'),
                    end: t('label.toDate', 'To Date')
                },
                validation: {
                    deps: ['request_type_id']
                }
            }
        },
        {
            type: 'textArea',
            display: ({ request_type_id }) => !!request_type_id,
            props: {
                name: 'description',
                value: '',
                label: t('label.description', 'Description'),
                validation: {
                    pattern: {
                        value: regex.text,
                        message: message.text
                    },
                    deps: ['request_type_id', 'workplace_id']
                }
            }
        },
        {
            type: 'custom',
            display: () => {
                return isFundsLoading || (usersExceededFundList?.length ?? 0) > 0;
            },
            props: {
                name: 'message',
                values: {},
                render: () => (
                    <>
                        <Divider />
                        {isFundsLoading ? (
                            <Skeleton animation="wave" variant="rectangular" width="100%" height="2em" />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                {usersExceededFundList?.map((fund) => {
                                    return (
                                        <Typography
                                            key={getUserToVacationFundKey(fund)}
                                            color="error.dark"
                                            variant="subtitle1"
                                        >
                                            {t(
                                                'message.error.vacationFundForYearWillBeExceeded',
                                                'For {{year}} vacation fund will be exhausted by: {{exceeded}}',
                                                {
                                                    year: fund.year,
                                                    exceeded: DateHelper.getMinutesInHumanFormat(
                                                        ((fund.exhausted ?? 0) - (fund.fund ?? 0)) * 60
                                                    )
                                                }
                                            )}
                                        </Typography>
                                    );
                                })}
                            </Box>
                        )}
                    </>
                )
            }
        }
    ];

    if (onlyFields) {
        return (
            <FieldsGenerator
                fields={fields}
                clearErrors={clearErrors}
                control={control}
                fullWidth={fullWidth}
                getFieldState={getFieldState}
                getValues={getValues}
                setValue={setValue}
                initialValues={initialValues}
                watch={watch}
                register={register}
                readOnly={readOnly}
                name="requests"
                shouldUnregister={false}
            />
        );
    }

    return (
        <CuForm
            {...rest}
            maxWidth={'xl'}
            name="userToRequest"
            resource={PermissionsEnum.UserToRequests}
            creatingStatus={creatingStatus}
            updatingStatus={updatingStatus}
            onOpen={handleOpen}
            items={fields}
            onSubmitCreate={handleCreate}
            isValid={isValid}
        />
    );
};

export default UserToRequestForm;
