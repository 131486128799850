import { getSchedulePlanApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppSelector } from '@/data/hooks';
import SchedulePlanApplicationSettingsForm from '@/forms/ApplicationsSettingsForms/SchedulePlanApplicationSettingsForm/SchedulePlanApplicationSettingsForm';
import { StyledApplicationSettingsActionPanel } from '@/forms/ApplicationsSettingsForms/utils';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsSchedulePlanItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsSchedulePlanItemEnum';
import StackInformation from '@/wrappers/StackInformations';
import Switch from '@/wrappers/Switch';

const SchedulePlanApplicationSettingsFormsViewPage = () => {
    const { t } = useAppTranslation();
    const data = useAppSelector(getSchedulePlanApplicationsSettings);

    return (
        <>
            <StyledApplicationSettingsActionPanel>
                <SchedulePlanApplicationSettingsForm />
            </StyledApplicationSettingsActionPanel>
            <StackInformation
                items={[
                    {
                        label: t('label.onHolidayInsertSetShiftAsEmpty', 'On Holiday Insert Set Shift As Empty'),
                        value: (
                            <Switch
                                name="onHolidayInsertSetShiftAsEmpty"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty
                                )}
                            />
                        )
                    },
                    {
                        label: t(
                            'label.dndShiftBetweenDays',
                            'When Drag & Drop Shift between dates move Original Shift into Empty Shift'
                        ),
                        value: (
                            <Switch
                                name="dndShiftBetweenDays"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays
                                )}
                            />
                        )
                    },
                    {
                        label: t('label.canTakeTradeWithoutCounteroffer', 'Can take trade without counteroffer'),
                        value: (
                            <Switch
                                name={'canTakeTradeWithoutCounteroffer'}
                                readOnly={true}
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer
                                )}
                            />
                        )
                    }
                ]}
            />
        </>
    );
};

export default SchedulePlanApplicationSettingsFormsViewPage;
