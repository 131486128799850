import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { ISchedulePlanDayShiftFromBEModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { selectSchedulePlanDayShiftsBySchedulePlanById } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { SkillListValidationModel } from '@/data/Skills/SkillModels';
import { IUserToRequestCUModel } from '@/data/UserToRequests/UserToRequestModels';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    calculatePlan,
    clear,
    create,
    createOrUpdateRequest,
    fetchBreaksSummaries,
    fetchById,
    fetchList,
    fetchOnGoingSchedulePlansList,
    fetchSchedulePlansListForSelect,
    logInToPlan,
    logOutFromPlan,
    modifyNeeds,
    publishSchedulePlan,
    recalculate,
    recalculateWithShifts,
    remove,
    setSchedulePlanStateToClose,
    setSchedulePlanStateToOpen,
    update,
    updateRequirements,
    validateSchedulePlan
} from './SchedulePlanApi';
import { ISchedulePlanCUModel, ISchedulePlanUpdateRequirementsModel } from './SchedulePlanModels';
import {
    isFetchSchedulePlanByIdInProgress,
    isPlanClosed,
    isSchedulePlanFindInProgress,
    schedulePlanPaging,
    schedulePlansForAttendance
} from './SchedulePlanSlice';

export const removeSchedulePlanFromStore = createAction('schedulePlans/cleanSchedulePlan', (args: number) => ({
    payload: args
}));
export const fetchSchedulePlans = createAsyncThunk('schedulePlans/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = schedulePlanPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchOnGoingSchedulePlans = createAsyncThunk('schedulePlans/ongoingList', async () => {
    return await fetchOnGoingSchedulePlansList();
});
export const fetchSchedulePlansForSelect = createAsyncThunk(
    'schedulePlans/select',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSchedulePlansListForSelect(args.search, args.fields || []);
    }
);

export const fetchLogInToPlan = createAsyncThunk(
    'schedulePlans/logIn',
    async (schedulePlanId: string | null, { getState }) => {
        const state = getState() as IRootState;
        const plans = schedulePlansForAttendance(state);

        let planId: string | number | null = schedulePlanId;

        if (planId === null) {
            const plan = plans.find((item) => !item.exists);

            if (!plan) {
                return Promise.reject();
            }

            planId = plan.id;
        }

        return await logInToPlan(planId).then((response) => {
            return {
                schedulePlans: response.attendance_plans.map((plan) => ({
                    ...plan,
                    exists: Boolean(plan.exists)
                })),
                shift: response.schedule_plan_day_shift
            };
        });
    },
    {
        condition(schedulePlanId, { getState }) {
            const state = getState() as IRootState;

            return schedulePlansForAttendance(state).some(
                (plan) => !plan.exists && (schedulePlanId === null || `${plan.id}` === schedulePlanId)
            );
        }
    }
);

export const fetchLogOutFromPlan = createAsyncThunk(
    'schedulePlans/logOut',
    async (schedulePlanId: string | null, { getState }) => {
        const state = getState() as IRootState;
        const plans = schedulePlansForAttendance(state);

        let planId: string | number | null = schedulePlanId;

        if (planId === null) {
            const plan = plans.find((item) => item.exists);

            if (!plan) {
                return Promise.reject();
            }

            planId = plan.id;
        }

        return await logOutFromPlan(planId).then((response) => {
            return {
                schedulePlans: response.attendance_plans.map((plan) => ({
                    ...plan,
                    exists: Boolean(plan.exists)
                })),
                shift: response.schedule_plan_day_shift
            };
        });
    },
    {
        condition(schedulePlanId, { getState }) {
            const state = getState() as IRootState;

            return schedulePlansForAttendance(state).some(
                (plan) => plan.exists !== null && (schedulePlanId === null || `${plan.id}` === schedulePlanId)
            );
        }
    }
);

export const fetchSchedulePlanById = createAsyncThunk(
    'schedulePlans/byId',
    async (args: number) => {
        const response = await fetchById(args);

        return {
            ...response,
            skills: SkillListValidationModel.parse(response.skills) ?? [],
            skills_from_shift: SkillListValidationModel.parse(response.skills_from_shift) ?? []
        };
    },
    {
        condition(arg: number, { getState }) {
            const state = getState() as IRootState;

            return !isFetchSchedulePlanByIdInProgress(state, arg);
        }
    }
);

export const fetchScheduleBreaksSummaries = createAsyncThunk(
    'schedulePlans/breaksSummaries',
    async (args: { period: number; workplace: number; schedulePlan: number }) => await fetchBreaksSummaries(args)
);

export const createSchedulePlan = createAsyncThunk(
    'schedulePlans/create',
    async (args: ISchedulePlanCUModel) => await create(args)
);
export const calculateSchedulePlan = createAsyncThunk(
    'schedulePlans/calculate',
    async (schedulePlanId: number, { dispatch }) => {
        return await calculatePlan(schedulePlanId)
            .then(() =>
                dispatch(
                    addNotification({
                        context: 'message.info.calculatingOfSchedulePlanWithId1IsInProgress',
                        defaultMessage:
                            'Calculating of Schedule Plan with ID {{ID}} is in progress. We will inform you when the plan is ready.',
                        values: { id: schedulePlanId },
                        variant: 'info'
                    })
                )
            )
            .catch((error) =>
                dispatch(
                    addNotification({
                        message: error.message,
                        variant: 'error'
                    })
                )
            );
    }
);

export const updateSchedulePlan = createAsyncThunk(
    'schedulePlans/update',
    (args: { id: number; data: ISchedulePlanCUModel }) => update(args.id, args.data)
);

export const removeSchedulePlan = createAsyncThunk(
    'schedulePlans/remove',
    async (args: { id: number; onSuccess?: () => void }) => {
        await remove(args.id).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);

export const updateRequirementsOnSkill = createAsyncThunk(
    'schedulePlans/updateRequirements',
    async ({
        from,
        to,
        mode,
        ...data
    }: ISchedulePlanUpdateRequirementsModel<DateTimeType> & { mode: ISchedulerCalendarHeaderProps['mode'] }) =>
        await updateRequirements({
            ...data,
            from: DateHelper.formatISO(from),
            to: DateHelper.formatISO(to)
        }).then(async () => await fetchById(data.schedule_plan_id))
);
export const modifyNeedsOfSchedulePlan = createAsyncThunk(
    'schedulePlans/modifyNeeds',
    async ({
        from,
        isPercentage,
        to,
        changeTo,
        schedulePlanId,
        skillIds
    }: {
        from: DateTimeType | null;
        isPercentage: boolean;
        schedulePlanId: number;
        to: DateTimeType | null;
        skillIds: number[];
        changeTo: number;
    }) =>
        await modifyNeeds(schedulePlanId, {
            from: DateHelper.formatISO(from!),
            to: DateHelper.formatISO(to!),
            change_to: changeTo,
            is_percentage: isPercentage,
            skill_ids: skillIds
        }).then(async () => await fetchById(schedulePlanId)),
    {
        condition: ({ changeTo, from, to }) => changeTo !== 100 && from !== null && to !== null
    }
);

export const openSchedulePlan = createAsyncThunk('schedulePlans/open', async (schedulePlanId: number, { dispatch }) => {
    return await setSchedulePlanStateToOpen(schedulePlanId)
        .then((response) => {
            dispatch(
                addNotification({
                    variant: 'success',
                    context: 'message.info.schedulePlanStateChangedTo',
                    defaultMessage: 'Schedule Plan State Changed: {{state}}',
                    values: { state: response.state }
                })
            );

            return response;
        })
        .catch((error) => {
            dispatch(
                addNotification({
                    message: error.message,
                    variant: 'error'
                })
            );

            return Promise.reject(error);
        });
});

export const closeSchedulePlan = createAsyncThunk(
    'schedulePlans/close',
    async (schedulePlanId: number, { dispatch }) => {
        return await setSchedulePlanStateToClose(schedulePlanId)
            .then((response) => {
                dispatch(
                    addNotification({
                        variant: 'success',
                        context: 'message.info.schedulePlanStateChangedTo',
                        defaultMessage: 'Schedule Plan State Changed: {{state}}',
                        values: { state: response.state }
                    })
                );

                return response;
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        message: error.message,
                        variant: 'error'
                    })
                );

                return Promise.reject(error);
            });
    }
);

export const publishPlan = createAsyncThunk('schedulePlans/publish', async (schedulePlanId: number, { dispatch }) => {
    await publishSchedulePlan(schedulePlanId)
        .then((response) => {
            dispatch(
                addNotification({
                    variant: 'success',
                    values: { id: schedulePlanId },
                    context: 'message.info.schedulePlanPublished',
                    defaultMessage: 'Schedule Plan {{id}} Published'
                })
            );

            return response;
        })
        .catch((error) => {
            addNotification({
                variant: 'error',
                values: { error: error?.join(',') ?? '' },
                context: 'message.error.error',
                defaultMessage: '{{error}}'
            });

            return error;
        });

    return await fetchById(schedulePlanId);
});

export const validatePlan = createAsyncThunk(
    'schedulePlans/validate',
    async (schedulePlanId: number, { dispatch }) =>
        await validateSchedulePlan(schedulePlanId)
            .then((response) => {
                dispatch(
                    addNotification({
                        variant: 'success',
                        values: { id: schedulePlanId },
                        context: 'message.info.schedulePlanValidating',
                        defaultMessage: 'Schedule Plan {{id}} Validating'
                    })
                );

                return response;
            })
            .catch((error) => {
                addNotification({
                    variant: 'error',
                    values: { error: error?.join(',') ?? '' },
                    context: 'message.error.error',
                    defaultMessage: '{{error}}'
                });

                return error;
            })
);

export const findSchedulePlan = createAsyncThunk(
    'schedulePlans/find',
    async (args: { workplaceId: number; periodId: number }) =>
        await fetchList({
            limit: 1,
            filters: {
                workplace_id: {
                    values: [args.workplaceId]
                },
                period_id: {
                    values: [args.periodId]
                }
            }
        }).then(({ data }) => data),
    {
        condition(_, { getState }) {
            const state = getState() as IRootState;

            return !isSchedulePlanFindInProgress(state);
        }
    }
);
export const createOrUpdateSchedulePlanDayShiftRequest = createAsyncThunk<
    ArrayElement<Required<ISchedulePlanDayShiftFromBEModel>['schedule_plan_day_shift_requests']>,
    {
        schedulePlanId: number;
        data: IUserToRequestCUModel & {
            schedule_plan_day_shift_id: number;
        };
    }
>('schedulePlans/dayUsers_request', async ({ schedulePlanId, data }, { getState, dispatch }) => {
    const state = getState() as IRootState;

    const isClosed = isPlanClosed(state, schedulePlanId);

    if (isClosed) {
        dispatch(
            addNotification({
                context: 'message.error.schedulePlanIsClosed',
                defaultMessage: 'Schedule Plan is closed',
                variant: 'error'
            })
        );

        return Promise.reject();
    }

    return await createOrUpdateRequest(schedulePlanId, data.schedule_plan_day_shift_id, data).then((ret) => {
        dispatch(
            addNotification({
                context: 'message.success.theRequestForAssignedShiftWasSuccessfullyUpdatedOrCreated',
                defaultMessage: 'The Request for Assigned Shift was successfully updated or created',
                variant: 'success'
            })
        );

        return ret;
    });
});

export const clearSchedulePlans = createAsyncThunk(
    'schedulePlans/clear',
    async ({ schedulePlanId }: { schedulePlanId: number; scheduleId: number }) => {
        return await clear(schedulePlanId);
    }
);

export const recalculateSchedulePlan = createAsyncThunk(
    'schedulePlans/recalculate',
    async (schedulePlanId: number, { dispatch, getState }) => {
        const schedulePlanDayShiftIds: number[] =
            selectSchedulePlanDayShiftsBySchedulePlanById(getState() as IRootState, schedulePlanId)?.map(
                ({ id }) => id
            ) ?? [];

        return await recalculate(schedulePlanId)
            .then((ret) => {
                dispatch(
                    addNotification({
                        variant: 'info',
                        context: 'message.info.schedulePlanIsRecalculating',
                        defaultMessage: 'Schedule Plan {{id}} is recalculating',
                        values: { id: schedulePlanId }
                    })
                );

                return { ...ret, schedulePlanDayShiftIds };
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        message: error.message,
                        variant: 'error'
                    })
                );

                return error;
            });
    }
);

export const recalculateSchedulePlanWithShifts = createAsyncThunk(
    'schedulePlans/recalculateWithShifts',
    async (schedulePlanId: number, { dispatch, getState }) => {
        const schedulePlanDayShiftIds: number[] =
            selectSchedulePlanDayShiftsBySchedulePlanById(getState() as IRootState, schedulePlanId)?.map(
                ({ id }) => id
            ) ?? [];

        return await recalculateWithShifts(schedulePlanId)
            .then((ret) => {
                dispatch(
                    addNotification({
                        variant: 'info',
                        context: 'message.info.schedulePlanIsRecalculatingWithShifts',
                        defaultMessage: 'Schedule Plan {{id}} is recalculating with Shifts',
                        values: { id: schedulePlanId }
                    })
                );

                return { ...ret, schedulePlanDayShiftIds };
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        message: error.message,
                        variant: 'error'
                    })
                );

                return error;
            });
    }
);
