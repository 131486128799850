import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/data/hooks';
import { isSigned as isUserSigned, refreshTokenStatus } from '@/data/System/SystemReducer';
import LoginForm from '@/forms/LoginForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import { ReactComponent as Logo } from '@/images/logoText.svg';
import config from '@/utils/config';
import * as routes from '@/utils/routes';

const StyledContainer = styled(Grid)`
    height: 100vh;
    justify-content: center;
`;
const StyledFormGrid = styled(Grid)(
    ({ theme }) => `
        position: relative;
        padding-top: 10%;
        background: ${theme.palette.background.paper};
        text-align: center;
        overflow: auto;
        height: 100%;
    `
);
const StyledContentGrid = styled(Grid)`
    position: relative;
    overflow: hidden;
`;
const StyledLogo = styled(Logo)`
    max-width: 200px;
    margin: 0 auto 80px;
`;
const StyledFormHeading = styled(Typography)(
    ({ theme }) => `
        margin-bottom: 1rem;
        color: ${theme.palette.text.primary};
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.005em;
    `
);
const StyledForm = styled(LoginForm)`
    width: 70%;
    max-width: 440px;
    margin-inline: auto;
`;
const StyledContent = styled(Box)(
    ({ theme }) => `
        padding-top: 100px;
        padding-inline: 15%;
        position: relative;
        z-index: 1;
        background: ${theme.palette.background.default};
        line-height: 1.2;

        &::before {
            width: 100%;
            height: 20px;
            display: block;
            position: absolute;
            left: 0;
            bottom: -20px;
            content: '';
            background: linear-gradient(${theme.palette.background.default}, transparent);
        }
    `
);
const StyledContentHeading = styled(Typography)`
    font-size: 2.5rem;
    font-weight: 700;
`;
const StyledContentParagraph = styled(Typography)(
    ({ theme }) => `
        max-width: 60ch;
        margin-block: ${theme.spacing(3)};
        color: ${theme.palette.text.secondary};
        font-size: 1.25rem;
    `
);
const StyledContentFounder = styled(Typography)(
    ({ theme }) => `
        font-size: 1.25rem;
        font-weight: 700;

        span {
            color: ${theme.palette.text.secondary}
        }
    `
);
const StyledContentImage = styled('img')(
    ({ theme }) => `
        max-width: 100%;
        max-height: 75vmin;
        position: relative;
        left: 0;
        bottom: 0;
        z-index: 0;
        transform: translate(15%, 10%);
        border-radius: 25px;
        border-top: 10px solid ${theme.palette.text.primary};
        border-left: 10px solid ${theme.palette.text.primary};
    `
);
const StyledFooter = styled('footer')`
    width: 100%;
`;

const LoginPage = () => {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const isSigned = useAppSelector(isUserSigned);
    const checkToken = useAppSelector(refreshTokenStatus);

    useEffect(() => {
        const pathname = window.location.pathname;

        if (isSigned && checkToken !== 'loading' && (pathname === routes.login() || pathname === routes.login(false))) {
            return navigate(routes.mainDashboard());
        }
    }, [isSigned, checkToken]);

    return (
        <StyledContainer container data-testid="login-page">
            <StyledFormGrid item md={4}>
                <Box sx={{ display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Box>
                        <StyledLogo />
                        <StyledFormHeading>
                            {t('message.info.signInToYourAccount', 'Sign in to your account')}
                        </StyledFormHeading>
                        <StyledForm />
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <StyledFooter>{config.version}</StyledFooter>
                    </Box>
                </Box>
            </StyledFormGrid>
            <StyledContentGrid item md={8}>
                <StyledContent>
                    <StyledContentHeading>
                        {t('message.info.loginPageHeading', 'Set Your Scheduling on Auto-Pilot')}
                    </StyledContentHeading>
                    <StyledContentParagraph>
                        {t(
                            'message.info.loginPageParagraph',
                            'Few things make me feel more powerful than setting up automations in Sunrise to make my life easier and more efficient.'
                        )}
                    </StyledContentParagraph>
                    <StyledContentFounder>
                        John Doe
                        <br />
                        <span>{t('message.info.loginPageFounder', 'Founder, CCMasters')}</span>
                    </StyledContentFounder>
                    <StyledContentImage src="/images/background_login.png" alt="" loading="lazy" />
                </StyledContent>
            </StyledContentGrid>
        </StyledContainer>
    );
};

export default LoginPage;
