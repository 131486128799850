import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IRoleCUModel, IRoleModel, IRoleSelectModel } from './RoleModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/roles', paging || {});

    return fetchGet<IListResponseWithPaging<IRoleModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/roles/select', { search, fields });

    return fetchGet<IListResponse<IRoleSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IRoleModel>(`/api/roles/${id}`);
}

export function create(data: IRoleCUModel) {
    return fetchPost<IRoleModel>('/api/roles/', data);
}

export function update(id: number, data: IRoleCUModel) {
    return fetchPatch<IRoleModel>(`/api/roles/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/roles/${id}`);
}
