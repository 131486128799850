import { BoxProps } from '@mui/material/Box';
import { useCallback, useState } from 'react';
import { useAppSelector } from '@/data/hooks';
import { requestById } from '@/data/UserToRequests/UserToRequestSlice';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper from '@/helpers/date/DateHelper';
import { ICellDataType } from '@/helpers/schedule';
import { ISchedulerCellContentProps } from '@/modules/Scheduler/components/SchedulerCalendarCellContent/SchedulerCellConent';
import SchedulerRequestMenu from '@/modules/Scheduler/SchedulerRequestMenu';
import SchedulerRequestTooltip from '../CellContent/SchedulerRequestTooltip';

export type ISchedulerTableCellContentWrapperProps = Omit<BoxProps, 'onClick'> &
    Pick<ISchedulerCellContentProps, 'schedulePlanId' | 'timeZone'> & {
        request: ArrayElement<ICellDataType<Date>['requests']>;
        onLoadingStart?: () => void;
        onLoadingFinished?: () => void;
    };

const CellContentWrapper = ({
    request,
    schedulePlanId,
    timeZone,
    onLoadingStart,
    onLoadingFinished
}: ISchedulerTableCellContentWrapperProps) => {
    const data = useAppSelector((state) => requestById(state, request.id));

    const [disableHoverListener, setDisableHoverListener] = useState(false);

    const handleOpen = useCallback(() => {
        setDisableHoverListener(true);
    }, []);
    const handleCLose = useCallback(() => {
        setDisableHoverListener(false);
    }, []);

    return data ? (
        <SchedulerRequestTooltip
            request={{
                ...data,
                datetime_from: DateHelper.fromDateTimeString(data.datetime_from),
                datetime_to: DateHelper.fromDateTimeString(data.datetime_to),
                state_changed_by_user_at: data.state_changed_by_user_at
                    ? DateHelper.fromDateTimeString(data.state_changed_by_user_at)
                    : undefined
            }}
            disableHoverListener={disableHoverListener}
            timeZone={timeZone}
        >
            <SchedulerRequestMenu
                request={{
                    ...data,
                    datetime_from: DateHelper.fromDateTimeString(data.datetime_from),
                    datetime_to: DateHelper.fromDateTimeString(data.datetime_to),
                    state_changed_by_user_at: data.state_changed_by_user_at
                        ? DateHelper.fromDateTimeString(data.state_changed_by_user_at)
                        : undefined
                }}
                onOpen={handleOpen}
                onClose={handleCLose}
                onLoadingStart={onLoadingStart}
                onLoadingFinished={onLoadingFinished}
                schedulePlanId={schedulePlanId ?? undefined}
                timeZone={timeZone}
            />
        </SchedulerRequestTooltip>
    ) : (
        <></>
    );
};

export default CellContentWrapper;
