import CrudDatatable from '@/components/CrudDatatable';
import { fetchActivities, removeActivity } from '@/data/Activities/ActivityActions';
import { IActivityModel } from '@/data/Activities/ActivityModels';
import {
    activityList,
    activityPaging,
    activityRemovingStatus,
    isActivityListInProgress
} from '@/data/Activities/ActivitySlice';
import { useAppSelector } from '@/data/hooks';
import ActivityForm from '@/forms/ActivityForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

export default function ActivitiesListPage() {
    const inProgress = useAppSelector(isActivityListInProgress);
    const data = useAppSelector(activityList);
    const { t } = useAppTranslation();

    return (
        <LayoutOfPage title={t('title.activitiesList', 'Activities List')}>
            <CrudDatatable<IActivityModel>
                name="activity"
                resource={PermissionsEnum.Activities}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue) => (
                    <ActivityForm displayAsSidebar justIcon={justIcon} id={id} openButtonValue={openButtonValue} />
                )}
                header={[
                    { title: 'Activity name', field: 'name' },
                    {
                        title: 'Created At',
                        type: 'datetime',
                        field: 'created'
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(activityPaging)}
                removingStatus={useAppSelector(activityRemovingStatus)}
                onFetchList={fetchActivities}
                onRemove={removeActivity}
            />
        </LayoutOfPage>
    );
}
