import MuiDialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

type IDialogActions = DialogActionsProps;

const StyledActions = styled(MuiDialogActions)(
    ({ theme }) => `
        padding: ${theme.spacing(2)} ${theme.spacing(3)};
    `
);

const DialogActions = ({ sx, children }: IDialogActions) =>
    children ? <StyledActions sx={sx}>{children}</StyledActions> : null;

export default DialogActions;
