import { Breakpoint } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

export type IDrawerContainerProps = Omit<DrawerProps, 'onClose'> & {
    name?: string;
    maxWidth?: string;
    size?: Breakpoint;
    onClose?: () => void;
};

const StyledContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'size'
})<{ size?: Breakpoint }>(({ theme, size = 'md' }) => {
    const sizes: { [key: string]: string } = {
        xs: '20vw',
        sm: '25vw',
        md: '35vw',
        lg: '45vw',
        xl: '50vw'
    };

    return `
        width: ${sizes[size] || sizes.md};
        max-width: 1000px;
        height: 100%;
        padding: ${theme.spacing(3)} ${theme.spacing(5)};
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(3)};
    `;
});

const Container = ({
    anchor = 'right',
    name = '',
    size = 'md',
    open,
    onClose,
    className,
    ...props
}: IDrawerContainerProps) => {
    const [isOpen, setOpen] = useState(open || false);

    useEffect(() => {
        if (open && !isOpen && onClose) {
            onClose();
        }
    }, [isOpen]);
    useEffect(() => {
        if (open !== isOpen) {
            setOpen(open || false);
        }
    }, [open]);

    return (
        <MuiDrawer {...props} anchor={anchor} open={isOpen} onClose={() => setOpen(false)}>
            <StyledContainer size={size} data-testid={`drawer-${name}`}>
                {props.children}
            </StyledContainer>
        </MuiDrawer>
    );
};

export default Container;
