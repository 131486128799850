enum RoleEnum {
    SystemAdmin = 1,
    Admin,
    TeamLeader,
    Agent,
    PlannerForWorkplace,
    OnlyAttendance
}

export default RoleEnum;
