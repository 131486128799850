import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { defaultPaging, IPaging } from '@/data/Paging';
import { IPeriodModel } from '@/data/Periods/PeriodModels';
import { periodEntities, periodSelectValuesStatus } from '@/data/Periods/PeriodSlice';
import { IRoleModel } from '@/data/Roles/RoleModels';
import { roleEntities } from '@/data/Roles/RoleSlice';
import {
    assignShiftIntoDay,
    assignShiftToSignedUser,
    detachShiftFromDay,
    moveShift,
    updateAssignedShift
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import { updateAssignedShiftStatus } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import { shiftSelectValuesStatus } from '@/data/Shifts/ShiftSlice';
import { agreeWithOfferedShift } from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import { takeThisShiftFromTrade } from '@/data/ShiftTrades/ShiftTradeActions';
import { shiftTradeApprovingStatus } from '@/data/ShiftTrades/ShiftTradeSlice';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { skillEntities, skillSelectValuesStatus } from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import { refreshToken, signIn, signInWithToken } from '@/data/System/SystemActions';
import { fetchUsers } from '@/data/Users/UserActions';
import { IUserExtendedModel } from '@/data/Users/UserModels';
import { isUserByIdInProgress, selectUsers } from '@/data/Users/UserSlice';
import { patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import { IWorkplaceModel } from '@/data/Workplaces/WorkplaceModels';
import {
    isWorkplaceByIdInProgress,
    selectWorkplaces,
    workplaceById,
    workplaceEntities,
    workplaceSelectValuesStatus
} from '@/data/Workplaces/WorkplaceSlice';
import unique from '@/helpers/array/unique';
import PeriodViewEnum from '@/utils/enums/PeriodViewEnum';
import BreakSummariesFilterEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/BreakSummariesFilterEnum';
import {
    calculateSchedulePlan,
    clearSchedulePlans,
    closeSchedulePlan,
    createSchedulePlan,
    fetchLogInToPlan,
    fetchLogOutFromPlan,
    fetchOnGoingSchedulePlans,
    fetchScheduleBreaksSummaries,
    fetchSchedulePlanById,
    fetchSchedulePlans,
    fetchSchedulePlansForSelect,
    findSchedulePlan,
    openSchedulePlan,
    recalculateSchedulePlan,
    recalculateSchedulePlanWithShifts,
    removeSchedulePlan,
    removeSchedulePlanFromStore,
    updateSchedulePlan
} from './SchedulePlanActions';
import { ISchedulePlanBreaksSummaries, ISchedulePlanModel, ISchedulePlanOptModel } from './SchedulePlanModels';

type BreakSummaries = { [key in BreakSummariesFilterEnum]?: { id: string; label: string | undefined } };

const initNewPlanData = {
    name: 'Name of Schedule Plan',
    abbreviation: '',
    description: ''
};

type SchedulePlansReducerState = {
    assignedUsers: { schedulePlanId: number; selected: number[] }[];
    attendancePlans: { exists: boolean; id: number; name: string }[];
    breaksSummariesItems: ISchedulePlanBreaksSummaries;
    onGoingList: ISchedulePlanModel[];
    breakSummariesView: PeriodViewEnum;
    breakSummariesFilters: BreakSummaries;
    paging: IPaging;
    calculatingStatus: IRequestState;
    loadingByIdStatus: { id: number; state: IRequestState }[];
    loadingFindStatus: IRequestState;
    loadingListStatus: IRequestState;
    loadingOnGoingListStatus: IRequestState;
    loadingBreaksSummariesStatus: IRequestState;
    creatingStatus: IRequestState;
    userAssignedShiftStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
    newSchedulePlanData: typeof initNewPlanData;
    recalculatedStatus: IRequestState;
    recalculatedWithShiftsStatus: IRequestState;
};

const initialState: SchedulePlansReducerState = {
    assignedUsers: [],
    attendancePlans: [],
    breaksSummariesItems: {},
    breakSummariesView: PeriodViewEnum.week,
    breakSummariesFilters: {},
    paging: defaultPaging('name'),
    calculatingStatus: 'idle',
    loadingByIdStatus: [],
    onGoingList: [],
    loadingFindStatus: 'idle',
    loadingListStatus: 'idle',
    loadingOnGoingListStatus: 'idle',
    loadingBreaksSummariesStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle',
    userAssignedShiftStatus: 'idle',
    newSchedulePlanData: initNewPlanData,
    recalculatedStatus: 'idle',
    recalculatedWithShiftsStatus: 'idle'
};
const adapter = createEntityAdapter<ISchedulePlanOptModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.created.localeCompare(b.created)
});

const schedulePlansSlice = createSlice({
    name: 'schedulePlans',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        assignUsersToPlan(state, action: PayloadAction<{ schedulePlanId: number; selected: number[] }>) {
            state.assignedUsers = [
                ...state.assignedUsers.filter((item) => item.schedulePlanId !== action.payload.schedulePlanId),
                action.payload
            ];
        },
        setBreakSummariesView(state, action: PayloadAction<{ view: PeriodViewEnum }>) {
            state.breakSummariesView = action.payload.view;
        },
        setBreakSummariesFilters(state, action: PayloadAction<{ filter: BreakSummaries }>) {
            state.breakSummariesFilters = action.payload.filter;
        },
        setNewSchedulePlanData(state, action: PayloadAction<typeof initNewPlanData>) {
            state.newSchedulePlanData = action.payload;
        },
        clearNewSchedulePlanData(state) {
            state.newSchedulePlanData = initNewPlanData;
        },
        invalidate(state, action: PayloadAction<number>) {
            adapter.removeOne(state, action.payload);
            state.loadingByIdStatus = state.loadingByIdStatus.filter((item) => item.id !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlans.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchSchedulePlans.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                state.paging = action.payload.collection;

                const old = Object.values(state.entities).filter(
                    (item) => typeof item?.id === 'number'
                ) as ISchedulePlanOptModel[];

                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((loadingItem) =>
                        action.payload.data.some((dataItem) => dataItem.id === loadingItem.id)
                    )
                ];

                adapter.setAll(
                    state,
                    action.payload.data.map(({ period, workplace, ...plan }) => {
                        const previousPlan = old.find(({ id }) => id === plan.id);

                        return {
                            ...(previousPlan ?? {}),
                            ...plan,
                            body: previousPlan?.body ?? [],
                            next_schedule_plans: previousPlan?.next_schedule_plans ?? [],
                            previous_schedule_plans: previousPlan?.previous_schedule_plans ?? [],
                            roleIds: previousPlan?.skillIds ?? [],
                            skillsFromShift: previousPlan?.skillsFromShift ?? [],
                            skillIds: previousPlan?.skillIds ?? [],
                            table_body: previousPlan?.table_body ?? [],
                            user_ids_with_shift: previousPlan?.user_ids_with_shift ?? []
                        };
                    })
                );
            })
            .addCase(fetchUsers.fulfilled, (state) => {
                state.loadingByIdStatus = [];
            })
            .addCase(fetchSchedulePlans.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchOnGoingSchedulePlans.pending, (state) => {
                state.loadingOnGoingListStatus = 'loading';
            })
            .addCase(fetchOnGoingSchedulePlans.fulfilled, (state, action) => {
                state.loadingOnGoingListStatus = 'idle';
                state.onGoingList = action.payload.data;
            })
            .addCase(fetchOnGoingSchedulePlans.rejected, (state) => {
                state.loadingOnGoingListStatus = 'failed';
            })
            .addCase(fetchSchedulePlansForSelect.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchSchedulePlansForSelect.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';

                const old = Object.values(state.entities).filter(
                    (item) => typeof item?.id === 'number'
                ) as ISchedulePlanOptModel[];

                adapter.upsertMany(
                    state,
                    action.payload.data.map((item) => {
                        const previousPlan = old.find(({ id }) => id === item.id);

                        return {
                            ...(previousPlan ?? {}),
                            ...item,
                            body: previousPlan?.body ?? [],
                            next_schedule_plans: previousPlan?.next_schedule_plans ?? [],
                            previous_schedule_plans: previousPlan?.previous_schedule_plans ?? [],
                            roleIds: previousPlan?.skillIds ?? [],
                            skillsFromShift: previousPlan?.skillsFromShift ?? [],
                            skillIds: previousPlan?.skillIds ?? [],
                            table_body: previousPlan?.table_body ?? [],
                            user_ids_with_shift: previousPlan?.user_ids_with_shift ?? []
                        };
                    })
                );
            })
            .addCase(fetchSchedulePlansForSelect.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(findSchedulePlan.pending, (state) => {
                state.loadingFindStatus = 'loading';
            })
            .addCase(findSchedulePlan.fulfilled, (state) => {
                state.loadingFindStatus = 'idle';
            })
            .addCase(findSchedulePlan.rejected, (state) => {
                state.loadingFindStatus = 'failed';
            })
            .addCase(fetchSchedulePlanById.pending, (state, action) => {
                state.loadingByIdStatus = [...state.loadingByIdStatus, { id: action.meta.arg, state: 'loading' }];
            })
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => item.id !== action.meta.arg),
                    {
                        id: action.meta.arg,
                        state: 'idle'
                    }
                ];

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    breaks,
                    period,
                    roles,
                    shifts,
                    schedule_plan_day_shifts,
                    schedule_plan_days,
                    schedule_plan_validations,
                    skills_from_shift,
                    skills,
                    users,
                    workplace,
                    ...plan
                } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.upsertOne(state, {
                    ...plan,
                    roleIds: roles.map(({ id }) => id),
                    skillsFromShift: skills_from_shift.map(({ id }) => id),
                    skillIds: skills.map(({ id }) => id)
                });
            })
            .addCase(agreeWithOfferedShift.fulfilled, (state, action) => {
                if (action.payload?.plan) {
                    adapter.updateOne(state, {
                        id: action.meta.arg.schedulePlanId,
                        changes: { body: action.payload.plan.body, table_body: action.payload.plan.table_body }
                    });
                }
            })
            .addCase(assignShiftIntoDay.fulfilled, (state, action) => {
                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const { body } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.updateOne(state, { id: action.meta.arg.schedule_plan_id, changes: { body } });
            })
            .addCase(takeThisShiftFromTrade.fulfilled, (state, action) => {
                if (action.meta.arg.schedulePlanId !== null) {
                    adapter.updateOne(state, {
                        id: action.meta.arg.schedulePlanId,
                        changes: { body: action.payload.body }
                    });
                }
            })
            .addCase(patchStateToRequest.fulfilled, (state, action) => {
                if (action.meta.arg.schedulePlanId !== null) {
                    adapter.updateOne(state, {
                        id: action.meta.arg.schedulePlanId,
                        changes: { body: action.payload.body }
                    });
                }
            })
            .addCase(detachShiftFromDay.fulfilled, (state, action) => {
                adapter.updateOne(state, {
                    id: action.meta.arg.schedulePlanId,
                    changes: { body: action.payload.body, table_body: action.payload.table_body }
                });
            })
            .addCase(fetchSchedulePlanById.rejected, (state, action) => {
                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => item.id !== action.meta.arg),
                    {
                        id: action.meta.arg,
                        state: 'failed'
                    }
                ];
            })
            .addCase(fetchScheduleBreaksSummaries.pending, (state) => {
                state.loadingBreaksSummariesStatus = 'loading';
            })
            .addCase(fetchScheduleBreaksSummaries.fulfilled, (state, action) => {
                state.loadingBreaksSummariesStatus = 'idle';
                state.breaksSummariesItems = action.payload;
            })
            .addCase(fetchScheduleBreaksSummaries.rejected, (state) => {
                state.loadingBreaksSummariesStatus = 'failed';
            })
            .addCase(createSchedulePlan.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createSchedulePlan.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                state.newSchedulePlanData = initNewPlanData;

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    period,
                    roles,
                    schedule_plan_day_shifts,
                    schedule_plan_days,
                    schedule_plan_validations,
                    skills_from_shift,
                    skills,
                    workplace,
                    users,
                    ...plan
                } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.addOne(state, {
                    ...plan,
                    roleIds: roles.map(({ id }) => id),
                    skillsFromShift: skills_from_shift.map(({ id }) => id),
                    skillIds: skills.map(({ id }) => id)
                });
            })
            .addCase(createSchedulePlan.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeSchedulePlan.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeSchedulePlan.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(removeSchedulePlan.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateSchedulePlan.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateSchedulePlan.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.meta.arg.data });
            })
            .addCase(updateSchedulePlan.rejected, (state) => {
                state.updatingStatus = 'failed';
            })
            .addCase(calculateSchedulePlan.pending, (state, action) => {
                state.calculatingStatus = 'loading';
                if (state.ids.includes(action.meta.arg)) {
                    const schedulePlan = state.entities[action.meta.arg];

                    adapter.updateOne(state, {
                        id: action.meta.arg,
                        changes: { ...schedulePlan, state: 'Calculating', message: undefined }
                    });
                }
            })
            .addCase(calculateSchedulePlan.fulfilled, (state, action) => {
                state.calculatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg, changes: { state: 'Calculating' } });
            })
            .addCase(calculateSchedulePlan.rejected, (state) => {
                state.calculatingStatus = 'failed';
            })
            .addCase(recalculateSchedulePlan.pending, (state, action) => {
                state.recalculatedStatus = 'loading';
                if (state.ids.includes(action.meta.arg)) {
                    const schedulePlan = state.entities[action.meta.arg];

                    adapter.updateOne(state, {
                        id: action.meta.arg,
                        changes: { ...schedulePlan, state: 'Locked', message: undefined }
                    });
                }
            })
            .addCase(recalculateSchedulePlan.fulfilled, (state, action) => {
                state.recalculatedStatus = 'idle';
                if (state.ids.includes(action.meta.arg)) {
                    const schedulePlan = state.entities[action.meta.arg];

                    adapter.updateOne(state, {
                        id: action.meta.arg,
                        changes: { ...schedulePlan, state: 'Locked', message: undefined }
                    });
                }
            })
            .addCase(recalculateSchedulePlan.rejected, (state) => {
                state.recalculatedStatus = 'failed';
            })
            .addCase(recalculateSchedulePlanWithShifts.pending, (state) => {
                state.recalculatedWithShiftsStatus = 'loading';
            })
            .addCase(recalculateSchedulePlanWithShifts.fulfilled, (state, action) => {
                state.recalculatedWithShiftsStatus = 'idle';
                if (state.ids.includes(action.meta.arg)) {
                    const schedulePlan = state.entities[action.meta.arg];

                    adapter.updateOne(state, {
                        id: action.meta.arg,
                        changes: { ...schedulePlan, state: 'Locked', message: undefined }
                    });
                }
            })
            .addCase(recalculateSchedulePlanWithShifts.rejected, (state) => {
                state.recalculatedWithShiftsStatus = 'failed';
            })
            .addCase(clearSchedulePlans.fulfilled, (state, action) => {
                adapter.removeOne(state, action.meta.arg.schedulePlanId);
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.attendancePlans = action.payload.schedulePlans;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.attendancePlans = action.payload.schedulePlans;
            })
            .addCase(signInWithToken.fulfilled, (state, action) => {
                state.attendancePlans = action.payload.schedulePlans;
            })
            .addCase(fetchLogInToPlan.fulfilled, (state, action) => {
                state.attendancePlans = action.payload.schedulePlans;
            })
            .addCase(fetchLogOutFromPlan.fulfilled, (state, action) => {
                state.attendancePlans = action.payload.schedulePlans;
            })
            .addCase(openSchedulePlan.fulfilled, (state, action) => {
                adapter.updateOne(state, {
                    id: action.meta.arg,
                    changes: {
                        state: action.payload.state,
                        state_changed_by_user: action.payload.state_changed_by_user,
                        state_changed_by_id: action.payload.state_changed_by_id,
                        state_changed_at: action.payload.state_changed_at
                    }
                });
            })
            .addCase(closeSchedulePlan.fulfilled, (state, action) => {
                adapter.updateOne(state, {
                    id: action.meta.arg,
                    changes: { state: action.payload.state }
                });
            })
            .addCase(moveShift.fulfilled, (state, action) => {
                if (action.payload.schedule_plan_id !== null) {
                    adapter.updateOne(state, {
                        id: action.payload.schedule_plan_id,
                        changes: { body: action.payload.body }
                    });
                }
            })
            .addCase(assignShiftToSignedUser.fulfilled, (state, action) => {
                if (action.payload.schedule_plan_id !== null) {
                    adapter.updateOne(state, {
                        id: action.payload.schedule_plan_id,
                        changes: { body: action.payload.body, table_body: action.payload.table_body }
                    });
                }
            })
            .addCase(updateAssignedShift.fulfilled, (state, action) => {
                if (action.payload.new.schedule_plan_id !== null) {
                    adapter.updateOne(state, {
                        id: action.payload.new.schedule_plan_id,
                        changes: { body: action.payload.new.body, table_body: action.payload.new.table_body }
                    });
                }
            })
            .addCase(removeSchedulePlanFromStore, (state, action) => {
                adapter.removeOne(state, action.payload);
                state.loadingByIdStatus = state.loadingByIdStatus.filter((item) => item.id !== action.payload);
            });
    }
});

const getState = (state: IRootState) => state[schedulePlansSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default schedulePlansSlice;

export const isSchedulePlanListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const isSchedulePlanByIdInProgress = (state: IRootState, id: number | null) =>
    getState(state).loadingByIdStatus.some((item) => item.id === id && item.state === 'loading');
export const isSchedulePlanByIdInLoaded = (state: IRootState, id: number | null) =>
    getState(state).loadingByIdStatus.find((item) => item.id === id)?.state === 'idle';
export const isFetchSchedulePlanByIdInProgress = (state: IRootState, id?: number) => {
    const fetchState = getState(state).loadingByIdStatus.find((item) => item.id === id);

    return !!fetchState && fetchState.state !== 'failed';
};

export const isSchedulePlanFindInProgress = (state: IRootState) => getState(state).loadingFindStatus === 'loading';
export const isPlanLoaded = (state: IRootState, id: number | null) =>
    !isSchedulePlanFindInProgress(state) &&
    isSchedulePlanByIdInLoaded(state, id) &&
    !isSchedulePlanByIdInProgress(state, id);
export const isSchedulePlanBreaksSummariesInProgress = (state: IRootState) =>
    getState(state).loadingBreaksSummariesStatus === 'loading';
export const newSchedulePlanData = (state: IRootState) => getState(state).newSchedulePlanData;
export const schedulePlanPaging = (state: IRootState) => getState(state).paging;

const schedulePlanAssignedUsersBySchedulePlan = createSelector(
    (state: IRootState) => getState(state).assignedUsers,
    (assignedUsers) => {
        const result: Record<number, number[]> = {};

        assignedUsers.forEach((assignedUser) => {
            result[assignedUser.schedulePlanId] = assignedUser.selected;
        });

        return result;
    }
);

export const schedulePlanAssignedUsersBySchedulePlanId = (state: IRootState, schedulePlanId: number | null) =>
    schedulePlanId ? schedulePlanAssignedUsersBySchedulePlan(state)[schedulePlanId] ?? null : null;
export const onGoingSchedulePlanList = (state: IRootState) => getState(state).onGoingList;
export const schedulePlanList = adapterSelectors.selectAll;
export const schedulePlanCalculatingStatus = (state: IRootState) => getState(state).calculatingStatus;
export const schedulePlanBreaksSummaries = (state: IRootState) => getState(state).breaksSummariesItems;
export const schedulePlanById = (state: IRootState, id: number | null) =>
    id !== null ? adapterSelectors.selectById(state, id) ?? null : null;
export const schedulePlansForAttendance = (state: IRootState) => getState(state).attendancePlans;
export const schedulePlanCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const schedulePlanUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const schedulePlanRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const findSchedulePlanByPeriodAndWorkplace = (state: IRootState, periodId: number, workplaceId: number) =>
    schedulePlanList(state).find(
        ({ period_id, workplace_id }) => period_id === periodId && workplace_id === workplaceId
    );
export const breakSummariesView = (state: IRootState) => getState(state).breakSummariesView;
export const breakSummariesFilters = (state: IRootState) => getState(state).breakSummariesFilters;
export const getTimeZoneBySchedulePlanId = (state: IRootState, id: number) => {
    const plan = schedulePlanById(state, id);
    const workplace = workplaceById(state, plan?.workplace_id ?? null);

    return workplace?.time_zone ?? null;
};
export const getPreparedBodyOfPlan = (state: IRootState, id: number | null) =>
    schedulePlanById(state, id)?.body ?? null;
export const getPreparedTableBodyOfPlan = (state: IRootState, id: number | null) =>
    schedulePlanById(state, id)?.table_body ?? null;
export const {
    assignUsersToPlan: assignUsersToSchedulePlan,
    invalidate: invalidateSchedulePlan,
    setBreakSummariesView,
    setBreakSummariesFilters,
    clearNewSchedulePlanData,
    setNewSchedulePlanData
} = schedulePlansSlice.actions;
export const schedulePlanAssignedShiftStatus = (state: IRootState) => getState(state).userAssignedShiftStatus;

export const selectSchedulePlanList = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => periodEntities(state),
    (state: IRootState) => workplaceEntities(state),
    (plans, periods, workplaces) => {
        const result: (ISchedulePlanModel & {
            period: IPeriodModel;
            workplace: IWorkplaceModel;
        })[] = [];

        plans.forEach((plan) => {
            const period = periods[plan.period_id];
            const workplace = workplaces[plan.workplace_id];

            if (period && workplace) {
                result.push({
                    ...plan,
                    period,
                    workplace
                });
            }
        });

        return result;
    }
);

export const stateOfPlan = (state: IRootState, id: number | null) => schedulePlanById(state, id)?.state ?? null;
export const isPlanClosed = (state: IRootState, id: number | null) =>
    id ? stateOfPlan(state, id) === SchedulePlanStateEnum.Closed : false;
export const selectPeriodOfPlan = (state: IRootState, id: number | null) => {
    const plan = schedulePlanById(state, id);

    return plan ? periodEntities(state)[plan.period_id] ?? null : null;
};

const selectSkillsUsedByPlan = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => skillEntities(state),
    (plans, skills) => {
        const result: Record<number, { header: ISkillModel[]; table: ISkillModel[] }> = {};

        plans
            .map((plan) => {
                const skillsForHeader = plan.skillIds
                    .map((itemId) => skills[itemId] ?? null)
                    .filter((item) => item !== null) as ISkillModel[];

                return {
                    id: plan.id,
                    skills: skillsForHeader,
                    forTable: unique(
                        [
                            ...skillsForHeader,
                            ...(plan.skillsFromShift
                                .map((itemId) => skills[itemId] ?? null)
                                .filter((item) => item !== null) as ISkillModel[])
                        ],
                        ({ id }) => id
                    )
                };
            })
            .forEach((item) => {
                result[item.id] = {
                    header: item.skills,
                    table: item.forTable
                };
            });

        return result;
    }
);

export const selectOnGoingPlanById = (state: IRootState, id: number | null) =>
    onGoingSchedulePlanList(state).find((item) => item.id == id) ?? null;

export const selectSkillRequirementsByPlanId = (state: IRootState, id: number | null) =>
    id ? selectSkillsUsedByPlan(state)[id]?.header ?? null : null;
export const selectSkillsUsedByPlanId = (state: IRootState, id: number | null) =>
    id ? selectSkillsUsedByPlan(state)[id]?.table ?? null : null;
export const selectRolesUsedByPlan = createSelector(
    schedulePlanById,
    (state: IRootState) => roleEntities(state),
    (plan, roles) => {
        if (plan && plan.roleIds.length > 0) {
            return plan.roleIds.map((itemId) => roles[itemId] ?? null).filter((item) => item !== null) as IRoleModel[];
        }

        return null;
    }
);
export const selectUsersWithShiftByPlan = createSelector(
    schedulePlanById,
    (state: IRootState) => selectUsers(state),
    (plan, users) => {
        const start = Date.now();
        const result = plan
            ? (plan.user_ids_with_shift
                  .map((userId) => users[userId] ?? null)
                  .filter((item) => item !== null) as IUserExtendedModel[])
            : [];

        const time = Date.now() - start;

        if (time > 5) {
            console.warn('selectUsersWithShiftByPlan is slow ', time);
        }

        if (result.length) {
            return result;
        }

        return null;
    }
);
export const selectWorkplaceOfPlan = (state: IRootState, id: number | null) => {
    const plan = schedulePlanById(state, id);

    return plan ? workplaceEntities(state)[plan.workplace_id] ?? null : null;
};
export const selectTimeZoneOfPlan = (state: IRootState, id: number | null) => {
    return selectWorkplaceOfPlan(state, id)?.time_zone ?? null;
};
export const selectUserIdsWithShiftByPlan = (state: IRootState, schedulePlanId: number | null) =>
    schedulePlanById(state, schedulePlanId)?.user_ids_with_shift ?? null;
export const availableUsersInPlan = (state: IRootState, id: number | null) => {
    if (id) {
        const plan = schedulePlanById(state, id);
        const result = plan ? selectWorkplaces(state)[plan.workplace_id]?.user_to_workplaces ?? [] : [];

        return result.length > 0 ? result : null;
    }

    return null;
};

export const selectUsersInPlanWithRequests = createSelector(
    (state: IRootState, schedulePlanId: number | null) => availableUsersInPlan(state, schedulePlanId),
    (usersToWorkplaceExtended) => {
        const result: Omit<IUserExtendedModel, 'user_to_workplaces'>[] = [];

        usersToWorkplaceExtended?.forEach((userToWorkplace) => {
            const user = userToWorkplace.user;

            if (user.user_to_requests.length > 0) {
                result.push(user);
            }
        });

        return result.length > 0 ? result : null;
    }
);

export const availableShiftsInPlan = (state: IRootState, id: number | null) => {
    if (id) {
        const plan = schedulePlanById(state, id);
        const result = plan ? selectWorkplaces(state)[plan.workplace_id]?.shift_to_workplaces ?? [] : [];

        return result.length > 0 ? result : null;
    }

    return null;
};
export const nameOfPlan = (state: IRootState, id: number | null) => schedulePlanById(state, id)?.name ?? null;
export const startOfPlan = (state: IRootState, id: number | null) => {
    return selectPeriodOfPlan(state, id)?.period_start ?? null;
};
export const endOfPlan = (state: IRootState, id: number | null) => {
    return selectPeriodOfPlan(state, id)?.period_end ?? null;
};

export const isInProgressSelector = (state: IRootState, schedulePlanId: number | null) => {
    return (
        ![
            '',
            SchedulePlanStateEnum.Draft,
            SchedulePlanStateEnum.Prepared,
            SchedulePlanStateEnum.Calculated,
            SchedulePlanStateEnum.Error,
            SchedulePlanStateEnum.Closed
        ].includes(stateOfPlan(state, schedulePlanId) ?? '') ||
        workplaceSelectValuesStatus(state) === 'loading' ||
        periodSelectValuesStatus(state) === 'loading' ||
        skillSelectValuesStatus(state) === 'loading' ||
        shiftSelectValuesStatus(state) === 'loading' ||
        shiftTradeApprovingStatus(state) === 'loading' ||
        schedulePlanCalculatingStatus(state) === 'loading' ||
        schedulePlanRemovingStatus(state) === 'loading' ||
        schedulePlanUpdatingStatus(state) === 'loading' ||
        updateAssignedShiftStatus(state) === 'loading' ||
        isWorkplaceByIdInProgress(state) ||
        isSchedulePlanFindInProgress(state) ||
        isSchedulePlanByIdInProgress(state, schedulePlanId) ||
        isUserByIdInProgress(state)
    );
};
