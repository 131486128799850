import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { Ref, useEffect, useImperativeHandle } from 'react';
import { useFieldArray } from 'react-hook-form';
import { TransitionGroup } from 'react-transition-group';
import isEmptyArray from '@/helpers/array/isEmptyArray';
import { ISimpleFieldTypes, ISortMultiRowInputsItems } from './FieldTypes';
import SimpleFieldRenderer, { ISimpleFieldRendererProps } from './SimpleFieldRenderer';
import { getFullNameOfField, getValuesFromProps, ISupportedValueType, IValuesOfSimpleFields } from './utils';

export type ICallableRef = {
    setValues: (values: ISupportedValueType) => void;
};

const StyledTransitionGroup = styled(TransitionGroup)`
    width: 100%;
`;

type IProps = Pick<
    ISimpleFieldRendererProps,
    | 'clearErrors'
    | 'control'
    | 'getFieldState'
    | 'getValues'
    | 'register'
    | 'watch'
    | 'setValue'
    | 'shouldUnregister'
    | 'readOnly'
    | 'fullWidth'
> &
    ISortMultiRowInputsItems & {
        innerRef?: Ref<ICallableRef>;
        fixedSize?: boolean;
        maxNumberOfItems?: number;
        inputs: ISimpleFieldTypes[];
        initialValues: ISupportedValueType;
        name: string;
        disableButtons?: boolean;
        sx?: SxProps<Theme>;
    };

const MultiRowGenerator = ({
    innerRef,
    fixedSize = false,
    disableButtons = false,
    initialValues,
    inputs,
    name,
    shouldUnregister = false,
    sort,
    maxNumberOfItems,
    sx,
    ...rest
}: IProps) => {
    const { fields, append, remove } = useFieldArray({
        control: rest.control,
        name,
        shouldUnregister
    });

    useImperativeHandle(innerRef, () => ({
        setValues: (values) => {
            console.error('Not implemented', values);
        }
    }));

    if (sort && initialValues[name]) {
        sort(initialValues[name] as unknown as IValuesOfSimpleFields[]);
    }

    const defaultsValues = getValuesFromProps(inputs);
    const valuesFromInitial = initialValues[name];

    useEffect(() => {
        if (!fixedSize) {
            if (fields.length === 0 && !isEmptyArray(valuesFromInitial)) {
                append(valuesFromInitial);
            } else if (fields.length === 0) {
                append(defaultsValues);
            }
        }
    }, []);
    useEffect(() => {
        if (fixedSize) {
            rest.setValue(name, valuesFromInitial);
        }
    }, [valuesFromInitial]);

    return (
        <StyledTransitionGroup data-testid={`multiRowForm-${name}`}>
            {fields.map((item, index) => (
                <Collapse key={`${name}-${item.id}`} in={true}>
                    {inputs.map((input) => (
                        <SimpleFieldRenderer
                            {...rest}
                            key={getFullNameOfField(input.props.name, name, index)}
                            input={input}
                            fullName={getFullNameOfField(input.props.name, name, index)}
                            parentName={getFullNameOfField('', name, index)}
                            rowIndex={index}
                            initialValues={{
                                ...initialValues,
                                [name]: fields
                            }}
                        />
                    ))}
                    {fields.length > 1 && !fixedSize && (
                        <SimpleFieldRenderer
                            {...rest}
                            key={`${name}-${index}-remove`}
                            input={{
                                type: 'button',
                                props: {
                                    name: 'remove',
                                    variant: 'text',
                                    children: disableButtons ? undefined : <DeleteIcon spacing={0} />,
                                    color: 'error',
                                    onClick: () => remove(index),
                                    width: 1,
                                    sx: {
                                        p: 1,
                                        justifyContent: 'center'
                                    }
                                }
                            }}
                            fullName={getFullNameOfField('remove', name, index)}
                            initialValues={initialValues}
                        />
                    )}
                    {index + 1 === fields.length &&
                        !fixedSize &&
                        (!maxNumberOfItems || maxNumberOfItems > index + 1) && (
                            <SimpleFieldRenderer
                                {...rest}
                                key={`${name}-${index}-add`}
                                input={{
                                    type: 'button',
                                    props: {
                                        name: 'add',
                                        variant: 'text',
                                        children: disableButtons ? undefined : <AddIcon />,
                                        color: 'primary',
                                        onClick: () => append(defaultsValues),
                                        width: 1,
                                        sx: {
                                            p: 1,
                                            justifyContent: 'center'
                                        }
                                    }
                                }}
                                fullName={getFullNameOfField('add', name, index)}
                                initialValues={initialValues}
                            />
                        )}
                    <Box key={`${name}-newLine-${index}`} sx={{ width: '100%' }} />
                </Collapse>
            ))}
        </StyledTransitionGroup>
    );
};

export default MultiRowGenerator;
