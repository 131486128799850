import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system';
import { IUserModel } from '@/data/Users/UserModels';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';

type IProps = {
    user?: IUserModel;
    day: Date;
    timeZone: string;
};

const StyledSubtitle = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.26);

    p > svg {
        margin-bottom: -6px;
    }

    .MuiDivider-root {
        height: 20px;
        margin-right: 20px;
        margin-left: 20px;
    }
`;

const StyledSubtitlesSkills = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default function SubTitle({ day, timeZone, user }: IProps) {
    const formatter = useLocalizeDateFormatter({ timeZone });

    return (
        <StyledSubtitle>
            {user?.user_to_roles?.map(({ role }) => (
                <Typography key={role.id} data-testid={`subTitle_role_${role.id}`}>
                    <PersonIcon />
                    {role.name}
                </Typography>
            ))}
            <Divider orientation="vertical" />
            <Typography sx={{ color: (theme: Theme) => theme.palette?.success.main }}>
                {formatter.format(day)}
            </Typography>
            <Divider orientation="vertical" />
            <StyledSubtitlesSkills>
                {user?.user_to_skills?.map(({ skill }) => (
                    <Typography key={skill.id} data-testid={`subTitle_skill_${skill.id}`} sx={{ m: 1 }}>
                        {skill.name}
                    </Typography>
                ))}
            </StyledSubtitlesSkills>
        </StyledSubtitle>
    );
}
