import { useCallback } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { createTimeoff, updateTimeoff } from '@/data/Timeoffs/TimeoffActions';
import { ITimeoffModel } from '@/data/Timeoffs/TimeoffModels';
import {
    timeoffById,
    timeoffCreatingStatus,
    timeoffUpdatingStatus,
    updatePaging,
    timeoffPaging
} from '@/data/Timeoffs/TimeoffReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';

type IProps = Omit<ICuProps<ITimeoffModel>, 'resource'>;

const TimeoffForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const loadedData = useAppSelector((state) => timeoffById(state, id));
    const paging = useAppSelector(timeoffPaging);
    const handleData = (values: IOutputValueType) => ({
        abbreviation: (values.abbreviation as IAbbreviationValue).text as string,
        color: (values.abbreviation as IAbbreviationValue).color as string,
        background: (values.abbreviation as IAbbreviationValue).background as string,
        name: values.name as string,
        paid: values.paid as boolean,
        active: values.active as boolean,
        priority: values.priority as number,
        description: values.description as string
    });
    const handleCreate = useCallback((values: IOutputValueType) => createTimeoff(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateTimeoff({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    return (
        <CuForm
            {...props}
            id={id}
            name="timeOff"
            resource={PermissionsEnum.Timeoffs}
            creatingStatus={useAppSelector(timeoffCreatingStatus)}
            updatingStatus={useAppSelector(timeoffUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'active',
                        label: 'Active',
                        value: loadedData?.active ?? true,
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'paid',
                        label: 'Paid',
                        value: loadedData?.paid ?? true,
                        width: 6
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: 'Name',
                        value: loadedData?.name,
                        width: 6,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'priority',
                        type: 'number',
                        label: 'Priority',
                        value: loadedData?.priority,
                        width: 6,
                        InputProps: {
                            inputProps: {
                                min: 1
                            }
                        },
                        validation: {
                            min: {
                                value: 1,
                                message: 'Minimal value is 1.'
                            }
                        }
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        name: 'description',
                        label: 'Description',
                        value: loadedData?.description,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'abbreviation',
                    props: {
                        required: true,
                        name: 'abbreviation',
                        label: {
                            text: t('label.abbreviation', 'Abbreviation'),
                            color: t('label.color', 'Color'),
                            background: t('label.background', 'Background')
                        },
                        value: {
                            text: loadedData?.abbreviation || '',
                            color: loadedData?.color || '',
                            background: loadedData?.background || ''
                        },
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default TimeoffForm;
