import theme from '@/assets/theme';
import { alertItem, unsetAlert } from '@/data/Alert/AlertSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import countTimeout from '@/helpers/countTimeout';
import getResponseStatusVariant from '@/helpers/getResponseStatusVariant';
import useAppTranslation from '@/hooks/useAppTranslation';
import Alert from '@/wrappers/Alert';

const PopupAlert = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const { status, context, defaultMessage, values, type } = useAppSelector(alertItem);

    return (
        <Alert
            open={defaultMessage !== ''}
            severity={status ? getResponseStatusVariant(+status) : type ?? undefined}
            message={
                <>
                    {status && <strong>[{status}] </strong>}
                    {context ? t(context, defaultMessage, values) : defaultMessage}
                </>
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={countTimeout(defaultMessage) < 2 ? 3000 : countTimeout(defaultMessage) * 1000}
            transitionDuration={{ enter: theme.transitions.duration.enteringScreen, exit: 0 }}
            onClose={() => dispatch(unsetAlert())}
            data-testid="mainAlert"
        />
    );
};

export default PopupAlert;
