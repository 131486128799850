import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { fetchFund, getFundForInsertedVacation } from '@/data/UserToVacationFunds/UserToVacationFundApi';

export const fetchUserFund = createAsyncThunk(
    'userToFund/fund',
    async (args: { userId: number; year: number; requestTypeId: number; fund?: number }, { dispatch }) => {
        return await fetchFund(args).then((response) => {
            const fund = args.fund ?? response.data.fund ?? 0;
            const exhausted = response.data.exhausted ?? 0;

            if (fund < exhausted) {
                dispatch(
                    addNotification({
                        context: 'message.warning.theVacationFundForYearIsSmallerThenItHasBeenAlreadyExhausted',
                        defaultMessage:
                            'The Vacation Fund for {{year}} is smaller then it has been already exhausted: {{fund}} / {{exhausted}}',
                        values: { fund: fund, exhausted: exhausted, year: response.data.year },
                        variant: 'warning',
                        autoHideDuration: 6000
                    })
                );
            }

            return response;
        });
    }
);

export const getUsersFundForInsertedVacation = createAsyncThunk(
    'userToFund/calculateVacation',
    async (args: { userId: number; requestTypeId: number; vacationStart: string; vacationEnd: string }) => {
        return await getFundForInsertedVacation(args);
    }
);
