const getDifferenceOfDates = (from: Date, to: Date) => to.getTime() - from.getTime();
const doFloorOfValue = (value: number) => Math.floor(Math.abs(value)) * (value < 0 ? -1 : 1);
const getDifferenceOfDateInMinutes = (from: Date, to: Date) => getDifferenceOfDates(from, to) / (60 * 1000);
const getDifferenceOfDateInHours = (from: Date, to: Date) => getDifferenceOfDateInMinutes(from, to) / 60;
const getDifferenceOfDateAsMinutes = (from: Date, to: Date) => doFloorOfValue(getDifferenceOfDateInMinutes(from, to));
const getDifferenceOfDateAsDays = (from: Date, to: Date) => doFloorOfValue(getDifferenceOfDateInHours(from, to) / 24);

export { getDifferenceOfDateAsMinutes, getDifferenceOfDateAsDays, getDifferenceOfDateInHours };

export default getDifferenceOfDates;
