import { Fragment } from 'react';
import SchedulerCalendarDataRowCell, { ISchedulerCalendarDataRowCellProps } from './SchedulerCalendarDataRowCell';

type DataItem<DATE_TYPE> = {
    uId: string;
    id: number;
    from: DATE_TYPE;
    shiftId: number | null;
    shiftName: string | null;
    paddingRight: boolean;
    to: DATE_TYPE;
    type: 'empty' | 'request' | 'shift' | 'vacation';
    userId: number | null;
    width: number;
};

export type RenderAbleBlock<DATE_TYPE = Date> = {
    uId: string;
    id: number;
    from: DATE_TYPE;
    to: DATE_TYPE;
    toMerge: boolean;
    items: {
        uId: string;
        id: number;
        from: DATE_TYPE;
        to: DATE_TYPE;
        shift_id: number | null;
        shift_name: string | null;
        extra_padding_right: boolean;
        type: 'empty' | 'request' | 'shift' | 'vacation';
        width: number;
        user_id: number | null;
    }[];
};
type BaseColumn<DATA, DATE_TYPE> = {
    uId: string;
    columnStart: number;
    type: 'simple' | 'merged';
    from: DATE_TYPE;
    to: DATE_TYPE;
    width: number;
    data: DATA;
};
export type ISimpleColumn<DATE_TYPE = Date> = BaseColumn<DataItem<DATE_TYPE>, DATE_TYPE> & {
    type: 'simple';
};
export type IMergeRow<DATE_TYPE = Date> = Pick<ISimpleColumn<DATE_TYPE>, 'uId' | 'from' | 'to'> & {
    data: ISimpleColumn<DATE_TYPE>['data'][];
};
export type IMergedColumn<DATE_TYPE = Date> = BaseColumn<IMergeRow<DATE_TYPE>[], DATE_TYPE> & {
    type: 'merged';
};
type ISchedulerTableCellProps = Omit<ISchedulerCalendarDataRowCellProps, 'data'> & {
    columns: (ISimpleColumn | IMergedColumn)[];
};

const SchedulerCalendarDataRow = ({
    from,
    mode,
    columns,
    timeZone,
    isSchedulePlanClosed = false,
    to,
    ...rest
}: ISchedulerTableCellProps) => {
    if (rest.userName === null) {
        return <></>;
    }

    return (
        <>
            {columns.map((column) => (
                <Fragment key={column.uId}>
                    <SchedulerCalendarDataRowCell
                        {...rest}
                        data={column}
                        from={from}
                        to={to}
                        mode={mode}
                        isSchedulePlanClosed={isSchedulePlanClosed}
                        timeZone={timeZone}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default SchedulerCalendarDataRow;
