import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export type IComponentLoaderProps = Pick<CircularProgressProps, 'size'> & {
    inProgress: boolean;
};

const StyledGridContainer = styled(Grid)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    background-color: rgb(255 255 255 / 70%);
    transition:
        visibility 0s,
        opacity 0.75s linear;

    &.inProgress {
        z-index: 10;
        visibility: visible;
    }
`;

export default function ComponentLoader({ children, inProgress, ...rest }: PropsWithChildren<IComponentLoaderProps>) {
    return (
        <>
            {children}
            <StyledGridContainer
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classNames({
                    inProgress
                })}
                data-testid="component-loader"
            >
                <Grid item>
                    <CircularProgress {...rest} disableShrink={true} />
                </Grid>
            </StyledGridContainer>
        </>
    );
}
