import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { shiftEntities } from '@/data/Shifts/ShiftSlice';
import { IRootState } from '@/data/store';
import { createUser, fetchUserById, updateUser } from '@/data/Users/UserActions';
import { IUserToShiftItemsExtendedModel, IUserToShiftItemsModel } from '@/data/UserToShiftItems/UserToShiftItemModels';

const adapter = createEntityAdapter<IUserToShiftItemsModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.id < b.id ? 1 : -1)
});

const userToShiftItemSlice = createSlice({
    name: 'userToShiftItems',
    initialState: adapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                const usersFromWorkplace = action.payload.users;
                const userToShiftItems = usersFromWorkplace
                    .flatMap(
                        ({ user_to_shifts }) =>
                            user_to_shifts?.flatMap(({ user_to_shift_items }) => user_to_shift_items)
                    )
                    .filter((item) => !!item) as IUserToShiftItemsModel[];

                adapter.upsertMany(state, userToShiftItems);
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.user_to_shifts?.flatMap((items) => items.user_to_shift_items) ?? []
                );
            })
            .addCase(createUser.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_shifts?.flatMap((items) => items.user_to_shift_items) ?? []
                );
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (Object.values(state.entities) as IUserToShiftItemsModel[])
                        .filter(
                            (userToShiftItem) =>
                                !action.payload.user_to_shifts?.some((entity) =>
                                    entity.user_to_shift_items.some(
                                        (entityItem) => entityItem.id === userToShiftItem.id
                                    )
                                )
                        )
                        .map(({ id }) => id)
                );
                adapter.upsertMany(
                    state,
                    action.payload.user_to_shifts?.flatMap((items) => items.user_to_shift_items) ?? []
                );
            });
    }
});

const getState = (state: IRootState) => state[userToShiftItemSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userToShiftItemSlice;

export const selectUserToShiftItems = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => shiftEntities(state),
    (state: IRootState) => breakEntities(state),
    (userToShiftItems, shiftsList, breaks): IUserToShiftItemsExtendedModel[] => {
        const result: IUserToShiftItemsExtendedModel[] = [];

        userToShiftItems.forEach((userToShiftItem) => {
            const shift = Object.values(shiftsList).find(
                (shiftEntity) =>
                    shiftEntity?.shift_items.some((shiftItem) => shiftItem.id === userToShiftItem.shift_item_id)
            );

            const shiftItem = shift?.shift_items.find(
                (shiftItemEntity) => shiftItemEntity.id === userToShiftItem.shift_item_id
            );
            const shiftItemBreak = shiftItem?.break_id ? breaks[shiftItem.break_id] : null;

            if (shiftItem && shiftItemBreak) {
                result.push({
                    ...userToShiftItem,
                    shift_item: {
                        ...shiftItem,
                        break: shiftItemBreak
                    }
                });
            }
        });

        return result;
    }
);
