import CrudDatatable from '@/components/CrudDatatable';
import { fetchAgentGroups, removeAgentGroup } from '@/data/AgentGroups/AgentGroupActions';
import { IAgentGroupModel } from '@/data/AgentGroups/AgentGroupModels';
import {
    agentGroupList,
    agentGroupPaging,
    agentGroupRemovingStatus,
    isAgentGroupListInProgress
} from '@/data/AgentGroups/AgentGroupReducer';
import { useAppSelector } from '@/data/hooks';
import AgentGroupForm from '@/forms/AgentGroupForm';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import PageHeading from '@/wrappers/PageHeading';

export default function AgentGroupsListPage() {
    const inProgress = useAppSelector(isAgentGroupListInProgress);
    const data = useAppSelector(agentGroupList);

    return (
        <>
            <PageHeading>Agent Groups</PageHeading>
            <CrudDatatable<IAgentGroupModel>
                name="agentGroup"
                resource={PermissionsEnum.AgentGroups}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue) => (
                    <AgentGroupForm displayAsSidebar justIcon={justIcon} id={id} openButtonValue={openButtonValue} />
                )}
                header={[
                    {
                        title: 'Agent Group Name',
                        field: 'name'
                    },
                    {
                        title: 'Team Leaders',
                        field: 'leaders'
                    },
                    {
                        title: 'Workplace',
                        field: 'place'
                    },
                    {
                        title: 'Employees (min/max)',
                        field: 'employees'
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(agentGroupPaging)}
                removingStatus={useAppSelector(agentGroupRemovingStatus)}
                onFetchList={fetchAgentGroups}
                onRemove={removeAgentGroup}
            />
        </>
    );
}
