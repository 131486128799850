import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import Box from '@/wrappers/Box';

type CollapseSnackbarProps = CustomContentProps & {
    customProps: ICollapseCustomProps;
};

export type ICollapseCustomProps = {
    titleContext: string;
    titleDefaultMessage: string;
    titleValues: { [name: string]: string | number };
    innerMessages: ICollapseCustomInnerMessageProps[];
    variant: 'default' | 'error' | 'success' | 'warning' | 'info';
};

type ICollapseCustomInnerMessageProps = ICollapseCustomInnerMessageAProps | ICollapseCustomInnerMessageBProps;
type ICollapseCustomInnerMessageAProps = {
    context: string;
    defaultMessage: string;
    values?: { [name: string]: string | number };
    message?: undefined;
};
type ICollapseCustomInnerMessageBProps = {
    message: string;
    context?: undefined;
    defaultMessage?: undefined;
    values?: undefined;
};

const StyledInnerMessage = styled(Typography)(() => ({
    // display: 'block'
}));

const StyledTitleBox = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    paddingLeft: '1em',
    gap: 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

const StyledSnackBarContentBox = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const StyledInnerContent = styled(Box)(() => ({
    width: '96%',
    height: '100%',
    marginLeft: '2%'
}));

const StyledPaper = styled(Paper, {
    shouldForwardProp: (props) => !['type'].includes(props as string)
})<{
    type: 'default' | 'error' | 'success' | 'warning' | 'info';
}>(({ theme, type }) => {
    let borderColor: string | undefined;

    switch (type) {
        case 'error':
            borderColor = theme.palette.error.dark;
            break;
        case 'success':
            borderColor = theme.palette.success.main;
            break;
        case 'warning':
            borderColor = theme.palette.warning.main;
            break;
        case 'info':
            borderColor = theme.palette.info.main;
            break;
        case 'default':
        default:
            borderColor = theme.palette.primary.main;
            break;
    }

    return {
        padding: 10,
        paddingBottom: 15,
        marginBottom: -10,
        border: '2px solid',
        borderColor: borderColor,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        maxHeight: '15em',
        overflowX: 'auto',
        ['&::-webkit-scrollbar-thumb']: {
            backgroundColor: borderColor + ' !important',
            borderRadius: `${theme.shape.borderRadius}px`,
            margin: '0 2px'
        },
        ['&::-webkit-scrollbar']: {
            width: '8px',
            height: '8px'
        }
    };
});

const StyledCard = styled(Card, {
    shouldForwardProp: (props) => !['type'].includes(props as string)
})<{
    type: 'default' | 'error' | 'success' | 'warning' | 'info';
}>(({ theme, type }) => {
    let backgroundColor: string | undefined;
    let color: string | undefined;

    switch (type) {
        case 'error':
            backgroundColor = theme.palette.error.dark;
            color = theme.palette.error.contrastText;
            break;
        case 'success':
            backgroundColor = theme.palette.success.main;
            color = theme.palette.success.contrastText;
            break;
        case 'warning':
            backgroundColor = theme.palette.warning.main;
            color = theme.palette.warning.contrastText;
            break;
        case 'info':
            backgroundColor = theme.palette.info.main;
            color = theme.palette.info.contrastText;
            break;
        case 'default':
        default:
            backgroundColor = theme.palette.primary.main;
            color = theme.palette.primary.contrastText;
            break;
    }

    return {
        width: '100%',
        height: '100%',
        minHeight: '3em',
        gap: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: backgroundColor,
        color: color
    };
});

const CollapseSnackbar = forwardRef<HTMLDivElement, CollapseSnackbarProps>(function CollapseSnackbarInner(
    { id, customProps, ...props },
    ref
) {
    const { closeSnackbar } = useSnackbar();
    const { t } = useAppTranslation();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleClose = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    const hasCollapseData = useMemo(() => {
        return customProps.innerMessages?.length > 0;
    }, [customProps]);

    return (
        <SnackbarContent ref={ref} onClick={handleExpandClick}>
            <StyledSnackBarContentBox>
                {hasCollapseData && (
                    <StyledInnerContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <StyledPaper elevation={5} type={customProps.variant}>
                                {customProps.innerMessages.map((innerMessage, key) => {
                                    let message = '';

                                    if (innerMessage.message) {
                                        message = innerMessage.message;
                                    } else if (innerMessage.context && innerMessage.defaultMessage) {
                                        message = t(
                                            innerMessage.context,
                                            innerMessage.defaultMessage,
                                            innerMessage.values
                                        );
                                    }

                                    return (
                                        <StyledInnerMessage key={key} gutterBottom variant="caption">
                                            {message}
                                        </StyledInnerMessage>
                                    );
                                })}
                            </StyledPaper>
                        </Collapse>
                    </StyledInnerContent>
                )}
                <StyledCard type={customProps.variant}>
                    <StyledTitleBox>
                        {props.iconVariant[customProps.variant]}
                        {t(customProps.titleContext, customProps.titleDefaultMessage, customProps.titleValues)}
                    </StyledTitleBox>
                    <CardActions>
                        {hasCollapseData && (
                            <IconButton
                                size="small"
                                onClick={(event) => {
                                    event.stopPropagation();

                                    handleExpandClick();
                                }}
                            >
                                {expanded ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
                            </IconButton>
                        )}
                        <IconButton
                            size="small"
                            onClick={(event) => {
                                event.stopPropagation();

                                handleClose();
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </CardActions>
                </StyledCard>
            </StyledSnackBarContentBox>
        </SnackbarContent>
    );
});

export default CollapseSnackbar;
