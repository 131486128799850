import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { useCallback, useState } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { useAppDispatch } from '@/data/hooks';
import { resetUserPassword } from '@/data/System/SystemActions';
import { IResetPassword } from '@/data/System/UserModel';
import { IUserModel } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';

type IProps = {
    id: number;
    userData: IUserModel;
};

export default function ResetPasswordToUserForm({ id, userData }: IProps) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);
    const handleSubmit = useCallback((values: IOutputValueType) => {
        dispatch(resetUserPassword({ userId: id, password: values.password } as IResetPassword)).then(() => {
            setIsOpen(false);
        });
    }, []);

    return (
        <FormGenerator
            openForm={isOpen}
            onOpen={() => setIsOpen(true)}
            name="resetPasswordToUserForm"
            className="resetPasswordToUserForm"
            title={t('label.resetPasswordToUser', `Reset Password to ${serializeUser(userData)}`, {
                user: serializeUser(userData)
            })}
            displayAsSidebar={true}
            displayAsModal={false}
            justIcon
            icon={<LockResetOutlinedIcon titleAccess={t('label.resetPassword', 'Reset Password')} />}
            fields={[
                {
                    type: 'password',
                    props: {
                        required: true,
                        name: 'password',
                        label: t('label.password', 'Password'),
                        size: 'small'
                    }
                },
                {
                    type: 'password',
                    props: {
                        required: true,
                        name: 'confirmPassword',
                        label: t('label.confirmPassword', 'Confirm password'),
                        size: 'small',
                        confirmName: 'password'
                    }
                }
            ]}
            actions={[
                {
                    type: 'button',
                    props: {
                        type: 'button',
                        name: 'cancel',
                        variant: 'text',
                        onClick: handleCancel,
                        children: t('label.cancel', 'Cancel'),
                        width: 6
                    }
                },
                {
                    type: 'loadingButton',
                    props: {
                        type: 'submit',
                        name: 'reset',
                        variant: 'contained',
                        children: t('label.resetPassword', 'Reset Password'),
                        width: 6
                    }
                }
            ]}
            openButtonValue={t('label.resetPassword', 'Reset Password')}
            onSubmit={handleSubmit}
        />
    );
}
