import Paper from '@mui/material/Paper';
import { useCallback, useEffect } from 'react';
import { FieldWrapper, IValuesOfSimpleFields, IValueType } from '@/base/FormGenerator';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { fetchBreaksForSelect } from '@/data/Breaks/BreakActions';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchGooglesSettings } from '@/data/SettingsItems/SettingsItemActions';
import { isGoogleEnabled } from '@/data/SettingsItems/SettingsItemSlice';
import { IShiftToWorkplaceModel } from '@/data/ShiftToWorkplaces/ShiftToWorkplaceModels';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { usersForSelect } from '@/data/Users/UserSlice';
import { IUserToWorkplaceModel } from '@/data/UserToWorkplaces/UserToWorkplaceModels';
import { createWorkplace, fetchWorkplaceById, updateWorkplace } from '@/data/Workplaces/WorkplaceActions';
import { IWorkplaceCUModel, IWorkplaceModel, IWorkplaceWorkingHoursCUModel } from '@/data/Workplaces/WorkplaceModels';
import {
    selectWorkplaceById,
    updatePaging,
    workplaceCreatingStatus,
    workplacePaging,
    workplaceUpdatingStatus
} from '@/data/Workplaces/WorkplaceSlice';
import Employees from '@/forms/WorkplaceForm/Employees';
import Forecasts from '@/forms/WorkplaceForm/Forecasts/Forecasts';
import Googles from '@/forms/WorkplaceForm/Googles';
import Queues from '@/forms/WorkplaceForm/Queues';
import Settings from '@/forms/WorkplaceForm/Settings';
import Shifts from '@/forms/WorkplaceForm/Shifts';
import list from '@/helpers/countries/list';
import DateHelper from '@/helpers/date/DateHelper';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import WorkplaceAlgorithmTypeEnum from '@/utils/enums/WorkplaceAlgorithmTypeEnum';
import { message, regex } from '@/utils/validations';
import { ITimePickerProps } from '@/wrappers/TimePicker';
import { ITimeRangeOrTimeAndLengthProps } from '@/wrappers/TimeRangeOrTimeAndLength';

type IProps = Omit<ICuProps<IWorkplaceModel>, 'resource'>;

const WorkplaceForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => selectWorkplaceById(state, id ?? null));
    const googleEnabled = useAppSelector(isGoogleEnabled);
    const usersForSelectData = useAppSelector(usersForSelect);
    const paging = useAppSelector(workplacePaging);
    const countries = list;

    useEffect(() => {
        dispatch(fetchBreaksForSelect({ search: '' }));
    }, []);

    const handleData = (values: IOutputValueType): IWorkplaceCUModel => {
        const nightTimeStartEnd = values?.nightTimeStartEnd as ITimeRangeOrTimeAndLengthProps['value'];
        const employees = values.employees as { administrators: string[]; employees: string[] };
        const settings = values.settings as { [name: string]: IValueType };

        return {
            administrators: employees.administrators.map((value) => parseInt(value)),
            algorithm_type: values.algorithm_type as string,
            allowed_shift: (values.allowed_shift as string[]).map((value) => parseInt(value)),
            allowed_shifts_for_calculation: (values.allowed_shifts_for_calculation as string[]).map((value) =>
                parseInt(value)
            ),
            after_night_shift: settings?.after_night_shift as number,
            automatic_approve_trade: settings?.automatic_approve_trade as boolean,
            automatic_approve_trade_level_of_competence_difference:
                settings?.automatic_approve_trade === true
                    ? parseInt(`${settings?.automatic_approve_trade_level_of_competence_difference}`)
                    : null,
            before_night_shift: settings?.before_night_shift as number,
            calendar_id: values?.calendarId ?? null,
            country_name: (values?.country_name ?? '') as string,
            disable_empty_shifts: settings?.disable_empty_shifts as boolean,
            disabled_validations: ((values?.selectedDisabledValidations ?? []) as string[]).map((value) => ({
                validation: value
            })),
            employees: employees.employees.map((value) => parseInt(value)),
            enable_work_from_home: settings.enable_work_from_home as boolean,
            external_connection_id: settings.external_connection_id as string,
            choose_breaks_to_synchronize: values?.synchronizeBreaks
                ? values?.chooseBreaksToSynchronize ?? false
                : false,
            location: values.location,
            name: values.name,
            night_time_duration: nightTimeStartEnd?.duration ?? null,
            night_time_start: nightTimeStartEnd?.start ? DateHelper.formatTime(nightTimeStartEnd?.start) : null,
            number_of_seats_max: settings?.enable_work_from_home ? (settings.number_of_seats_max as number) : 0,
            number_of_seats_min: settings?.enable_work_from_home ? (settings.number_of_seats_min as number) : 0,
            queues: (values.queues as string[]).map((value) => parseInt(value)),
            remind_time: settings?.remind_time
                ? DateHelper.formatTimeFromDateTime(values?.remind_time as ITimePickerProps['value'])
                : null,
            start_day_of_emergency: values.start_day_of_emergency as number,
            selected_breaks_to_synchronized:
                values?.synchronizeBreaks && values?.chooseBreaksToSynchronize
                    ? values?.selectedBreaksToSynchronized ?? []
                    : [],
            shift_distance: settings?.shift_distance as number,
            show_attendees_name_in_title: values?.showAttendeesNameInTitle ?? false,
            show_attendees_skill_in_name_title: values?.showAttendeesNameInTitle
                ? values?.showAttendeesSkillInNameTitle ?? false
                : false,
            synchronize_breaks: values?.synchronizeBreaks ?? false,
            synchronize_schedules: values?.synchronizeSchedules ?? false,
            time_zone: values.time_zone,
            trade_compare_level_of_competence: settings?.trade_compare_level_of_competence as boolean,
            trade_compare_level_of_competence_difference:
                settings?.trade_compare_level_of_competence_difference as number,
            work_on_holiday: values.work_on_holiday as boolean,
            working_hours: (
                values.days_and_working_hours as IValuesOfSimpleFields[]
            ).map<IWorkplaceWorkingHoursCUModel>((item) => ({
                days: item.days as number,
                start: DateHelper.formatTime((item.working_hours as ITimeRangeOrTimeAndLengthProps['value'])?.start),
                duration: (item.working_hours as ITimeRangeOrTimeAndLengthProps['value'])?.duration ?? 0
            }))
        } as Partial<IWorkplaceCUModel> as IWorkplaceCUModel;
    };
    const handleCreate = useCallback((values: IOutputValueType) => {
        return createWorkplace(handleData(values));
    }, []);

    const handleUpdate = useCallback(
        (values: IOutputValueType) => {
            return updateWorkplace({ id: id!, data: handleData(values) });
        },
        [id]
    );

    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);
    const handleOpen = useCallback(() => {
        dispatch(fetchGooglesSettings());
        dispatch(fetchUsersForSelect({ search: '' }));
        if (id) {
            dispatch(fetchWorkplaceById(id));
        }
    }, [id]);

    const getFilteredUsers = (type: IUserToWorkplaceModel['type']): string[] =>
        loadedData?.user_to_workplaces
            ?.filter((joinEntity) => joinEntity.type === type)
            .map(({ user_id }) => user_id.toString()) ?? [];

    const getFilteredShifts = (type: IShiftToWorkplaceModel['type']): string[] =>
        loadedData?.shift_to_workplaces
            ?.filter((joinEntity) => joinEntity.type === type)
            .map(({ shift_id }) => shift_id.toString()) ?? [];

    const translatedDays = useTranslatedDays();

    const algorithmOptions = Object.keys(WorkplaceAlgorithmTypeEnum).map((key) => ({
        id: WorkplaceAlgorithmTypeEnum[key as keyof typeof WorkplaceAlgorithmTypeEnum],
        label: t(`enums.workplaceAlgorithmTypeEnum.${formatPascalToCamel(key)}`, key)
    }));

    return (
        <CuForm
            {...props}
            id={id}
            name="workplace"
            resource={PermissionsEnum.Workplaces}
            maxWidth="lg"
            creatingStatus={useAppSelector(workplaceCreatingStatus)}
            updatingStatus={useAppSelector(workplaceUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name,
                        width: 6,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'location',
                        label: t('label.location', 'Location'),
                        value: loadedData?.location,
                        width: 6,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'algorithm_type',
                        label: t('label.algorithmType', 'Type of generating Shifts'),
                        value: loadedData?.algorithm_type,
                        options: algorithmOptions,
                        validation: {
                            deps: 'start_day_of_emergency'
                        },
                        width: 6
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'country_name',
                        label: t('label.country', 'Country'),
                        value: loadedData?.country_name,
                        options: countries.map((item) => ({
                            id: item.name,
                            label: item.title
                        })),
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'work_on_holiday',
                        label: t('label.workOnHoliday', 'Work On Holiday'),
                        value: loadedData?.work_on_holiday ?? true,
                        width: 4
                    }
                },
                {
                    type: 'select',
                    display: ({ algorithm_type }) => algorithm_type === WorkplaceAlgorithmTypeEnum.Recurring,
                    props: {
                        name: 'start_day_of_emergency',
                        label: t('label.startOfEmergency', 'Start of emergency'),
                        value: loadedData?.start_day_of_emergency,
                        options: translatedDays.map((item) => ({
                            id: `${item.id}`,
                            label: item.name
                        })),
                        validation: {
                            deps: 'algorithm_type'
                        },
                        width: 4
                    }
                },
                {
                    type: 'multiRowInputs',
                    props: {
                        name: 'days_and_working_hours',
                        value: loadedData?.workplace_working_hours?.map((item) => ({
                            days: item.days,
                            working_hours: {
                                start: DateHelper.fromTimeString(item.start),
                                duration: item.duration
                            }
                        })),
                        inputs: [
                            {
                                type: 'weekdays',
                                props: {
                                    required: true,
                                    name: 'days',
                                    multiple: true,
                                    width: 12,
                                    sx: { alignItems: 'center' }
                                }
                            },
                            {
                                type: 'timeRangeOrTimeAndLength',
                                props: {
                                    name: 'working_hours',
                                    label: {
                                        start: t('label.workingHoursStart', 'Working Hours Start'),
                                        end: t('label.workingHoursEnd', 'Working Hours End'),
                                        length: t('label.length', 'Length'),
                                        range: t('label.range', 'Range')
                                    },
                                    required: true,
                                    minutesStep: 15,
                                    width: 10
                                }
                            }
                        ]
                    }
                },
                {
                    type: 'tabs',
                    props: {
                        name: 'tabs',
                        value: 'employees',
                        tabsContainer: Paper,
                        tabs: [
                            {
                                name: 'employees',
                                label: t('subTitle.employees', 'Employees'),
                                inputs: [
                                    {
                                        type: 'custom',
                                        props: {
                                            name: 'employees',
                                            values: {
                                                'employees.administrators': getFilteredUsers('admin'),
                                                'employees.employees': getFilteredUsers('user')
                                            },
                                            render: ({ initialValues, setValue, watch }) => (
                                                <FieldWrapper type="custom" fullWidth={true} isDisplayed={true}>
                                                    <Employees
                                                        selectedEmployees={
                                                            watch('employees.employees') ??
                                                            initialValues.employees ??
                                                            []
                                                        }
                                                        selectedAdministrators={
                                                            watch('employees.administrators') ??
                                                            initialValues.administrators ??
                                                            []
                                                        }
                                                        onChangeEmployees={(newValue) =>
                                                            setValue('employees.employees', newValue)
                                                        }
                                                        onChangeAdministrators={(newValue) =>
                                                            setValue('employees.administrators', newValue)
                                                        }
                                                        users={usersForSelectData}
                                                    />
                                                </FieldWrapper>
                                            )
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'queues',
                                label: t('subTitle.queues', 'Queues'),
                                inputs: [
                                    {
                                        type: 'custom',
                                        props: {
                                            name: 'queues',
                                            values: {
                                                queues:
                                                    loadedData?.queue_to_workplaces?.map(({ queue_id }) =>
                                                        queue_id.toString()
                                                    ) ?? []
                                            },
                                            render: ({ initialValues, getValues, setValue }) => (
                                                <FieldWrapper type="custom" fullWidth={true} isDisplayed={true}>
                                                    <Queues
                                                        selectedQueues={getValues('queues') ?? initialValues.queues}
                                                        onChange={(newValue) => setValue('queues', newValue)}
                                                    />
                                                </FieldWrapper>
                                            )
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'shifts',
                                label: t('subTitle.shifts', 'Shifts'),
                                inputs: [
                                    {
                                        type: 'custom',
                                        props: {
                                            name: 'shifts',
                                            values: {
                                                allowed_shift: getFilteredShifts('allowed_shift'),
                                                allowed_shifts_for_calculation: getFilteredShifts(
                                                    'allowed_shifts_for_calculation'
                                                )
                                            },
                                            render: ({ initialValues, setValue, watch }) => (
                                                <FieldWrapper type="custom" fullWidth={true} isDisplayed={true}>
                                                    <Shifts
                                                        selectedAllowedShifts={
                                                            watch('allowed_shift') ?? initialValues.allowed_shift
                                                        }
                                                        selectedAllowedShiftsForCalculation={
                                                            watch('allowed_shifts_for_calculation') ??
                                                            initialValues.allowed_shifts_for_calculation
                                                        }
                                                        onChangeAllowedShifts={(newValue) =>
                                                            setValue('allowed_shift', newValue)
                                                        }
                                                        onChangeAllowedShiftsForCalculation={(newValue) =>
                                                            setValue('allowed_shifts_for_calculation', newValue)
                                                        }
                                                    />
                                                </FieldWrapper>
                                            )
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'google',
                                label: t('subTitle.google', 'Google'),
                                display: () => googleEnabled,
                                inputs: [
                                    {
                                        type: 'custom',
                                        props: {
                                            name: 'google',
                                            values: {
                                                calendarId: loadedData?.calendar_id ?? '',
                                                synchronizeSchedules: loadedData?.synchronize_schedules ?? false,
                                                synchronizeBreaks: loadedData?.synchronize_breaks ?? false,
                                                chooseBreaksToSynchronize:
                                                    loadedData?.choose_breaks_to_synchronize ?? false,
                                                selectedBreaksToSynchronized:
                                                    loadedData?.workplaces_breaks_to_synchronized_with_google?.map(
                                                        ({ break_id }) => break_id.toString()
                                                    ) ?? [],
                                                showAttendeesNameInTitle:
                                                    loadedData?.show_attendees_name_in_title ?? false,
                                                showAttendeesSkillInNameTitle:
                                                    loadedData?.show_attendees_skill_in_name_title ?? false
                                            },
                                            render: (args) => (
                                                <FieldWrapper type="custom" fullWidth={true} isDisplayed={true}>
                                                    <Googles key={'workplace_google'} {...args} fullWidth />
                                                </FieldWrapper>
                                            )
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'forecast',
                                label: t('label.forecast', 'Forecast'),
                                inputs: [
                                    {
                                        type: 'custom',
                                        props: {
                                            name: 'forecast',
                                            values: {
                                                nightTimeStartEnd: {
                                                    start: DateHelper.fromOptionalTime(loadedData?.night_time_start),
                                                    duration: loadedData?.night_time_duration ?? null,
                                                    isRange: false
                                                },
                                                selectedDisabledValidations:
                                                    loadedData?.workplace_disabled_validations?.map(({ validation }) =>
                                                        validation.toString()
                                                    ) ?? []
                                            },
                                            render: (args) => (
                                                <FieldWrapper type="custom" fullWidth={true} isDisplayed={true}>
                                                    <Forecasts key={'workplace_forecast'} {...args} fullWidth />
                                                </FieldWrapper>
                                            )
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'settings',
                                label: t('label.settings', 'Settings'),
                                inputs: [
                                    {
                                        type: 'custom',
                                        props: {
                                            name: 'settings',
                                            values: {
                                                'settings.after_night_shift': loadedData?.after_night_shift,
                                                'settings.automatic_approve_trade': loadedData?.automatic_approve_trade,
                                                'settings.automatic_approve_trade_level_of_competence_difference':
                                                    loadedData?.automatic_approve_trade_level_of_competence_difference,
                                                'settings.before_night_shift': loadedData?.before_night_shift,
                                                'settings.disable_empty_shifts':
                                                    loadedData?.disable_empty_shifts ?? false,
                                                'settings.enable_work_from_home': loadedData?.enable_work_from_home,
                                                'settings.external_connection_id': loadedData?.external_connection_id,
                                                'settings.number_of_seats_min': loadedData?.number_of_seats_min,
                                                'settings.number_of_seats_max': loadedData?.number_of_seats_max,
                                                'settings.remind_time': loadedData?.remind_time
                                                    ? DateHelper.fromTimeStringToTimePickerHack(loadedData?.remind_time)
                                                    : null,
                                                'settings.shift_distance': loadedData?.shift_distance,
                                                'settings.trade_compare_level_of_competence_difference':
                                                    loadedData?.trade_compare_level_of_competence_difference,
                                                'settings.trade_compare_level_of_competence':
                                                    loadedData?.trade_compare_level_of_competence ?? false
                                            },
                                            render: (args) => (
                                                <FieldWrapper type="custom" fullWidth={true} isDisplayed={true}>
                                                    <Settings key={'workplace_settings'} {...args} fullWidth />
                                                </FieldWrapper>
                                            )
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                }
            ]}
            onOpen={handleOpen}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default WorkplaceForm;
