import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { forwardRef, SyntheticEvent } from 'react';
import useIcons from '@/hooks/useIcons';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';

export type IIconPickerOption = {
    id: string;
    label: string;
};

export type IIconPickerProps = Omit<
    MuiAutocompleteProps<IIconPickerOption, false, true, false>,
    | 'multiple'
    | 'name'
    | 'getOptionLabel'
    | 'getOptionId'
    | 'renderInput'
    | 'renderOption'
    | 'onChange'
    | 'defaultValue'
    | 'options'
    | 'value'
> &
    Pick<ITextFieldProps, 'name' | 'label' | 'disabled' | 'readOnly' | 'required'> & {
        value?: string;
        error?: boolean;
        helperText?: string;
        disabledInput?: boolean;
        onChange?: (selected: string | null, event?: SyntheticEvent) => void;
    };

const StyledItem = styled(Box<'li'>)(
    ({ theme }) => `
        line-height: ${theme.spacing(2)};
    `
);
const StyledIcon = styled(Icon)(
    ({ theme }) => `
        margin-right: ${theme.spacing(1)};
    `
);

const IconPicker = forwardRef<HTMLDivElement, IIconPickerProps>(function Inner(
    { name, label, value, disabled, required, size = 'small', error, helperText, onChange, ...props },
    ref
) {
    const icons = useIcons();

    return (
        <Autocomplete
            disableClearable
            value={icons.find((icon) => icon.label === value) || { id: '', label: '' }}
            autoHighlight
            size={size}
            getOptionLabel={(option) => option.label}
            renderOption={(renderProps, option) => (
                <StyledItem {...renderProps} component="li" data-testid="select-item">
                    <StyledIcon color="action">{option.id}</StyledIcon> {option.label}
                </StyledItem>
            )}
            disabled={disabled}
            options={icons}
            isOptionEqualToValue={(option, selected) => option.label === selected.label}
            renderInput={(params) => (
                <TextField
                    {...params}
                    ref={ref}
                    name={name}
                    label={label}
                    disabled={!!props.disabledInput || disabled}
                    required={required}
                    error={error}
                    helperText={helperText}
                />
            )}
            onChange={(event, selected) => onChange && onChange(selected?.label ?? null, event)}
        />
    );
});

export default IconPicker;
