import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import {
    getPredictedTimeSliderValue,
    isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading,
    setPredictedTimeSliderValue
} from '@/data/Reports/ReportSlice';
import RoleEnum from '@/data/System/RoleEnum';
import { hasRole } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';

const ReportSchedulePlanSlider = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const isPredictedTimeDataLoading = useAppSelector(isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading);
    const predictedTimeSliderValue = useAppSelector(getPredictedTimeSliderValue);
    const isSignedUserSystemAdmin = useAppSelector((state) => hasRole(state, RoleEnum.SystemAdmin));

    return !isPredictedTimeDataLoading && isSignedUserSystemAdmin ? (
        <Box
            sx={{
                height: 'auto',
                width: '90%',
                paddingTop: '4em',
                margin: 'auto'
            }}
        >
            <Typography variant="subtitle1" gutterBottom>
                {t('label.predictionBoost', 'Prediction Boost')}
            </Typography>
            <Slider
                size="small"
                value={predictedTimeSliderValue}
                valueLabelDisplay="auto"
                marks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((itemValue) => ({
                    value: itemValue,
                    label: `${itemValue}%`
                }))}
                valueLabelFormat={(value) => <>{value}</>}
                onChange={(_, value: number | number[]) => {
                    if (typeof value === 'number') {
                        dispatch(setPredictedTimeSliderValue({ value }));
                    }
                }}
                step={5}
                min={0}
                max={100}
            />
        </Box>
    ) : (
        <></>
    );
};

export default ReportSchedulePlanSlider;
