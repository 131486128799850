import MuiLoadingButton, { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton';

export type ILoadingButtonProps = Omit<MuiLoadingButtonProps, 'onClick' | 'ref' | 'name' | 'onChange'> & {
    onClick?: () => void;
    name: string;
    onChange?: () => void | MuiLoadingButtonProps['onChange'];
};

function LoadingButton(props: ILoadingButtonProps) {
    const { onClick = () => {}, ...rest } = props;

    return <MuiLoadingButton data-testid={`loadingButton-${props.name}`} {...rest} onClick={() => onClick()} />;
}

LoadingButton.defaultProps = {
    type: 'button',
    variant: 'contained',
    color: 'primary'
};

export default LoadingButton;
