import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './BreakApi';
import { IBreaksCUModel } from './BreakModels';

export const fetchBreaks = createAsyncThunk('breaks/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = (thunkAPI.getState() as IRootState).breaks.paging;

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchBreaksForSelect = createAsyncThunk(
    'breaks/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (state.breaks.loadingForSelectStatus !== 'idle' || state.breaks.selectItems.length) {
                return false;
            }
        }
    }
);

export const fetchBreakById = createAsyncThunk('breaks/byId', async (args: number) => await fetchById(args));

export const createBreak = createAsyncThunk('breaks/create', async (args: IBreaksCUModel) => await create(args));

export const updateBreak = createAsyncThunk(
    'breaks/update',
    async (args: { id: number; data: Partial<IBreaksCUModel> }) => await update(args.id, args.data)
);

export const removeBreak = createAsyncThunk('breaks/remove', async (args: { id: number; onSuccess?: () => void }) => {
    await remove(args.id).then(() => args.onSuccess && args.onSuccess());

    return args.id;
});
