import version from '@/utils/version';

const appLocation = import.meta.env.PUBLIC_URL || '';
const nodeEnv = import.meta.env.NODE_ENV || 'production';

const loadConfig = () => ({
    appLocation,
    nodeEnv,
    version
});

export default loadConfig;
