import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { ISkillCUModel, ISkillModel, ISkillSelectModel } from './SkillModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/skills', paging || {});

    return fetchGet<IListResponseWithPaging<ISkillModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/skills/select', { search, fields });

    return fetchGet<IListResponse<ISkillSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<ISkillModel>(`/api/skills/${id}`);
}

export function create(data: ISkillCUModel) {
    return fetchPost<ISkillModel>('/api/skills/', data);
}

export function update(id: number, data: ISkillCUModel) {
    return fetchPatch<ISkillModel>(`/api/skills/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/skills/${id}`);
}
