import { i18n as i18nType } from 'i18next';
import { useTranslation } from 'react-i18next';

const useAppTranslation: () => {
    t: (key: string, defaultValue: string, options?: { [name: string]: string | number }) => string;
    i18n: i18nType;
    ready: boolean;
} = useTranslation;

export default useAppTranslation;
