import { FileUpload, ICallable } from '@connect-soft/react-file-upload';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { lighten, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { fetchUploadCsvFilesTempleData } from '@/data/Queues/QueueActions';
import { IQueueModel } from '@/data/Queues/QueueModels';
import { isQueueCsvFileTempleDataInProgress, queueCsvFileTempleData } from '@/data/Queues/QueueSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import Datatable from '@/wrappers/Datatable';

export type IProps = {
    queue?: IQueueModel;
    onSubmit?: () => void;
};

const StyledInputField = styled(TextField)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '5px',
    ['input::file-selector-button']: {
        border: 'none',
        background: theme.palette.primary.main,
        marginRight: '20px',
        marginTop: '-10px',
        borderRadius: '10px',
        color: lighten(theme.palette.primary.main, 0.75),
        padding: '10px 20px',
        cursor: 'pointer'
    }
}));

const UploadCsv = forwardRef<ICallable, IProps>(function UploadCsvInner({ queue, onSubmit }, ref) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState(false);
    const token = localStorage.getItem('token');
    const data = useAppSelector(queueCsvFileTempleData);
    const fileRef = useRef<ICallable>(null);

    useImperativeHandle(
        ref,
        () => ({
            sendFiles: () => {
                setInProgress(true);
                fileRef?.current?.sendFiles();
            }
        }),
        [fileRef.current]
    );

    useEffect(() => {
        dispatch(fetchUploadCsvFilesTempleData());
    }, []);

    const handleOnUploaded = useCallback((status: 'success' | 'error') => {
        if (status === 'success') {
            dispatch(
                addNotification({
                    variant: 'success',
                    context: 'message.success.csvFileToQueueUploaded',
                    defaultMessage: 'The CSV file was successfully uploaded to queue {{queueName}}',
                    values: { queueName: queue?.name ?? '' }
                })
            );

            if (onSubmit) {
                onSubmit();
            }
        } else {
            dispatch(
                addNotification({
                    variant: 'error',
                    context: 'message.error.somethingWentWrong',
                    defaultMessage: 'Something went wrong'
                })
            );
        }

        setInProgress(false);
    }, []);

    return (
        <Box component="form">
            {inProgress && (
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        top: '4em',
                        marginTop: '-4em',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 5
                    }}
                >
                    <Skeleton
                        sx={{ bgcolor: 'rgba(245,245,245,0.80)' }}
                        animation="wave"
                        variant="rounded"
                        width="100%"
                        height="4em"
                    />
                </Box>
            )}
            <FileUpload
                apiPath={`/api/queues/uploadCsv/${queue?.id}`}
                slots={{
                    input: (
                        <StyledInputField
                            type="file"
                            inputProps={{
                                multiple: true
                            }}
                        />
                    )
                }}
                requestHeaders={{
                    JWT: `Bearer ${token}`
                }}
                withButton={false}
                innerRef={fileRef}
                onFinished={handleOnUploaded}
            />
            <Typography variant="subtitle2" sx={{ paddingTop: 2 }}>
                {t(
                    'message.info.queueCsvUpload',
                    'Temple of csv file to upload. If the file does not contain full header than the order of columns must be followed'
                )}
            </Typography>
            <Datatable
                header={data?.header?.map((item) => ({ title: item, field: item })) ?? []}
                hasTitle={false}
                hasSearch={false}
                hasSelection={false}
                hasSorting={false}
                hasPaginating={false}
                isDraggable={false}
                isLoading={useAppSelector(isQueueCsvFileTempleDataInProgress)}
                data={data?.templeData ?? []}
            />
        </Box>
    );
});

export default UploadCsv;
