import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IDownloadUsers, IUserCUModel, IUserFromBeModel, IUserModel, IUserSelectModel } from './UserModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/users', paging || {});

    return fetchGet<IListResponseWithPaging<IUserFromBeModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/users/select', { search, fields });

    return fetchGet<IListResponse<IUserSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IUserFromBeModel>(`/api/users/${id}`);
}

export function create(data: Omit<IUserCUModel, 'skills' | 'roles' | 'contracts' | 'requests'>) {
    return fetchPost<IUserFromBeModel>('/api/users/', data);
}

export function update(id: number, data: Partial<Omit<IUserCUModel, 'contracts' | 'skills' | 'roles' | 'requests'>>) {
    return fetchPatch<IUserFromBeModel>(`/api/users/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/users/${id}`);
}

export function usersInWorkplace(id: number) {
    return fetchGet<IListResponse<Omit<IUserModel, 'user_to_requests' | 'user_to_contracts'>>>(
        `/api/users/in-workplace/${id}`
    );
}

export function fetchDownloadUsers(data: IDownloadUsers) {
    return fetchPost('/api/users/download', data);
}
