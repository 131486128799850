import { createAsyncThunk } from '@reduxjs/toolkit';
import { canTakeTradeWithoutOffer } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { addNotification } from '@/data/Notification/NotificationSlice';
import {
    schedulePlanDayShiftById,
    selectSchedulePlanDayShiftById
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { invalidateSchedulePlan, isPlanClosed, selectWorkplaceOfPlan } from '@/data/SchedulePlans/SchedulePlanSlice';
import { isAnyShiftTradeOfferActive } from '@/data/ShiftTradeOffers/ShiftTradeOfferSlice';
import { IRootState } from '@/data/store';
import { signedUser } from '@/data/System/SystemReducer';
import {
    acceptTradeWithoutCounterOffer,
    approve,
    create,
    fetchTradesBySchedulePlanDayShiftId,
    reject,
    remove
} from './ShiftTradeApi';
import {
    isAssignedShiftInTradeCenter,
    selectShiftTradesToApprove,
    shiftTradesBySchedulePlanDayShiftId
} from './ShiftTradeSlice';

export const fetchShiftTradesByAssignedShift = createAsyncThunk(
    'shiftTrades/tradesByAssignedShift',
    async (id: number, { getState }) => {
        const state = getState() as IRootState;

        const assignedShift = schedulePlanDayShiftById(state, id);
        const workplace = selectWorkplaceOfPlan(state, assignedShift?.schedule_plan_id ?? null);

        if (!workplace) {
            throw Error(
                `Workplace of SchedulePlan with ID ${assignedShift?.id} or AssignedShift with ID ${id} was not found in store`
            );
        }

        return await fetchTradesBySchedulePlanDayShiftId(id);
    },
    {
        condition: (schedulePlanDayShiftId: number, { getState }) => {
            const state = getState() as IRootState;

            return (
                !isAssignedShiftInTradeCenter(state, schedulePlanDayShiftId) &&
                !isAnyShiftTradeOfferActive(state, schedulePlanDayShiftId)
            );
        }
    }
);
export const approveTrade = createAsyncThunk('shiftTrades/approve', async (id: number, { getState, dispatch }) => {
    return await approve(id).then(() => {
        const state = getState() as IRootState;
        const trades = selectShiftTradesToApprove(state);

        dispatch(
            addNotification({
                context: 'message.status.success',
                defaultMessage: 'Success',
                variant: 'success'
            })
        );
        dispatch(invalidateSchedulePlan(trades[id].schedule_plan_day_shift.schedule_plan_id));

        return trades[id];
    });
});
export const rejectTrade = createAsyncThunk('shiftTrades/reject', async (id: number, { dispatch }) => {
    return await reject(id).then(() =>
        dispatch(
            addNotification({
                context: 'message.status.success',
                defaultMessage: 'Success',
                variant: 'success'
            })
        )
    );
});
export const createShiftTrade = createAsyncThunk(
    'shiftTrades/create',
    async (args: { schedulePlanDayShiftId: number; schedulePlanId: number }, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        return await create({
            state: 'OPENED',
            schedule_plan_day_shift_id: args.schedulePlanDayShiftId,
            description: null
        })
            .then((response) => {
                dispatch(
                    addNotification({
                        context: 'message.success.theRequestForAssignedShiftWasSuccessfullyAddedIntoTrades',
                        defaultMessage: 'The Request for Assigned Shift was successfully added into Trades',
                        variant: 'success'
                    })
                );

                return response;
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        message: error.message,
                        variant: 'error'
                    })
                );

                return Promise.reject(error);
            });
    }
);
export const deleteShiftTrade = createAsyncThunk(
    'shiftTrades/delete',
    async (args: { id: number; schedulePlanId: number | null }, { dispatch, getState }) => {
        const state = getState() as IRootState;
        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        return await remove(args.id);
    }
);

export const takeThisShiftFromTrade = createAsyncThunk(
    'schedulePlanDayShifts/takeThisShiftFromTrade',
    async (args: { id: number; schedulePlanId: number | null }, { getState, dispatch }) => {
        const state = getState() as IRootState;
        const trades = shiftTradesBySchedulePlanDayShiftId(state, args.id) ?? [];
        const tradeId = trades.find((item) => item.state === 'OPENED' || item.state === 'SELECTED')?.id;
        const user = signedUser(state);

        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        if (tradeId) {
            return await acceptTradeWithoutCounterOffer(tradeId).then((res) => ({
                ...res,
                signedUserId: user?.id,
                tradeId
            }));
        } else {
            return Promise.reject('No Trade');
        }
    },
    {
        condition(args, { getState }) {
            const state = getState() as IRootState;
            const isFeatureEnabled = canTakeTradeWithoutOffer(state);
            const trades = shiftTradesBySchedulePlanDayShiftId(state, args.id) ?? [];
            const shift = selectSchedulePlanDayShiftById(state, args.id) ?? null;
            const user = signedUser(state) ?? null;

            return (
                isFeatureEnabled && trades.length > 0 && user !== null && shift !== null && user.id !== shift.user_id
            );
        }
    }
);
