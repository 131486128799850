import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import useTranslatedDays from '@/hooks/useTranslatedDays';

const StyledWrapper = styled(Box)``;

type IProps = BoxProps & {
    isDayMode: boolean;
    isCompact?: boolean;
    from: Date | DateTimeType;
    timeZone: string;
};

const HeaderDateContent = forwardRef<HTMLDivElement, IProps>(function HeaderDateContentForward(
    { isCompact = false, isDayMode, from, timeZone, ...rest },
    ref
) {
    const translatedDays = useTranslatedDays();
    const dateFormatter = useLocalizeDateFormatter({
        timeZone,
        formatMatcher: isCompact ? 'basic' : undefined,
        day: isCompact ? 'numeric' : undefined,
        month: isCompact ? 'numeric' : undefined
    });
    const timeFormatter = useLocalizeTimeFormatter(undefined, timeZone);

    return (
        <StyledWrapper {...rest} ref={ref}>
            {isDayMode ? (
                timeFormatter.format(DateHelper.toDate(from) ?? undefined)
            ) : (
                <>
                    {!isCompact ? (
                        <Box>
                            {
                                translatedDays.find(
                                    (day) =>
                                        day.id ===
                                        DateHelper.weekDay(
                                            DateHelper.setTimeZone(DateHelper.getInstanceOf(from), timeZone)
                                        )
                                )?.name
                            }
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Box>{dateFormatter.format(DateHelper.toDate(from) ?? undefined)}</Box>
                </>
            )}
        </StyledWrapper>
    );
});

export default HeaderDateContent;
