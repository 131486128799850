import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { daySet, dayTest } from '@/helpers/bit';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import Checkbox from '@/wrappers/Checkbox/Checkbox';

export type IWeekdaysPickerProps = {
    name: string;
    readOnly?: boolean;
    disabled?: boolean;
    value?: number;
    onChange?: (value: number) => void;
};

const UnControlledWeekDaysPicker = ({ disabled, name, onChange, readOnly, value }: IWeekdaysPickerProps) => {
    const translatedDays = useTranslatedDays();
    const days = translatedDays.map((day) => dayTest(value || 0, day.id));
    const handleOnChange = (dayId: number, isChecked: boolean) => {
        if (readOnly !== true && onChange) {
            onChange(daySet(value || 0, dayId, isChecked));
        }
    };

    return (
        <FormGroup row sx={{ justifyContent: 'center' }} data-testid={`checkbox_group_${name}`}>
            {translatedDays.map((day) => (
                <FormControlLabel
                    disabled={disabled}
                    sx={{
                        margin: 1
                    }}
                    key={day.id}
                    label={day.name}
                    value={day.id}
                    name={`${name}.${day.id}`}
                    labelPlacement="bottom"
                    onChange={(e) => handleOnChange(day.id, Boolean(e))}
                    control={<Checkbox name={`${name}[]`} value={days[day.id]} readOnly={readOnly} />}
                />
            ))}
        </FormGroup>
    );
};

export default UnControlledWeekDaysPicker;
