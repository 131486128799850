import { useState } from 'react';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import theme from '@/assets/theme';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { requestTypeAll } from '@/data/RequestTypes/RequestTypeSlice';
import { fetchUserFund } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import { IUserVacationFund } from '@/data/UserToVacationFunds/UserToVacationFundModel';
import { fundsByUserId, getUserToVacationFundKey } from '@/data/UserToVacationFunds/UserToVacationFundSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import Box from '@/wrappers/Box';
import CrudInlineDatatable from '@/wrappers/CrudInlineDatatable';
import { ICrudInlineDataList } from '@/wrappers/CrudInlineDatatable/CrudInlineDatatable';
import Switch from '@/wrappers/Switch';

type IRequestTypesTab = {
    userId: number | null;
    data: ICrudInlineDataList<IUserVacationFund>;
    handleData: (value: ICrudInlineDataList<IUserVacationFund>) => void;
};

const VacationFundTab = ({ userId, data, handleData }: IRequestTypesTab) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const requestsForSelectData = useAppSelector(requestTypeAll) ?? [];
    const usersFunds = useAppSelector((state) => fundsByUserId(state, userId));
    const [showHistory, setShowHistory] = useState(false);
    const findRequestType = (id: string) => requestsForSelectData.find((requestType) => `${requestType.id}` === id);
    const isTimeOff = (id: string) => findRequestType(id)?.type === RequestTypeEnum.times_off;
    const requestsForSelectTImesOffData = requestsForSelectData.filter((item) => isTimeOff(`${item.id}`));

    const preparedData: IUserVacationFund[] = data.map((item) => ({
        ...item,
        exhausted:
            item.exhausted ??
            usersFunds?.find(
                (entity) =>
                    userId &&
                    getUserToVacationFundKey(entity) ===
                        getUserToVacationFundKey({
                            user_id: userId,
                            year: item.year,
                            request_type_id: item.request_type_id
                        })
            )?.exhausted ??
            0
    }));

    return (
        <CrudInlineDatatable<IUserVacationFund>
            resource={PermissionsControllerEnum.UserToVacationFund}
            labelAddButton={t('label.addRequestSetting', 'Add Request Setting')}
            actionsInHeader={
                <Box sx={{ display: 'flex', gap: 1, marginRight: 2 }}>
                    <Switch
                        name="showHistory"
                        label={t('label.showHistory', 'Show History')}
                        value={showHistory}
                        labelPlacement="start"
                        onChange={(newValue) => {
                            setShowHistory(newValue);
                        }}
                    />
                </Box>
            }
            header={[
                {
                    field: 'request_type_id',
                    title: t('header.nameOfRequestType', 'NameOfRequestType'),
                    options: requestsForSelectTImesOffData,
                    settings: {
                        unfiltered: true
                    },
                    validate: (rowData) => {
                        return rowData.request_type_id &&
                            !data
                                .filter((item) => item.id != rowData.id)
                                .some(
                                    (item) =>
                                        item.year == rowData.year && item.request_type_id == rowData.request_type_id
                                )
                            ? true
                            : { isValid: false };
                    },
                    render: (rowData) => {
                        const request = requestsForSelectTImesOffData.find(
                            (item) => `${item.id}` === `${rowData.request_type_id}`
                        );

                        return request?.name ?? '';
                    }
                },
                {
                    field: 'year',
                    title: t('header.year', 'Year'),
                    defaultSort: 'desc',
                    type: 'year',
                    validate: (rowData) => (rowData.year ? true : { isValid: false, helperText: 'It cannot be empty' })
                },
                {
                    field: 'fund',
                    title: t('header.fund', 'Fund'),
                    type: 'numeric',
                    inputProps: {
                        inputProps: {
                            step: 1,
                            min: 0
                        },
                        endAdornment: t('label.' + 'requestHoursPerYearShort', 'h/y')
                    },
                    validate: (rowData) => (rowData.fund ? true : { isValid: false, helperText: 'It cannot be empty' })
                },
                {
                    field: 'exhausted',
                    title: t('header.exhausted', 'Exhausted'),
                    editable: 'never',
                    cellStyle: (tableData, rowdata) => ({
                        color:
                            rowdata && rowdata.exhausted == rowdata.fund
                                ? theme.palette.warning.dark
                                : rowdata && (rowdata.exhausted ?? 0) > (rowdata.fund ?? 0)
                                ? theme.palette.error.dark
                                : undefined
                    })
                }
            ]}
            data={showHistory ? preparedData : preparedData.filter((entity) => entity.year >= DateHelper.now().year())}
            onRowAdd={async (newData) => {
                handleData([
                    ...data,
                    {
                        ...newData,
                        id: generateUniqueID(),
                        year: DateHelper.fromOptionalYear(newData.year)?.year() ?? 0
                    }
                ]);
                if (userId) {
                    dispatch(
                        fetchUserFund({
                            userId: userId,
                            year: newData.year,
                            requestTypeId: newData.request_type_id,
                            fund: newData.fund
                        })
                    );
                }
            }}
            onRowRemove={async (oldData) =>
                handleData(
                    data.filter(
                        (row) =>
                            !(
                                row.request_type_id == oldData?.request_type_id &&
                                row.year == (DateHelper.fromOptionalYear(oldData?.year)?.year() ?? false)
                            )
                    )
                )
            }
            onRowUpdate={async (newData, oldData) => {
                handleData([
                    ...data.filter(
                        (row) =>
                            !(
                                row.request_type_id == oldData?.request_type_id &&
                                row.year == (DateHelper.fromOptionalYear(oldData?.year)?.year() ?? false)
                            )
                    ),
                    {
                        ...newData,
                        id: oldData?.id,
                        // year: newData.year
                        year: DateHelper.fromOptionalYear(newData.year)?.year() ?? 0
                    }
                ]);

                if (userId) {
                    dispatch(
                        fetchUserFund({
                            userId: userId,
                            year: newData.year,
                            requestTypeId: newData.request_type_id,
                            fund: newData.fund
                        })
                    );
                }
            }}
        />
    );
};

export default VacationFundTab;
