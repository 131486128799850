import { IListResponseWithPaging } from '../ApiResponse';
import { IFilters } from '../Filters';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { INeedCUModel, INeedModel } from './NeedModels';

export function fetchList(paging: Partial<IPaging>, filters: IFilters) {
    const url = urlBuilderWithParams('/api/needs', { ...paging, filters });

    return fetchGet<IListResponseWithPaging<INeedModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<INeedModel>(`/api/needs/${id}`);
}

export function create(data: Omit<INeedCUModel, 'need_items'>) {
    return fetchPost<INeedModel>('/api/needs/', data);
}

export function update(id: number, data: Omit<INeedCUModel, 'need_items'>) {
    return fetchPatch<INeedModel>(`/api/needs/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/needs/${id}`);
}
