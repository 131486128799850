import Icon from '@mui/material/Icon';
import { ISkillModel } from '@/data/Skills/SkillModels';
import handleIconReplaces from '@/utils/icons/handleIconReplaces';

type IProps = Pick<ISkillModel, 'color' | 'icon'> & {
    fontSize?: number;
};

const SkillIcon = ({ color, fontSize, icon }: IProps) => (
    <>
        {icon && <Icon sx={{ color: color, width: 'auto', height: 'auto', fontSize }}>{handleIconReplaces(icon)}</Icon>}
    </>
);

export default SkillIcon;
