import AddIcon from '@mui/icons-material/Add';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const StyledBodyAddIcon = styled(AddIcon, {
    shouldForwardProp: (propName) => !['noHover'].includes(propName as string)
})<{
    noHover?: boolean;
}>(
    ({ noHover }) => `
    ${noHover ? '' : 'display: none;'}
    border-radius: 12.5px;
    height: auto;
    width: auto;
    left: calc(50% - 12.5px);
    margin: 0 auto;
    max-height: 25px;
    max-width: 25px;
    line-height: inherit;
    position: absolute;
    transition: transform 0.2s;
    transition-property: background-color;
    ${
        noHover
            ? `background-color: ${blue[50]};`
            : `&:hover,
    &:focus {
        background-color: ${blue['50']};
        display: block;
    }`
    }
`
);
const StyledBodyUserIcon = styled(PersonOutlineIcon)`
    margin-right: 8px;
    margin-left: -2px;
    height: 16px;
    width: 16px;
`;

const StyledBodyUnassignedIcon = styled(PersonSearchOutlinedIcon)`
    margin-right: 8px;
    margin-left: -2px;
    height: 16px;
    width: 16px;
`;

export { StyledBodyUserIcon, StyledBodyAddIcon, StyledBodyUnassignedIcon };
