import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/700.css';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import LocalizationProvider from '@/components/providers/LocalizationProvider';
import ThemeProvider from '@/components/providers/ThemeProvider';
import WebSocketProvider from '@/components/providers/WebSocketContextProvider';
import { persistedStore, store } from '@/data/store';
import config from '@/utils/config';
import reportWebVitals from '@/utils/reportWebVitals';
import CollapseSnackbar, { ICollapseCustomProps } from '@/wrappers/SnackbarComponents/CollapseSnackbar';
import App from './App';
import '@/styles/index.scss';

const app = document.getElementById('js_app');

declare module 'notistack' {
    interface VariantOverrides {
        collapse: {
            customProps?: ICollapseCustomProps;
        };
    }
}

if (app) {
    const root = ReactDOM.createRoot(app);

    root.render(
        <ThemeProvider>
            <LocalizationProvider>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={5} Components={{ collapse: CollapseSnackbar }}>
                        <PersistGate loading={null} persistor={persistedStore}>
                            <BrowserRouter basename={config.subDir}>
                                <WebSocketProvider>
                                    <App />
                                </WebSocketProvider>
                            </BrowserRouter>
                        </PersistGate>
                    </SnackbarProvider>
                </Provider>
            </LocalizationProvider>
        </ThemeProvider>
    );
} else {
    console.log("There's no application element");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
