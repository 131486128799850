import { IListResponse } from '@/data/ApiResponse';
import { IDaktelaCategories, IDaktelaQueues } from '@/data/Daktela/DaktelaModels';
import { fetchGet, urlBuilderWithParams } from '@/data/request';
import OmnichannelEnum from '@/utils/enums/OmnichannelEnum';

export function fetchCategories(callCenterId: number) {
    const url = urlBuilderWithParams('/api/daktela/categories', {
        call_center_id: callCenterId
    });

    return fetchGet<IListResponse<IDaktelaCategories>>(url);
}

export function fetchQueues(type: OmnichannelEnum[], callCenterId: number) {
    const url = urlBuilderWithParams('/api/daktela/queues', {
        type: type,
        call_center_id: callCenterId
    });

    return fetchGet<IListResponse<IDaktelaQueues>>(url);
}
