import UserSourceEnum from '@/utils/enums/UserSourceEnum';
import { fetchGet, fetchPost } from '../request';
import { ILoginResponseModel, IResetPassword, IResetPasswordByToken, ISendEmailToResetPassword } from './UserModel';

export function fetchSignIn(login: string, password: string) {
    const url = '/api/auth/';

    return fetchPost<ILoginResponseModel>(url, { login, password });
}

export function fetchSignInWithToken(token: string, type: UserSourceEnum, login?: string) {
    const url = '/api/auth/token';

    return fetchPost<ILoginResponseModel>(url, { token, type, login });
}

export function resetPasswordByToken(args: IResetPasswordByToken) {
    return fetchPost('/api/auth/resetPasswordByToken', args);
}

export function resetPassword(args: IResetPassword) {
    return fetchPost('/api/auth/resetPassword', args);
}

export function sendEmailToResetPassword(args: ISendEmailToResetPassword) {
    return fetchPost('/api/auth/sendEmailToResetPassword', args);
}

export function fetchNewToken() {
    return fetchGet<{
        status: number;
        data?: ILoginResponseModel;
    }>('/api/auth/refresh-token');
}
