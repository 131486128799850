import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getAzureSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const Azure = (props: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getAzureSettingsItems) ?? [];
    const { t } = useAppTranslation();

    return (
        <FieldsGenerator
            {...props}
            name="azure"
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'client_id',
                        required: true,
                        label: t('label.clientId', 'Client ID'),
                        value: getSettingsItemValueByKey(data, 'client_id')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'client_secret',
                        required: true,
                        label: t('label.clientSecret', 'Client Secret'),
                        value: getSettingsItemValueByKey(data, 'client_secret')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'tenant_id',
                        label: t('label.tenantId', 'Tenant ID'),
                        value: getSettingsItemValueByKey(data, 'tenant_id'),
                        helperText: t(
                            'message.info.tenantId',
                            'Tenant ID should be filled if the users has enabled MFA'
                        )
                    }
                }
            ]}
        />
    );
};

export default Azure;
