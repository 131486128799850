import { fetchGet, urlBuilderWithParams } from '@/data/request';
import {
    IFetchUserHoursList,
    IFetchUserShiftsSummaries,
    IFetchUserSkillsList,
    IUserHoursResponse,
    IUserShiftsSummariesResponse,
    IUserSkillsResponse
} from '@/data/Summaries/SummariesModels';

export function fetchUserShiftsSummariesList(args: IFetchUserShiftsSummaries) {
    const url = urlBuilderWithParams('/api/summaries/users-shifts-summaries', args);

    return fetchGet<IUserShiftsSummariesResponse[]>(url);
}
export function fetchUserHoursList(args: IFetchUserHoursList) {
    const url = urlBuilderWithParams('/api/summaries/users-hours', args);

    return fetchGet<IUserHoursResponse[]>(url);
}

export function fetchUserSkillsList(args: IFetchUserSkillsList) {
    const url = urlBuilderWithParams('/api/summaries/users-skills', args);

    return fetchGet<IUserSkillsResponse[]>(url);
}
