import Box from '@mui/material/Box';
import { forwardRef } from 'react';
import { IOutputValueType } from '@/base/FormGenerator';
import FormGenerator from '@/base/FormGenerator/FormGenerator';
import { useAppDispatch } from '@/data/hooks';
import { removeQueueAggregatedData } from '@/data/Queues/QueueActions';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';

export type IProps = {
    queueId?: number;
    queueName?: string;
    onSubmit?: () => void;
};

const DeleteAggregationData = forwardRef<HTMLFormElement, IProps>(function DeleteAggregationDataInner(
    { queueId, queueName, onSubmit },
    ref
) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();

    const handleSubmit = (values: IOutputValueType) => {
        if (queueId) {
            if (values.valid) {
                dispatch(
                    removeQueueAggregatedData({
                        queueId: queueId,
                        queueName: queueName ?? '',
                        dateFrom: DateHelper.formatDate((values.valid as IDateRangePickerProps['value'])?.start),
                        dateTo: DateHelper.formatDate((values.valid as IDateRangePickerProps['value'])?.end)
                    })
                );
            }

            if (onSubmit) {
                onSubmit();
            }
        }
    };

    return (
        <FormGenerator
            ref={ref}
            id={queueId ? `deleteAggregationData_${queueId}` : 'deleteAggregationData'}
            name="deleteAggregationData"
            className="deleteAggregationData"
            fields={[
                {
                    type: 'dateRange',
                    props: {
                        required: true,
                        name: 'valid',
                        label: {
                            start: t('label.from', 'from'),
                            end: t('label.to', 'to')
                        },
                        width: 12
                    }
                },
                {
                    type: 'custom',
                    props: {
                        name: 'message',
                        values: {},
                        render: ({ watch }) => {
                            const from = watch('from') ?? '';
                            const to = watch('to') ?? '';

                            return from && to ? (
                                <Box>
                                    {t(
                                        'message.question.doYouWantToDeleteAggregationDataFromTo',
                                        'Do You Want To Delete Aggregation Data {{from}} - {{to}} from {{queueName}}',
                                        {
                                            queueName: queueName ?? '',
                                            from: from ?? '',
                                            to: to ?? ''
                                        }
                                    )}
                                </Box>
                            ) : (
                                <></>
                            );
                        },
                        validation: {
                            deps: ['']
                        }
                    }
                }
            ]}
            actions={[
                {
                    type: 'button',
                    props: {
                        name: 'submit',
                        type: 'submit',
                        variant: 'contained',
                        children: 'DeleteAggregationData',
                        hidden: true
                    }
                }
            ]}
            onSubmit={handleSubmit}
            displayAsModal={false}
        />
    );
});

export default DeleteAggregationData;
