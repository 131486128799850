import { useAppSelector } from '@/data/hooks';
import { shiftById } from '@/data/Shifts/ShiftSlice';
import { StyledUserItem } from '@/modules/Scheduler/Table/SchedulerTable';

type IProps = {
    id: number | null;
    isFirst: boolean;
    rowEnd: number;
    rowStart: number;
};

const SchedulerTableSideBarShift = ({ id, isFirst, rowEnd, rowStart, ...rest }: IProps) => {
    const shift = useAppSelector((state) => shiftById(state, id ?? undefined));

    return (
        <StyledUserItem
            {...rest}
            withBorder={rowStart !== 1 && !isFirst}
            sx={{
                gridRow: `${rowStart}/${rowEnd}`
            }}
        >
            {id === null ? 'WFH' : shift?.abbreviation}
        </StyledUserItem>
    );
};

export default SchedulerTableSideBarShift;
