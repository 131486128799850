import MuiDialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

type IDialogContent = DialogContentProps;

const StylesContent = styled(MuiDialogContent)(
    ({ theme }) => `
        min-width: ${theme.breakpoints.values.sm}px;
    `
);

const DialogContent = (props: IDialogContent) => <StylesContent sx={props.sx}>{props.children}</StylesContent>;

export default DialogContent;
