import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueues, removeQueue, updateQueue } from '@/data/Queues/QueueActions';
import { isQueueLocked } from '@/data/Queues/QueueEnum';
import { IQueueModel } from '@/data/Queues/QueueModels';
import {
    queueList,
    queuePaging,
    queueRemovingStatus,
    queuesListStatus,
    queueUpdatingStatus
} from '@/data/Queues/QueueSlice';
import { fetchRolesForSelect } from '@/data/Roles/RoleActions';
import { fetchSkillsForSelect } from '@/data/Skills/SkillActions';
import { IRootState } from '@/data/store';
import QueueForm from '@/forms/QueueForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import config from '@/utils/config';
import OmnichannelEnum from '@/utils/enums/OmnichannelEnum';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Chip from '@/wrappers/Chip';
import ChipStack from '@/wrappers/ChipStack';
import QueuesPredictorsProcessDialog from '@/wrappers/QueuesPredictorsProcessDialogs';
import Switch from '@/wrappers/Switch';

const isLoadingSelector = createSelector(
    (state: IRootState) => queueUpdatingStatus(state) === 'loading',
    (state: IRootState) => queuesListStatus(state) === 'loading',
    (...states) => states.some((state) => state)
);

const QueuesListPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(queueList);
    const isLoading = useAppSelector(isLoadingSelector);

    useEffect(() => {
        dispatch(fetchRolesForSelect({ search: '' }));
        dispatch(fetchSkillsForSelect({ search: '' }));
    }, []);

    return (
        <LayoutOfPage
            title={t('title.queuesList', 'Queues List')}
            subTitle={t(
                'subTitle.queuesDownloadOccurEveryDayAtMidnight',
                'Queues download occur every day at midnight'
            )}
        >
            <CrudDatatable<IQueueModel>
                name="queue"
                resource={PermissionsEnum.Queues}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <QueueForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                actions={(id, _) => (id ? <QueuesPredictorsProcessDialog queueId={id} /> : <></>)}
                header={[
                    { title: t('header.name', 'Name'), field: 'name' },
                    {
                        title: t('header.ccQueues', 'CC Queues'),
                        field: 'queue_to_daktela_queues',
                        render: (rowData) => (
                            <ChipStack
                                name="queues"
                                direction="row"
                                overflowHeight="5em"
                                values={
                                    rowData.queue_to_daktela_queues?.map((item) => {
                                        const indexOfEnum = Object.values(OmnichannelEnum).indexOf(
                                            item.daktela_queue_type as OmnichannelEnum
                                        );

                                        const key = Object.keys(OmnichannelEnum)[indexOfEnum];

                                        return {
                                            id: item.daktela_queue_id,
                                            name: item.daktela_queue_name,
                                            label: item.daktela_queue_name,
                                            title: t(`enums.omnichannel.${key}`, item.daktela_queue_type),
                                            color: 'primary'
                                        };
                                    }) || []
                                }
                            />
                        )
                    },
                    {
                        title: t('header.type', 'Type'),
                        field: 'queue_to_types',
                        render: (rowData) => (
                            <ChipStack
                                name="queue_to_types"
                                overflowHeight="5em"
                                direction="row"
                                values={
                                    rowData.queue_to_types?.map((queueToType) => {
                                        const indexOfEnum = Object.values(OmnichannelEnum).indexOf(
                                            queueToType.daktela_type as unknown as OmnichannelEnum
                                        );

                                        const key = Object.keys(OmnichannelEnum)[indexOfEnum];

                                        return {
                                            id: queueToType.daktela_type,
                                            name: queueToType.daktela_type,
                                            label: t(`enums.omnichannel.${key}`, queueToType.daktela_type),
                                            color: 'primary'
                                        };
                                    }) || []
                                }
                            />
                        )
                    },
                    {
                        title: t('header.categories', 'Categories'),
                        field: 'queue_to_daktela_categories',
                        render: (rowData) => (
                            <ChipStack
                                name="queue_to_daktela_categories"
                                direction="row"
                                overflowHeight="5em"
                                values={
                                    rowData.queue_to_daktela_categories?.map((queueToCategory) => {
                                        return {
                                            id: queueToCategory.daktela_category_id,
                                            name: queueToCategory.daktela_category_name,
                                            label: queueToCategory.daktela_category_name,
                                            color: 'primary'
                                        };
                                    }) || []
                                }
                            />
                        )
                    },
                    {
                        title: t('header.skills', 'Skills'),
                        field: 'queue_items',
                        render: (rowData) => (
                            <ChipStack
                                name={`queue_item_${rowData.id}`}
                                overflowHeight="5em"
                                direction="row"
                                values={
                                    rowData.queue_items?.map((qeueueItem) => {
                                        return {
                                            id: qeueueItem.id.toString(),
                                            name: `queueItem${qeueueItem.id}`,
                                            label: qeueueItem.skill?.name ?? '',
                                            color: 'primary'
                                        };
                                    }) || []
                                }
                            />
                        )
                    },
                    {
                        title: t('header.maxHistoryDate', 'Max history date'),
                        field: 'max_history_date',
                        render: (rowData) =>
                            DateHelper.formatDate(
                                DateHelper.fromOptionalDateString(rowData.max_history_date ?? null),
                                config.date.momentFormat
                            )
                    },
                    {
                        title: t('header.state', 'State'),
                        field: 'state',
                        render: ({ state }: IQueueModel) => (
                            <Chip
                                name="state"
                                color={isQueueLocked(state) ? 'warning' : 'success'}
                                label={t(`enums.queueState.${state}`, state)}
                            />
                        )
                    },
                    {
                        title: t('header.active', 'Active'),
                        field: 'active',
                        render: (rowData: IQueueModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.active}
                                title={
                                    rowData.active
                                        ? t('label.deactivate', 'Deactivate')
                                        : t('label.activate', 'Activate')
                                }
                                onChange={(value) => {
                                    dispatch(
                                        //TODO: zatím nejsou testy a je to minoritní ->
                                        // po testech dodělat refactor add a edit queues ( na patch odeslaných dat)
                                        // a poté tu předělat na "partialQueueUpdate" aby se zbytečně neposílal celý objekt a neupdatovala celá entita
                                        updateQueue({
                                            id: rowData.id,
                                            data: {
                                                ...rowData,
                                                active: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    }
                ]}
                isLoading={isLoading}
                data={data}
                paging={useAppSelector(queuePaging)}
                removingStatus={useAppSelector(queueRemovingStatus)}
                onFetchList={fetchQueues}
                onRemove={removeQueue}
            />
        </LayoutOfPage>
    );
};

export default QueuesListPage;
