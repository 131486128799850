import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import TransferList from '@/wrappers/TransferList';

type IShifts = {
    selectedAllowedShifts: string[];
    selectedAllowedShiftsForCalculation: string[];
    onChangeAllowedShifts: (value: string[]) => void;
    onChangeAllowedShiftsForCalculation: (value: string[]) => void;
};

const StyledContainer = styled(Grid)`
    padding-top: 1em;
    display: grid;
    align-items: stretch;
    gap: 30px;
`;

const Shifts = ({
    selectedAllowedShifts,
    selectedAllowedShiftsForCalculation,
    onChangeAllowedShifts,
    onChangeAllowedShiftsForCalculation
}: IShifts) => {
    const { t } = useAppTranslation();
    const shiftListData = useAppSelector(shiftsForSelect) ?? [];
    const dispatch = useAppDispatch();

    const shiftsOptions = useMemo(
        () =>
            shiftListData.map((entity) => ({
                id: `${entity.id}`,
                sortableValue: entity.name
            })),
        [shiftListData]
    );

    useEffect(() => {
        dispatch(fetchShiftsForSelect({ search: '' }));
    }, []);

    return (
        <StyledContainer>
            <Box>
                <TransferList
                    label={t('label.allowedShiftsForManualWork', 'Allowed shifts for manual work')}
                    items={shiftsOptions}
                    values={selectedAllowedShifts}
                    onChange={onChangeAllowedShifts}
                />
            </Box>
            <Box>
                <TransferList
                    label={t('label.allowedShiftsForEA', 'Allowed shifts for EA')}
                    items={shiftsOptions}
                    values={selectedAllowedShiftsForCalculation}
                    onChange={onChangeAllowedShiftsForCalculation}
                />
            </Box>
        </StyledContainer>
    );
};

export default Shifts;
