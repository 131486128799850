import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { ISupportedValueType } from '@/base/FormGenerator';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { fetchCallCentersForSelect } from '@/data/CallCenters/CallCenterActions';
import { callCenterForSelect, callCenterForSelectStatus } from '@/data/CallCenters/CallCenterSlice';
import { fetchCategoriesList, fetchQueuesList } from '@/data/Daktela/DaktelaActions';
import { ICategoriesModel } from '@/data/Daktela/DaktelaModels';
import {
    categoriesList,
    categoriesListStatus,
    queuesList,
    queuesListStatus as daktelaQueuesListStatus
} from '@/data/Daktela/DaktelaSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { createQueue, updateQueue } from '@/data/Queues/QueueActions';
import { isQueueLocked } from '@/data/Queues/QueueEnum';
import { IQueueModel, IQueueOmnichannelItemCU } from '@/data/Queues/QueueModels';
import {
    queueById,
    queueCreatingStatus,
    queuePaging,
    queuesListStatus,
    queueUpdatingStatus,
    updatePaging
} from '@/data/Queues/QueueSlice';
import { skillsForSelect } from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import isEmptyArray from '@/helpers/array/isEmptyArray';
import list from '@/helpers/countries/list';
import DateHelper from '@/helpers/date/DateHelper';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import OmnichannelEnum from '@/utils/enums/OmnichannelEnum';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import { IDatePickerProps } from '@/wrappers/DatePicker';

type IProps = Omit<ICuProps<IQueueModel>, 'resource'>;

const isLoadedSelector = createSelector(
    (state: IRootState) => categoriesListStatus(state) !== 'loading',
    (state: IRootState) => queuesListStatus(state) !== 'loading',
    (state: IRootState) => daktelaQueuesListStatus(state) !== 'loading',
    (state: IRootState) => callCenterForSelectStatus(state) !== 'loading',
    (...states) => !states.some((state) => !state)
);

const QueueForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const skillsData = useAppSelector(skillsForSelect);
    const categoriesData = useAppSelector(categoriesList);
    const loadedData = useAppSelector((state) => queueById(state, id));
    const daktelaQueuesList = useAppSelector(queuesList);
    const callCentersList = useAppSelector(callCenterForSelect);
    const paging = useAppSelector(queuePaging);
    const isLoaded = useAppSelector(isLoadedSelector);
    const countries = list;
    const [queueType, setQueueType] = useState<string[]>([]);
    const [callCenterId, setCallCenterId] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleData = (values: IOutputValueType) => ({
        active: !!values.active,
        name: values.name as string,
        country_name: values.country_name as string,
        description: values.description as string,
        effectivity: values.effectivity as number,
        call_center_id: values.call_center_id as number,
        max_history_date: DateHelper.formatDate(values.max_history_date as IDatePickerProps['value']),
        queue_to_types: (values.queue_to_types as string[]).map((value) => ({
            queue_id: id,
            daktela_type: value
        })) as IQueueOmnichannelItemCU[],
        queue_to_daktela_categories: categoriesData
            .filter((daktelaCategoryItem) =>
                (values.queue_to_daktela_categories as string[]).some(
                    (categoryItemId) => daktelaCategoryItem.id == categoryItemId
                )
            )
            .map((daktelaCategoryItem) => ({
                queue_id: id,
                daktela_category_id: daktelaCategoryItem.id,
                daktela_category_name: daktelaCategoryItem.name
            })) as ICategoriesModel[],
        role_ids: [], // values.role_ids as number[],
        skill_ids: values.skill_ids as number[],
        minimum_number_of_operators: values.minimum_number_of_operators as number,
        queues:
            daktelaQueuesList?.filter((item) =>
                (values.queues as string[]).some((valueItem) => valueItem === `${item.id}`)
            ) ?? []
    });

    const handleCreate = useCallback(
        (values: IOutputValueType) => createQueue(handleData(values)),
        [daktelaQueuesList, categoriesData]
    );

    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateQueue({ id: id!, data: handleData(values) }),
        [id, daktelaQueuesList, categoriesData]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    const typeOptions = Object.keys(OmnichannelEnum)
        .map((key) => ({
            id: OmnichannelEnum[key as keyof typeof OmnichannelEnum],
            label: t(`enums.omnichannel.${formatPascalToCamel(key)}`, key)
        }))
        .sort((prev, next) => {
            if (prev.label < next.label) {
                return -1;
            }

            if (prev.label > next.label) {
                return 1;
            }

            return 0;
        });

    useEffect(() => {
        setQueueType(loadedData?.queue_to_types.map((item) => item.daktela_type) ?? []);
    }, [loadedData]);

    useEffect(() => {
        setCallCenterId(loadedData?.call_center_id ?? null);
    }, [loadedData]);

    useEffect(() => {
        if (
            isOpen &&
            callCenterId &&
            (queueType.length > 1 ||
                (queueType.length == 1 && !queueType.some((item) => item == OmnichannelEnum.csv_file)))
        ) {
            dispatch(fetchCategoriesList({ callCenterId: callCenterId }));
            dispatch(fetchQueuesList({ type: queueType as OmnichannelEnum[], callCenterId: callCenterId }));
        }
    }, [queueType, isOpen, callCenterId]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleOpen = useCallback(() => {
        dispatch(fetchCallCentersForSelect({ search: '' }));
        setQueueType(loadedData?.queue_to_types.map((item) => item.daktela_type) ?? []);
        setIsOpen(true);
        setCallCenterId(loadedData?.call_center_id ?? null);
    }, [loadedData, queueType, callCenterId]);

    const isRemoteOmnichannelType = useCallback(
        (values: ISupportedValueType) => {
            const typesIdsList = typeOptions.filter((type) =>
                (values.queue_to_types as OmnichannelEnum[]).some((typeId) => typeId == type.id)
            );

            return (
                typesIdsList.length > 1 ||
                (typesIdsList.length == 1 && !typesIdsList.some((item) => item.id == OmnichannelEnum.csv_file))
            );
        },
        [typeOptions]
    );

    const displayRestOfForm = useCallback(
        (values: ISupportedValueType, onlyRemote = false) => {
            const isRemote = isRemoteOmnichannelType(values);
            const callCentersId = values.call_center_id as number | null;
            const queueToTypesList = (values.queue_to_types ?? []) as string[];

            if (onlyRemote) {
                return (
                    Array.isArray(queueToTypesList) &&
                    !isEmptyArray(queueToTypesList.length) &&
                    isRemote &&
                    !!callCentersId
                );
            } else {
                return (
                    Array.isArray(queueToTypesList) &&
                    !isEmptyArray(queueToTypesList.length) &&
                    (!isRemote || (isRemote && !!callCentersId))
                );
            }
        },
        [queueType]
    );

    return (
        <CuForm
            {...props}
            id={id}
            name="queue"
            readOnly={loadedData && isQueueLocked(loadedData.state)}
            resource={PermissionsEnum.Queues}
            subTitle={
                loadedData ? (
                    <>
                        {t('label.state', 'State')}: {t(`enum.queueState.${loadedData.state}`, loadedData.state)}
                    </>
                ) : undefined
            }
            maxWidth="lg"
            creatingStatus={useAppSelector(queueCreatingStatus)}
            updatingStatus={useAppSelector(queueUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            onOpen={handleOpen}
            onClose={handleClose}
            items={[
                {
                    type: 'multiSelect',
                    props: {
                        name: 'queue_to_types',
                        required: true,
                        label: t('label.type', 'Type'),
                        value: queueType,
                        options: typeOptions,
                        onChange: (value) => setQueueType(value ?? []),
                        validation: {
                            deps: 'queue_to_types'
                        }
                    }
                },
                {
                    type: 'select',
                    display: isRemoteOmnichannelType,
                    props: {
                        name: 'call_center_id',
                        label: t('label.callCenter', 'Call Center'),
                        required: isRemoteOmnichannelType,
                        value: callCenterId ?? undefined,
                        onChange: (newValue) => setCallCenterId(newValue ? parseInt(newValue) : null),
                        options: callCentersList
                            .slice()
                            .sort((prev, next) => prev.name.localeCompare(next.name))
                            .map((item) => ({ id: `${item.id}`, label: item.name })),
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        }
                    }
                },
                {
                    type: 'switch',
                    display: displayRestOfForm,
                    props: {
                        name: 'active',
                        label: t('label.active', 'Active'),
                        value: loadedData?.active ?? true,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        }
                    }
                },
                {
                    type: 'multiSelect',
                    display: displayRestOfForm,
                    props: {
                        required: ({ role_ids }) => !(role_ids as string[])?.length,
                        name: 'skill_ids',
                        label: t('label.skills', 'Skills'),
                        value: loadedData?.queue_items
                            ?.filter((item) => item.skill_id)
                            .map((item) => `${item.skill?.id}` || ''),
                        options: skillsData.map((item) => ({
                            id: `${item.id}`,
                            label: item.name
                        })),
                        width: 6,
                        validation: {
                            deps: ['role_ids', 'queue_to_types', 'call_center_id']
                        }
                    }
                },
                {
                    type: 'multiSelect',
                    display: displayRestOfForm,
                    props: {
                        name: 'queue_to_daktela_categories',
                        label: t('label.category', 'Category'),
                        value: categoriesData
                            .filter(
                                (daktelaCategoryItem) =>
                                    loadedData?.queue_to_daktela_categories?.some(
                                        (categoryItem) => daktelaCategoryItem.id == categoryItem.daktela_category_id
                                    )
                            )
                            .sort((prev, next) => {
                                if (prev.name < next.name) {
                                    return -1;
                                }

                                if (prev.name > next.name) {
                                    return 1;
                                }

                                return 0;
                            })
                            .map((daktelaCategoryItem) => daktelaCategoryItem.id),
                        options: categoriesData
                            .slice()
                            .map((daktelaCategoryItem) => ({
                                id: daktelaCategoryItem.id,
                                label: daktelaCategoryItem.name
                            }))
                            .sort((prev, next) => {
                                if (prev.label < next.label) {
                                    return -1;
                                }

                                if (prev.label > next.label) {
                                    return 1;
                                }

                                return 0;
                            }),
                        width: 6,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: displayRestOfForm,
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name,
                        width: 4,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id'],
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'select',
                    display: displayRestOfForm,
                    props: {
                        required: true,
                        name: 'country_name',
                        label: t('label.country', 'Country'),
                        value: loadedData?.country_name,
                        options: countries.map((item) => ({
                            id: item.name,
                            label: item.title
                        })),
                        width: 4,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        }
                    }
                },
                {
                    type: 'date',
                    display: displayRestOfForm,
                    props: {
                        name: 'max_history_date',
                        label: t('label.maxHistoryDate', 'Max history date'),
                        value: DateHelper.fromOptionalDateString(loadedData?.max_history_date ?? null),
                        maxDate: DateHelper.now(),
                        width: 4,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: displayRestOfForm,
                    props: {
                        name: 'minimum_number_of_operators',
                        label: t('label.minimumNumberOfOperators', 'Minimum number of operators'),
                        type: 'number',
                        value: loadedData?.minimum_number_of_operators,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        },
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 1
                            }
                        }
                    }
                },
                {
                    type: 'textArea',
                    display: displayRestOfForm,
                    props: {
                        name: 'description',
                        label: t('label.description', 'Description'),
                        value: loadedData?.description,
                        validation: {
                            deps: ['queue_to_types', 'call_center_id'],
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'slider',
                    display: ({ queue_to_types }) =>
                        Array.isArray(queue_to_types) && !isEmptyArray(queue_to_types.length),
                    props: {
                        name: 'effectivity',
                        label: t('label.effectivity', 'Effectivity'),
                        min: 0,
                        max: 100,
                        value: loadedData?.effectivity ?? 70
                    }
                },
                {
                    type: 'transferList',
                    display: (values) => displayRestOfForm(values, true),
                    props: {
                        name: 'queues',
                        values: loadedData?.queue_to_daktela_queues?.map((item) => item.daktela_queue_id) || [],
                        items:
                            daktelaQueuesList?.map((item) => ({
                                id: `${item.id}`,
                                sortableValue: item.name
                            })) ?? [],
                        validation: {
                            deps: ['queue_to_types', 'call_center_id']
                        }
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
            isLoaded={isLoaded}
        />
    );
};

export default QueueForm;
