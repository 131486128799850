import { useCallback, useEffect } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { createContract, updateContract } from '@/data/Contracts/ContractActions';
import { IContractCUModel, IContractModel } from '@/data/Contracts/ContractModels';
import {
    contractById,
    contractCreatingStatus,
    contractPaging,
    contractUpdatingStatus,
    updatePaging
} from '@/data/Contracts/ContractSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';

type IProps = Omit<ICuProps<IContractModel>, 'resource'>;

const ContractForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => contractById(state, id));
    const shifts = useAppSelector(shiftsForSelect);
    const paging = useAppSelector(contractPaging);
    const handleData = useCallback(
        (values: IOutputValueType): IContractCUModel => ({
            name: values.name as string,
            day_hours_limit: values.day_hours_limit as number,
            week_hours_limit: values.week_hours_limit as number,
            number_of_hours_per_day:
                typeof values.month_hours_limit === 'number' ? null : (values.number_of_hours_per_day as number),
            min_continuous_rest_per_week: values.min_continuous_rest_per_week as number,
            min_break_hours_per_day: values.min_break_hours_per_day as number,
            min_break_hours_per_day_splitted: values.min_break_hours_per_day_splitted as number,
            break_at_least_after_hours: values.break_at_least_after_hours as number,
            month_hours_limit:
                typeof values.number_of_hours_per_day === 'number' ? null : (values.month_hours_limit as number),
            holidays_allowed: values.holidays_allowed as boolean,
            night_shifts_allowed: values.night_shifts_allowed as boolean,
            external_connection_id: values.external_connection_id as string,
            contract_groups: (
                ((values.groups as []) ?? []) as {
                    id: number | null;
                    min: string;
                    max: string | null;
                    shift_ids: string[];
                }[]
            )
                .filter((item) => Array.isArray(item.shift_ids) && item.shift_ids.length > 0)
                .map((item) => ({
                    id: item.id,
                    min: parseInt(`${item.min}`),
                    max: item.max !== null ? parseInt(`${item.max}`) : null,
                    shift_ids: item.shift_ids.map((shift_id: string) => parseInt(`${shift_id}`))
                }))
        }),
        []
    );
    const handleCreate = useCallback((values: IOutputValueType) => createContract(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateContract({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    useEffect(() => {
        dispatch(fetchShiftsForSelect({ search: '' }));
    }, []);

    return (
        <CuForm
            {...props}
            id={id}
            name="contract"
            resource={PermissionsEnum.Contracts}
            creatingStatus={useAppSelector(contractCreatingStatus)}
            updatingStatus={useAppSelector(contractUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            maxWidth="lg"
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'holidays_allowed',
                        label: t('label.holidaysAllowed', 'Holidays Allowed'),
                        value: loadedData?.holidays_allowed,
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'night_shifts_allowed',
                        label: t('label.nightShiftsAllowed', 'Night Shifts Allowed'),
                        value: loadedData?.night_shifts_allowed,
                        width: 6
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'day_hours_limit',
                        label: t('label.dayHoursLimit', 'Day Hours Limit'),
                        value: loadedData?.day_hours_limit,
                        width: 6,
                        type: 'number',

                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'week_hours_limit',
                        label: t('label.weekHoursLimit', 'Week Hours Limit'),
                        value: loadedData?.week_hours_limit,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'number_of_hours_per_day',
                        label: t('label.numberOfHoursPerDay', 'Number Of Hours Per Day'),
                        value: loadedData?.number_of_hours_per_day,
                        disabled: (currentValues) => currentValues.month_hours_limit !== '',
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['month_hours_limit'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'min_continuous_rest_per_week',
                        label: t('label.minContinuousRestPerWeek', 'Min Continuous Rest Per Week'),
                        value: loadedData?.min_continuous_rest_per_week,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'min_break_hours_per_day',
                        label: t('label.minBreakHoursPerDay', 'Min Break Hours Per Day'),
                        value: loadedData?.min_break_hours_per_day,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'min_break_hours_per_day_splitted',
                        label: t('label.minBreakHoursPerDaySplitted', 'Min Break Hours Per Day Splitted'),
                        value: loadedData?.min_break_hours_per_day_splitted,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'break_at_least_after_hours',
                        label: t('label.breakAtLeastAfterHours', 'Break At Least After Hours'),
                        value: loadedData?.break_at_least_after_hours,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'month_hours_limit',
                        type: 'number',
                        label: t('label.monthHoursLimit', 'Month Hours Limit'),
                        value: loadedData?.month_hours_limit,
                        disabled: (currentValues) => currentValues.number_of_hours_per_day !== '',
                        width: 6,
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['number_of_hours_per_day'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'html',
                    props: {
                        name: 'groupsTitle',
                        render: () => t('title.groups', 'Groups')
                    }
                },
                {
                    type: 'multiRowInputs',
                    props: {
                        name: 'groups',
                        value:
                            loadedData?.contract_groups.map((value) => ({
                                id: value.id,
                                min: value.min,
                                max: value.max,
                                shift_ids: value.contract_group_shifts?.map((valueShifts) => `${valueShifts.shift_id}`)
                            })) ?? [],
                        inputs: [
                            {
                                type: 'textField',
                                props: {
                                    required: (data, rowIndex) =>
                                        Array.isArray(data.contract_groups) &&
                                        rowIndex !== null &&
                                        data.contract_groups.length > rowIndex &&
                                        data.contract_groups[rowIndex].shift_ids.length !== 0,
                                    name: 'min',
                                    label: t('label.min', 'Min'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2,
                                    validation: {
                                        deps: ['shift_ids', 'min'],
                                        min: {
                                            value: 1,
                                            message: t('message.info.minimalValueIs1', 'Minimal value is {{value}}.', {
                                                value: 1
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'max',
                                    label: t('label.max', 'Max'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2
                                }
                            },
                            {
                                type: 'multiSelect',
                                props: {
                                    name: 'shift_ids',
                                    required: (data, rowIndex) =>
                                        Array.isArray(data.contract_groups) &&
                                        rowIndex !== null &&
                                        data.contract_groups.length > rowIndex &&
                                        data.contract_groups[rowIndex].min !== '' &&
                                        parseInt(`${data.contract_groups[rowIndex].min}`) !== 0,
                                    label: t('label.shifts', 'Shifts'),
                                    options:
                                        shifts?.map((item) => ({
                                            id: `${item.id}`,
                                            label: `${item.name}`
                                        })) ?? [],
                                    width: 6,
                                    validation: {
                                        deps: ['shift_ids', 'min']
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'external_connection_id',
                        value: loadedData?.external_connection_id,
                        label: t('label.externalConnectionId', 'External Connection ID'),
                        type: 'text',
                        width: 4
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default ContractForm;
