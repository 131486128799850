import { styled } from '@mui/material/styles';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';
import { ClockPickerView } from '@mui/x-date-pickers/internals/models';
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';
import { forwardRef, useCallback } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import config from '@/utils/config';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';
import TimePickerErrorMessages from './TimePickerErrorMessages';

export type ITimePickerProps = Omit<
    MuiTimePickerProps<Dayjs, Dayjs>,
    'label' | 'name' | 'onError' | 'onChange' | 'renderInput' | 'shouldDisableTime'
> &
    Pick<ITextFieldProps, 'name' | 'required' | 'size' | 'hidden'> & {
        label?: string;
        error?: boolean;
        disableEmpty?: boolean;
        helperText?: string;
        onError?: (message: string) => void;
        onChange?: (value: Dayjs | null) => void;
        shouldDisableTime?: (value: Dayjs | null, timeValue: number, clockType: ClockPickerView) => boolean;
    };

const StyledField = styled(TextField)`
    flex: 1;
`;

const TimePicker = forwardRef<HTMLDivElement, ITimePickerProps>(function TimePickerInner(
    {
        inputFormat = config.time.inputFormat,
        name,
        value = null,
        required,
        label,
        disabled,
        readOnly,
        shouldDisableTime,
        hidden,
        size,
        error,
        helperText,
        onError,
        onChange,
        disableEmpty = true,
        ...rest
    },
    ref
) {
    const handleError = useCallback(
        (reason: TimeValidationError) => {
            if (!value) return;

            if (onError) onError(TimePickerErrorMessages(reason));
        },
        [onError]
    );

    const handleOnChange = useCallback(
        (newValue: DateTimeType | null) => {
            if (onChange && ((newValue === null && !disableEmpty) || DateHelper.isValid(newValue))) {
                onChange(newValue);
            }
        },
        [disableEmpty, onChange]
    );

    return (
        <MuiTimePicker
            {...rest}
            ref={ref}
            disabled={disabled || readOnly}
            inputFormat={inputFormat}
            label={label}
            value={value}
            onError={handleError}
            readOnly={readOnly}
            onChange={handleOnChange}
            shouldDisableTime={
                shouldDisableTime ? (timeValue, clockType) => shouldDisableTime(value, timeValue, clockType) : undefined
            }
            renderInput={({ onChange: onChangeValue, ...restField }) => (
                <StyledField
                    {...restField}
                    data-testid={`time_picker_${name}`}
                    disabled={disabled}
                    label={label}
                    size={size}
                    name={name}
                    error={error}
                    hidden={hidden}
                    helperText={helperText}
                    required={required}
                    readOnly={readOnly}
                    onBlur={(e) => {
                        //TODO
                        if (e.target.value && !DateHelper.isStrictValid(e.target.value, inputFormat)) {
                            handleError('invalidDate');
                        }
                    }}
                    onChange={(e) => e && onChangeValue && onChangeValue(e)}
                />
            )}
        />
    );
});

export default TimePicker;
