import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import DateHelper from '@/helpers/date/DateHelper';
import { normalizeNumber } from '@/helpers/number';
import CheckBox from '@/wrappers/Checkbox';
import TextField from '@/wrappers/TextField';
import TimePicker from '@/wrappers/TimePicker';
import TimeRangePicker, { ITimeRangePickerProps } from '@/wrappers/TimeRangePicker';

export type ITimeRangeOrTimeAndLengthValueType = ITimeRangePickerProps['value'] & {
    isRange?: boolean;
};
export type ITimeRangeOrTimeAndLengthProps = {
    name: string;
    disabled?: boolean;
    readOnly?: boolean;
    label: {
        start: string;
        end: string;
        length: string;
        range: string;
    };
    value?: ITimeRangeOrTimeAndLengthValueType;
    inputFormat?: string;
    required?: boolean;
    minutesStep?: number;
    minStart?: ITimeRangeOrTimeAndLengthValueType['start'];
    maxDuration?: number;
    minDuration?: number;
    error?: { time?: boolean; duration?: boolean };
    helperText?: { time?: string; duration?: string };
    onChange?: (value: ITimeRangeOrTimeAndLengthValueType) => void;
    onError?: (value: { time?: string; duration?: string }) => void;
};

const StyledWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr max-content;
`;
const StyledLength = styled(Box)(({ hidden }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    ...(hidden && { display: 'none' })
}));
const StyledSign = styled(Box)(({ theme, hidden }) => ({
    margin: `${theme.spacing(1)} ${theme.spacing(1.7)} 0`,
    ...(hidden && { display: 'none' })
}));
const StyledTextField = styled(TextField)`
    flex: 1;
`;
const StyledCheckbox = styled(FormControlLabel)(
    ({ theme }) => `
        margin-left: ${theme.spacing(1)};
        align-items: flex-start;

        .MuiFormControlLabel-label {
            margin-top: ${theme.spacing(1)};
        }
    `
);

function TimeRangeOrTimeAndLength({
    name,
    label,
    value,
    inputFormat,
    disabled,
    readOnly,
    required,
    minutesStep,
    minStart,
    maxDuration,
    error,
    helperText,
    onError,
    minDuration = 0.25,
    onChange
}: ITimeRangeOrTimeAndLengthProps) {
    const validValue = {
        start: value?.start ?? null,
        duration: value?.duration ?? null,
        isRange: value?.isRange
    };
    const hasLimits = minStart && maxDuration;

    const maxStart = hasLimits ? DateHelper.addHours(minStart ?? null, maxDuration) : undefined;
    const maxValidStart = hasLimits ? DateHelper.addHours(minStart ?? null, maxDuration - minDuration) : undefined;
    const maxDurationSource =
        validValue.start && maxStart ? DateHelper.getDifferenceInHours(validValue.start, maxStart) : 24;
    const maxValidDuration = Math.round(maxDurationSource / minDuration) * minDuration;

    useEffect(() => {
        if (onChange && !validValue.isRange && !validValue.start && hasLimits) {
            onChange({
                ...validValue,
                start: minStart ?? null
            });
        }
    }, [validValue, hasLimits, minStart, onChange]);

    return (
        <StyledWrapper data-testid={`time_range_or_time_and_length_${name}`}>
            {validValue.isRange ? (
                <TimeRangePicker
                    label={label}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                    name={name}
                    inputFormat={inputFormat}
                    minutesStep={minutesStep}
                    value={validValue}
                    error={{ start: !!error?.time, end: !!error?.duration }}
                    helperText={{ start: helperText?.time || '', end: helperText?.duration || '' }}
                    minTime={hasLimits ? minStart : undefined}
                    maxTime={hasLimits ? DateHelper.addHours(validValue.start, maxValidDuration) : undefined}
                    onError={({ start, end }) => onError && onError({ time: start, duration: end })}
                    onChange={(newValue) => {
                        if (onChange) {
                            onChange({
                                isRange: true,
                                ...newValue
                            });
                        }
                    }}
                />
            ) : (
                <StyledLength>
                    <TimePicker
                        name={`${name}.start`}
                        label={label.start}
                        inputFormat={inputFormat}
                        value={validValue.start}
                        readOnly={readOnly}
                        disabled={disabled}
                        required={required}
                        minutesStep={minutesStep}
                        minTime={minStart ?? undefined}
                        maxTime={maxValidStart ?? undefined}
                        error={error?.time}
                        helperText={helperText?.time}
                        onError={(message) => onError && onError({ time: message })}
                        onChange={(newValue) => {
                            if (onChange) {
                                if (validValue.isRange) {
                                    const diff =
                                        DateHelper.getDifferenceInHours(newValue, validValue.start) +
                                        parseFloat(`${validValue.duration ?? '0.0'}`);

                                    onChange({
                                        ...validValue,
                                        start: newValue,
                                        duration: normalizeNumber(diff, (minutesStep ?? 60) / 60)
                                    });
                                } else {
                                    onChange({
                                        ...validValue,
                                        start: newValue
                                    });
                                }
                            }
                        }}
                    />
                    <StyledSign>+</StyledSign>
                    <StyledTextField
                        name={`${name}.duration`}
                        data-testid={`text_field_${name}.duration`}
                        required={required}
                        label={label.length}
                        type="number"
                        readOnly={readOnly}
                        disabled={disabled}
                        value={validValue.duration}
                        error={error?.duration}
                        helperText={helperText?.duration}
                        sx={{ flex: 1 }}
                        onChange={(_, newValue) => {
                            if (onChange) {
                                onChange({
                                    ...validValue,
                                    duration: !newValue ? 0 : parseFloat(`${newValue}`)
                                });
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                min: minDuration,
                                max: hasLimits && validValue.start && maxValidDuration ? maxValidDuration : 24,
                                step: 0.25
                            },
                            endAdornment: 'h'
                        }}
                    />
                </StyledLength>
            )}
            <StyledCheckbox
                disabled={disabled}
                value={!!value?.isRange}
                control={<CheckBox name={name} value={!!value?.isRange} />}
                label={label.range}
                onChange={(newValue) =>
                    !readOnly &&
                    onChange &&
                    onChange({
                        ...validValue,
                        isRange: !!newValue
                    })
                }
            />
        </StyledWrapper>
    );
}

export default TimeRangeOrTimeAndLength;
