import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * TODO dodělat překlady (až v nextjs)
 */
export default function WelcomePage() {
    return (
        <Box
            className="welcomePage"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            data-testid="welcome-page"
        >
            <Box sx={{ typography: 'h1' }}>Welcome</Box>
            <CircularProgress />
            <Box sx={{ typography: 'h6' }}>System is starting...</Box>
        </Box>
    );
}
