import { Dispatch, isRejected } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import { setAlert } from './Alert/AlertSlice';

const dispatchError = (dispatch: Dispatch, message?: string, type?: string) => {
    let parsedMessage = message,
        status = type;

    if (!message?.startsWith('[SKIP]')) {
        if (message?.startsWith('[')) {
            // Template '[XYZ] ErrorMessage'
            status = message?.substring(1, 4);
            parsedMessage = message?.substring(6);
        }

        dispatch(
            setAlert({
                status: Number.parseInt(status ?? '400'),
                context: parsedMessage ? undefined : 'message.error.somethingWentWrong',
                defaultMessage: parsedMessage ?? 'Something went wrong.'
            })
        );
    }
};

const handleErrorMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        try {
            const res = next(action);

            if (isRejected(action) && action.error.code !== '0') {
                dispatchError(dispatch, action.error.message);
            }

            return res;
        } catch (e) {
            dispatchError(dispatch);
        }
    };

export default handleErrorMiddleware;
