import Box from '@mui/material/Box';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { FC, PropsWithChildren } from 'react';
import { ISupportedInputTypes } from './FieldTypes';
import { getSxValue, isFullWidth } from './utils';

type IProps = PropsWithChildren<{
    type: ISupportedInputTypes;
    fullWidth: boolean;
    isDisplayed: boolean;
    width?: number;
    noWrap?: boolean;
    widthByContent?: boolean;
    sx?: FormControlProps['sx'];
}>;

const FieldWrapper: FC<IProps> = ({
    children,
    type,
    fullWidth,
    isDisplayed,
    sx,
    width = 12,
    noWrap = false,
    widthByContent = false,
    ...rest
}) =>
    type === 'multiRowInputs' ? (
        <div style={{ ...(!isDisplayed ? { display: 'none' } : {}), width: '100%' }}>{children}</div>
    ) : type === 'html' ? (
        <Box
            {...rest}
            sx={{
                ...(widthByContent ? {} : getSxValue(width)),
                ...sx,
                ...(!isDisplayed ? { display: 'none' } : {})
            }}
        >
            {children}
        </Box>
    ) : (
        <FormControl
            {...rest}
            variant="outlined"
            sx={{
                ...sx,
                ...getSxValue(width),
                ...(!isDisplayed ? { display: 'none' } : {}),
                ...(!noWrap ? { marginBottom: 0 } : {})
            }}
            fullWidth={isFullWidth(fullWidth, { type, props: { fullWidth, name: '', sx } })}
        >
            {children}
        </FormControl>
    );

export default FieldWrapper;
