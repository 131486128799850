import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { IColors, IPredefinedColors, predefinedColors } from '@/assets/theme';
import Tooltip from '@/wrappers/Tooltip';

export type IChipProps = Omit<MuiChipProps, 'name' | 'color'> & {
    name: string;
    color?: IPredefinedColors | 'string';
};

export default function Chip({ className = '', color, style, title = '', variant = 'outlined', ...rest }: IChipProps) {
    let colorToSet: IColors | undefined;

    if (predefinedColors.includes(color as IPredefinedColors)) {
        colorToSet = color as IColors;
    }

    const content = (
        <MuiChip
            {...rest}
            className={`${className} chip`}
            color={colorToSet}
            variant={variant}
            style={{
                ...style,
                backgroundColor: colorToSet || variant === 'outlined' ? 'none' : color,
                borderColor: !colorToSet && variant === 'outlined' ? color : undefined,
                color: !colorToSet && variant === 'outlined' ? color : undefined
            }}
        />
    );

    return title !== '' ? <Tooltip title={title}>{content}</Tooltip> : content;
}
