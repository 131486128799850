import { useCallback } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { createActivity, updateActivity } from '@/data/Activities/ActivityActions';
import { IActivityModel } from '@/data/Activities/ActivityModels';
import {
    activityById,
    activityCreatingStatus,
    activityPaging,
    activityUpdatingStatus,
    updatePaging
} from '@/data/Activities/ActivitySlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';

type IProps = Omit<ICuProps<IActivityModel>, 'resource'>;

const ActivityForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...rest }: IProps) => {
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => activityById(state, id));
    const paging = useAppSelector(activityPaging);
    const handleData = (values: IOutputValueType) => ({ name: values.name as string });
    const handleCreate = useCallback((values: IOutputValueType) => createActivity(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) =>
            updateActivity({
                id: id!,
                data: handleData(values)
            }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    return (
        <CuForm
            {...rest}
            id={id}
            name="activity"
            resource={PermissionsEnum.Activities}
            creatingStatus={useAppSelector(activityCreatingStatus)}
            updatingStatus={useAppSelector(activityUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            maxWidth="sm"
            items={[
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: 'Name',
                        value: loadedData?.name,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default ActivityForm;
