import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { detachShiftFromDay } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import { ISchedulePlanDayShiftExtendedModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { selectSchedulePlanDayShiftEntities } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { agreeWithOfferedShift } from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import { IShiftTradeOfferModel } from '@/data/ShiftTradeOffers/ShiftTradeOfferModels';
import { selectAcceptedShiftTradeOffers } from '@/data/ShiftTradeOffers/ShiftTradeOfferSlice';
import {
    approveTrade,
    createShiftTrade,
    deleteShiftTrade,
    fetchShiftTradesByAssignedShift,
    rejectTrade,
    takeThisShiftFromTrade
} from '@/data/ShiftTrades/ShiftTradeActions';
import { IRootState } from '@/data/store';
import { uniqueArrayOfSimpleValues } from '@/helpers/array/unique';
import { IShiftTradeExtendedModel, IShiftTradeModel } from './ShiftTradeModels';

type IShiftTradeState = {
    tradesForAssignedShift: Record<number, number[]>;
    approvingStatus: IRequestState;
    creatingStatus: IRequestState;
    removingStatus: IRequestState;
    byAssignedShiftStatus: IRequestState;
};

const initialState: IShiftTradeState = {
    tradesForAssignedShift: {},
    approvingStatus: 'idle',
    creatingStatus: 'idle',
    removingStatus: 'idle',
    byAssignedShiftStatus: 'idle'
};

const adapter = createEntityAdapter<IShiftTradeModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.created.localeCompare(b.created)
});

const filterTrades = (input: (IShiftTradeModel | null)[]) =>
    input.filter((item) => typeof item?.id === 'number') as IShiftTradeModel[];

const shiftTradeSlice = createSlice({
    name: 'shiftTrades',
    initialState: adapter.getInitialState(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
            adapter.upsertMany(
                state,
                filterTrades(
                    action.payload.schedule_plan_day_shifts.flatMap(
                        ({ schedule_plan_day_shift_trades = [] }) => schedule_plan_day_shift_trades
                    )
                )
            );
        });
        builder.addCase(detachShiftFromDay.fulfilled, (state, action) => {
            adapter.removeMany(
                state,
                (Object.values(state.entities) as IShiftTradeModel[])
                    .filter(({ schedule_plan_day_shift_id }) => schedule_plan_day_shift_id === action.meta.arg.id)
                    .map(({ id }) => id)
            );
        });
        builder
            .addCase(createShiftTrade.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createShiftTrade.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const { schedule_plan_day_shift, ...entity } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.addOne(state, entity);
            })
            .addCase(createShiftTrade.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(takeThisShiftFromTrade.pending, (state) => {
                state.approvingStatus = 'loading';
            })
            .addCase(takeThisShiftFromTrade.fulfilled, (state, action) => {
                state.approvingStatus = 'idle';

                adapter.updateOne(state, {
                    id: action.payload.tradeId,
                    changes: { state: 'CLOSED' }
                });
            })
            .addCase(takeThisShiftFromTrade.rejected, (state) => {
                state.approvingStatus = 'failed';
            })
            .addCase(deleteShiftTrade.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(deleteShiftTrade.fulfilled, (state, action) => {
                state.removingStatus = 'idle';

                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(deleteShiftTrade.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(fetchShiftTradesByAssignedShift.pending, (state) => {
                state.byAssignedShiftStatus = 'loading';
            })
            .addCase(fetchShiftTradesByAssignedShift.fulfilled, (state, action) => {
                state.byAssignedShiftStatus = 'idle';

                const trades = action.payload.data.map(({ schedule_plan_day_shift, ...item }) => item);

                if (trades.length) {
                    adapter.upsertMany(state, trades);
                    state.tradesForAssignedShift[action.meta.arg] = trades.map(({ id }) => id);
                }
            })
            .addCase(fetchShiftTradesByAssignedShift.rejected, (state) => {
                state.byAssignedShiftStatus = 'failed';
            })
            .addCase(agreeWithOfferedShift.pending, (state) => {
                state.byAssignedShiftStatus = 'loading';
            })
            .addCase(agreeWithOfferedShift.fulfilled, (state, action) => {
                state.byAssignedShiftStatus = 'idle';

                if (action.payload) {
                    if (!action.payload.plan) {
                        adapter.updateOne(state, { id: action.payload.offer.id, changes: action.payload.offer });
                    } else {
                        adapter.updateOne(state, { id: action.payload.offer.id, changes: { state: 'SWAPPED' } });
                    }
                }
            })
            .addCase(agreeWithOfferedShift.rejected, (state) => {
                state.byAssignedShiftStatus = 'failed';
            })
            .addCase(approveTrade.pending, (state) => {
                state.approvingStatus = 'loading';
            })
            .addCase(approveTrade.fulfilled, (state, action) => {
                state.approvingStatus = 'idle';

                adapter.updateOne(state, { id: action.meta.arg, changes: { state: 'SWAPPED' } });
            })
            .addCase(approveTrade.rejected, (state) => {
                state.approvingStatus = 'failed';
            })
            .addCase(rejectTrade.pending, (state) => {
                state.approvingStatus = 'loading';
            })
            .addCase(rejectTrade.fulfilled, (state, action) => {
                state.approvingStatus = 'idle';

                adapter.updateOne(state, { id: action.meta.arg, changes: { state: 'CLOSED' } });
            })
            .addCase(rejectTrade.rejected, (state) => {
                state.approvingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[shiftTradeSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default shiftTradeSlice;

export const isActiveShiftTradeFilter = (state: IShiftTradeModel['state']) => ['OPENED', 'SELECTED'].includes(state);
export const shiftTrades = adapterSelectors.selectAll;

export const shiftTradeIdBySchedulePlanDayShiftId = (state: IRootState, schedulePlanDayShiftId: number) =>
    adapterSelectors
        .selectAll(state)
        .find(({ schedule_plan_day_shift_id }) => schedule_plan_day_shift_id === schedulePlanDayShiftId)?.id ?? null;

const selectShiftTradeEntities = createSelector(adapterSelectors.selectAll, (trades) => {
    const result: Record<number, IShiftTradeModel[]> = {};

    trades.forEach((trade) => {
        if (isActiveShiftTradeFilter(trade.state)) {
            if (trade.schedule_plan_day_shift_id in result) {
                result[trade.schedule_plan_day_shift_id].push(trade);
            } else {
                result[trade.schedule_plan_day_shift_id] = [trade];
            }
        }
    });

    return result;
});

/**
 * Shift trades on Assigned shift to User.
 * @param state
 * @param schedulePlanDayShiftId ID of Assigned Shift to User
 */
export const shiftTradesBySchedulePlanDayShiftId = (
    state: IRootState,
    schedulePlanDayShiftId: number | null
): IShiftTradeModel[] | null =>
    schedulePlanDayShiftId !== null ? selectShiftTradeEntities(state)[schedulePlanDayShiftId] ?? null : null;

export const isAssignedShiftInTradeCenter = (state: IRootState, schedulePlanDayShiftId?: number) =>
    schedulePlanDayShiftId &&
    adapterSelectors
        .selectAll(state)
        .some(
            (shiftTrade) =>
                shiftTrade.schedule_plan_day_shift_id === schedulePlanDayShiftId &&
                isActiveShiftTradeFilter(shiftTrade.state)
        );

export const shiftTradesByAssignedShiftStatus = (state: IRootState) => getState(state).byAssignedShiftStatus;
export const selectShiftTrades = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => selectSchedulePlanDayShiftEntities(state),
    (entities, schedulePlanDayShifts) => {
        const result: Record<number, IShiftTradeExtendedModel> = {};

        entities.forEach((entity) => {
            const schedulePlanDayShift = schedulePlanDayShifts[entity.schedule_plan_day_shift_id] ?? null;

            if (
                schedulePlanDayShift &&
                schedulePlanDayShift.shift_id !== null &&
                schedulePlanDayShift.shift_end !== null &&
                schedulePlanDayShift.shift !== null &&
                typeof schedulePlanDayShift.user_id === 'number'
            ) {
                result[entity.id] = {
                    ...entity,
                    schedule_plan_day_shift: schedulePlanDayShift as ISchedulePlanDayShiftExtendedModel
                };
            }
        });

        return result;
    }
);

export const selectShiftTradesToApprove = createSelector(
    shiftTrades,
    selectShiftTrades,
    (state: IRootState) => selectAcceptedShiftTradeOffers(state),
    (shiftTradeList, shiftTradesEntities, shiftTradeOfferEntities) => {
        const result: Record<
            number,
            IShiftTradeExtendedModel & {
                selected_offer: IShiftTradeOfferModel & {
                    schedule_plan_day_shift: ISchedulePlanDayShiftExtendedModel;
                };
            }
        > = {};

        shiftTradeList
            .filter((shiftTrade) => shiftTrade.state === 'SELECTED')
            .forEach((item) => {
                const shiftTrade = shiftTradesEntities[item.id];
                const shiftTradeOffer = shiftTradeOfferEntities[item.id];

                if (item && shiftTradeOffer) {
                    result[item.id] = {
                        ...shiftTrade,
                        selected_offer: shiftTradeOffer
                    };
                }
            });

        return result;
    }
);

export const selectShiftIdsWithTradeToApprove = createSelector(selectShiftTradesToApprove, (shiftTradesToApprove) =>
    uniqueArrayOfSimpleValues(
        Object.values(shiftTradesToApprove).flatMap((shiftTrade) => [
            shiftTrade.schedule_plan_day_shift_id,
            shiftTrade.selected_offer.schedule_plan_day_shift_id
        ])
    )
);
export const selectOfferedShiftTrades = createSelector(
    (state: IRootState) => getState(state).tradesForAssignedShift,
    selectShiftTrades,
    (offeredShiftTrades, entities) => {
        const result: Record<number, IShiftTradeExtendedModel[]> = {};

        Object.keys(offeredShiftTrades)
            .map((id) => parseInt(id))
            .forEach((schedulePlanDayShiftId) => {
                const offeredIds = offeredShiftTrades[schedulePlanDayShiftId];

                result[schedulePlanDayShiftId] = offeredIds
                    .map((id) => entities[id])
                    .filter((item) => !!item) as IShiftTradeExtendedModel[];
            });

        return result;
    }
);

export const shiftTradeRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const shiftTradeApprovingStatus = (state: IRootState) => getState(state).approvingStatus;
