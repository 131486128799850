import { useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@/data/hooks';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import UserDetail from '@/modules/UserDetail';
import { ControlRef } from '@/modules/UserDetail/UserDetail';
import { mainUsers } from '@/utils/routes';
import LayoutOfPage from './LayoutOfPage';

export default function UsersDetailPage() {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const parsedId = Number(id);
    const goToList = useCallback(() => navigate(mainUsers()), []);
    const formRef = useRef<ControlRef>(null);
    const isCurrentUserUser = useAppSelector(isSignedUserUser);
    const handleSave = () => formRef.current?.submitForm();

    if (Number.isNaN(parsedId)) {
        goToList();
    }

    return (
        <LayoutOfPage
            title={t('title.detailOfEmployee', 'Detail of Employee')}
            actionBack={{
                display: true,
                onClick: goToList
            }}
            actionSave={{
                display: !isCurrentUserUser,
                onClick: handleSave
            }}
        >
            <UserDetail ref={formRef} id={parsedId} onSaveFinished={goToList} readOnly={isCurrentUserUser} />
        </LayoutOfPage>
    );
}
