import { fetchPost } from '../request';
import { IGoogleSyncModel } from './GoogleModels';

export function sync(schedulePlanId: number) {
    return fetchPost<{ status: number }>('/api/google/sync-calendar', { schedulePlanId });
}

export function syncSchedules(data: IGoogleSyncModel) {
    return fetchPost('/api/google/sync-schedule-plan-calendar', data);
}

export function syncBreaks(data: IGoogleSyncModel) {
    return fetchPost('/api/google/sync-break-calendar', data);
}
