import { DateValidationError } from '@mui/x-date-pickers/internals';
import { message } from '@/utils/validations';

const DatePickerErrorMessages = (reason: DateValidationError) => {
    switch (reason) {
        case 'invalidDate':
            return message.date.invalid;
        case 'minDate':
            return message.date.minDate;
        case 'maxDate':
            return message.date.maxDate;
        default:
            return '';
    }
};

export default DatePickerErrorMessages;
