const message = {
    required: 'This field is required.',
    name: 'This field could contain only letters, numbers, spaces and special characters (-, /).',
    text: 'This field contains invalid characters.',
    number: 'This field could contain only numbers.',
    phone: 'Phone is not in correct format.',
    email: 'E-mail is not in correct format.',
    password: {
        min: 'Password must contain at least 8 characters.',
        match: 'Passwords do not match.',
        pattern: 'The password must contain at least one upper and lower case letter and one digit.'
    },
    date: {
        invalid: 'Invalid date format.',
        minDate: 'Value is before minimal date.',
        maxDate: 'Value is after maximal date.'
    },
    time: {
        invalid: 'Invalid time format',
        minTime: 'Value is before minimal time',
        maxTime: 'Value is after maximal time'
    },
    datetime: {
        invalid: 'Invalid time format',
        minDate: 'Value is before minimal date.',
        maxDate: 'Value is after maximal date.',
        minTime: 'Value is before minimal time',
        maxTime: 'Value is after maximal time'
    }
};

export default message;
