import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShifts, removeShift, updateShiftActive } from '@/data/Shifts/ShiftActions';
import { IShiftModel } from '@/data/Shifts/ShiftModels';
import {
    isShiftsListInProgress,
    selectFilteredShifts,
    shiftPaging,
    shiftRemovingStatus
} from '@/data/Shifts/ShiftSlice';
import ShiftForm from '@/forms/ShiftForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import DateRange from '@/wrappers/Datatable/CellViews/DateRange';
import TimeRange from '@/wrappers/Datatable/CellViews/TimeRange';
import Weekdays from '@/wrappers/Datatable/CellViews/Weekdays';
import Switch from '@/wrappers/Switch';

const ShiftsListPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const inProgress = useAppSelector(isShiftsListInProgress);
    const data = useAppSelector(selectFilteredShifts);

    return (
        <LayoutOfPage title={t('title.shiftsList', 'Shifts List')}>
            <CrudDatatable<IShiftModel>
                name="shift"
                resource={PermissionsEnum.Shifts}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <ShiftForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { title: t('header.shiftName', 'Shift Name'), field: 'name' },
                    {
                        title: t('header.days', 'Days'),
                        field: 'days',
                        sorting: false,
                        render: (rowData: IShiftModel) => <Weekdays days={rowData.days} holidays={rowData.holiday} />
                    },
                    {
                        title: t('header.valid', 'Valid'),
                        field: 'valid',
                        type: 'date',
                        sorting: false,
                        render: (rowData: IShiftModel) => (
                            <DateRange start={rowData.valid_start} end={rowData.valid_end} />
                        )
                    },
                    {
                        title: t('header.time', 'Time'),
                        field: 'time',
                        type: 'datetime',
                        sorting: false,
                        render: (rowData: IShiftModel) => (
                            <TimeRange
                                start={rowData.time_start}
                                end={DateHelper.fromTimeStringAndDuration(rowData.time_start, rowData.duration)}
                            />
                        )
                    },
                    {
                        title: t('header.active', 'Active'),
                        field: 'active',
                        render: (rowData: IShiftModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.active}
                                title={
                                    rowData.active
                                        ? t('label.deactivate', 'Deactivate')
                                        : t('label.activate', 'Activate')
                                }
                                onChange={(value) => {
                                    dispatch(
                                        updateShiftActive({
                                            id: rowData.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(shiftPaging)}
                removingStatus={useAppSelector(shiftRemovingStatus)}
                onFetchList={fetchShifts}
                onRemove={removeShift}
            />
        </LayoutOfPage>
    );
};

export default ShiftsListPage;
