import { ReactNode } from 'react';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppSelector } from '@/data/hooks';
import { getPermissionsList } from '@/data/System/SystemReducer';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';

type IProps<RowType extends object> = Pick<
    IDatatableProps<ICrudInlineData<RowType>>,
    'data' | 'header' | 'actionsInHeader' | 'actions'
> & {
    labelAddButton: ReactNode;
    resource?: PermissionsEnumType;
    title?: string;
    onRowAdd?: (newData: ICrudInlineData<RowType>) => Promise<void>;
    onRowUpdate?: (
        newData: ICrudInlineData<RowType>,
        oldData?: ICrudInlineData<RowType> & { tableData?: { id: number } }
    ) => Promise<void>;
    onRowRemove?: (oldData: ICrudInlineData<RowType> & { tableData?: { id: number } }) => Promise<void>;
};

export type ICrudInlineData<T extends object> = T & { id?: string };
export type ICrudInlineDataList<T extends object> = ICrudInlineData<T>[];

const CrudInlineDatatable = <RowType extends object>({
    resource,
    onRowAdd,
    onRowRemove,
    onRowUpdate,
    ...props
}: IProps<RowType>) => {
    const permissionsList = useAppSelector(getPermissionsList);

    return (
        <Datatable
            {...props}
            labelAddButton={props.labelAddButton}
            hasSearch={false}
            hasSelection={false}
            hasTitle={!!props.title}
            editable={{
                onRowAdd:
                    onRowAdd &&
                    (!resource ||
                        isUserAllowed(
                            {
                                id: resource,
                                mode: Mode.CREATE
                            },
                            permissionsList
                        ))
                        ? async (newData) => {
                              try {
                                  await onRowAdd(newData);
                              } catch (error) {
                                  console.log(`On row add error: ${error}`);
                              }
                          }
                        : undefined,
                onRowUpdate:
                    onRowUpdate &&
                    (!resource ||
                        isUserAllowed(
                            {
                                id: resource,
                                mode: Mode.UPDATE
                            },
                            permissionsList
                        ))
                        ? async (newData, oldData?: RowType & { tableData?: { id: number } }) => {
                              try {
                                  await onRowUpdate(newData, oldData);
                              } catch (error) {
                                  console.log(`On row update error: ${error}`);
                              }
                          }
                        : undefined,
                onRowDelete:
                    onRowRemove &&
                    (!resource ||
                        isUserAllowed(
                            {
                                id: resource,
                                mode: Mode.CREATE
                            },
                            permissionsList
                        ))
                        ? async (oldData: RowType & { tableData?: { id: number } }) => {
                              try {
                                  await onRowRemove(oldData);
                              } catch (error) {
                                  console.log(`On row remove error: ${error}`);
                              }
                          }
                        : undefined
            }}
        />
    );
};

export default CrudInlineDatatable;
