import { memo, useCallback } from 'react';
import { daysArrayOfIndexIntoValue, getDayBitBooleanArrayFromValue } from '@/helpers/bit';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import MultiSelect from '@/wrappers/MultiSelect';

export type IWeekdaysMultiSelectProps = {
    name: string;
    label: string;
    value?: number;
    onChange?: (value: number) => void;
};

function WeekdaysMultiSelect({ name, label, value, onChange }: IWeekdaysMultiSelectProps) {
    const translatedDays = useTranslatedDays();
    const days = getDayBitBooleanArrayFromValue(value || 0);
    const handleOnChange = useCallback(
        (values: string[] | number[] | null) => onChange && onChange(daysArrayOfIndexIntoValue(values) ?? 0),
        []
    );

    return (
        <MultiSelect
            name={name}
            label={label}
            options={translatedDays.map((day) => ({
                id: day.id.toString(),
                label: day.name
            }))}
            value={translatedDays.filter((day) => days[day.id]).map((item) => item.id.toString())}
            onChange={handleOnChange}
        />
    );
}

export default memo(WeekdaysMultiSelect);
