import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { notificationList, removeAllNotification } from '@/data/Notification/NotificationSlice';
import useAppTranslation from '@/hooks/useAppTranslation';

const NotificationStack = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const notifications = useAppSelector(notificationList);

    useEffect(() => {
        if (notifications.length) {
            dispatch(removeAllNotification());

            notifications.forEach((item) => {
                if (item.variant === 'collapse') {
                    enqueueSnackbar({
                        variant: 'collapse',
                        customProps: item.customProps,
                        autoHideDuration: item.autoHideDuration ?? 3000,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        style: { whiteSpace: 'pre-line' },
                        persist: typeof item.autoHideDuration === 'number' ? true : undefined
                    });
                } else {
                    let message;

                    if (!item.message && item.context) {
                        message = t(item.context, item.defaultMessage ?? item.context, item.values);
                    } else {
                        message = item.message ?? '';
                    }

                    enqueueSnackbar(message, {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        autoHideDuration: item.autoHideDuration ?? 3000,
                        variant: item.variant,
                        style: { whiteSpace: 'pre-line' },
                        action: (snackbarId: SnackbarKey) => (
                            <IconButton onClick={() => closeSnackbar(snackbarId)}>
                                <CloseIcon />
                            </IconButton>
                        )
                    });
                }
            });
        }
    }, [notifications]);

    return <></>;
};

export default NotificationStack;
