import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { schedulePlanById, selectOnGoingPlanById } from '@/data/SchedulePlans/SchedulePlanSlice';
import { IFilterChanged } from '@/helpers/schedule';
import Scheduler, { ISchedulerProps } from '@/modules/Scheduler/Scheduler';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import { mainSchedulePlansDetail } from '@/utils/routes';
import LoadingButton from '@/wrappers/LoadingButton';

type IProps = {
    id: number;
};

type IDashboardSchedule = {
    id: ISchedulerProps['schedulePlanId'];
    from: ISchedulerProps['fromDate'];
    to: ISchedulerProps['toDate'];
    displayMode: ISchedulerProps['displayMode'];
    type: ISchedulerProps['type'];
    workplaceId: ISchedulerProps['workplaceId'];
    periodId: ISchedulerProps['periodId'];
};

const StyledScheduleSubtitleBox = styled(Box)`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 10px;
`;

const OnGoingSchedulerWrapper = ({ id }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const onGoingSchedulePlan = useAppSelector((state) => selectOnGoingPlanById(state, id));
    const schedulePlanData = useAppSelector((state) => schedulePlanById(state, id));
    const [navigateSchedulePlanLoading, setNavigateSchedulePlanLoading] = useState(false);
    const [scheduleFilterData, setScheduleFilterData] = useState<IDashboardSchedule | null>(null);
    const handleOnFilterChanged = useCallback(
        (values: IFilterChanged) => {
            let paramsList: Record<string, string | number | undefined> = {
                id: undefined,
                from: undefined,
                to: undefined,
                displayMode: SchedulePlanWidthTypeEnum.Period,
                type: 'table',
                workplaceId: '',
                periodId: ''
            };

            values.forEach((param) => {
                if (paramsList[param.name] !== `${param.value || ''}`) {
                    paramsList = {
                        ...paramsList,
                        [param.name]: param.value || ''
                    };
                }
            });

            Object.keys(paramsList).forEach((key) => {
                if (!values.some((param) => param.name == key)) {
                    if (scheduleFilterData) {
                        paramsList[key] = scheduleFilterData[key as keyof IDashboardSchedule] ?? undefined;
                    }
                }
            });

            if (paramsList.id) {
                setScheduleFilterData(
                    paramsList.id
                        ? ({
                              id: parseInt(`${paramsList.id}`),
                              from: paramsList.from,
                              to: paramsList.to,
                              displayMode: paramsList.displayMode,
                              type: paramsList.type,
                              workplaceId: paramsList.workplaceId,
                              periodId: paramsList.periodId
                          } as IDashboardSchedule)
                        : null
                );
            }
        },
        [scheduleFilterData]
    );

    useEffect(() => {
        if (scheduleFilterData && scheduleFilterData.id && scheduleFilterData?.id != id) {
            dispatch(fetchSchedulePlanById(scheduleFilterData.id));
        }
    }, [scheduleFilterData]);

    const navigateToSchedulePlan = useCallback(
        (
            schedulePlanId: number,
            workplaceId?: number,
            periodId?: number,
            dateFrom?: string,
            dateTo?: string,
            displayMode?: string,
            type?: string
        ) => {
            setNavigateSchedulePlanLoading(true);

            navigate(
                mainSchedulePlansDetail({
                    isNew: 0,
                    id: schedulePlanId,
                    ...(workplaceId && { workplaceId: workplaceId }),
                    ...(periodId && { periodId: periodId }),
                    ...(dateFrom && { from: dateFrom }),
                    ...(dateTo && { to: dateTo }),
                    ...(displayMode && { displayMode: displayMode }),
                    type: type ? type : 'table'
                })
            );
        },
        []
    );

    return onGoingSchedulePlan ? (
        <>
            <StyledScheduleSubtitleBox>
                <LoadingButton
                    loading={navigateSchedulePlanLoading}
                    variant={'text'}
                    name={`schedulePlanDetail_${id}`}
                    startIcon={<PageviewOutlinedIcon />}
                    onClick={() =>
                        navigateToSchedulePlan(
                            scheduleFilterData?.id ?? onGoingSchedulePlan.id,
                            scheduleFilterData?.workplaceId ?? onGoingSchedulePlan.workplace_id,
                            scheduleFilterData?.periodId ?? onGoingSchedulePlan.period_id,
                            scheduleFilterData?.from ?? undefined,
                            scheduleFilterData?.to ?? undefined,
                            scheduleFilterData?.displayMode ?? SchedulePlanWidthTypeEnum.Period,
                            scheduleFilterData?.type ?? 'table'
                        )
                    }
                >
                    <Typography sx={{ cursor: 'pointer' }}>
                        {schedulePlanData?.name ?? onGoingSchedulePlan.name}
                    </Typography>
                </LoadingButton>
            </StyledScheduleSubtitleBox>
            <Scheduler
                schedulePlanId={scheduleFilterData?.id ?? onGoingSchedulePlan.id}
                canCalculate={false}
                isNew={false}
                dashboardView
                workplaceId={scheduleFilterData?.workplaceId ?? onGoingSchedulePlan.workplace_id}
                periodId={scheduleFilterData?.periodId ?? onGoingSchedulePlan.period_id}
                displayMode={scheduleFilterData?.displayMode ?? SchedulePlanWidthTypeEnum.Period}
                fromDate={scheduleFilterData?.from ?? null}
                toDate={scheduleFilterData?.to ?? null}
                type={scheduleFilterData?.type ?? 'table'}
                onFilterChanged={handleOnFilterChanged}
                onSchedulePlanCreated={() => {}}
            />
        </>
    ) : (
        <></>
    );
};

export default OnGoingSchedulerWrapper;
