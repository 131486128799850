import { IDataResponse, IListResponse } from '@/data/ApiResponse';
import { fetchGet, urlBuilderWithParams } from '@/data/request';
import { IUserVacationFundModel } from '@/data/UserToVacationFunds/UserToVacationFundModel';

export function fetchFund(args: { userId: number; year: number; requestTypeId: number }) {
    const url = urlBuilderWithParams(`/api/user-to-vacation-fund/get-users-actual-vacation-fund/${args.userId}`, args);

    return fetchGet<IDataResponse<IUserVacationFundModel>>(url);
}

export function getFundForInsertedVacation(args: {
    userId: number;
    requestTypeId: number;
    vacationStart: string;
    vacationEnd: string;
}) {
    const url = urlBuilderWithParams(
        `/api/user-to-vacation-fund/get-user-vacation-fund-after-inserted-vacation/${args.userId}`,
        args
    );

    return fetchGet<IListResponse<IUserVacationFundModel>>(url);
}
