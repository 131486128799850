import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createAgentGroup,
    fetchAgentGroupById,
    fetchAgentGroups,
    removeAgentGroup,
    updateAgentGroup
} from './AgentGroupActions';
import { IAgentGroupModel } from './AgentGroupModels';

type IAgentGroupReducerState = {
    paging: IPaging;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IAgentGroupReducerState = {
    paging: defaultPaging('name'),
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IAgentGroupModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const agentGroupReducer = createSlice({
    name: 'agentGroups',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgentGroups.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchAgentGroups.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchAgentGroups.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchAgentGroupById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchAgentGroupById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchAgentGroupById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createAgentGroup.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createAgentGroup.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createAgentGroup.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeAgentGroup.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeAgentGroup.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(removeAgentGroup.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateAgentGroup.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateAgentGroup.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateAgentGroup.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

export const agentGroupInitialState = agentGroupReducer.getInitialState();

const getState = (state: IRootState) => state[agentGroupReducer.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default agentGroupReducer.reducer;

export const isAgentGroupListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const agentGroupPaging = (state: IRootState) => getState(state).paging;
export const isAgentGroupByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const agentGroupList = adapterSelectors.selectAll;
export const agentGroupById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) : undefined;
export const agentGroupCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const agentGroupUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const agentGroupRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = agentGroupReducer.actions;
