import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IShiftCUModel, IShiftModel, IShiftSelectModel } from './ShiftModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/shifts', paging || {});

    return fetchGet<IListResponseWithPaging<IShiftModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/shifts/select', { search, fields });

    return fetchGet<IListResponse<IShiftSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IShiftModel>(`/api/shifts/${id}`);
}

export function create(data: Omit<IShiftCUModel, 'shift_items'>) {
    return fetchPost<IShiftModel>('/api/shifts/', data);
}

export function update(id: number, data: Partial<Omit<IShiftCUModel, 'shift_items'>>) {
    return fetchPatch<IShiftModel>(`/api/shifts/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/shifts/${id}`);
}
