import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IRequestTypeCUModel, IRequestTypeModel, IRequestTypeSelectModel } from './RequestTypeModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/request-types', paging || {});

    return fetchGet<IListResponseWithPaging<IRequestTypeModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/request-types/select', { search, fields });

    return fetchGet<IListResponse<IRequestTypeSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IRequestTypeModel>(`/api/request-types/${id}`);
}

export function create(data: IRequestTypeCUModel) {
    return fetchPost<IRequestTypeModel>('/api/request-types/', data);
}

export function update(id: number, data: Partial<IRequestTypeCUModel>) {
    return fetchPatch<IRequestTypeModel>(`/api/request-types/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/request-types/${id}`);
}
