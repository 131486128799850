import dayjs from 'dayjs';

type IAvailableType = Date | null;

const dateAdd = <T extends IAvailableType>(source: T, time: number, timeType: 'd' | 'h' | 'm' | 's' = 's'): T =>
    (source === null ? null : dayjs(source).add(time, timeType).toDate()) as T;
const dateAddDays = <T extends IAvailableType>(source: T, days: number) => {
    if (source) {
        const result = new Date(source);

        result.setDate(source.getDate() + days);

        return result as T;
    }

    return null as T;
};
const dateAddMinutes = <T extends IAvailableType>(source: T, minutes: number) => dateAdd(source, minutes, 'm');
const dateAddHours = <T extends IAvailableType>(source: T, hours: number) => dateAdd(source, hours, 'h');

export { dateAddDays, dateAddMinutes, dateAddHours };

export default dateAdd;
