import Typography from '@mui/material/Typography';
import {
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
    Area,
    AreaChart,
    ReferenceLine,
    ResponsiveContainer
} from 'recharts';
import { useAppSelector } from '@/data/hooks';
import { ISchedulePlanCoverageRequireForecastAccuracyReport } from '@/data/Reports/ReportModels';
import { isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading } from '@/data/Reports/ReportSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import LinearProgress from '@/wrappers/LinearProgress';

type IProps = {
    data?: ISchedulePlanCoverageRequireForecastAccuracyReport[];
    colorRequired?: string;
    colorCoverage?: string;
    width?: number;
    height?: number;
    timeZone?: string;
};

const SchedulePlanReportCoverageRequiredChart = ({
    data = [],
    colorRequired = '#D82134',
    colorCoverage = '#29CA54',
    timeZone,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const isLoading = useAppSelector(isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading);
    const xAxisDateFormatter = useLocalizeDateFormatter({
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateTickFormatter = (value: Date) => xAxisDateFormatter.format(value);

    return (
        <>
            <Typography variant="subtitle2">
                {t('label.schedulePlanAdherenceCoverageToRequired', 'Schedule Plan Reports Coverage/Required')}
            </Typography>

            {isLoading && <LinearProgress />}
            <ResponsiveContainer width={props.width} height={'50%'}>
                <AreaChart
                    syncId="areaChartCoverageToRequire"
                    data={
                        isLoading
                            ? undefined
                            : data.map((item) => ({
                                  ...item,
                                  name: DateHelper.fromDateTimeString(item.start).toDate()
                              }))
                    }
                    margin={{
                        top: 15,
                        right: 30,
                        left: 10,
                        bottom: 60
                    }}
                >
                    <defs>
                        <linearGradient id="colorRequired" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorRequired} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={colorRequired} stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id="colorCoverage" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorCoverage} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={colorCoverage} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        angle={270}
                        tickMargin={40}
                        height={45}
                        interval={95}
                        tickFormatter={dateTickFormatter}
                    />
                    <ReferenceLine y={0} stroke="#000" />
                    <YAxis
                        domain={([_, dataMax]) => {
                            const max = 2 * Math.round((dataMax + 1) / 2);

                            return [0, max];
                        }}
                        label={{
                            value: t('label.operators', 'Operators'),
                            angle: -90,
                            position: 'insideBottomLeft'
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                        labelFormatter={(value) => dateTimeFormatter.format(value)}
                        formatter={(value, name) => [value, t(`label.${name}`, name.toString())]}
                    />
                    <Area type="monotone" dataKey="required" stroke={colorRequired} fill="url(#colorRequired)" />
                    <Area type="monotone" dataKey="coverage" stroke={colorCoverage} fill="url(#colorCoverage)" />
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                        formatter={(value) => <>{t(`label.${value}`, value)}</>}
                        wrapperStyle={{
                            paddingTop: '12px'
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default SchedulePlanReportCoverageRequiredChart;
