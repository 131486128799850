import { styled, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';
import TimeRange from '@/wrappers/Datatable/CellViews/TimeRange';

type IItemProps = {
    backgroundColor?: string;
    borderColor?: string;
    left: number;
    width: number;
};

const Item: React.FC = (props) => <div {...props} />;

const StyledItem = styled(Item, {
    shouldForwardProp: (propName) => !['left', 'width', 'backgroundColor', 'borderColor'].includes(propName as string)
})<IItemProps>(({ backgroundColor, borderColor, left, width }) => ({
    backgroundColor,
    position: 'absolute',
    display: 'inline-block',
    width: `${width}%`,
    left: `${left}%`,
    height: '42px',
    lineHeight: 1,
    border: `1px solid ${borderColor}`
}));
const StyledContainer = styled(Item, {
    shouldForwardProp: (propName) => !['width', 'backgroundColor', 'borderColor'].includes(propName as string)
})<PropsWithChildren<Omit<IItemProps, 'left'>>>(({ backgroundColor, width }) => ({
    backgroundColor,
    position: 'relative',
    width: `${width}%`,
    height: '42px',
    lineHeight: 1
}));

const StyledText = styled(Item)<PropsWithChildren<{}>>`
    position: absolute;
    padding-left: 3px;
    top: 0;
    left: 0;
    line-height: 1;
    user-select: none;
`;

export type IShiftAsTimelineViewProps = {
    abbr?: IAbbreviationValue;
    breaks: {
        id: number;
        break_id: number;
        start: DateTimeType;
        breakDuration: number;
        breakBackground: string;
        required: boolean;
        used: boolean;
    }[];
    shiftEnd?: DateTimeType;
    shiftStart?: DateTimeType;
    timeZone?: string;
};

const ShiftAsTimelineView = ({ abbr, breaks, shiftStart, shiftEnd, timeZone }: IShiftAsTimelineViewProps) => {
    if (!shiftStart || !shiftEnd || !abbr) {
        return <></>;
    }

    const parts = DateHelper.getDifferenceAsMinutes(shiftStart, shiftEnd);
    const transformedItems = breaks
        .filter((item) => item.required || item.used)
        .sort((a, b) => (DateHelper.isBefore(a.start, b.start) ? 1 : -1))
        .map((item) => ({
            id: item.id,
            backgroundColor: item.breakBackground,
            left: DateHelper.getDifferenceAsMinutes(shiftStart, item.start),
            duration: item.breakDuration
        }));

    return (
        <StyledContainer backgroundColor={abbr.background} width={100}>
            {transformedItems.map(({ id, duration, left, ...rest }) => (
                <StyledItem
                    {...rest}
                    key={id}
                    left={(left / parts) * 100}
                    width={(duration / parts) * 100}
                    borderColor={abbr.background}
                />
            ))}
            <StyledText>
                <Typography variant="body2" color={abbr.color}>
                    {abbr.text}
                </Typography>
                <Typography variant="caption" color={abbr.color}>
                    <TimeRange start={shiftStart} end={shiftEnd} timeZone={timeZone} />
                </Typography>
            </StyledText>
        </StyledContainer>
    );
};

export default ShiftAsTimelineView;
