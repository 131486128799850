import { IListResponse, IListResponseWithPaging } from '@/data/ApiResponse';
import { ICallCenterCUModel, ICallCenterModel } from '@/data/CallCenters/CallCenterModels';
import { IPaging } from '@/data/Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '@/data/request';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/call-centers', paging || {});

    return fetchGet<IListResponseWithPaging<ICallCenterModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/call-centers/select', { search, fields });

    return fetchGet<IListResponse<ICallCenterModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<ICallCenterModel>(`/api/call-centers/${id}`);
}

export function create(data: ICallCenterCUModel) {
    return fetchPost<ICallCenterModel>('/api/call-centers/', data);
}

export function update(id: number, data: Partial<ICallCenterCUModel>) {
    return fetchPatch<ICallCenterModel>(`/api/call-centers/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/call-centers/${id}`);
}
