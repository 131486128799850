import { useDrop } from 'react-dnd';
import { useAppDispatch } from '@/data/hooks';
import { moveShift } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import SchedulerBodyTooltip from '@/modules/Scheduler/components/SchedulerBodyTooltip';
import SchedulerCellContent from '@/modules/Scheduler/components/SchedulerCalendarCellContent';
import {
    IMergedColumn,
    ISimpleColumn
} from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRow';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import CellContentWrapper from '@/modules/Scheduler/components/SchedulerRequestBody/CellContent/CellContentWrapper';
import { AvailableScheduleTypes } from '@/modules/Scheduler/Scheduler';
import ShiftAsText, { IShiftAsTimelineViewProps } from '@/modules/Scheduler/ShiftAsText';
import {
    EmptyCell,
    EmptyCellWrapper,
    NotEmpty,
    RequestNotEmptyCell,
    UserDataRowWrapper,
    VacationWrapper
} from '@/modules/Scheduler/StyledParts';
import { StyledBodyAddIcon } from '@/modules/Scheduler/StyledTableCell';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import VisibleOnlyRenderer from '@/wrappers/VisibleOnlyRenderer';
import SchedulerCellVacation from '../SchedulerCellVacation';

export type ISchedulerCalendarDataRowCellProps = {
    data: ISimpleColumn | IMergedColumn;
    from: DateTimeType;
    flooredNowDate: Date;
    isCurrentUser: boolean;
    isUserAdmin: boolean;
    isSchedulePlanClosed?: boolean;
    mode: ISchedulerCalendarHeaderProps['mode'];
    rowIndex: number;
    schedulePlanId: number;
    timeZone: string;
    to: DateTimeType;
    type: AvailableScheduleTypes;
    userId: number | null;
    userName?: string;
    onClick: (data: {
        date: DateTimeType | Date;
        from: Date | null;
        id: number | null;
        to: Date | null;
        userId: number | null;
    }) => void;
};

const ShiftPlaceholder = ({
    from,
    inStoreOrWorkFromHome,
    isEmpty,
    shiftId,
    userId,
    ...rest
}: {
    from: Date | DateTimeType;
    inStoreOrWorkFromHome: boolean;
    isEmpty: boolean;
    shiftId?: number | null;
    userId: number | null;
} & Pick<IShiftAsTimelineViewProps, 'mode' | 'shiftName' | 'timeZone'>) =>
    shiftId === null && !isEmpty ? (
        <></>
    ) : (
        <ShiftAsText
            {...rest}
            abbr={{
                text: rest.shiftName ?? '',
                color: '#000'
            }}
            inStore={inStoreOrWorkFromHome}
            simpleView={true}
            shiftStart={null}
            shiftEnd={null}
            skills={
                isEmpty
                    ? []
                    : [
                          {
                              id: -1,
                              icon: 'pending',
                              color: 'black'
                          }
                      ]
            }
            data-testid={`schedulerTableBodyCell_${userId}_${DateHelper.formatISO(from)}`}
        />
    );
const SchedulerCalendarDataRowCell = ({
    data,
    from,
    flooredNowDate,
    isCurrentUser,
    isSchedulePlanClosed = false,
    isUserAdmin,
    mode,
    schedulePlanId,
    timeZone,
    type,
    userId,
    userName,
    onClick
}: ISchedulerCalendarDataRowCellProps) => {
    const dispatch = useAppDispatch();

    const [{ isOver }, setNodeRef] = useDrop(() => ({
        accept: 'shift',
        drop: (item: { id: number }) => {
            dispatch(moveShift({ id: item.id, userId, date: data.from }));
        },
        collect: (monitor) => ({
            isOver: Boolean(monitor.isOver()),
            canDrop: Boolean(monitor.canDrop())
        })
    }));

    let backgroundColor: string | undefined = undefined;

    if (isOver) {
        backgroundColor = 'darkgreen';
    }

    return data.type === 'simple' ? (
        data.data.type === 'empty' ? (
            <VisibleOnlyRenderer
                autoUnmount={true}
                columnEnd={data.width}
                columnStart={data.columnStart}
                disabled={true}
                partialVisibility={false}
                placeholder={
                    <ShiftPlaceholder
                        from={data.from}
                        inStoreOrWorkFromHome={false}
                        isEmpty
                        mode={mode}
                        shiftName=""
                        timeZone={timeZone}
                        userId={data.data.userId}
                    />
                }
            >
                {(innerRef) => (
                    <>
                        <EmptyCell
                            ref={(current: HTMLDivElement) => {
                                setNodeRef(current);
                                if (innerRef) {
                                    innerRef(current);
                                }
                            }}
                            style={{
                                ...(backgroundColor
                                    ? {
                                          backgroundColor
                                      }
                                    : {})
                            }}
                            bodyFrom={DateHelper.toDate(from)}
                            columnWidth={data.data.width}
                            columnStart={data.columnStart}
                            columnFrom={data.from}
                            columnTo={data.to}
                            currentDate={flooredNowDate}
                            isClosed={isSchedulePlanClosed || type === 'shiftTrades'}
                            isCurrentUser={isCurrentUser}
                            isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                            onClick={
                                isUserAdmin && onClick
                                    ? () =>
                                          onClick({
                                              date: data.from,
                                              from: null,
                                              id: null,
                                              to: null,
                                              userId
                                          })
                                    : undefined
                            }
                            onDragOver={(e) => e.preventDefault()}
                            data-testid={`schedulerTableBodyCell_${userId}_${DateHelper.formatISO(data.data.from)}`}
                        >
                            {isSchedulePlanClosed ? (
                                <></>
                            ) : (
                                <SchedulerBodyTooltip
                                    columnFrom={data.from}
                                    mode={mode}
                                    timeZone={timeZone}
                                    userName={userName}
                                    wrapperStyles={{ height: '100%', width: '100%' }}
                                >
                                    <EmptyCellWrapper>
                                        {isUserAdmin && <StyledBodyAddIcon className="icon" data-testid="noShift" />}
                                    </EmptyCellWrapper>
                                </SchedulerBodyTooltip>
                            )}
                        </EmptyCell>
                    </>
                )}
            </VisibleOnlyRenderer>
        ) : (
            <VisibleOnlyRenderer
                autoUnmount={true}
                columnEnd={data.data.width}
                columnStart={data.columnStart}
                partialVisibility={true}
                placeholder={
                    <ShiftPlaceholder
                        from={data.from}
                        isEmpty={data.data.type === 'shift' && typeof data.data.shiftId !== 'number'}
                        mode={mode}
                        inStoreOrWorkFromHome={data.data.type === 'shift' && data.data.paddingRight}
                        timeZone={timeZone}
                        shiftName={data.data.type === 'shift' ? data.data.shiftName ?? null : null}
                        shiftId={data.data.type === 'shift' ? data.data.shiftId ?? null : null}
                        userId={data.data.userId}
                    />
                }
                withBorders={data.data.type !== 'shift' || data.data.shiftId !== null}
            >
                {(innerRef) => (
                    <NotEmpty
                        bodyFrom={DateHelper.toDate(from)}
                        columnEnd={data.data.width}
                        columnStart={data.columnStart}
                        currentDate={flooredNowDate}
                        isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                        ref={innerRef}
                        onClick={
                            onClick
                                ? () =>
                                      onClick({
                                          date: data.data.from,
                                          from: data.data.from,
                                          id: data.data.id as number,
                                          to: data.data.to,
                                          userId
                                      })
                                : undefined
                        }
                        data-testid={`schedulerTableBodyCell_${userId}_${DateHelper.formatISO(data.data.from)}`}
                    >
                        {data.data.type === 'shift' ? (
                            <SchedulerCellContent
                                data-testid={`schedulerTableBodyCell_${userId}_${DateHelper.formatISO(
                                    data.data.from
                                )}_${data.data.id}`}
                                columnFrom={data.data.from}
                                mode={mode}
                                shiftJoin={{
                                    id: data.data.id as number,
                                    from: data.data.from,
                                    to: data.data.to
                                }}
                                schedulePlanId={schedulePlanId}
                                timeZone={timeZone}
                                withWrappedStyles={false}
                            />
                        ) : data.data.type === 'request' ? (
                            <CellContentWrapper
                                schedulePlanId={schedulePlanId}
                                request={data.data}
                                timeZone={timeZone}
                            />
                        ) : (
                            <SchedulerCellVacation {...data.data} mode={mode} timeZone={timeZone} />
                        )}
                    </NotEmpty>
                )}
            </VisibleOnlyRenderer>
        )
    ) : (
        <NotEmpty
            bodyFrom={DateHelper.toDate(from)}
            columnEnd={DateHelper.getDifferenceAsMinutes(data.from, data.to) / 15}
            columnStart={data.columnStart}
            currentDate={flooredNowDate}
            isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
            data-testid="SchedulerCalendarDataRowCell-merged-notEmpty"
        >
            {data.data.map((row) => (
                <UserDataRowWrapper key={`${data.uId}_${row.uId}`} start={data.from} end={data.to}>
                    {row.data.map((mergedItem) => (
                        <VisibleOnlyRenderer
                            key={data.uId}
                            autoUnmount={true}
                            disabled={mergedItem.type === 'empty'}
                            columnEnd={mergedItem.width}
                            columnStart={DateHelper.getDifferenceAsMinutes(data.from, mergedItem.from) / 15}
                            partialVisibility={true}
                            placeholder={
                                <ShiftPlaceholder
                                    from={data.from}
                                    inStoreOrWorkFromHome={mergedItem.type === 'shift' && mergedItem.paddingRight}
                                    isEmpty={
                                        (mergedItem.type === 'shift' && typeof mergedItem.shiftId !== 'number') ||
                                        mergedItem.type === 'empty'
                                    }
                                    mode={mode}
                                    shiftName={mergedItem.shiftName}
                                    timeZone={timeZone}
                                    userId={userId}
                                />
                            }
                            withBorders={mergedItem.type !== 'empty'}
                        >
                            {(innerRef) =>
                                mergedItem.type === 'empty' ? (
                                    <EmptyCell
                                        key={mergedItem.uId}
                                        bodyFrom={DateHelper.toDate(from)}
                                        columnStart={DateHelper.getDifferenceAsMinutes(data.from, mergedItem.from) / 15}
                                        columnWidth={mergedItem.width}
                                        columnFrom={mergedItem.from}
                                        columnTo={mergedItem.to}
                                        currentDate={flooredNowDate}
                                        isCurrentUser={isCurrentUser}
                                        isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                                        ref={innerRef}
                                        sx={{
                                            borderRight: '1px solid black'
                                        }}
                                        onClick={
                                            isUserAdmin && onClick
                                                ? () =>
                                                      onClick({
                                                          date: mergedItem.from,
                                                          from: null,
                                                          id: null,
                                                          to: null,
                                                          userId
                                                      })
                                                : undefined
                                        }
                                        data-testid={`schedulerTableBodyCell_${
                                            userId === null ? 'emptyShift' : userId
                                        }_${DateHelper.formatISO(mergedItem.from)}_${row.uId}`}
                                    >
                                        <SchedulerBodyTooltip
                                            isEmpty
                                            columnFrom={mergedItem.from}
                                            mode={mode}
                                            userName={userName}
                                            wrapperStyles={{
                                                height: '100%',
                                                width: '100%'
                                            }}
                                        >
                                            <EmptyCellWrapper>
                                                {isUserAdmin && (
                                                    <StyledBodyAddIcon className="icon" data-testid="noShift" />
                                                )}
                                            </EmptyCellWrapper>
                                        </SchedulerBodyTooltip>
                                    </EmptyCell>
                                ) : mergedItem.type === 'shift' ? (
                                    <SchedulerCellContent
                                        data-testid={`schedulerTableBodyCell_${
                                            userId === null ? 'emptyShift' : userId
                                        }_${DateHelper.formatISO(mergedItem.from)}_${mergedItem.uId}`}
                                        columnFrom={data.from}
                                        innerRef={innerRef}
                                        mode={mode}
                                        shiftJoin={{
                                            id: mergedItem.id as number,
                                            from: mergedItem.from,
                                            to: mergedItem.to
                                        }}
                                        schedulePlanId={schedulePlanId}
                                        timeZone={timeZone}
                                        withRightBorder={false}
                                        onClick={
                                            onClick
                                                ? () =>
                                                      onClick({
                                                          date: mergedItem.from,
                                                          from: mergedItem.from,
                                                          id: mergedItem.id,
                                                          to: mergedItem.to,
                                                          userId
                                                      })
                                                : undefined
                                        }
                                    />
                                ) : mergedItem.type === 'request' ? (
                                    <RequestNotEmptyCell
                                        key={mergedItem.uId}
                                        columnStartFromDate={data.from}
                                        columnStartToDate={mergedItem.from}
                                        columnEndFromDate={mergedItem.from}
                                        columnEndToDate={mergedItem.to}
                                        ref={innerRef}
                                    >
                                        <CellContentWrapper
                                            schedulePlanId={schedulePlanId}
                                            request={mergedItem}
                                            timeZone={timeZone}
                                        />
                                    </RequestNotEmptyCell>
                                ) : (
                                    <VacationWrapper
                                        key={mergedItem.uId}
                                        columnStart={DateHelper.getDifferenceAsMinutes(data.from, mergedItem.from) / 15}
                                        columnEnd={
                                            DateHelper.getDifferenceAsMinutes(mergedItem.from, mergedItem.to) / 15
                                        }
                                        ref={innerRef}
                                    >
                                        <SchedulerCellVacation {...mergedItem} mode={mode} timeZone={timeZone} />
                                    </VacationWrapper>
                                )
                            }
                        </VisibleOnlyRenderer>
                    ))}
                </UserDataRowWrapper>
            ))}
        </NotEmpty>
    );
};

export default SchedulerCalendarDataRowCell;
