import Box from '@mui/material/Box';
import { ReactNode, Ref } from 'react';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import Tooltip, { ITooltipProps } from '@/wrappers/Tooltip';

export type IScheduleBodyTooltipProps = Omit<ITooltipProps, 'title' | 'ref'> & {
    columnFrom: Date;
    columnTo?: Date;
    errorMessages?: ReactNode[];
    hasError?: boolean;
    innerRef?: Ref<HTMLDivElement>;
    isEmpty?: boolean;
    mode: ISchedulerCalendarHeaderProps['mode'];
    timeZone?: string | null;
    userName?: string;
};

export default function SchedulerBodyTooltip({
    children,
    columnFrom,
    columnTo,
    errorMessages = [],
    hasError = false,
    innerRef,
    isEmpty = true,
    mode,
    userName,
    timeZone,
    ...rest
}: IScheduleBodyTooltipProps) {
    const { t } = useAppTranslation();
    const timeFormatter = useLocalizeTimeFormatter(undefined, timeZone);
    const dateFormatter = useLocalizeDateFormatter({ timeZone });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });

    let titleFromTo: ReactNode;

    if (mode === SchedulePlanWidthTypeEnum.Day) {
        titleFromTo = [
            <Box key="from">
                <b>{t('label.from', 'From')}</b>: {timeFormatter.format(columnFrom)}
            </Box>,
            <Box key="to">
                <b>{t('label.to', 'To')}</b>: {timeFormatter.format(DateHelper.addMinutes(columnFrom, 15))}
            </Box>
        ];
    } else {
        if (columnTo) {
            const formater =
                DateHelper.getDifferenceAsDays(columnFrom, columnTo) > 1 ? dateTimeFormatter : timeFormatter;

            titleFromTo = [
                <Box key="from">
                    <b>{t('label.from', 'From')}</b>: {formater.format(columnFrom)}
                </Box>,
                <Box key="to">
                    <b>{t('label.to', 'To')}</b>: {formater.format(columnTo)}
                </Box>
            ];
        } else {
            titleFromTo = (
                <>
                    {typeof userName !== 'undefined' ? <br /> : <></>}
                    <b>{t('label.date', 'Date')}</b>: {dateFormatter.format(columnFrom)}
                </>
            );
        }
    }

    return (
        <Tooltip
            data-testid="scheduleGrid_TableBody_tooltip"
            {...rest}
            ref={innerRef}
            color="primary"
            title={
                hasError ? (
                    <>
                        <b>{t('label.error', 'Error')}</b>:
                        <ul>
                            {errorMessages.map((errorMessage, index) => (
                                <li key={index}>{errorMessage}</li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <>
                        {userName && (
                            <>
                                <b>{t('label.user', 'User')}</b>: {userName}
                            </>
                        )}
                        {titleFromTo}
                    </>
                )
            }
            withBoxWrapper={!isEmpty}
            disableHoverListener={isEmpty ? false : !hasError}
        >
            {children}
        </Tooltip>
    );
}
