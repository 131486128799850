import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '../store';

type ISidebarReducerState = {
    isPinned: boolean;
};

const initialState: ISidebarReducerState = {
    isPinned: false
};

const sidebarReducer = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebar: (state, action: PayloadAction<ISidebarReducerState>) => {
            state.isPinned = action.payload.isPinned;
        },
        unsetSidebar: (state) => {
            state.isPinned = initialState.isPinned;
        }
    }
});

export const sidebarInitialState = sidebarReducer.getInitialState();

export default sidebarReducer.reducer;
export const sidebarItem = (state: IRootState) => state.sidebar;
export const isSidebarPinned = (state: IRootState) => state.sidebar.isPinned;
export const { setSidebar, unsetSidebar } = sidebarReducer.actions;
