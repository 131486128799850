import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { cloneElement } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchLogInToPlan, fetchLogOutFromPlan } from '@/data/SchedulePlans/SchedulePlanActions';
import { schedulePlansForAttendance } from '@/data/SchedulePlans/SchedulePlanSlice';
import { isUserAllowedStartAttendance } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button/Button';
import DropDownMenu from '@/wrappers/DropDownMenu';

const OnlineIcon = ({ online }: { online: boolean }) =>
    online ? <RadioButtonCheckedIcon color="success" /> : <RadioButtonUncheckedIcon color="error" />;
const AttendanceButton = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const displayAttendanceButton = useAppSelector(isUserAllowedStartAttendance);
    const attendancePlans = useAppSelector(schedulePlansForAttendance);
    const alreadyOnline = attendancePlans.some((plan) => plan.exists);

    const button = (
        <Button name="attendance" variant="text">
            {attendancePlans.length > 1 && alreadyOnline ? (
                `(${attendancePlans.filter((plan) => plan.exists).length})`
            ) : (
                <OnlineIcon online={alreadyOnline} />
            )}{' '}
            {t('label.attendance', 'Attendance')}
        </Button>
    );

    return displayAttendanceButton && attendancePlans.length !== 0 ? (
        attendancePlans.length === 1 ? (
            cloneElement(button, {
                onClick: () => (alreadyOnline ? dispatch(fetchLogOutFromPlan(null)) : dispatch(fetchLogInToPlan(null)))
            })
        ) : (
            <DropDownMenu
                data-testid="attendance-dropdown"
                options={attendancePlans.map((plan) => ({
                    id: `${plan.id}`,
                    label: (
                        <>
                            <OnlineIcon online={plan.exists} /> {plan.name}
                        </>
                    )
                }))}
                onClick={(id) =>
                    (attendancePlans.find((plan) => `${plan.id}` === id) ?? { exists: false }).exists === false
                        ? dispatch(fetchLogInToPlan(id))
                        : dispatch(fetchLogOutFromPlan(id))
                }
            >
                {button}
            </DropDownMenu>
        )
    ) : (
        <></>
    );
};

export default AttendanceButton;
