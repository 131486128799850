import formatPascalToSnake from '@/helpers/format/formatPascalToSnake';

const replace: { [key: string]: string } = {
    Co2: 'co2',
    EightK: '8k',
    EightKPlus: '8k+',
    EightMp: '8mp',
    EighteenMp: '18mp',
    EighteenUpRating: '18_up_rating',
    EightteenMp: '18mp',
    ElevenMp: '11mp',
    FifteenMp: '15mp',
    FiveG: '5g',
    FiveK: '5k',
    FiveKPlus: '5k+',
    FiveMp: '5mp',
    FivteenMp: '15mp',
    FourGMobiledata: '4g_mobiledata',
    FourGPlusMobiledata: '4g_plus_mobiledata',
    FourK: '4k',
    FourKPlus: '4k+',
    FourMp: '4mp',
    FourteenMp: '14mp',
    Grid3x3: 'grid_3x3',
    Grid4x4: 'grid_4x4',
    NineK: '9k',
    NineKPlus: '9k+',
    NineMp: '9mp',
    NineteenMp: '19mp',
    OneK: '1k',
    OneKPlus: '1kplus',
    OneKk: '10k',
    SevenK: '7k',
    SevenKPlus: '7k+',
    SevenMp: '7mp',
    SeventeenMp: '17mp',
    SixK: '6k',
    SixKPlus: '6k+',
    SixMp: '6mp',
    SixteenMp: '16mp',
    SixtyFps: '60fps',
    SixtyFpsSelect: '60fps_select',
    TenMp: '10mp',
    ThirteenMp: '13mp',
    ThirtyFps: '30fps',
    ThirtyFpsSelect: '30fps_select',
    ThreeDRotation: '3d_rotation',
    ThreeGMobiledata: '3g_mobiledata',
    ThreeK: '3k',
    ThreeKPlus: '3k+',
    ThreeMp: '3mp',
    ThreeP: '3p',
    ThreeSixty: '360',
    TimesOneMobiledata: '1x_mobiledata',
    TwelveMp: '12mp',
    TwentyFourMp: '24mp',
    TwentyOneMp: '21mp',
    TwentyThreeMp: '23mp',
    TwentyTwoMp: '22mp',
    TwentyZeroMp: '20mp',
    TwoK: '2k',
    TwoKPlus: '2k+',
    TwoMp: '2mp'
};

const handleIconReplaces = (item: string) => (replace[item] ? replace[item] : formatPascalToSnake(item));

export default handleIconReplaces;
