import { useMemo } from 'react';
import { useAppSelector } from '@/data/hooks';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { IUserShiftsSummaries } from '@/data/Summaries/SummariesModels';
import { isUserShiftsSummariesLoading, userShiftsSummariesList } from '@/data/Summaries/SummariesSlice';
import { usersForSelect } from '@/data/Users/UserSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';
import Datatable from '@/wrappers/Datatable';

const Shifts = () => {
    const { t } = useAppTranslation();
    const usersData = useAppSelector(usersForSelect);
    const summarizationUserData = useAppSelector(userShiftsSummariesList);
    const shiftsData = useAppSelector(shiftsForSelect) ?? [];

    const generateShifts = useMemo(
        () =>
            shiftsData
                .filter((shift) =>
                    summarizationUserData.some((summaryUser) => {
                        return summaryUser.summary.some((summary) => summary.shift_id === shift.id);
                    })
                )
                .map((shift) => ({
                    title: shift.name,
                    field: `shift_${shift.id}`,
                    render: (rowData: IUserShiftsSummaries) =>
                        rowData.summary.find((entity) => entity.shift_id === shift.id)?.number_of_shift ?? 0
                })),
        [shiftsData, summarizationUserData]
    );

    return (
        <Datatable
            header={[
                {
                    title: t('label.user', 'User'),
                    field: 'name',
                    render: (rowData) => serializeUser(usersData.find((user) => user.id === rowData.user_id))
                },
                ...generateShifts
            ]}
            data={summarizationUserData}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            hasSorting={false}
            hasPaginating={false}
            isLoading={useAppSelector(isUserShiftsSummariesLoading)}
        />
    );
};

export default Shifts;
