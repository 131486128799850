import { IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IAgentGroupCUModel, IAgentGroupModel } from './AgentGroupModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/agent-groups', paging || {});

    return fetchGet<IListResponseWithPaging<IAgentGroupModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IAgentGroupModel>(`/api/agent-groups/${id}`);
}

export function create(data: IAgentGroupCUModel) {
    return fetchPost<IAgentGroupModel>('/api/agent-groups/', data);
}

export function update(id: number, data: IAgentGroupCUModel) {
    return fetchPatch<IAgentGroupModel>(`/api/agent-groups/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/agent-groups/${id}`);
}
