export enum SchedulePlanStateEnum {
    Locked = 'Locked',
    Creating = 'Creating',
    Calculating = 'Calculating',
    Calculated = 'Calculated',
    Error = 'Error',
    Draft = 'Draft',
    Prepared = 'Prepared',
    Closed = 'Closed'
}
