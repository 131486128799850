import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { i18n as i18nType } from 'i18next';
import { PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { dayjsInstance } from '@/helpers/date/DateHelper';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import i18next from '@/utils/i18next';

type IProps = PropsWithChildren<{
    i18n?: i18nType;
}>;

const LocalizationProvider = ({ i18n = i18next, ...props }: IProps) => {
    const formatter = useLocalizeDateFormatter();

    dayjsInstance.locale(formatter.resolvedOptions().locale);

    return (
        <I18nextProvider i18n={i18n}>
            <MuiLocalizationProvider
                {...props}
                adapterLocale={formatter.resolvedOptions().locale.toLowerCase()}
                dateAdapter={AdapterDayjs}
                dateLibInstance={dayjsInstance}
            />
        </I18nextProvider>
    );
};

export default LocalizationProvider;
