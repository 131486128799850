import { createContext, PropsWithChildren } from 'react';

type ISchedulerContext = {
    compactMode: boolean;
};

export const SchedulerContext = createContext<ISchedulerContext>({
    compactMode: true
});

export const SchedulerContextProvider = ({ compactMode, children }: PropsWithChildren<ISchedulerContext>) => {
    return <SchedulerContext.Provider value={{ compactMode }}>{children}</SchedulerContext.Provider>;
};
