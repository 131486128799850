import { cloneElement, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { VisibilityOnlyRenderContext } from '@/wrappers/VisibleOnlyRenderer/VisibilityOnlyRenderContext';

type IProps = {
    autoUnmount?: boolean;
    columnStart: number;
    columnEnd: number;
    delay?: number;
    disabled?: boolean;
    partialVisibility?: boolean;
    placeholder: ReactElement;
    rowStart?: number;
    children: ReactElement | ((innerRef: (ref: HTMLDivElement) => void) => ReactElement);
    withBorders?: boolean;
};

const VisibleOnlyRenderer = ({
    autoUnmount = true,
    columnStart,
    columnEnd,
    children,
    delay = 250,
    disabled = false,
    partialVisibility = true,
    placeholder,
    rowStart,
    withBorders = false
}: IProps) => {
    const [isVisible, setIsVisible] = useState(disabled);
    const { container, offsetTop } = useContext(VisibilityOnlyRenderContext);
    const [alreadyStartedWatching, setAlreadyStartedWatching] = useState(false);
    const intervalCheckRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const currentChild = useRef<HTMLDivElement | null>(null);
    const prevContainerRef = useRef<HTMLDivElement | null>(null);
    const listenersOnContainer = useRef<{ type: string; fn: () => void }[]>([]);

    const stopWatching = useCallback(() => {
        listenersOnContainer.current.forEach((listener) => {
            container?.removeEventListener(listener.type, listener.fn);
        });
        if (intervalCheckRef.current) {
            clearInterval(intervalCheckRef.current);
            intervalCheckRef.current = null;
        }

        setAlreadyStartedWatching(false);
    }, []);
    const checkIsVisible = useCallback(() => {
        if (currentChild.current && container) {
            const childRect = currentChild.current?.getBoundingClientRect();
            const originalContainerRect = container?.getBoundingClientRect();
            const containerRect = {
                bottom: originalContainerRect.bottom ?? 0,
                top: (originalContainerRect.top ?? 0) + offsetTop * 50,
                left: (originalContainerRect.left ?? 0) + 100,
                right: originalContainerRect.right ?? 0,
                width: originalContainerRect.width ?? 0,
                height: originalContainerRect.height ?? 0
            };

            const isInside = {
                bottom: containerRect.top < childRect.bottom && childRect.bottom <= (containerRect.bottom ?? 0),
                top: containerRect.top <= childRect.top && childRect.top < (containerRect.bottom ?? 0),
                right: containerRect.left < childRect.right && childRect.right <= (containerRect.right ?? 0),
                left: containerRect.left <= childRect.left && childRect.left < (containerRect.right ?? 0)
            };

            const hasSize =
                childRect.height > 0 && childRect.width > 0 && containerRect?.width > 0 && containerRect.height > 0;
            const newIsVisible = hasSize && isInside.top && isInside.right && isInside.bottom && isInside.left;
            const longShift =
                (isInside.top || isInside.bottom) &&
                childRect.left <= (containerRect.left ?? 0) &&
                (containerRect.left ?? 0) <= childRect.right;
            const cornerShift = (isInside.top || isInside.bottom) && (isInside.left || isInside.right);
            const newPartialVisibility = partialVisibility ? longShift || cornerShift : false;

            if (autoUnmount) {
                setIsVisible(newIsVisible || newPartialVisibility);
            } else {
                setIsVisible(isVisible || newIsVisible || newPartialVisibility);
            }

            if (!autoUnmount && (newIsVisible || newPartialVisibility)) {
                stopWatching();
            }
        }
    }, [container]);
    const addEventListener = useCallback(
        (element: HTMLElement | Window, type: 'scroll' | 'resize') => {
            let timeout: ReturnType<typeof setTimeout> | null;

            const later = () => {
                timeout = null;
                checkIsVisible();
            };

            const func = () => {
                if (timeout) {
                    clearTimeout(timeout);
                }

                timeout = setTimeout(later, delay || 0);
            };

            element.addEventListener(type, func);

            listenersOnContainer.current.push({
                type,
                fn: func
            });
        },
        [container]
    );
    const startWatching = useCallback(() => {
        if (container && prevContainerRef.current !== container && !disabled) {
            setAlreadyStartedWatching(true);

            addEventListener(container, 'scroll');
            addEventListener(window, 'resize');

            checkIsVisible();
        }

        prevContainerRef.current = container;
    }, [container, alreadyStartedWatching]);

    useEffect(() => {
        startWatching();

        return () => stopWatching();
    }, []);
    useEffect(() => {
        startWatching();
    }, [container]);

    if (!isVisible) {
        return (
            <div
                ref={currentChild}
                style={{
                    ...(typeof rowStart === 'number' ? { gridRowStart: rowStart } : {}),
                    gridColumn: `${columnStart + 1}/span ${columnEnd}`,
                    position: 'relative',
                    ...(withBorders ? { borderRight: '1px black solid' } : {})
                }}
            >
                {placeholder}
            </div>
        );
    }

    if (typeof children === 'function') {
        return children((ref: HTMLDivElement) => (currentChild.current = ref));
    }

    return cloneElement(children, { innerRef: (ref: HTMLDivElement) => (currentChild.current = ref) });
};

export default VisibleOnlyRenderer;
