import Box, { BoxProps } from '@mui/material/Box';
import { FormEvent, forwardRef, PropsWithChildren } from 'react';
import ComponentLoader from '@/wrappers/ComponentLoader';

type IProps = PropsWithChildren<{
    className?: string;
    name: string;
    readOnly?: boolean;
    isLoaded?: boolean;
    onSubmit?: (e: FormEvent<HTMLFormElement>, buttonName: string) => void;
}> &
    Pick<BoxProps, 'sx'>;

const FormWrapper = forwardRef<HTMLFormElement, IProps>(function FormWrapperInner(
    { children, className, name, onSubmit, readOnly, isLoaded = true, ...rest },
    ref
) {
    if (readOnly) {
        return (
            <Box {...rest} ref={ref} data-testid={`form-${name}`} className={className}>
                <ComponentLoader inProgress={!isLoaded}>{children}</ComponentLoader>
            </Box>
        );
    }

    return (
        <Box
            {...rest}
            ref={ref}
            component="form"
            className={className}
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();

                if (onSubmit) {
                    onSubmit(e, (e.nativeEvent as SubmitEvent).submitter?.getAttribute('name') || '');
                }
            }}
            data-testid={`form-${name}`}
        >
            <ComponentLoader inProgress={!isLoaded}>{children}</ComponentLoader>
        </Box>
    );
});

export default FormWrapper;
