import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledApplicationSettingsActionPanel = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
    paddingBottom: '2rem'
}));
