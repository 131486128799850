export const normalizeNumber = (value: number, normValue: number) => {
    return Math.round(value / normValue) * normValue;
};

export const normalizeFloatNumber = (value: string | number | undefined, fixes?: number) => {
    let float = 0;

    if (typeof value === 'string' && value.length > 0) {
        float = parseFloat(value ?? 0);
    } else if (typeof value === 'number') {
        float = parseFloat(value?.toString() ?? 0);
    }

    if (typeof fixes !== 'undefined') {
        return float.toFixed(fixes);
    } else {
        return float.toString().replace(/\.0+$/, '');
    }
};
