import { defaultLng } from '@/utils/i18next';

export const getDayNames = (locale = defaultLng, format: 'long' | 'short' | 'narrow' | undefined = 'long') => {
    const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: 'UTC' });
    const days = [1, 2, 3, 4, 5, 6, 7].map((day) => {
        const dd = day < 10 ? `0${day}` : day;

        return new Date(`2007-01-${dd}T00:00:00+00:00`);
    });

    return days.map((date) => formatter.format(date));
};

const namesOfWeekDays: { id: number; name: string }[] = getDayNames(defaultLng, 'short').map((day, index) => ({
    id: index,
    name: day
}));

export default namesOfWeekDays;
