import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

type IProps = {
    sx?: SxProps;
};

const Content = ({ sx, children }: PropsWithChildren<IProps>) => <Box sx={sx}>{children}</Box>;

export default Content;
