import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSlider, { SliderProps as MuiFieldProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { forwardRef, useCallback } from 'react';

export type ISliderFieldProps = Omit<MuiFieldProps, 'name' | 'onChange' | 'ref' | 'defaultValue'> & {
    name: string;
    label: string;
    readOnly?: boolean;
    onChange?: (event: Event, value: number | number[]) => void;
};

const StyledField = styled(MuiSlider)(({ hidden }) => ({
    ...(hidden && { display: 'none' })
}));

const Slider = forwardRef<HTMLDivElement, ISliderFieldProps>(function Inner(
    { hidden, label, marks = true, readOnly, size = 'small', step = 1, value, onChange, ...props },
    ref
) {
    const memoizedOnChange = useCallback(
        (event: Event, newValue: number | number[]) => {
            if (onChange && value !== newValue && !readOnly) {
                onChange(event, newValue);
            }
        },
        [readOnly, value, onChange]
    );

    return (
        <FormControlLabel
            labelPlacement="top"
            value={value}
            control={
                <StyledField
                    {...props}
                    ref={ref}
                    hidden={hidden}
                    marks={marks}
                    size={size}
                    step={step}
                    valueLabelDisplay="auto"
                    onChange={memoizedOnChange}
                />
            }
            label={label}
        />
    );
});

export default Slider;
