import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { AllSystemCSSProperties } from '@mui/system/styleFunctionSx/styleFunctionSx';
import {
    forwardRef,
    memo,
    MouseEvent,
    ReactElement,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import config from '@/utils/config';
import DialogActions from '@/wrappers/Dialog/Actions';
import DialogContainer, { IDialogContainerProps } from '@/wrappers/Dialog/Container';
import DialogContent from '@/wrappers/Dialog/Content';
import DialogOpenButton from '@/wrappers/Dialog/OpenButton';
import DialogTitle from '@/wrappers/Dialog/Title';
import DrawerActions from '@/wrappers/Drawer/Actions';
import DrawerContainer from '@/wrappers/Drawer/Container';
import DrawerContent from '@/wrappers/Drawer/Content';
import DrawerSubtitle from '@/wrappers/Drawer/Subtitle';
import DrawerTitle from '@/wrappers/Drawer/Title';
import FieldsGenerator from './FieldsGenerator';
import { ISupportedFieldType, ITabsItem } from './FieldTypes';
import FormWrapper from './FormWrapper';
import { getValuesFromProps, ISupportedValueType, IValueType } from './utils';

export type IOutputValueType = ISupportedValueType;

type IBaseProps = {
    innerRef?: Ref<ICallableRef>;
    /** Only for tests */
    id?: string;
    /** Only for tests */
    name: string;
    /** Only for styles */
    className?: string;
    readOnly?: boolean;
    error?: boolean;
    isEdit?: boolean;
    justIcon?: boolean;
    icon?: ReactElement;
    displayAsModal: boolean;
    displayAsSidebar?: boolean;
    noFullWidth?: boolean;
    fields: (ISupportedFieldType | false)[];
    actions?: ISupportedFieldType[];
    maxWidth?: IDialogContainerProps['maxWidth'];
    openForm?: boolean;
    onSubmit?: (values: IOutputValueType, buttonName: string) => void;
    title?: ReactNode;
    subTitle?: ReactNode;
    openButtonRender?: (onClick: (event: MouseEvent) => void) => ReactElement;
    openButtonValue?: string;
    onOpen?: () => void;
    onClose?: () => void;
    isLoaded?: boolean;
    justifyContent?: AllSystemCSSProperties['justifyContent'];
};
type IModalProps = Omit<IBaseProps, 'title'> & {
    title: ReactNode;
    displayAsModal: true;
};
type IPageFormProps = IBaseProps & {
    displayAsModal: false;
};
export type IProps = IModalProps | IPageFormProps;

type IOpenIButtonProps = {
    nameWithId: string;
    render?: (onClick: (event: MouseEvent) => void) => ReactNode;
    openButtonValue?: string;
    justIcon: boolean;
    onClick: () => void;
    isEdit: boolean;
    icon?: ReactElement;
};

export const StyledOpenButton = styled(IconButton, {
    shouldForwardProp: (propName) => 'isEdit' !== propName && 'hasError' !== propName
})<{ isEdit?: boolean; hasError?: boolean }>(
    ({ theme, isEdit, hasError }) => `
        color: ${hasError ? theme.palette.error.main : isEdit ? theme.palette.info.main : theme.palette.primary.main};
    `
);

const StyleButton = styled(IconButton)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
    `
);

export function OpenButton({ icon, isEdit, justIcon, nameWithId, openButtonValue, ...rest }: IOpenIButtonProps) {
    const render =
        rest.render ||
        (justIcon
            ? (onClick: (event: MouseEvent) => void) =>
                  icon ? (
                      <StyleButton onClick={onClick} title={openButtonValue} data-testid={`openDialog-${nameWithId}`}>
                          {icon}
                      </StyleButton>
                  ) : (
                      <StyledOpenButton
                          onClick={onClick}
                          title={openButtonValue}
                          isEdit={isEdit}
                          data-testid={`openDialog-${nameWithId}`}
                      >
                          {isEdit ? <EditIcon /> : <AddIcon />}
                      </StyledOpenButton>
                  )
            : undefined);

    return <DialogOpenButton onClick={rest.onClick} value={openButtonValue} render={render} />;
}

export type ICallableRef = {
    setFieldValue: (name: string, value: IValueType) => void;
    getFieldValue: (name: string) => IValueType;
    setOpen: (open: boolean) => void;
};

function clearEmptyArrayFormValues(data: FieldValues) {
    Object.keys(data).map((key) => {
        const attribute = data[`${key}`];

        if (Array.isArray(attribute)) {
            data[`${key}`] = attribute.filter((arrayItem: FieldValues) => Object.keys(arrayItem).length !== 0);
        }

        return key;
    });

    return data;
}

const FormGeneratorInner = forwardRef<HTMLFormElement, IProps>(function FormGeneratorForward(props: IProps, ref) {
    const [isFormOpen, setOpenForm] = useState(false);
    const {
        innerRef,
        actions = [],
        className,
        fields,
        displayAsModal,
        displayAsSidebar,
        noFullWidth,
        openForm,
        readOnly,
        justifyContent,
        isLoaded,
        maxWidth
    } = props;
    const {
        formState: { errors },
        reset,
        trigger,
        getValues,
        setValue,
        ...formMethods
    } = useForm({
        mode: 'all',
        criteriaMode: 'firstError',
        shouldFocusError: true
    });

    const flagDisplayId = localStorage.getItem('FORM_DISPLAY_ENTITY_ID');

    const filteredField = [
        ...(props.id && (flagDisplayId !== null ? ['1', 'true'].includes(flagDisplayId) : config.isDevelop)
            ? [
                  {
                      type: 'html',
                      display: () => Boolean(props.id && config.isDevelop),
                      props: {
                          name: 'id',
                          render: () => <>ID: {props.id}</>
                      }
                  }
              ]
            : []),
        ...fields.filter((field) => field !== false)
    ] as ISupportedFieldType[];
    const initialValues = getValuesFromProps(filteredField);

    const fullWidthForm = noFullWidth !== true;
    const handleClose = useCallback(() => {
        if (displayAsModal || displayAsSidebar) {
            reset();
            setOpenForm(false);

            if (props.onClose) {
                props.onClose();
            }
        }
    }, [fields, props.onClose]);

    const onSubmit = async (buttonName: string) => {
        if (readOnly) {
            return;
        }

        const isValid = await trigger();

        if (Object.keys(errors).length || !isValid) {
            const errorFields = Object.keys(errors);

            errorFields
                .map((errorField) => {
                    const fieldWithError = fields.find(
                        (field) =>
                            field && field.type === 'tabs' && field.props.tabs.some((tab) => errorField === tab.name)
                    ) as ITabsItem | undefined;

                    return {
                        isTab: typeof fieldWithError !== 'undefined',
                        name: fieldWithError?.props.name ?? errorField,
                        tabToSelect: errorField
                    };
                })
                .forEach((error) => {
                    if (error.isTab) {
                        setValue(error.name, error.tabToSelect);
                    }
                });

            return;
        }

        const filledValues = clearEmptyArrayFormValues(getValues());

        if (props.onSubmit) {
            props.onSubmit(filledValues, buttonName);
        }
    };

    useEffect(() => {
        if (!!openForm !== isFormOpen) {
            setOpenForm(openForm || false);
        }
    }, [openForm]);

    useEffect(() => {
        if (isFormOpen) {
            const data = getValuesFromProps(filteredField);

            Object.keys(data).forEach((fieldName) => {
                setValue(fieldName, data[fieldName]);
            });
        }
    }, [isFormOpen]);

    useEffect(() => {
        const data = getValues();

        Object.keys(data).forEach((fieldName) => {
            setValue(fieldName, data[fieldName]);
        });
    }, [fields]);

    useEffect(() => {
        if (isLoaded) {
            const data = getValuesFromProps(filteredField);

            Object.keys(data).forEach((fieldName) => {
                setValue(fieldName, data[fieldName]);
            });
        }
    }, [isLoaded]);

    useImperativeHandle(innerRef, () => ({
        setFieldValue: (name: string, value: IValueType) => {
            setValue(name, value);
        },
        getFieldValue: (name: string) => {
            return getValues(name);
        },
        setOpen: (open: boolean) => {
            setOpenForm(open);
        }
    }));

    const handleOnClickOpenButton = useCallback(() => {
        setOpenForm(true);

        if (props.onOpen) {
            props.onOpen();
        }
    }, [props.onOpen]);

    const nameWithId = `${props.name}${props.id ? `-${props.id}` : ''}`;

    if (props.displayAsModal) {
        return (
            <>
                <OpenButton
                    nameWithId={`${props.name}_openButton`}
                    openButtonValue={props.openButtonValue}
                    isEdit={!!props.isEdit}
                    justIcon={!!props.justIcon}
                    icon={props.icon}
                    onClick={handleOnClickOpenButton}
                    render={props.openButtonRender}
                />
                <DialogContainer
                    className={className}
                    open={isFormOpen}
                    fullWidth={fullWidthForm}
                    maxWidth={maxWidth}
                    onClose={handleClose}
                    data-testid={`dialog-${props.name}`}
                >
                    <DialogTitle
                        onClose={props.subTitle ? undefined : handleClose}
                        subTitle={props.subTitle}
                        data-testid="dialog-title"
                    >
                        {props.title}
                    </DialogTitle>
                    <FormWrapper
                        className={className}
                        name={props.name}
                        isLoaded={isLoaded}
                        ref={ref}
                        readOnly={readOnly}
                        onSubmit={async (_, buttonName) => onSubmit(buttonName)}
                    >
                        <DialogContent>
                            <FieldsGenerator
                                {...formMethods}
                                justifyContent={justifyContent}
                                getValues={getValues}
                                initialValues={initialValues}
                                readOnly={readOnly}
                                setValue={setValue}
                                fields={filteredField}
                                fullWidth={fullWidthForm}
                            />
                        </DialogContent>
                        <DialogActions>
                            <FieldsGenerator
                                {...formMethods}
                                justifyContent={justifyContent}
                                getValues={getValues}
                                initialValues={initialValues}
                                readOnly={readOnly}
                                setValue={setValue}
                                actions
                                fields={actions}
                                fullWidth={fullWidthForm}
                            />
                        </DialogActions>
                    </FormWrapper>
                </DialogContainer>
            </>
        );
    }

    if (props.displayAsSidebar) {
        return (
            <>
                {(props.openButtonValue || props.openButtonRender || props.icon) && (
                    <OpenButton
                        icon={props.icon}
                        nameWithId={`${nameWithId}_openButton`}
                        openButtonValue={props.openButtonValue}
                        isEdit={!!props.isEdit}
                        justIcon={!!props.justIcon}
                        onClick={handleOnClickOpenButton}
                        render={props.openButtonRender}
                    />
                )}
                <DrawerContainer
                    size={!maxWidth ? undefined : maxWidth}
                    open={isFormOpen}
                    onClose={handleClose}
                    name={props.name}
                >
                    {props.title && (
                        <DrawerTitle sx={{ paddingInline: 1 }} data-testid="title">
                            <Box>{props.title}</Box>
                        </DrawerTitle>
                    )}

                    {props.subTitle && (
                        <DrawerSubtitle sx={{ paddingInline: 1 }}>
                            <Box>{props.subTitle}</Box>
                        </DrawerSubtitle>
                    )}
                    <FormWrapper
                        className={className}
                        name={nameWithId}
                        isLoaded={isLoaded}
                        ref={ref}
                        readOnly={readOnly}
                        onSubmit={async (_, buttonName) => onSubmit(buttonName)}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <DrawerContent>
                            <FieldsGenerator
                                {...formMethods}
                                readOnly={readOnly}
                                justifyContent={justifyContent}
                                getValues={getValues}
                                initialValues={initialValues}
                                setValue={setValue}
                                fields={filteredField}
                                fullWidth={fullWidthForm}
                            />
                        </DrawerContent>
                        <DrawerActions sx={{ justifyContent: 'space-between' }}>
                            <FieldsGenerator
                                {...formMethods}
                                justifyContent={justifyContent}
                                getValues={getValues}
                                initialValues={initialValues}
                                setValue={setValue}
                                actions
                                fields={actions.slice(0, 1)}
                                fullWidth={fullWidthForm}
                                readOnly={readOnly}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <FieldsGenerator
                                    {...formMethods}
                                    getValues={getValues}
                                    initialValues={initialValues}
                                    readOnly={readOnly}
                                    setValue={setValue}
                                    actions
                                    fields={actions.slice(1)}
                                    fullWidth={fullWidthForm}
                                />
                            </Box>
                        </DrawerActions>
                    </FormWrapper>
                </DrawerContainer>
            </>
        );
    }

    return (
        <FormWrapper
            className={className}
            name={nameWithId}
            ref={ref}
            readOnly={readOnly}
            isLoaded={isLoaded}
            onSubmit={async (_, buttonName) => onSubmit(buttonName)}
        >
            <FieldsGenerator
                {...formMethods}
                justifyContent={justifyContent}
                getValues={getValues}
                initialValues={initialValues}
                setValue={setValue}
                fields={[...filteredField, ...(readOnly ? [] : actions)]}
                fullWidth={fullWidthForm}
                readOnly={readOnly}
            />
        </FormWrapper>
    );
});

export default memo(FormGeneratorInner);
