/*
    List of countries used in this (https://www.yasumi.dev/docs/providers/) backend package
    (names are same as yasumi providers)
*/

const list = [
    { name: 'Argentina', code: 'AR', title: 'Argentina' },
    { name: 'Australia', code: 'AU', title: 'Australia' },
    { name: 'Austria', code: 'AT', title: 'Austria' },
    { name: 'Belgium', code: 'BE', title: 'Belgium' },
    { name: 'Bosnia', code: 'BA', title: 'Bosnia and Herzegovina' },
    { name: 'Brazil', code: 'BR', title: 'Brazil' },
    { name: 'Canada', code: 'CA', title: 'Canada' },
    { name: 'Croatia', code: 'HR', title: 'Croatia' },
    { name: 'CzechRepublic', code: 'CZ', title: 'Czech Republic' },
    { name: 'Denmark', code: 'DK', title: 'Denmark' },
    { name: 'Estonia', code: 'EE', title: 'Estonia' },
    { name: 'Finland', code: 'FI', title: 'Finland' },
    { name: 'France', code: 'FR', title: 'France' },
    { name: 'Georgia', code: 'GE', title: 'Georgia' },
    { name: 'Germany', code: 'DE', title: 'Germany' },
    { name: 'Greece', code: 'GR', title: 'Greece' },
    { name: 'Hungary', code: 'HU', title: 'Hungary' },
    { name: 'Ireland', code: 'IE', title: 'Ireland' },
    { name: 'Italy', code: 'IT', title: 'Italy' },
    { name: 'Japan', code: 'JP', title: 'Japan' },
    { name: 'SouthKorea', code: 'KR', title: 'Korea, Republic of' },
    { name: 'Latvia', code: 'LV', title: 'Latvia' },
    { name: 'Lithuania', code: 'LT', title: 'Lithuania' },
    { name: 'Luxembourg', code: 'LU', title: 'Luxembourg' },
    { name: 'Netherlands', code: 'NL', title: 'Netherlands' },
    { name: 'NewZealand', code: 'NZ', title: 'New Zealand' },
    { name: 'Norway', code: 'NO', title: 'Norway' },
    { name: 'Poland', code: 'PL', title: 'Poland' },
    { name: 'Portugal', code: 'PT', title: 'Portugal' },
    { name: 'Romania', code: 'RO', title: 'Romania' },
    { name: 'Russia', code: 'RU', title: 'Russian Federation' },
    { name: 'Slovakia', code: 'SK', title: 'Slovakia' },
    { name: 'SouthAfrica', code: 'ZA', title: 'South Africa' },
    { name: 'Spain', code: 'ES', title: 'Spain' },
    { name: 'Sweden', code: 'SE', title: 'Sweden' },
    { name: 'Switzerland', code: 'CH', title: 'Switzerland' },
    { name: 'Turkey', code: 'TR', title: 'Turkey' },
    { name: 'Ukraine', code: 'UA', title: 'Ukraine' },
    { name: 'UnitedKingdom', code: 'GB', title: 'United Kingdom' },
    { name: 'USA', code: 'US', title: 'United States' }
];

export default list;
