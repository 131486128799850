import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCategories, fetchQueues } from '@/data/Daktela/DaktelaApi';
import OmnichannelEnum from '@/utils/enums/OmnichannelEnum';

export const fetchCategoriesList = createAsyncThunk(
    'daktela/categoriesList',
    async (args: { callCenterId: number }) => await fetchCategories(args.callCenterId)
);

export const fetchQueuesList = createAsyncThunk(
    'daktela/queues',
    async (args: { type: OmnichannelEnum[]; callCenterId: number }) => await fetchQueues(args.type, args.callCenterId)
);
