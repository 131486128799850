import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import UserPermission, { Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchOnGoingSchedulePlans } from '@/data/SchedulePlans/SchedulePlanActions';
import { onGoingSchedulePlanList } from '@/data/SchedulePlans/SchedulePlanSlice';
import { signedUser } from '@/data/System/SystemReducer';
import UserToRequestForm from '@/forms/UserToRequestForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import OnGoingSchedulerWrapper from '@/modules/Scheduler/OnGoingSchedulerWrapper';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

const DashboardPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const signedUserData = useAppSelector(signedUser);
    const onGoingSchedulePlansDataList = useAppSelector(onGoingSchedulePlanList);

    useEffect(() => {
        dispatch(fetchOnGoingSchedulePlans());
    }, []);

    return (
        <UserPermission
            id={[PermissionsEnum.Shifts, PermissionsEnum.SchedulePlans, PermissionsEnum.Breaks]}
            mode={Mode.READ}
        >
            <LayoutOfPage
                title={t('title.dashboard', 'Dashboard')}
                data-testid="dashboardPage"
                actionButtonsAfterSave={
                    signedUserData
                        ? [
                              <UserToRequestForm
                                  key="userToRequests"
                                  displayAsSidebar
                                  onlyFields={false}
                                  user={signedUserData}
                                  successCreateMessage={t(
                                      'message.info.requestHasBeenCreated',
                                      'Request has been created'
                                  )}
                              />
                          ]
                        : []
                }
            >
                {onGoingSchedulePlansDataList.length > 0 && (
                    <Grid container spacing={2} direction="row" sx={{ height: '100%' }}>
                        {onGoingSchedulePlansDataList.map((onGoingSchedulePlan) => (
                            <Grid item xs={12} key={onGoingSchedulePlan.id} sx={{ maxHeight: '100%' }}>
                                <OnGoingSchedulerWrapper id={onGoingSchedulePlan.id} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </LayoutOfPage>
        </UserPermission>
    );
};

export default DashboardPage;
