import { useTheme } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { MouseEventHandler } from 'react';
import { useAppSelector } from '@/data/hooks';
import {
    abbreviationOfSchedulePlanDayShift,
    backgroundOfSchedulePlanDayShift,
    colorOfSchedulePlanDayShift,
    endOfSchedulePlanDayShift,
    nameOfSchedulePlanDayShift,
    selectSchedulePlanDayShiftById,
    shiftIdOfSchedulePlanDayShift,
    startOfSchedulePlanDayShift,
    userBySchedulePlanDayShift
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { scheduleGridSettings } from '@/data/Settings/SettingSlice';
import { signedUser } from '@/data/System/SystemReducer';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper from '@/helpers/date/DateHelper';
import { ICellDataType } from '@/helpers/schedule';
import SchedulerCellContent, {
    ISchedulerCellContentProps
} from '@/modules/Scheduler/components/SchedulerCalendarCellContent/SchedulerCellConent';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import { serializeUser } from '@/utils/UserHelper';

export type ISchedulerTableCellContentWrapperProps = Omit<BoxProps, 'onClick'> &
    Pick<ISchedulerCellContentProps, 'columnFrom' | 'innerRef' | 'schedulePlanId' | 'withRightBorder' | 'timeZone'> & {
        mode: ISchedulerCalendarHeaderProps['mode'];
        isTableView?: boolean;
        shiftJoin: Pick<ArrayElement<ICellDataType<Date>['shift_joins']>, 'id' | 'from' | 'to'>;
        withWrappedStyles?: boolean;
        onClick?: MouseEventHandler;
    };

const SchedulerCellContentWrapper = ({
    isTableView = false,
    mode,
    shiftJoin,
    withWrappedStyles = true,
    schedulePlanId,
    onClick,
    ...rest
}: ISchedulerTableCellContentWrapperProps) => {
    const theme = useTheme();
    const signedUserData = useAppSelector(signedUser);
    const scheduleSettings = useAppSelector(scheduleGridSettings);
    const assignedShift = useAppSelector((state) => selectSchedulePlanDayShiftById(state, shiftJoin.id));
    //TODO zkratka
    const abbreviation = useAppSelector((state) => abbreviationOfSchedulePlanDayShift(state, shiftJoin.id)) ?? 'WFH';
    const shiftEnd = useAppSelector((state) => endOfSchedulePlanDayShift(state, shiftJoin.id));
    const shiftId = useAppSelector((state) => shiftIdOfSchedulePlanDayShift(state, shiftJoin.id));
    const shiftName = useAppSelector((state) => nameOfSchedulePlanDayShift(state, shiftJoin.id)) ?? '';
    const shiftStart = useAppSelector((state) => startOfSchedulePlanDayShift(state, shiftJoin.id));
    const user = useAppSelector((state) => userBySchedulePlanDayShift(state, shiftJoin.id));
    const shiftBackgroundColor = useAppSelector((state) => backgroundOfSchedulePlanDayShift(state, shiftJoin.id)) ?? '';
    const shiftColorOfShift = useAppSelector((state) => colorOfSchedulePlanDayShift(state, shiftJoin.id)) ?? '';

    const color =
        isTableView && signedUserData?.id === user?.id
            ? theme.palette.primary.contrastText
            : shiftId === null
            ? scheduleSettings.shiftWithoutDescriptionColor
            : shiftColorOfShift;
    const backgroundColor =
        isTableView && signedUserData?.id === user?.id
            ? theme.palette.primary.main
            : shiftId === null
            ? scheduleSettings.shiftWithoutDescriptionBackgroundColor
            : shiftBackgroundColor;

    const parsedBreaks =
        mode === SchedulePlanWidthTypeEnum.Day
            ? assignedShift?.schedule_plan_day_shift_breaks
                  .map((item) => ({
                      ...item,
                      start: DateHelper.fromDateTimeString(item.start)
                  }))
                  .map((item) => ({
                      ...item,
                      start: DateHelper.isBefore(shiftJoin.from, item.start.toDate())
                          ? item.start.toDate()
                          : shiftJoin.from,
                      duration: DateHelper.isAfter(
                          shiftJoin.to,
                          DateHelper.addMinutes(item.start.toDate(), item.break.duration)
                      )
                          ? item.break.duration
                          : DateHelper.getDifferenceAsMinutes(item.start.toDate(), shiftJoin.to),
                      toRemove:
                          DateHelper.getDifferenceAsMinutes(item.start.toDate(), shiftJoin.from) >=
                              item.break.duration ||
                          DateHelper.getDifferenceAsMinutes(item.start.toDate(), shiftJoin.to) <= 0
                  }))
                  .filter(({ toRemove }) => !toRemove)
                  .sort((a, b) => (DateHelper.isAfter(a.start, b.start) ? 1 : -1))
                  .map((item) => ({
                      id: item.id,
                      columnStart: 1 + DateHelper.getDifferenceAsMinutes(shiftJoin.from, item.start) / 15,
                      width: item.duration / 15,
                      break: item.break
                  })) ?? []
            : [];

    return shiftId && shiftStart && shiftEnd ? (
        <SchedulerCellContent
            {...rest}
            id={shiftJoin.id}
            breaks={parsedBreaks}
            color={color}
            abbreviation={abbreviation}
            backgroundColor={backgroundColor}
            isTableView={isTableView}
            mode={mode}
            schedulePlanId={schedulePlanId}
            shiftId={shiftId}
            shiftName={shiftName}
            shiftStart={DateHelper.fromDateTimeString(shiftStart)}
            shiftEnd={shiftId === null ? DateHelper.now() : DateHelper.fromDateTimeString(shiftEnd)}
            skills={
                isTableView
                    ? []
                    : assignedShift?.schedule_plan_day_shift_skills.map(({ skill, ...item }) => ({
                          ...skill,
                          id: item.id
                      })) ?? []
            }
            userId={user?.id ?? null}
            userName={user ? serializeUser(user) : undefined}
            width={DateHelper.getDifferenceAsMinutes(shiftJoin.from, shiftJoin.to) / 15}
            wrapperStyles={
                withWrappedStyles
                    ? {
                          display: 'flex',
                          gridColumnStart: 1 + DateHelper.getDifferenceAsMinutes(rest.columnFrom, shiftJoin.from) / 15,
                          gridColumnEnd: `span ${DateHelper.getDifferenceAsMinutes(shiftJoin.from, shiftJoin.to) / 15}`
                      }
                    : {}
            }
            onClick={onClick}
        />
    ) : (
        <></>
    );
};

export default SchedulerCellContentWrapper;
