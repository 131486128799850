import Typography from '@mui/material/Typography';
import { Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAppSelector } from '@/data/hooks';
import { ISchedulePlanPredictedTimeAndOperatorsForecastAccuracyReport } from '@/data/Reports/ReportModels';
import { isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading } from '@/data/Reports/ReportSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import LinearProgress from '@/wrappers/LinearProgress';

type IProps = {
    data?: ISchedulePlanPredictedTimeAndOperatorsForecastAccuracyReport[];
    colorPredictedOperatorsCountDifference?: string;
    colorRealOperatorsCountDifference?: string;
    width?: number;
    height?: number;
    timeZone?: string;
};

const SchedulePlanReportOperatorsDiffChart = ({
    data = [],
    colorPredictedOperatorsCountDifference = '#CA6E0E',
    colorRealOperatorsCountDifference = '#7159D8',
    timeZone,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const isLoading = useAppSelector(isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading);
    const xAxisDateFormatter = useLocalizeDateFormatter({
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateTickFormatter = (value: Date) => xAxisDateFormatter.format(value);

    return (
        <>
            <Typography variant="subtitle2">
                {t(
                    'label.schedulePlanAdherencePredictedOperatorsCountToRealOperatorsCountDifference',
                    'Schedule Plan Reports Predicted Operators Count/Real Operators Count'
                )}
            </Typography>
            {isLoading && <LinearProgress />}
            <ResponsiveContainer width={props.width} height={'50%'}>
                <AreaChart
                    syncId="areaChartOperators"
                    data={data.map((item) => {
                        const diff = item.predicted_operators_count - item.real_operators_count;

                        return {
                            name: DateHelper.fromDateTimeString(item.start).toDate(),
                            predictedOperatorsCountDifference: diff >= 0 ? diff : 0,
                            realOperatorsCountDifference: diff < 0 ? diff : 0
                        };
                    })}
                    margin={{
                        top: 15,
                        right: 30,
                        left: 10,
                        bottom: 40
                    }}
                >
                    <defs>
                        <linearGradient id="colorPredictedOperatorsCountDifference" x1="0" y1="1" x2="0" y2="0">
                            <stop offset="5%" stopColor={colorPredictedOperatorsCountDifference} stopOpacity={0.1} />
                            <stop offset="35%" stopColor={colorPredictedOperatorsCountDifference} stopOpacity={0.8} />
                        </linearGradient>
                        <linearGradient id="colorRealOperatorsCountDifference" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorRealOperatorsCountDifference} stopOpacity={0.1} />
                            <stop offset="35%" stopColor={colorRealOperatorsCountDifference} stopOpacity={0.8} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        angle={270}
                        tickMargin={40}
                        interval={95}
                        height={45}
                        tickFormatter={dateTickFormatter}
                    />
                    <YAxis
                        domain={([dataMin, dataMax]) => {
                            const max = 2 * Math.round(dataMax / 2);
                            const min = 2 * Math.round(Math.abs(dataMin) / 2);

                            return [-min, max];
                        }}
                        label={{ value: t('label.operators', 'Operators'), position: 'bottom', offset: 20 }}
                    />
                    <CartesianGrid strokeDasharray="4 4" />
                    <ReferenceLine y={0} stroke="#000" />
                    <Area
                        type="monotone"
                        dataKey="predictedOperatorsCountDifference"
                        stroke={colorPredictedOperatorsCountDifference}
                        fill="url(#colorPredictedOperatorsCountDifference)"
                    />
                    <Area
                        type="monotone"
                        dataKey="realOperatorsCountDifference"
                        stroke={colorRealOperatorsCountDifference}
                        fill="url(#colorRealOperatorsCountDifference)"
                    />
                    <Tooltip
                        labelFormatter={(value) => dateTimeFormatter.format(value)}
                        formatter={(value, name) => {
                            return [value, t(`label.${name}`, name.toString())];
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default SchedulePlanReportOperatorsDiffChart;
