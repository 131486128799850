const formatSecondsToTimeFormat = (seconds: number | string, formatter: Intl.DateTimeFormat) => {
    const date = new Date(Date.UTC(0, 0, 0, 0, 0, 0));

    seconds = Number(seconds);
    date.setSeconds(date.getSeconds() + seconds);

    return formatter.format(date);
};

export default formatSecondsToTimeFormat;
