import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '@/data/hooks';
import { queuesForSelect } from '@/data/Queues/QueueSlice';
import TransferList from '@/wrappers/TransferList';

type IQueues = {
    selectedQueues: string[];
    onChange: (value: string[]) => void;
};

const StyledContainer = styled(Grid)`
    padding-top: 3em;
    display: grid;
    align-items: stretch;
    gap: 30px;
`;

const Queues = ({ selectedQueues, onChange }: IQueues) => {
    const queues = useAppSelector(queuesForSelect) ?? [];

    return (
        <StyledContainer>
            <Box>
                <TransferList
                    items={queues.map((item) => ({
                        id: `${item.id}`,
                        sortableValue: item.name
                    }))}
                    values={selectedQueues.map((item) => `${item}`)}
                    onChange={onChange}
                />
            </Box>
        </StyledContainer>
    );
};

export default Queues;
