import { useEffect } from 'react';
import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { fetchBreaksForSelect } from '@/data/Breaks/BreakActions';
import { breaksForSelect } from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

type IProps = RequiredByFieldsGenerator;

const Googles = ({
    clearErrors,
    control,
    fullWidth,
    getFieldState,
    getValues,
    initialValues,
    register,
    setValue,
    watch
}: IProps) => {
    const { t } = useAppTranslation();
    const breaks = useAppSelector(breaksForSelect);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchBreaksForSelect({ search: '' }));
    }, []);

    return (
        <FieldsGenerator
            control={control}
            register={register}
            getFieldState={getFieldState}
            getValues={getValues}
            initialValues={initialValues}
            watch={watch}
            setValue={setValue}
            clearErrors={clearErrors}
            fullWidth={fullWidth}
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'calendarId',
                        label: t('label.googleCalendarId', 'Google Calendar ID')
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'synchronizeSchedules',
                        label: t('label.synchronizeSchedules', 'Synchronize Schedules'),
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'synchronizeBreaks',
                        label: t('label.synchronizeBreaks', 'Synchronize Breaks'),
                        width: 6,
                        validation: {
                            deps: 'synchronizeBreaks'
                        }
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'showAttendeesNameInTitle',
                        label: t('label.showAttendeesNameInTitle', 'Show Attendees Name In Title'),
                        width: 6,
                        validation: {
                            deps: 'showAttendeesNameInTitle'
                        }
                    }
                },
                {
                    type: 'switch',
                    display: ({ showAttendeesNameInTitle }) => showAttendeesNameInTitle === true,
                    props: {
                        name: 'showAttendeesSkillInNameTitle',
                        label: t('label.showAttendeesSkillInNameTitle', 'Show Skill In Attendees Name Title'),
                        width: 6,
                        validation: {
                            deps: 'showAttendeesNameInTitle'
                        }
                    }
                },
                {
                    type: 'switch',
                    display: ({ synchronizeBreaks }) => synchronizeBreaks === true,
                    props: {
                        name: 'chooseBreaksToSynchronize',
                        label: t('label.chooseBreaksSynchronize', 'Choose Breaks Synchronize'),
                        width: 6,
                        validation: {
                            deps: 'synchronizeBreaks'
                        }
                    }
                },
                {
                    type: 'transferList',
                    display: ({ chooseBreaksToSynchronize }) => chooseBreaksToSynchronize === true,
                    props: {
                        name: 'selectedBreaksToSynchronized',
                        items: breaks.map((item) => ({
                            id: `${item.id}`,
                            sortableValue: item.name
                        })),
                        validation: {
                            deps: 'chooseBreaksToSynchronize'
                        }
                    }
                }
            ]}
        />
    );
};

export default Googles;
