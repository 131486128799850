import { IUserCUVacations } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import Datatable from '@/wrappers/Datatable';

type IProps = {
    data: IUserCUVacations[];
};

const VacationsTab = ({ data }: IProps) => {
    const { t } = useAppTranslation();

    return (
        <Datatable
            header={[
                {
                    field: 'from_date',
                    title: t('header.fromDate', 'From date'),
                    type: 'datetime'
                },
                {
                    field: 'to_date',
                    title: t('header.toDate', 'To date'),
                    type: 'datetime'
                }
            ]}
            data={data}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            isDraggable={false}
            hasPaginating={false}
        />
    );
};

export default VacationsTab;
