import { useCallback } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { useAppSelector } from '@/data/hooks';
import { shitsList } from '@/data/Shifts/ShiftSlice';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { IUserToShiftFormDataType } from '@/data/UserToShifts/UserToShiftsModels';
import UserToShiftForm from '@/forms/UserToShiftForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import ChipStack from '@/wrappers/ChipStack';

type IShifts = {
    data: IUserToShiftFormDataType[];
    handleData: (value: IUserToShiftFormDataType[]) => void;
};

const ShiftsTab = ({ data, handleData }: IShifts) => {
    const { t } = useAppTranslation();
    const shiftsData = useAppSelector(shitsList) ?? [];
    const isSystemUser = useAppSelector(isSignedUserUser);

    const handleCreateData = useCallback(
        (values: IUserToShiftFormDataType) => {
            handleData([...data, { ...values }]);
        },
        [data, handleData]
    );

    const handleUpdateData = useCallback(
        (id: number | string, values: IUserToShiftFormDataType) => {
            handleData([...data.filter((item) => item.id !== id), { ...values, id: id }]);
        },
        [data, handleData]
    );

    const handleDeleteData = useCallback(
        (id: number | string) => {
            handleData([...data.filter((item) => item.id !== id)]);
        },
        [data, handleData]
    );

    return (
        <CrudDatatable<IUserToShiftFormDataType, number | string>
            name="userToShifts"
            disabled={isSystemUser}
            nameOfEntity={(item) => item.id.toString()}
            formRender={(id, justIcon, openButtonValue, rowData, renderButton) => (
                <UserToShiftForm
                    dataOnChange={(values) => (id ? handleUpdateData(id, values) : handleCreateData(values))}
                    onRemove={handleDeleteData}
                    usersShiftsData={data}
                    data={rowData}
                    props={{
                        displayAsSidebar: true,
                        openButtonRender: renderButton,
                        justIcon,
                        openButtonValue,
                        id
                    }}
                />
            )}
            header={[
                {
                    title: t('header.shift', 'Shift'),
                    field: 'shift_id',
                    render: (rowData) => (
                        <>{shiftsData.find((item) => item.id === rowData.shift_id)?.name ?? rowData.shift_id}</>
                    )
                },
                {
                    title: t('header.breaks', 'Breaks'),
                    field: 'shift_items',
                    render: (rowData) => {
                        return (
                            <ChipStack
                                name="breaks"
                                direction="row"
                                values={rowData.user_to_shift_items
                                    .filter((item) => typeof item.used === 'undefined' || item.used)
                                    .map((item) => ({
                                        id: item.id.toString(),
                                        label: item.shift_item.break?.name ?? '',
                                        title: DateHelper.formatTime(item.start),
                                        color: 'primary'
                                    }))}
                            />
                        );
                    }
                }
            ]}
            data={data}
            noRecordMessage={t('message.info.everyShiftsAreAllowedForUser', 'Every shifts are allowed for user')}
            hasSelection={false}
            handleRemove={handleDeleteData}
        />
    );
};

export default ShiftsTab;
