import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRootState } from '@/data/store';
import { fetchUserHoursList, fetchUserShiftsSummariesList, fetchUserSkillsList } from '@/data/Summaries/SummariesApi';
import {
    IFetchUserHoursList,
    IFetchUserShiftsSummaries,
    IFetchUserSkillsList,
    IUserShiftsSummaries,
    IUserSkills
} from '@/data/Summaries/SummariesModels';
import { getPermissionsList } from '@/data/System/SystemReducer';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

export const fetchUserShiftsSummaries = createAsyncThunk(
    'summaries/usersShiftsSummariesList',
    async (args: IFetchUserShiftsSummaries) => {
        const response = await fetchUserShiftsSummariesList(args);

        return response.reduce<IUserShiftsSummaries[]>((acc, item) => {
            const userSummary = acc.find((entity) => entity.user_id === item.user_id);

            if (userSummary) {
                userSummary.summary.push({
                    shift_id: item.shift_id,
                    number_of_shift: item.number_of_shift
                });
            } else {
                acc.push({
                    user_id: item.user_id,
                    summary: [
                        {
                            shift_id: item.shift_id,
                            number_of_shift: item.number_of_shift
                        }
                    ]
                });
            }

            return acc;
        }, []);
    }
);

export const fetchUserHours = createAsyncThunk(
    'summaries/usersHours',
    async (args: IFetchUserHoursList) => {
        const response = await fetchUserHoursList(args);

        return response.map((item) => ({
            period_id: args.periodId,
            user_id: item.user_id,
            workplace_id: args.workplaceId,
            summary: {
                contract_hours: item.contract_hours,
                shift_hours: item.shift_hours,
                paid_breaks_hours: item.paid_breaks_hours,
                unpaid_breaks_hours: item.unpaid_breaks_hours,
                remaining_hours: item.contract_hours - (item.shift_hours - item.unpaid_breaks_hours),
                shift_hours_with_paid_breaks: item.shift_hours - item.unpaid_breaks_hours,
                shift_hours_without_breaks: item.shift_hours - item.unpaid_breaks_hours - item.paid_breaks_hours
            }
        }));
    },
    {
        condition(_, { getState }) {
            const state = getState() as IRootState;

            return (
                0 !==
                    getPermissionsList(state).find((permission) => permission.id === PermissionsEnum.Summaries)
                        ?.permission ?? 0
            );
        }
    }
);

export const fetchUserSkills = createAsyncThunk('summaries/usersSkills', async (args: IFetchUserSkillsList) => {
    const response = await fetchUserSkillsList(args);
    const data = [] as IUserSkills[];

    response.forEach((item) => {
        if (data.some((entity) => entity.user_id === item.user_id)) {
            data
                .find((entity) => entity.user_id === item.user_id)
                ?.summary.push({
                    skill_id: item.skill_id,
                    hours: item.hours
                });
        } else {
            data.push({
                user_id: item.user_id,
                summary: [
                    {
                        skill_id: item.skill_id,
                        hours: item.hours
                    }
                ]
            });
        }
    });

    return data;
});
