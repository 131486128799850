import { useMemo, useState } from 'react';
import { ISchedulerUserToRequestFromBeModel } from '@/data/UserToRequests/UserToRequestModels';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import Box from '@/wrappers/Box';
import Datatable from '@/wrappers/Datatable';
import Select from '@/wrappers/Select';
import Switch from '@/wrappers/Switch';

type IRequests = {
    requestList: ISchedulerUserToRequestFromBeModel[];
};

type IFilter = 'all' | UserToRequestsStateEnum;

const RequestsTab = ({ requestList = [] }: IRequests) => {
    const { t } = useAppTranslation();
    const [showHistory, setShowHistory] = useState(false);
    const [filter, setFilter] = useState<IFilter>('all');

    const filteredRequestList = useMemo(() => {
        let filteredData = requestList;

        if (!showHistory) {
            filteredData = requestList.filter((request) =>
                DateHelper.isAfter(DateHelper.fromDateTimeString(request.datetime_to), DateHelper.now())
            );
        }

        if (filter !== 'all') {
            filteredData = filteredData.filter((request) => request.state === filter);
        }

        return filteredData;
    }, [showHistory, filter]);

    return (
        <Datatable
            actionsInHeader={
                <Box sx={{ display: 'flex', gap: 4, marginRight: 2, alignItems: 'center' }}>
                    <Switch
                        name="showHistory"
                        label={t('label.showHistory', 'Show History')}
                        value={showHistory}
                        labelPlacement="start"
                        onChange={(newValue) => {
                            setShowHistory(newValue);
                        }}
                    />

                    <Select
                        name="filter"
                        value={filter}
                        sx={{ minWidth: '180px' }}
                        onChange={(selected) => setFilter((selected as IFilter) ?? 'all')}
                        options={[
                            {
                                id: 'all',
                                label: t('label.all', 'All')
                            },
                            {
                                id: UserToRequestsStateEnum.ACCEPTED,
                                label: t('label.accepted', 'Accepted')
                            },
                            {
                                id: UserToRequestsStateEnum.REJECTED,
                                label: t('label.rejected', 'Rejected')
                            },
                            {
                                id: UserToRequestsStateEnum.CREATED,
                                label: t('label.created', 'Created')
                            }
                        ]}
                    />
                </Box>
            }
            rowStyle={(rowData) =>
                rowData.state !== UserToRequestsStateEnum.CREATED
                    ? {
                          backgroundColor:
                              rowData.state === UserToRequestsStateEnum.ACCEPTED
                                  ? 'rgba(197,234,184,0.72)'
                                  : 'rgba(255,134,138,0.73)'
                      }
                    : {}
            }
            header={[
                {
                    field: 'request_type.name',
                    title: t('header.name', 'name'),
                    type: 'string'
                },
                {
                    field: 'description',
                    title: t('header.description', 'Description'),
                    type: 'string'
                },
                {
                    field: 'datetime_from',
                    title: t('header.fromDate', 'From date'),
                    type: 'datetime',
                    defaultSort: 'asc'
                },
                {
                    field: 'datetime_to',
                    title: t('header.toDate', 'To date'),
                    type: 'datetime'
                },
                {
                    field: 'state',
                    title: t('header.state', 'State'),
                    type: 'string',
                    render: ({ state }) => <>{t(`enums.userToRequestsStateEnum.${state}`, state)}</>
                }
            ]}
            data={filteredRequestList}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            isDraggable={false}
            hasPaginating={false}
        />
    );
};

export default RequestsTab;
