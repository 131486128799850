import Box from '@mui/material/Box';
import MuiIcon from '@mui/material/Icon';
import { SxProps } from '@mui/material/styles';
import handleIconReplaces from '@/utils/icons/handleIconReplaces';

type IIcon = {
    name?: string;
    icon?: string;
    color?: string;
    display?: 'flex' | 'inline' | 'inline-block';
    sx?: SxProps;
};

const Icon = ({ name, icon, color, display = 'flex', sx = {} }: IIcon) => (
    <Box sx={{ display, alignItems: 'center' }}>
        {icon && <MuiIcon sx={{ ...sx, color, verticalAlign: 'middle' }}>{handleIconReplaces(icon)}</MuiIcon>}
        {name}
    </Box>
);

export default Icon;
