import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import SchedulerCellContent from '@/modules/Scheduler/components/SchedulerCalendarCellContent/SchedulerCellConentWrapper';
import { NotEmpty } from '@/modules/Scheduler/StyledParts';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';

type IProps = {
    assignedShiftId: number;
    bodyFrom: Date | DateTimeType;
    columnDateFrom: Date;
    columnDateTo: Date;
    currentDate: Date;
    gridColumnStart: number;
    gridRowStart: number;
    innerRef?: (ref: HTMLDivElement) => void;
    rowStart: number;
    skillRowStart: number;
    schedulePlanId: number;
    timeZone?: string;
    onClick: () => void;
};

const SchedulerTableBodyCell = ({
    assignedShiftId,
    bodyFrom,
    columnDateFrom,
    columnDateTo,
    currentDate,
    gridColumnStart,
    gridRowStart,
    innerRef,
    rowStart,
    skillRowStart,
    schedulePlanId,
    timeZone,
    onClick
}: IProps) => {
    return (
        <NotEmpty
            ref={innerRef}
            bodyFrom={DateHelper.toDate(bodyFrom)}
            columnEnd={1}
            columnStart={gridColumnStart}
            rowStart={rowStart + gridRowStart}
            currentDate={currentDate}
            isDayMode={false}
            isTableView={gridColumnStart !== 0}
            sx={
                gridRowStart === 0 && skillRowStart !== 0
                    ? {
                          borderTop: '1px solid black'
                      }
                    : {}
            }
            onClick={onClick}
        >
            <SchedulerCellContent
                columnFrom={columnDateFrom}
                mode={SchedulePlanWidthTypeEnum.Week}
                isTableView
                shiftJoin={{
                    id: assignedShiftId,
                    from: columnDateFrom,
                    to: columnDateTo
                }}
                schedulePlanId={schedulePlanId}
                timeZone={timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                withRightBorder
            />
        </NotEmpty>
    );
};

export default SchedulerTableBodyCell;
