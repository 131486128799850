import {
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import DialogActions from './Actions';
import DialogContainer, { IDialogContainerProps } from './Container';
import DialogContent from './Content';
import DialogOpenButton from './OpenButton';
import DialogTitle from './Title';

type IBaseProps = Pick<IDialogContainerProps, 'fullWidth' | 'maxWidth'> & {
    name: string;
    open?: boolean;
    title?: ReactNode;
    subTitle?: ReactNode;
    actions?: (onClose: () => void) => ReactNode;
    innerRef?: Ref<{ close: () => void }>;
    isPossibleClose?: boolean;
    openButtonValue: ReactNode;
    openButton?: (onClick: (event?: MouseEvent) => void) => ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
};
type IPropsWithOpenButton = Omit<IBaseProps, 'openButton'> & {
    openButtonValue: undefined;
    openButton: (onClick: (event?: MouseEvent) => void) => ReactNode;
};
type IDefaultProps = Omit<IBaseProps, 'openButtonValue'> & {
    openButtonValue: ReactNode;
    openButton: undefined;
};

export type IDialogProps = IDefaultProps | IPropsWithOpenButton;

const Dialog = ({ innerRef, isPossibleClose = true, ...props }: PropsWithChildren<IDialogProps>) => {
    const [open, setOpen] = useState(false);
    const actions = props.actions || (() => null);
    const handleClose = useCallback(() => {
        if (isPossibleClose) {
            setOpen(false);

            if (props.onClose) {
                props.onClose();
            }
        }
    }, [isPossibleClose]);

    useImperativeHandle(innerRef, () => ({
        close: () => {
            setOpen(false);
        }
    }));

    useEffect(() => {
        if (open && props.onOpen) {
            props.onOpen();
        }
    }, [open]);

    useEffect(() => {
        if (typeof props.open !== 'undefined') {
            setOpen(props.open);
        }
    }, [props.open]);

    return (
        <>
            {(props.openButton || props.openButtonValue) && (
                <DialogOpenButton
                    render={props.openButton}
                    onClick={(event) => {
                        event?.stopPropagation();
                        setOpen(!open);
                    }}
                    value={props.openButtonValue}
                    name={props.name}
                />
            )}
            <DialogContainer
                onClick={(event) => {
                    event.stopPropagation();
                }}
                open={props.open || open}
                maxWidth={props.maxWidth}
                fullWidth={props.fullWidth}
                data-testid={`${props.name}-dialog`}
                onClose={handleClose}
            >
                <DialogTitle onClose={() => handleClose()} subTitle={props.subTitle}>
                    {props.title}
                </DialogTitle>
                {/*<DialogContent>{children(() => setOpen(false))}</DialogContent>*/}
                <DialogContent>{props.children}</DialogContent>
                <DialogActions>{actions(() => handleClose())}</DialogActions>
            </DialogContainer>
        </>
    );
};

export default Dialog;
