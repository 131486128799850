import Typography from '@mui/material/Typography';
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useAppSelector } from '@/data/hooks';
import { ISchedulePlanPredictedTimeAndOperatorsForecastAccuracyReport } from '@/data/Reports/ReportModels';
import { isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading } from '@/data/Reports/ReportSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import LinearProgress from '@/wrappers/LinearProgress';

type IProps = {
    data?: ISchedulePlanPredictedTimeAndOperatorsForecastAccuracyReport[];
    colorPredictedOperatorsCountDifference?: string;
    colorRealOperatorsCountDifference?: string;
    width?: number;
    height?: number;
    timeZone?: string;
};

const SchedulePlanReportOperatorsChart = ({
    data = [],
    colorPredictedOperatorsCountDifference = '#CA6E0E',
    colorRealOperatorsCountDifference = '#7159D8',
    timeZone,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const isLoading = useAppSelector(isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading);
    const xAxisDateFormatter = useLocalizeDateFormatter({
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateTickFormatter = (value: Date) => xAxisDateFormatter.format(value);

    return (
        <>
            <Typography variant="subtitle2">
                {t(
                    'label.schedulePlanAdherencePredictedOperatorsCountToRealOperatorsCount',
                    'Schedule Plan Reports Predicted Operators Count/Real Operators Count'
                )}
            </Typography>
            {isLoading && <LinearProgress />}
            <ResponsiveContainer width={props.width} height={'50%'}>
                <AreaChart
                    syncId="areaChartOperators"
                    data={data.map((item) => ({
                        name: DateHelper.fromDateTimeString(item.start).toDate(),
                        realOperatorsCount: item.real_operators_count,
                        predictedOperatorsCount: item.predicted_operators_count
                    }))}
                    margin={{
                        top: 15,
                        right: 30,
                        left: 10,
                        bottom: 60
                    }}
                >
                    <defs>
                        <linearGradient id="colorPredictedOperatorsCountDifference" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorPredictedOperatorsCountDifference} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={colorPredictedOperatorsCountDifference} stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id="colorRealOperatorsCountDifference" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorRealOperatorsCountDifference} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={colorRealOperatorsCountDifference} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        angle={270}
                        tickMargin={40}
                        interval={95}
                        height={45}
                        tickFormatter={dateTickFormatter}
                    />
                    <ReferenceLine y={0} stroke="#000" />
                    <YAxis
                        domain={[0, 'dataMax + 1']}
                        label={{ value: t('label.operators', 'Operators'), position: 'bottom', offset: 20 }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                        labelFormatter={(value) => dateTimeFormatter.format(value)}
                        formatter={(value, name) => [value, t(`label.${name}`, name.toString())]}
                    />
                    <Area
                        type="monotone"
                        dataKey="predictedOperatorsCount"
                        stroke={colorPredictedOperatorsCountDifference}
                        fill="url(#colorPredictedOperatorsCountDifference)"
                    />
                    <Area
                        type="monotone"
                        dataKey="realOperatorsCount"
                        stroke={colorRealOperatorsCountDifference}
                        fill="url(#colorRealOperatorsCountDifference)"
                    />
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                        formatter={(value) => <>{t(`label.${value}`, value)}</>}
                        wrapperStyle={{
                            paddingTop: '12px'
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default SchedulePlanReportOperatorsChart;
