import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IWorkplaceCUModel, IWorkplaceFromBeModel, IWorkplaceModel, IWorkplaceRecordModel } from './WorkplaceModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/workplaces', paging || {});

    return fetchGet<IListResponseWithPaging<IWorkplaceFromBeModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/workplaces/select', { search, fields });

    return fetchGet<IListResponse<IWorkplaceModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IWorkplaceFromBeModel>(`/api/workplaces/${id}`);
}
export function fetchRecordsList() {
    return fetchGet<IListResponse<IWorkplaceRecordModel>>('/api/workplaces/get_workplaces_record_list');
}

export function create(data: IWorkplaceCUModel) {
    return fetchPost<IWorkplaceFromBeModel>('/api/workplaces/', data);
}

export function update(id: number, data: IWorkplaceCUModel) {
    return fetchPatch<IWorkplaceFromBeModel>(`/api/workplaces/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/workplaces/${id}`);
}
