import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';

type IProps<IDType = number> = {
    title?: string;
    justIcon?: boolean;
    template?: string;
    nameOfItem?: string;
    idOfItem?: IDType;
    onAgree: (onSuccess: () => void) => void;
};

const StyledButton = styled(IconButton)(
    ({ theme }) => `
        color: ${theme.palette.error.main};
    `
);

export default function RemoveDialog<IDType = number>(props: IProps<IDType>) {
    const { t } = useAppTranslation();
    const [inProgress, setInProgress] = useState(false);
    const dialogProps = props.justIcon
        ? {
              openButton: (onClick: (event: MouseEvent) => void) => (
                  <StyledButton
                      data-testid={`remove-${props.idOfItem}`}
                      onClick={onClick}
                      name="Remove icon"
                      title="Remove"
                  >
                      <DeleteIcon />
                  </StyledButton>
              ),
              openButtonValue: undefined
          }
        : {
              openButton: undefined,
              openButtonValue: t('label.remove', 'Remove')
          };
    const handleClick = (onClose: () => void) => {
        setInProgress(true);
        props.onAgree(() => {
            onClose();
            setInProgress(false);
        });
    };

    return (
        <Dialog
            {...dialogProps}
            name="removeDialog"
            title={props.title || t('title.doYouReallyRemove', 'Do you really remove?')}
            actions={(onClose) => (
                <Box sx={{ display: 'flex', flex: '1', justifyContent: 'space-between' }}>
                    <Button data-testid="remove-dialog-cancel" name="no" variant="outlined" onClick={onClose}>
                        {t('label.no', 'No')}
                    </Button>
                    <LoadingButton
                        data-testid="remove-dialog-confirm"
                        name="agree"
                        variant="contained"
                        color="error"
                        loading={inProgress}
                        onClick={() => handleClick(onClose)}
                    >
                        {t('label.remove', 'Remove')}
                    </LoadingButton>
                </Box>
            )}
        >
            {props.template ||
                t('message.question.doYouReallyWantToRemove1', 'Do you really want to remove {{entity}}', {
                    entity: props.nameOfItem!
                })}
        </Dialog>
    );
}
