import SummarizeIcon from '@mui/icons-material/Summarize';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import Box from '@mui/material/Box';
import theme from '@/assets/theme';
import { useAppSelector } from '@/data/hooks';
import { isUserHoursLoading, userHoursList } from '@/data/Summaries/SummariesSlice';
import { usersForSelect } from '@/data/Users/UserSlice';
import { normalizeFloatNumber } from '@/helpers/number';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';
import Datatable from '@/wrappers/Datatable';
import Table from '@/wrappers/Table';

const Hours = () => {
    const { t } = useAppTranslation();
    const usersData = useAppSelector(usersForSelect);
    const hoursUserData = useAppSelector(userHoursList);

    return (
        <Datatable
            header={[
                {
                    title: t('label.user', 'User'),
                    field: 'name',
                    render: (rowData) => serializeUser(usersData.find((user) => user.id === rowData.user_id))
                },
                {
                    title: t('header.contractHours', 'Contract Hours'),
                    tooltip: t('message.info.contractHours', 'Summarization of users contract hours'),
                    field: 'contract_hours',
                    render: (rowData) => <>{rowData.summary.contract_hours}</>
                },
                {
                    title: t('header.shiftHours', 'Shift Hours'),
                    tooltip: t(
                        'message.info.shiftHours',
                        'Summarization of shifts with paid and unpaid breaks in hours'
                    ),
                    field: 'shift_hours',
                    render: (rowData) => <>{normalizeFloatNumber(rowData.summary.shift_hours)}</>
                }
            ]}
            data={hoursUserData}
            detailPanel={[
                {
                    icon: () => <SummarizeOutlinedIcon />,
                    openIcon: () => <SummarizeIcon />,
                    tooltip: t('message.info.showSummarizationDetails', 'Show Summarization Details'),
                    render: (rowData) => {
                        return (
                            <Table
                                name={`summary_detail_of_user_${rowData.user_id}`}
                                rows={
                                    [
                                        {
                                            id: t('header.remainingHours', 'Remaining Hours'),
                                            value: rowData.summary.remaining_hours,
                                            tooltip: t(
                                                'message.info.remainingHours',
                                                'Remaining hours from contract hours minus shifts with paid breaks'
                                            )
                                        },
                                        {
                                            id: t('header.shiftHoursWithPaidBreaks', 'Shift Hours With Paid Breaks'),
                                            value: rowData.summary.shift_hours_with_paid_breaks,
                                            tooltip: t(
                                                'message.info.shiftHoursWithPaidBreaks',
                                                'Summarization of shifts with paid breaks in hours'
                                            )
                                        },
                                        {
                                            id: t('header.paidBreaksHours', 'Paid Breaks Hours'),
                                            value: rowData.summary.paid_breaks_hours,
                                            tooltip: t(
                                                'message.info.paidBreaksHours',
                                                'Summarization of paid breaks in hours'
                                            )
                                        },
                                        {
                                            id: t('header.unpaidBreaksHours', 'Unpaid Breaks Hours'),
                                            value: rowData.summary.unpaid_breaks_hours,
                                            tooltip: t(
                                                'message.info.unpaidBreaksHours',
                                                'Summarization of unpaid breaks in hours'
                                            )
                                        }
                                    ] as { id: string; value: number; tooltip: string }[]
                                }
                                columns={[
                                    {
                                        id: 'id',
                                        label: 'id',
                                        access: (subRowData) => <Box title={subRowData.tooltip}>{subRowData.id}:</Box>
                                    },
                                    {
                                        id: 'value',
                                        label: 'value',
                                        access: (subRowData) => (
                                            <Box title={subRowData.tooltip}>
                                                {normalizeFloatNumber(subRowData.value)}
                                            </Box>
                                        )
                                    }
                                ]}
                                columnProps={(columnId) => (columnId === 'id' ? { width: '30%' } : {})}
                                rowProps={{
                                    sx: {
                                        backgroundColor: theme.palette.grey.A200,
                                        borderTop: '1px solid ' + theme.palette.grey.A700
                                    }
                                }}
                                showHeader={false}
                            />
                        );
                    }
                }
            ]}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            hasSorting={false}
            isDraggable={false}
            hasPaginating={false}
            isLoading={useAppSelector(isUserHoursLoading)}
        />
    );
};

export default Hours;
