const compareByType = <T extends boolean | number | string | unknown>(aValue: T, bValue: T) => {
    const aType = typeof aValue,
        bType = typeof bValue;

    if (aValue === bValue || aType !== bType) {
        return 0;
    }

    switch (aType) {
        case 'boolean':
            return (aValue as boolean) ? 1 : -1;
        case 'number':
            return (aValue as number) < (bValue as number) ? 1 : -1;
        case 'string':
            return (aValue as string).localeCompare(bValue as string);
        default:
            return 0;
    }
};

const sortEntities =
    <Entity extends Record<string, unknown>>(sortBy: string, direction: 'asc' | 'desc' | 'ASC' | 'DESC') =>
    (a: Entity, b: Entity) => {
        if (Object.keys(a).includes(sortBy)) {
            const aValue = a[sortBy as keyof Entity],
                bValue = b[sortBy as keyof Entity];

            const resultOfCompare = compareByType(aValue, bValue);

            return direction.toLowerCase() === 'asc' ? resultOfCompare : -1 * resultOfCompare;
        }

        return 0;
    };

export default sortEntities;
