import { IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { ITimeoffCUModel, ITimeoffModel } from './TimeoffModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/timeoffs', paging || {});

    return fetchGet<IListResponseWithPaging<ITimeoffModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<ITimeoffModel>(`/api/timeoffs/${id}`);
}

export function create(data: ITimeoffCUModel) {
    return fetchPost<ITimeoffModel>('/api/timeoffs/', data);
}

export function update(id: number, data: Partial<ITimeoffCUModel>) {
    return fetchPatch<ITimeoffModel>(`/api/timeoffs/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/timeoffs/${id}`);
}
