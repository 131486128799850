import MuiBox from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Box = styled(MuiBox)(
    ({ theme }) => `
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            margin: 0 2px;
            background-color: ${theme.palette.primary.main};
            border-radius: ${theme.shape.borderRadius}px;
        }
`
);

export default Box;
