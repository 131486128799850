import { Component, ErrorInfo, PropsWithChildren } from 'react';
class State {
    error: Error | null = null;

    errorInfo: ErrorInfo | null = null;
}

export default class ErrorBoundary extends Component<PropsWithChildren<{}>, State> {
    state = new State();

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        // Normally, just render children
        return this.props.children;
    }
}
