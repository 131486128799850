import Chip from '@mui/material/Chip';
import { dayTest } from '@/helpers/bit';
import useAppTranslation from '@/hooks/useAppTranslation';
import useTranslatedDays from '@/hooks/useTranslatedDays';

type IProps = {
    days: number;
    holidays: boolean;
};

const getChipStyle = (dayId: number | string) => {
    const friday = 4;
    const style = {
        // Todo: asi refaktorovat do theme sytlů
        m: 0.5,
        color: ' #56CA00',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #4CAF50',
        textTransform: 'uppercase',
        borderRadius: '16px'
    };

    if (typeof dayId === 'number' && dayId > friday) {
        style.color = '#FFB400';
        style.background = 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #ED6C02';
    } else if (typeof dayId === 'string') {
        switch (dayId) {
            case 'weekend':
                style.color = '#FFB400';
                style.background =
                    'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #ED6C02';
                break;

            case 'workdays':
                style.color = 'green';
                style.background = 'rgba(0,128,0,0.1)';
                break;

            case 'holidays':
                style.color = '#9155FD';
                style.background =
                    'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #9155FD';
                break;
        }
    }

    return style;
};

const createChip = (i: number | string, text: string) => <Chip label={text} key={i} sx={getChipStyle(i)} />;
// TODO refactoring of this file... use names of day like in DayInWeekSelect etc..

export default function Weekdays({ days, holidays }: IProps) {
    const { t } = useAppTranslation();
    const translatedDays = useTranslatedDays();
    const daysNames = translatedDays.map((weekday) => weekday.name);
    const transformedDays: boolean[] = [];
    const monday = 0;
    const tuesday = 1;
    const wednesday = 2;
    const thursday = 3;
    const friday = 4;
    const saturday = 5;
    const sunday = 6;
    const out = [];
    let start = monday;
    let stop = sunday;
    let tmp;

    for (let i = 0; i <= 6; i++) {
        transformedDays.push(dayTest(days, i));
    }

    if (
        transformedDays[monday] &&
        transformedDays[tuesday] &&
        transformedDays[wednesday] &&
        transformedDays[thursday] &&
        transformedDays[friday]
    ) {
        out.push(createChip('Weekdays', t('label.weekdays', 'Weekdays')));
        start = friday + 1;
    }

    if (transformedDays[saturday] && transformedDays[sunday]) {
        tmp = createChip('weekend', t('label.weekend', 'Weekend'));
        stop = friday;
    }

    for (let i = start; i <= stop; i++) {
        if (transformedDays[i]) {
            out.push(createChip(i, daysNames[i]));
        }
    }

    out.push(tmp);

    if (holidays) {
        out.push(createChip('holidays', t('label.holidays', 'Holidays')));
    }

    return <>{out}</>;
}
