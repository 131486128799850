import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import {
    createApplicationSetting,
    fetchApplicationsSettingsItems,
    updateApplicationSetting
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import {
    IApplicationSettingItems,
    IRequestApplicationSettingItem,
    ISchedulePlanApplicationSettingItem
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import { IRootState } from '@/data/store';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';

type IState = {
    loadingListStatus: IRequestState;
};

const initialState: IState = {
    loadingListStatus: 'idle'
};

const getKey = <T extends Pick<IApplicationSettingItems, 'application_key' | 'key'>>(entity: T) =>
    `${entity.application_key}_${entity.key}`;

const adapter = createEntityAdapter<IApplicationSettingItems>({
    selectId: getKey,
    sortComparer: (a, b) =>
        a.application_key === b.application_key
            ? a.key.localeCompare(b.key)
            : a.application_key.localeCompare(b.application_key)
});

const applicationSettingsItemSlice = createSlice({
    name: 'applicationSettingItems',
    initialState: adapter.getInitialState(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApplicationsSettingsItems.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchApplicationsSettingsItems.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';

                adapter.upsertMany(state, action.payload.data.map(({ value }) => value).flat());
            })
            .addCase(fetchApplicationsSettingsItems.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(createApplicationSetting.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(createApplicationSetting.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.addMany(state, action.payload.value);
            })
            .addCase(createApplicationSetting.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(updateApplicationSetting.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(updateApplicationSetting.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.updateMany(
                    state,
                    action.payload.value.map((item) => ({
                        id: getKey(item),
                        changes: item
                    }))
                );
            })
            .addCase(updateApplicationSetting.rejected, (state) => {
                state.loadingListStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[applicationSettingsItemSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export const getApplicationSettingsItemsByApplicationKey = (
    state: IRootState,
    applicationKey: ApplicationSettingsItemTypesEnum
) => {
    return adapterSelectors.selectAll(state).filter((item) => item.application_key === applicationKey);
};

export const getSchedulePlanApplicationsSettings = (state: IRootState) =>
    getApplicationSettingsItemsByApplicationKey(
        state,
        ApplicationSettingsItemTypesEnum.schedulePlan
    ) as ISchedulePlanApplicationSettingItem[];

export const getRequestApplicationsSettings = (state: IRootState) =>
    getApplicationSettingsItemsByApplicationKey(
        state,
        ApplicationSettingsItemTypesEnum.request
    ) as IRequestApplicationSettingItem[];
export const applicationSettingsItemList = adapterSelectors.selectAll;
export const isApplicationSettingsItemListLoaded = (state: IRootState) => getState(state).loadingListStatus === 'idle';
export const onDnDShiftBetweenDatesRemoveOriginalShift = (state: IRootState) =>
    adapterSelectors.selectById(state, `${ApplicationSettingsItemTypesEnum.schedulePlan}_dnd_shift_between_days`)
        ?.value === false;
export const canTakeTradeWithoutOffer = (state: IRootState) =>
    adapterSelectors.selectById(
        state,
        `${ApplicationSettingsItemTypesEnum.schedulePlan}_can_take_trade_without_counteroffer`
    )?.value === true;

export default applicationSettingsItemSlice;
