import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import {
    createCallCenter,
    fetchCallCenterById,
    fetchCallCenters,
    fetchCallCentersForSelect,
    removeCallCenter,
    updateCallCenter
} from '@/data/CallCenters/CallCenterActions';
import { ICallCenterModel } from '@/data/CallCenters/CallCenterModels';
import { defaultPaging, IPaging } from '@/data/Paging';
import { IRootState } from '@/data/store';
import filterIncludeValue from '@/helpers/array/filterIncludeValue';
import sortEntities from '@/helpers/array/sortEntities';

type ICallCenterSliceState = {
    paging: IPaging;
    selectItems: ICallCenterModel[];
    loading: IRequestState;
    selectItemsStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: ICallCenterSliceState = {
    paging: defaultPaging('name'),
    selectItems: [],
    loading: 'idle',
    selectItemsStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};

const adapter = createEntityAdapter<ICallCenterModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const callCenterSlice = createSlice({
    name: 'callCenter',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCallCenters.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchCallCenters.fulfilled, (state, action) => {
            state.loading = 'idle';
            adapter.upsertMany(state, action.payload.data);
            state.paging = action.payload.collection;
        });
        builder.addCase(fetchCallCenters.rejected, (state) => {
            state.loading = 'failed';
        });
        builder.addCase(fetchCallCenterById.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchCallCenterById.fulfilled, (state, action) => {
            state.loading = 'idle';
            adapter.upsertOne(state, action.payload);
        });
        builder.addCase(fetchCallCenterById.rejected, (state) => {
            state.loading = 'failed';
        });
        builder.addCase(fetchCallCentersForSelect.pending, (state) => {
            state.selectItemsStatus = 'loading';
        });
        builder.addCase(fetchCallCentersForSelect.fulfilled, (state, action) => {
            state.selectItemsStatus = 'idle';
            state.selectItems = action.payload.data;
        });
        builder.addCase(fetchCallCentersForSelect.rejected, (state) => {
            state.selectItemsStatus = 'failed';
        });
        builder.addCase(updateCallCenter.pending, (state) => {
            state.updatingStatus = 'loading';
        });
        builder.addCase(updateCallCenter.fulfilled, (state, action) => {
            state.updatingStatus = 'idle';
            adapter.upsertOne(state, action.payload);
        });
        builder.addCase(updateCallCenter.rejected, (state) => {
            state.updatingStatus = 'failed';
        });
        builder.addCase(createCallCenter.pending, (state) => {
            state.creatingStatus = 'loading';
        });
        builder.addCase(createCallCenter.fulfilled, (state, action) => {
            state.creatingStatus = 'idle';
            adapter.addOne(state, action.payload);
        });
        builder.addCase(createCallCenter.rejected, (state) => {
            state.creatingStatus = 'failed';
        });
        builder.addCase(removeCallCenter.pending, (state) => {
            state.removingStatus = 'loading';
        });
        builder.addCase(removeCallCenter.fulfilled, (state, action) => {
            state.removingStatus = 'idle';
            adapter.removeOne(state, action.meta.arg.id);
        });
        builder.addCase(removeCallCenter.rejected, (state) => {
            state.removingStatus = 'failed';
        });
    }
});

const getState = (state: IRootState) => state[callCenterSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default callCenterSlice;
export const callCenterPaging = (state: IRootState) => getState(state).paging;
export const isCallCenterInProgress = (state: IRootState) => getState(state).loading === 'loading';
export const callCenterById = (state: IRootState, id?: number) => (id ? adapterSelectors.selectById(state, id) : null);
export const callCenterForSelect = (state: IRootState) => getState(state).selectItems;
export const callCenterForSelectStatus = (state: IRootState) => getState(state).selectItemsStatus;
export const callCenterCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const callCenterUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const callCenterRemovingStatus = (state: IRootState) => getState(state).removingStatus;

export const selectFilteredCallCenters = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => getState(state).paging,
    (state: IRootState) => adapterSelectors.selectTotal(state),
    (callCentersList, filter, total) => {
        const page = Math.min(filter.page - 1, Math.floor(total / filter.limit));

        return Object.values(callCentersList)
            .filter((callCenter) => filterIncludeValue(filter.search, [callCenter.name]))
            .sort(sortEntities(`${filter.sort}`, `${filter.direction}`))
            .slice(page * filter.limit, (page + 1) * filter.limit);
    }
);
