import { useCallback, useState } from 'react';
import { useAppDispatch } from '@/data/hooks';
import { recalculateSchedulePlan } from '@/data/SchedulePlans/SchedulePlanActions';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button/Button';
import DialogActions from '@/wrappers/Dialog/Actions';
import DialogContainer from '@/wrappers/Dialog/Container';
import DialogContent from '@/wrappers/Dialog/Content';
import DialogTitle from '@/wrappers/Dialog/Title';
import LoadingButton from '@/wrappers/LoadingButton';

type IProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    schedulePlanId: number;
    scheduleName: string | null;
    onClose: () => void;
    isPlanLocked: boolean;
};
export const RecalculateDialog = ({ open, setOpen, onClose, schedulePlanId, scheduleName, isPlanLocked }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const [recalculationInProgress, setRecalculationInProgress] = useState(false);

    const handleRecalculatePlan = useCallback(() => {
        if (schedulePlanId && !isPlanLocked) {
            setRecalculationInProgress(true);
            dispatch(recalculateSchedulePlan(schedulePlanId)).then(() => {
                setRecalculationInProgress(false);
                setOpen(false);
            });
        }
    }, [isPlanLocked, schedulePlanId]);

    return (
        <DialogContainer
            data-testid="schedulerMenu_confirmDialog_recalculate"
            open={open}
            onClick={(event) => {
                event.stopPropagation();
            }}
            onClose={onClose}
        >
            <DialogTitle>{t('label.recalculateSchedule', 'Recalculate Schedule')}</DialogTitle>
            <DialogContent>
                {t(
                    'message.question.doYouWantToRecalculateSchedule',
                    'Do you want to recalculate Schedule Plan {{entity}}',
                    {
                        entity: scheduleName ?? ''
                    }
                )}
            </DialogContent>
            <DialogActions>
                <Button name="no" variant="outlined" onClick={onClose}>
                    {t('label.no', 'No')}
                </Button>
                <LoadingButton
                    name="confirm"
                    variant="contained"
                    color="primary"
                    loading={recalculationInProgress}
                    onClick={handleRecalculatePlan}
                >
                    {t('label.confirm', 'Confirm')}
                </LoadingButton>
            </DialogActions>
        </DialogContainer>
    );
};
