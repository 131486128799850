import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { FC, PropsWithChildren, ReactNode } from 'react';

type IDialogTitleProps = Omit<DialogTitleProps, 'onClick'> & {
    subTitle?: ReactNode;
    onClose?: () => void;
};

const headerStyles = ({ theme, onClose }: { theme: Theme; onClose?: () => void }) => `
        padding: ${theme.spacing(2)};
        padding-right: ${onClose ? theme.spacing(6) : theme.spacing(4)};
        padding-left: ${theme.spacing(3)};
    `;
const TitleWithSubTitle: FC<DialogTitleProps> = (props) => <MuiDialogTitle {...props} component={Box} />;
const SimpleTitle = styled(MuiDialogTitle, {
    shouldForwardProp: (props) => props !== 'onClose'
})<{ onClose?: () => void }>(headerStyles);
const TitleWithSubtitle = styled(TitleWithSubTitle, {
    shouldForwardProp: (props) => props !== 'onClose'
})<{ onClose?: () => void }>(headerStyles);
const StyledButton = styled(IconButton)(
    ({ theme }) => `
        position: absolute;
        top: ${theme.spacing(1)};
        right: ${theme.spacing(1.5)};
        color: ${theme.palette.grey[500]};
    `
);

const DialogTitle = ({ children, subTitle, onClose, ...props }: PropsWithChildren<IDialogTitleProps>) =>
    children ? (
        subTitle ? (
            <TitleWithSubtitle {...props}>
                {children}
                {subTitle}
                {!!onClose && (
                    <StyledButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </StyledButton>
                )}
            </TitleWithSubtitle>
        ) : (
            <SimpleTitle {...props}>
                {children}
                {subTitle && <>{subTitle}</>}
                {!!onClose && (
                    <StyledButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </StyledButton>
                )}
            </SimpleTitle>
        )
    ) : null;

export default DialogTitle;
