import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { IRootState } from '@/data/store';
import { fetchUserFund, getUsersFundForInsertedVacation } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import { IUserVacationFundModel } from '@/data/UserToVacationFunds/UserToVacationFundModel';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';

type IState = {
    loadingStatus: {
        id: string;
        year: number;
        user_id: number;
        status: IRequestState;
    }[];
};

const initialState: IState = {
    loadingStatus: []
};

const getKey = <T extends Pick<IUserVacationFundModel, 'user_id' | 'request_type_id' | 'year'>>(entity: T) =>
    `${entity.user_id}_${entity.request_type_id}_${entity.year}`;

const adapter = createEntityAdapter<IUserVacationFundModel>({
    selectId: getKey,
    sortComparer: (a, b) => (a.year < b.year ? 1 : -1)
});

const userToVacationFundSlice = createSlice({
    name: 'userToVacationFund',
    initialState: adapter.getInitialState(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserFund.pending, (state, action) => {
                const key = getKey({
                    user_id: action.meta.arg.userId,
                    year: action.meta.arg.year,
                    request_type_id: action.meta.arg.requestTypeId
                });

                state.loadingStatus = [
                    ...state.loadingStatus.filter((item) => item.id !== key),
                    {
                        id: key,
                        user_id: action.meta.arg.userId,
                        year: action.meta.arg.year,
                        status: 'loading'
                    }
                ];
            })
            .addCase(fetchUserFund.fulfilled, (state, action) => {
                const key = getKey({
                    user_id: action.meta.arg.userId,
                    year: action.meta.arg.year,
                    request_type_id: action.meta.arg.requestTypeId
                });

                state.loadingStatus = [
                    ...state.loadingStatus.filter((item) => item.id !== key),
                    {
                        id: key,
                        user_id: action.meta.arg.userId,
                        year: action.meta.arg.year,
                        status: 'idle'
                    }
                ];
                adapter.upsertOne(state, action.payload.data);
            })
            .addCase(fetchUserFund.rejected, (state, action) => {
                const key = getKey({
                    user_id: action.meta.arg.userId,
                    year: action.meta.arg.year,
                    request_type_id: action.meta.arg.requestTypeId
                });

                state.loadingStatus = [
                    ...state.loadingStatus.filter((item) => item.id !== key),
                    {
                        id: key,
                        user_id: action.meta.arg.userId,
                        year: action.meta.arg.year,
                        status: 'failed'
                    }
                ];
            })
            .addCase(getUsersFundForInsertedVacation.pending, (state, action) => {
                const listOfYears: number[] = [];

                let start = DateHelper.fromDateTimeString(action.meta.arg.vacationStart);
                const end = DateHelper.getYear(DateHelper.fromDateTimeString(action.meta.arg.vacationStart));

                while (DateHelper.getYear(start) <= end) {
                    listOfYears.push(DateHelper.getYear(start));

                    start = DateHelper.addYears(start, 1);
                }

                state.loadingStatus = listOfYears.map((year) => ({
                    id: getKey({
                        user_id: action.meta.arg.userId,
                        year: year,
                        request_type_id: action.meta.arg.requestTypeId
                    }),
                    user_id: action.meta.arg.userId,
                    year: year,
                    status: 'loading'
                }));
            })
            .addCase(getUsersFundForInsertedVacation.fulfilled, (state, action) => {
                const listOfYears: number[] = [];

                let start = DateHelper.fromDateTimeString(action.meta.arg.vacationStart);
                const end = DateHelper.getYear(DateHelper.fromDateTimeString(action.meta.arg.vacationStart));

                while (DateHelper.getYear(start) <= end) {
                    listOfYears.push(DateHelper.getYear(start));

                    start = DateHelper.addYears(start, 1);
                }

                state.loadingStatus = listOfYears.map((year) => ({
                    id: getKey({
                        user_id: action.meta.arg.userId,
                        year: year,
                        request_type_id: action.meta.arg.requestTypeId
                    }),
                    user_id: action.meta.arg.userId,
                    year: year,
                    status: 'idle'
                }));

                adapter.setAll(state, action.payload.data);
            })
            .addCase(getUsersFundForInsertedVacation.rejected, (state, action) => {
                const listOfYears: number[] = [];

                let start = DateHelper.fromDateTimeString(action.meta.arg.vacationStart);
                const end = DateHelper.getYear(DateHelper.fromDateTimeString(action.meta.arg.vacationStart));

                while (DateHelper.getYear(start) <= end) {
                    listOfYears.push(DateHelper.getYear(start));

                    start = DateHelper.addYears(start, 1);
                }

                state.loadingStatus = listOfYears.map((year) => ({
                    id: getKey({
                        user_id: action.meta.arg.userId,
                        year: year,
                        request_type_id: action.meta.arg.requestTypeId
                    }),
                    user_id: action.meta.arg.userId,
                    year: year,
                    status: 'failed'
                }));
            });
    }
});

const getState = (state: IRootState) => state[userToVacationFundSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userToVacationFundSlice;

export const fundEntities = adapterSelectors.selectAll;

export const fundsByUserId = (state: IRootState, userId: number | null) =>
    userId ? fundEntities(state).filter((item) => item.user_id == userId) : null;

export const fundByRequestTypeId = (state: IRootState, userId: number | null, requestTypeId: number | null) =>
    userId && requestTypeId
        ? fundEntities(state).filter((item) => item.user_id == userId && item.request_type_id == requestTypeId)
        : null;

export const fundByRequestTypeIdAndDateTimeStartAndEnd = (
    state: IRootState,
    userId: number | null,
    requestTypeId: number | null,
    start: DateTimeType | null,
    end: DateTimeType | null
) => {
    if (start && end) {
        const funds = fundByRequestTypeId(state, userId, requestTypeId);

        return funds ? funds.filter((item) => item.year >= start?.year() && item.year <= end?.year()) : null;
    }

    return null;
};

export const exceededFundByRequestTypeIdAndDateTimeStartAndEnd = (
    state: IRootState,
    userId: number | null,
    requestTypeId: number | null,
    start: DateTimeType | null,
    end: DateTimeType | null
) => {
    if (start && end) {
        const funds = fundByRequestTypeId(state, userId, requestTypeId);

        return funds
            ? funds.filter(
                  (item) =>
                      (item.fund ?? 0) < (item.exhausted ?? 0) && item.year >= start?.year() && item.year <= end?.year()
              )
            : null;
    }

    return null;
};

export const isSomeVacationFundLoading = createSelector(
    (state: IRootState) => getState(state).loadingStatus,
    (_state: IRootState, startDate?: DateTimeType, endDate?: DateTimeType) => ({
        start: startDate,
        end: endDate
    }),
    (statusList, dates) => {
        if (dates && dates.start && dates.end) {
            return statusList
                .filter(
                    (item) =>
                        item.year >= DateHelper.getYear(dates.start as DateTimeType) &&
                        item.year <= DateHelper.getYear(dates.end as DateTimeType)
                )
                .some((item) => item.status === 'loading');
        }

        return false;
    }
);

export const getUserToVacationFundKey = <
    T extends Pick<IUserVacationFundModel, 'user_id' | 'request_type_id' | 'year'>
>(
    entity: T
) => getKey(entity);
