import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, remove, update } from './NeedApi';
import { INeedCUModel, INeedModel } from './NeedModels';
import { needPaging } from './NeedReducer';

export const fetchNeeds = createAsyncThunk('needs/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = needPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args }, {});
});

export const fetchNeedById = createAsyncThunk('needs/byId', async (args: number) => {
    return await fetchById(args);
});

export const createNeed = createAsyncThunk('needs/create', async (args: INeedCUModel) => await create(args));

export const updateNeed = createAsyncThunk<INeedModel, { id: number; data: INeedCUModel }>(
    'needs/update',
    async ({ id, data }) => await update(id, data)
);

export const removeNeed = createAsyncThunk('needs/remove', async (args: { id: number; onSuccess?: () => void }) => {
    await remove(args.id).then(() => args.onSuccess && args.onSuccess());

    return args.id;
});
