import Box from '@mui/material/Box';
import { forwardRef } from 'react';
import { IOutputValueType } from '@/base/FormGenerator';
import FormGenerator from '@/base/FormGenerator/FormGenerator';
import { useAppDispatch } from '@/data/hooks';
import { pullDataFromExternalSourceDaktelaQueue } from '@/data/Queues/QueueActions';
import useAppTranslation from '@/hooks/useAppTranslation';

export type IProps = {
    queueId?: number;
    queueName?: string;
    onSubmit?: () => void;
};

const PullDataFromExternalSource = forwardRef<HTMLFormElement, IProps>(function PullDataFromExternalSourceInner(
    { queueId, queueName, onSubmit },
    ref
) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();

    const handleSubmit = (values: IOutputValueType) => {
        if (queueId) {
            dispatch(pullDataFromExternalSourceDaktelaQueue({ queue_id: queueId }));

            if (onSubmit) {
                onSubmit();
            }
        }
    };

    return (
        <FormGenerator
            ref={ref}
            id={queueId ? `pullDataFromExternalSource_${queueId}` : 'pullDataFromExternalSource'}
            name="pullDataFromExternalSource"
            className="pullDataFromExternalSource"
            fields={[
                {
                    type: 'custom',
                    props: {
                        name: 'message',
                        values: {},
                        render: () => {
                            return (
                                <Box>
                                    {t(
                                        'message.question.doYouWantToPullDataFromExternalSource',
                                        'Do you want to pull data from external source to queue {{name}}?',
                                        {
                                            name: queueName ?? ''
                                        }
                                    )}
                                </Box>
                            );
                        }
                    }
                }
            ]}
            actions={[
                {
                    type: 'button',
                    props: {
                        name: 'submit',
                        type: 'submit',
                        variant: 'contained',
                        children: 'PullDataFromExternalSource',
                        hidden: true
                    }
                }
            ]}
            onSubmit={handleSubmit}
            displayAsModal={false}
        />
    );
});

export default PullDataFromExternalSource;
