import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById, fetchSchedulePlans } from '@/data/SchedulePlans/SchedulePlanActions';
import filterIncludeValue from '@/helpers/array/filterIncludeValue';
import sortEntities from '@/helpers/array/sortEntities';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createPeriod,
    fetchPeriodById,
    fetchPeriods,
    fetchPeriodsForSelect,
    removePeriod,
    updatePeriod
} from './PeriodActions';
import { IPeriodModel, IPeriodSelectModel } from './PeriodModels';

type IState = {
    selectItems: IPeriodSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IState = {
    selectItems: [],
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IPeriodModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const periodSlice = createSlice({
    name: 'periods',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.upsertOne(state, action.payload.period);
            })
            .addCase(fetchSchedulePlans.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.data.map(({ period }) => period)
                );
            });
        builder
            .addCase(fetchPeriods.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchPeriods.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchPeriods.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchPeriodsForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchPeriodsForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchPeriodsForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchPeriodById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchPeriodById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchPeriodById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createPeriod.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createPeriod.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name
                    } as IPeriodSelectModel);
                }
            })
            .addCase(createPeriod.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removePeriod.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removePeriod.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg.id);
                }
            })
            .addCase(removePeriod.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updatePeriod.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updatePeriod.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? {
                                  id: action.payload.id,
                                  name: action.payload.name
                              }
                            : item
                    );
                }
            })
            .addCase(updatePeriod.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[periodSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default periodSlice;

export const periodEntities = adapterSelectors.selectEntities;

export const isPeriodsListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const isPeriodByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const isPeriodsForSelectInProgress = (state: IRootState) => getState(state).loadingForSelectStatus === 'loading';
export const periodPaging = (state: IRootState) => getState(state).paging;
export const selectFilteredPeriods = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => getState(state).paging,
    (state: IRootState) => adapterSelectors.selectTotal(state),
    (entities, filter, total) => {
        const page = Math.min(filter.page - 1, Math.floor(total / filter.limit));

        return Object.values(entities)
            .filter((entity) => filterIncludeValue(filter.search, [entity.name]))
            .sort(sortEntities(`${filter.sort}`, `${filter.direction}`))
            .slice(page * filter.limit, (page + 1) * filter.limit);
    }
);
export const periodById = (state: IRootState, id: number | null) =>
    id ? adapterSelectors.selectById(state, id) ?? null : null;
export const periodsForSelect = (state: IRootState) => getState(state).selectItems;
export const periodSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const periodCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const periodUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const periodRemovingStatus = (state: IRootState) => getState(state).removingStatus;

export const { updatePaging } = periodSlice.actions;
