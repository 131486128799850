import DateHelper from '@/helpers/date/DateHelper';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';

type IProps = {
    start: string | null;
    end: string | null;
};

const DateRange = ({ start, end }: IProps) => {
    const parsedStart = start ? DateHelper.fromDateString(start) : null;
    const parsedEnd = end ? DateHelper.fromDateString(end) : null;
    const formatter = useLocalizeDateFormatter();

    //TODO missing translate

    return (
        <>
            {parsedStart || parsedEnd
                ? `${parsedStart ? formatter.format(parsedStart.toDate()) : ''}` +
                  (parsedStart && parsedEnd ? ' - ' : '') +
                  `${parsedEnd ? formatter.format(parsedEnd.toDate()) : ''}`
                : 'Date not selected'}
        </>
    );
};

export default DateRange;
