import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IBreaksCUModel, IBreakModel, IBreaksSelectModel } from './BreakModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/breaks', paging || {});

    return fetchGet<IListResponseWithPaging<IBreakModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/breaks/select', { search, fields });

    return fetchGet<IListResponse<IBreaksSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IBreakModel>(`/api/breaks/${id}`);
}

export function create(data: IBreaksCUModel) {
    return fetchPost<IBreakModel>('/api/breaks/', data);
}

export function update(id: number, data: Partial<IBreaksCUModel>) {
    return fetchPatch<IBreakModel>(`/api/breaks/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/breaks/${id}`);
}
