import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

export type IDialogContainerProps = Omit<MuiDialogProps, 'onClose'> & {
    onClose?: () => void;
};

const StyledContainer = styled(MuiDialog)`
    .MuiDialog-container {
        align-items: flex-start;
        transform: translateY(10vh);
        max-height: 90%;
    }
`;

const DialogContainer = ({ open, onClose, ...props }: IDialogContainerProps) => {
    const [isOpen, setOpen] = useState(open || false);

    useEffect(() => {
        if (!isOpen && onClose) {
            onClose();
        }
    }, [isOpen]);
    useEffect(() => {
        if (open !== isOpen) {
            setOpen(open || false);
        }
    }, [open]);

    return (
        <StyledContainer {...props} open={isOpen} onClose={() => setOpen(false)}>
            {props.children}
        </StyledContainer>
    );
};

export default DialogContainer;
