import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useMemo } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { fetchCallCentersForSelect } from '@/data/CallCenters/CallCenterActions';
import { callCenterForSelect } from '@/data/CallCenters/CallCenterSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { getMicrosoftSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import { downloadUsers } from '@/data/Users/UserActions';
import { isUsersDownloading } from '@/data/Users/UserSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import IntegrationsSettingsMicrosoftItemEnum from '@/utils/enums/IntegrationsSettings/IntegrationsSettingsMicrosoftItemEnum';
import UserSourceEnum from '@/utils/enums/UserSourceEnum';
import Dialog from '@/wrappers/Dialog';

const StyledTitle = styled(Box)`
    text-align: center;
`;

type IProps = {
    open: boolean;
    onDialogClose: () => void;
};

const UserDownloadDialog = ({ open, onDialogClose }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const callCentersList = useAppSelector(callCenterForSelect);
    const microsoftSettings = useAppSelector(getMicrosoftSettingsItems);

    const isMicrosoftDownloadEnabled = useMemo(
        () => getSettingsItemValueByKey(microsoftSettings, IntegrationsSettingsMicrosoftItemEnum.setDownloadSettings),
        [microsoftSettings]
    );

    const handleOpen = useCallback(() => {}, []);

    const handleClose = useCallback(() => {
        onDialogClose();
    }, [onDialogClose]);

    const handleSubmit = useCallback((values: IOutputValueType) => {
        switch (values.type as UserSourceEnum) {
            case UserSourceEnum.Daktela:
                dispatch(
                    downloadUsers({
                        type: UserSourceEnum.Daktela,
                        call_center_id:
                            typeof values.call_center_id == 'string'
                                ? parseInt(values.call_center_id)
                                : (values.call_center_id as number),
                        onFinished: handleClose
                    })
                );

                break;
            case UserSourceEnum.ActiveDirectory:
                dispatch(
                    downloadUsers({
                        type: UserSourceEnum.ActiveDirectory,
                        onFinished: handleClose
                    })
                );

                break;
            default:
                dispatch(
                    addNotification({
                        variant: 'error',
                        context: 'message.error.unsupported',
                        defaultMessage: 'Unsupported'
                    })
                );
                break;
        }
    }, []);

    const sourceList = useMemo(() => {
        const options: UserSourceEnum[] = [];

        if (callCentersList.length > 0) {
            options.push(UserSourceEnum.Daktela);
        }

        if (isMicrosoftDownloadEnabled) {
            options.push(UserSourceEnum.ActiveDirectory);
        }

        return options;
    }, [callCentersList, isMicrosoftDownloadEnabled]);

    const usersSourceOptions = useMemo(
        () =>
            Object.keys(UserSourceEnum)
                .filter((item) =>
                    sourceList.some((enumItem) => enumItem === UserSourceEnum[item as keyof typeof UserSourceEnum])
                )
                .slice()
                .sort((a, b) => a.localeCompare(b))
                .map((key) => ({
                    id: UserSourceEnum[key as keyof typeof UserSourceEnum],
                    label: t(`enums.userSourceEnum.${formatPascalToCamel(key)}`, key)
                })),
        [sourceList]
    );

    useEffect(() => {
        if (open) {
            dispatch(fetchCallCentersForSelect({ search: '' }));
        }
    }, [open]);

    return (
        <Dialog
            name="userDownloadDialog"
            openButtonValue={undefined}
            openButton={undefined}
            title={<StyledTitle>{t('label.downloadUsers', 'Download Users')}</StyledTitle>}
            maxWidth="md"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            fullWidth
        >
            <FormGenerator
                name="userDownloadForm"
                displayAsModal={false}
                displayAsSidebar={false}
                onSubmit={handleSubmit}
                fields={[
                    {
                        type: 'select',
                        props: {
                            name: 'type',
                            label: 'type',
                            options: usersSourceOptions,
                            validation: {
                                deps: ['type']
                            }
                        }
                    },
                    {
                        type: 'select',
                        display: ({ type }) => type === UserSourceEnum.Daktela,
                        props: {
                            required: ({ type }) => type === UserSourceEnum.Daktela,
                            name: 'call_center_id',
                            label: t('label.callCenter', 'callCenter'),
                            value: undefined,
                            options: callCentersList
                                .slice()
                                .sort((prev, next) => prev.name.localeCompare(next.name))
                                .map((item) => ({ id: `${item.id}`, label: item.name }))
                        }
                    }
                ]}
                actions={[
                    {
                        type: 'button',
                        props: {
                            type: 'button',
                            name: 'cancel',
                            variant: 'text',
                            onClick: handleClose,
                            children: t('label.cancel', 'Cancel'),
                            width: 6
                        }
                    },
                    {
                        type: 'loadingButton',
                        display: ({ type }) => !!type,
                        props: {
                            type: 'submit',
                            name: 'download',
                            variant: 'contained',
                            children: t('label.download', 'Download'),
                            width: 6,
                            loading: useAppSelector(isUsersDownloading),
                            validation: {
                                deps: ['type']
                            }
                        }
                    }
                ]}
            />
        </Dialog>
    );
};

export default UserDownloadDialog;
