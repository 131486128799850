import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { serializeUser } from '@/utils/UserHelper';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchDownloadUsers, fetchList, fetchSelectList, remove, update } from './UserApi';
import { IDownloadUsers, IUserCUModel, IUserFromBeModel } from './UserModels';
import {
    isUserByIdInProgress,
    isUserListInProgress,
    isUserLoaded,
    userPaging,
    userSelectValuesStatus,
    usersForSelect
} from './UserSlice';

export const transformFromBeToModel = ({
    subordinate,
    ...rest
}: IUserFromBeModel): Omit<IUserFromBeModel, 'subordinate'> & {
    subordinate: number[];
} => ({
    ...rest,
    subordinate: subordinate?.map(({ id }) => id) ?? []
});

export const fetchUsers = createAsyncThunk(
    'users/list',
    async (args: Partial<IPaging>, thunkAPI) => {
        const currentPaging = userPaging(thunkAPI.getState() as IRootState);

        return await fetchList({ ...currentPaging, ...args });
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (isUserListInProgress(state)) {
                return false;
            }
        }
    }
);

export const fetchUsersForSelect = createAsyncThunk(
    'users/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (userSelectValuesStatus(state) !== 'idle' || usersForSelect(state).length) {
                return false;
            }
        }
    }
);

export const fetchUserById = createAsyncThunk('users/byId', async (id: number) => await fetchById(id), {
    condition(id, thunkAPI) {
        const state = thunkAPI.getState() as IRootState;

        if (isUserByIdInProgress(state, id) || isUserLoaded(state, id)) {
            return false;
        }
    }
});

export const createUser = createAsyncThunk('users/create', async (data: IUserCUModel) => await create(data));
export const updateUser = createAsyncThunk(
    'users/update',
    async ({ id, data }: { id: number; data: IUserCUModel }) => await update(id, data)
);

export const updateUserActive = createAsyncThunk(
    'users/updateActive',
    async (
        { id, data, onSuccess }: { id: number; data: { active: boolean }; onSuccess?: () => void },
        { dispatch }
    ) => {
        return await update(id, data).then((response) => {
            if (onSuccess) {
                onSuccess();
            }

            if (response.active) {
                dispatch(
                    addNotification({
                        context: 'message.success.userActivated',
                        defaultMessage: '{{user}} Activated',
                        variant: 'success',
                        values: { user: serializeUser(response) }
                    })
                );
            }

            if (!response.active) {
                dispatch(
                    addNotification({
                        context: 'message.success.userDeactivated',
                        defaultMessage: '{{user}} Deactivated',
                        variant: 'success',
                        values: { user: serializeUser(response) }
                    })
                );
            }

            return response;
        });
    }
);

export const removeUser = createAsyncThunk('users/remove', async (args: { id: number; onSuccess?: () => void }) => {
    await remove(args.id).then(() => args.onSuccess && args.onSuccess());

    return args.id;
});

export const downloadUsers = createAsyncThunk(
    'users/download',
    async ({ onFinished, ...args }: IDownloadUsers & { onFinished?: () => void }, { dispatch }) => {
        return await fetchDownloadUsers(args)
            .then((response) => {
                dispatch(
                    addNotification({
                        context: 'message.info.userDownloads',
                        defaultMessage: 'Request to download users were send',
                        variant: 'info'
                    })
                );

                return response;
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        message: error.message,
                        variant: 'error'
                    })
                );

                return Promise.reject(error);
            })
            .finally(() => {
                if (onFinished) {
                    onFinished();
                }
            });
    }
);
