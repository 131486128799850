const unique = function <T, K extends string | number>(items: T[], keyAccess: (item: T) => K): T[] {
    const result: T[] = [];
    const keys: K[] = [];

    if (items instanceof Array) {
        items.forEach((value) => {
            const key = keyAccess(value);

            if (!keys.includes(key)) {
                result.push(value);
                keys.push(key);
            }
        });
    }

    return result;
};
const uniqueArrayOfSimpleValuesFilter = <T extends number | string>(value: T, index: number, array: T[]) =>
    array.indexOf(value) === index;

export const uniqueArrayOfSimpleValues = <T extends number | string>(array: T[]) =>
    array.filter(uniqueArrayOfSimpleValuesFilter);

export default unique;
