import { ArrowBack } from '@mui/icons-material';
import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FormGenerator, { IOutputValueType, ISupportedFieldType } from '@/base/FormGenerator';
import { useAppDispatch } from '@/data/hooks';
import { resetUserPasswordByToken, sendEmailToResetUserPassword } from '@/data/System/SystemActions';
import { IResetPasswordByToken, ISendEmailToResetPassword } from '@/data/System/UserModel';
import { StyledSeparator } from '@/forms/LoginForm/LoginForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import { ReactComponent as Logo } from '@/images/logoText.svg';
import * as routes from '@/utils/routes';

const StyledLogo = styled(Logo)`
    max-width: 200px;
    margin: 0 auto 80px;
`;

const StyledFormHeading = styled(Typography)(
    ({ theme }) => `
        margin-bottom: 1rem;
        color: ${theme.palette.text.primary};
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.005em;
    `
);

type Props = {
    setLocation: (location: string | undefined) => void;
};

const ResetPasswordPage = ({ setLocation }: Props) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const handleCancel = useCallback(() => {
        navigate(routes.login());
        setLocation(routes.login());
    }, []);

    const handleResetPassword = useCallback((values: IOutputValueType) => {
        dispatch(resetUserPasswordByToken({ password: values.password, token: token } as IResetPasswordByToken));
    }, []);

    const handleSendEmail = useCallback((values: IOutputValueType) => {
        dispatch(sendEmailToResetUserPassword({ email: values.email } as ISendEmailToResetPassword));
    }, []);

    const handleSubmit = useCallback((values: IOutputValueType) => {
        if (token) {
            handleResetPassword(values);
        } else {
            handleSendEmail(values);
        }
    }, []);

    return (
        <Container maxWidth="xs" sx={{ alignItems: 'center', display: 'flex', height: '100vh', textAlign: 'center' }}>
            <Paper sx={{ p: 3 }}>
                <StyledLogo />
                <StyledFormHeading>
                    {token
                        ? t('label.resetPassword', 'Reset Password')
                        : t('label.sendEmailToResetPassword', 'Send Email to Reset Password')}
                </StyledFormHeading>
                <FormGenerator
                    className="resetPassword"
                    name="resetPassword"
                    title={t('label.sendEmailToResetPassword', 'Send Email to Reset Password')}
                    displayAsModal={false}
                    displayAsSidebar={false}
                    fields={[
                        ...(token
                            ? ([
                                  {
                                      type: 'password',
                                      props: {
                                          required: true,
                                          name: 'password',
                                          label: t('label.password', 'Password'),
                                          size: 'small'
                                      }
                                  },
                                  {
                                      type: 'password',
                                      props: {
                                          required: true,
                                          name: 'confirmPassword',
                                          label: t('label.confirmPassword', 'Confirm password'),
                                          size: 'small',
                                          confirmName: 'password'
                                      }
                                  }
                              ] as ISupportedFieldType[])
                            : ([
                                  {
                                      type: 'textField',
                                      props: {
                                          required: true,
                                          name: 'email',
                                          label: t('label.email', 'E-mail'),
                                          type: 'email',
                                          size: 'small'
                                      }
                                  }
                              ] as ISupportedFieldType[]))
                    ]}
                    actions={[
                        {
                            type: 'loadingButton',
                            props: {
                                type: 'submit',
                                name: 'send',
                                variant: 'contained',
                                children: t('label.send', 'Send')
                            }
                        },
                        {
                            type: 'html',
                            props: {
                                name: 'or',
                                render: () => <StyledSeparator variant="subtitle2">Or</StyledSeparator>
                            }
                        },
                        {
                            type: 'button',
                            props: {
                                type: 'button',
                                name: 'cancel',
                                variant: 'text',
                                onClick: handleCancel,
                                children: (
                                    <>
                                        <ArrowBack /> {t('label.backToLoginPage', 'Back to Login Page')}
                                    </>
                                )
                            }
                        }
                    ]}
                    onSubmit={handleSubmit}
                />
            </Paper>
        </Container>
    );
};

export default ResetPasswordPage;
