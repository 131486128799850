import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import Chip from '@/wrappers/Chip';
import Switch from '@/wrappers/Switch';
import TimePicker from '@/wrappers/TimePicker';
import Tooltip from '@/wrappers/Tooltip';

const StyledBreaks = styled(Box)(
    ({ theme }) => `
        margin-top: ${theme.spacing(2)};
        display: flex;
        justify-content: space-between;
        gap: ${theme.spacing(2)};
    `
);
const StyledChip = styled(Box)(
    ({ theme }) => `
        margin-top: ${theme.spacing(0.5)};
        margin-right: ${theme.spacing(2)};
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
    `
);
const StyledPickers = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex: 2;
        gap: ${theme.spacing(2)};
    `
);

export type IBreakItemProps = {
    breakDuration: number;
    breakName: string;
    day: Date;
    disabled: boolean;
    start: DateTimeType;
    /** Break has to start between 'start' and ('start' + 'duration'). */
    duration: number;
    required: boolean;
    readOnly?: boolean;
    shiftEnd: DateTimeType | null;
    value: {
        start: DateTimeType;
        used: boolean;
    };
    onChange: (value: DateTimeType | null) => void;
    onError: (reason: string) => void;
    onDisableChange: (isChecked: boolean) => void;
};

const BreakItem = ({
    breakDuration,
    breakName,
    day,
    disabled,
    duration,
    readOnly,
    required,
    start,
    value,
    onDisableChange,
    onError,
    onChange,
    shiftEnd
}: IBreakItemProps) => {
    const validStartBreakTime = DateHelper.clone(start);
    const validEndBreakTime = DateHelper.addMinutes(DateHelper.addHours(validStartBreakTime, duration), -breakDuration);
    const isBeforeShiftEnd =
        (DateHelper.addMinutes(validEndBreakTime, breakDuration)?.toDate()?.getTime() ?? 0) <=
        (shiftEnd?.toDate()?.getTime() ?? 0);
    const maxDate = isBeforeShiftEnd ? validEndBreakTime : DateHelper.addMinutes(shiftEnd, -breakDuration) ?? undefined;
    const countOfDiffHour = maxDate ? DateHelper.getDifferenceInHours(validStartBreakTime, maxDate) : 0;
    const overMidnight = maxDate && DateHelper.getDay(validStartBreakTime) !== DateHelper.getDay(maxDate);
    const allowedHours: number[] = [];

    for (
        let startHour = DateHelper.getHour(validStartBreakTime);
        validStartBreakTime && startHour <= DateHelper.getHour(validStartBreakTime) + countOfDiffHour;
        startHour++
    ) {
        allowedHours.push(startHour % 24);
    }

    return (
        <StyledBreaks>
            {!required && <Switch name="disable" value={!disabled} onChange={onDisableChange} />}
            <Tooltip arrow title={DateHelper.getMinutesInHumanFormat(breakDuration)}>
                <StyledChip>
                    <Chip name={breakName} label={breakName} />
                </StyledChip>
            </Tooltip>
            <StyledPickers>
                <TimePicker
                    name="breakStart"
                    label="Start"
                    disabled={disabled}
                    required={required}
                    readOnly={readOnly}
                    shouldDisableTime={
                        overMidnight
                            ? (selectedValue, timeValue, clockType) => {
                                  if (!selectedValue) {
                                      return false;
                                  } else if (clockType === 'hours') {
                                      return !allowedHours.includes(timeValue);
                                  } else if (clockType === 'minutes') {
                                      const currentHour = DateHelper.getHour(selectedValue);

                                      if (currentHour === DateHelper.getHour(validStartBreakTime)) {
                                          return timeValue < DateHelper.getMinute(validStartBreakTime);
                                      } else if (currentHour === DateHelper.getHour(maxDate)) {
                                          return timeValue > DateHelper.getMinute(maxDate);
                                      }
                                  }

                                  return false;
                              }
                            : undefined
                    }
                    minTime={overMidnight ? undefined : validStartBreakTime}
                    maxTime={overMidnight ? undefined : maxDate}
                    minutesStep={15}
                    value={value?.start ?? validStartBreakTime}
                    onError={onError}
                    onChange={(newValue) => {
                        if (overMidnight && newValue) {
                            let normalizedValue = newValue;
                            const midnight = DateHelper.addDays(DateHelper.fromTimeStringAndLocalDate('00:00', day), 1),
                                valueInUTC = DateHelper.utc(newValue, false);

                            if (DateHelper.getDay(valueInUTC) < DateHelper.getDay(validStartBreakTime)) {
                                normalizedValue = DateHelper.addDays(normalizedValue, 1);
                            }

                            if (DateHelper.getHour(midnight) < DateHelper.getHour(normalizedValue)) {
                                const condition =
                                    DateHelper.getHour(validStartBreakTime) <= DateHelper.getHour(normalizedValue);

                                if (condition) {
                                    normalizedValue = DateHelper.setDay(
                                        normalizedValue,
                                        DateHelper.getDay(DateHelper.getInstanceOf(day))
                                    );
                                }
                            } else {
                                normalizedValue = DateHelper.setDay(
                                    normalizedValue,
                                    DateHelper.getDay(DateHelper.addDays(DateHelper.getInstanceOf(day), 1))
                                );
                            }

                            onChange(normalizedValue);
                        } else {
                            onChange(newValue);
                        }
                    }}
                />
                <TimePicker
                    disabled
                    name="breakEnd"
                    label="End"
                    value={value ? DateHelper.addMinutes(DateHelper.clone(value.start), breakDuration) : null}
                />
            </StyledPickers>
        </StyledBreaks>
    );
};

export default BreakItem;
