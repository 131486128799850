import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import filterIncludeValue from '@/helpers/array/filterIncludeValue';
import sortEntities from '@/helpers/array/sortEntities';
import unique from '@/helpers/array/unique';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createSkill,
    fetchSkillById,
    fetchSkills,
    fetchSkillsForSelect,
    removeSkill,
    updateSkill
} from './SkillActions';
import { ISkillModel, ISkillSelectModel } from './SkillModels';

type ISkillsReducerState = {
    selectItems: ISkillSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: ISkillsReducerState = {
    selectItems: [],
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<ISkillModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const skillSlice = createSlice({
    name: 'skills',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
            adapter.addMany(
                state,
                unique([...action.payload.skills, ...action.payload.skills_from_shift], ({ id }) => id)
            );
        });
        builder
            .addCase(fetchSkills.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchSkills.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchSkills.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchSkillsForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchSkillsForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchSkillsForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchSkillById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchSkillById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchSkillById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createSkill.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createSkill.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name
                    } as ISkillSelectModel);
                }
            })
            .addCase(createSkill.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeSkill.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeSkill.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg.id);
                }
            })
            .addCase(removeSkill.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateSkill.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateSkill.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? {
                                  id: action.payload.id,
                                  name: action.payload.name
                              }
                            : item
                    );
                }
            })
            .addCase(updateSkill.rejected, (state) => {
                state.updatingStatus = 'failed';
            })
            .addCase(patchStateToRequest.fulfilled, (state, action) => {
                if (action.payload.request?.state == UserToRequestsStateEnum.ACCEPTED) {
                    adapter.upsertMany(
                        state,
                        unique(
                            [...(action.payload.skills ?? []), ...(action.payload.skills_from_shift ?? [])],
                            ({ id }) => id
                        )
                    );
                }
            });
    }
});

const getState = (state: IRootState) => state[skillSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default skillSlice;
export const isSkillListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const skillPaging = (state: IRootState) => getState(state).paging;
export const selectFilteredSkills = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => getState(state).paging,
    (state: IRootState) => adapterSelectors.selectTotal(state),
    (entities, filter, total) => {
        const page = Math.min(filter.page - 1, Math.floor(total / filter.limit));

        return Object.values(entities)
            .filter((entity) => filterIncludeValue(filter.search, [entity.name]))
            .sort(sortEntities(`${filter.sort}`, `${filter.direction}`))
            .slice(page * filter.limit, (page + 1) * filter.limit);
    }
);
export const skillEntities = adapterSelectors.selectEntities;
export const skillById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) ?? null : null;
export const skillAll = (state: IRootState) => adapterSelectors.selectAll(state);
export const skillsForSelect = (state: IRootState) => getState(state).selectItems;
export const skillSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const skillCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const skillUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const skillRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = skillSlice.actions;
