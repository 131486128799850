import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MuiList, { ListProps as MuiListProps } from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import UserPermission, { Mode } from '@/components/UserPermision';
import { useAppSelector } from '@/data/hooks';
import { isSidebarPinned } from '@/data/Sidebar/SidebarReducer';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';

export type IItemType = {
    id: string;
    icon?: ReactElement;
    name: ReactNode;
    resource: PermissionsEnumType | PermissionsEnumType[];
    setAllowed?: boolean;
    display?: boolean;
    items?: IItemType[];
    isOutsideLink?: boolean;
};

type IListProps = Omit<MuiListProps, 'ref' | 'onSelect'> & {
    selected?: string;
    onlyClickable?: boolean;
    onSelect: (id: string | number) => void;
    items: IItemType[];
    showOnlyIcons: boolean;
};

const StyledItem = styled(MuiListItem)`
    margin-bottom: 6px;
    padding: 0;
`;
const StyledButton = styled(MuiItemButton, {
    shouldForwardProp: (prop) => prop !== 'hasSelectedSubitem' && prop !== 'isPinned' && prop !== 'isSubitem'
})<{ hasSelectedSubitem?: boolean; isPinned?: boolean; isSubitem?: boolean }>(
    ({ theme, selected, hasSelectedSubitem, isPinned, isSubitem }) => `
        height: 42px;
        padding-left: ${isSubitem ? theme.spacing(4) : theme.spacing(2)};
        color: ${
            selected || (hasSelectedSubitem && isPinned) ? theme.palette.common.white : theme.palette.text.primary
        };
        background: ${hasSelectedSubitem ? 'rgba(58, 53, 65, 0.16)' : 'transparent'};
        line-height: 1.3;
        border-top-right-radius: ${theme.shape.borderRadius}px;
        border-bottom-right-radius: ${theme.shape.borderRadius}px;

        ${(selected || (hasSelectedSubitem && isPinned)) && `background: ${theme.custom?.primary.gradient};`}
    `
);
const StyledIcon = styled(MuiListItemIcon, {
    shouldForwardProp: (prop) => prop !== 'isPinned'
})<{ isPinned?: boolean }>(
    ({ theme, isPinned }) => `
        min-width: 20px;
        max-width: 20px;
        margin-right: ${!isPinned && theme.spacing(2)};
        justify-content: center;
        color: inherit;
    `
);

const Menu = ({ selected: selectedProp, onlyClickable = false, onSelect, showOnlyIcons, ...rest }: IListProps) => {
    const isPinned = useAppSelector(isSidebarPinned);
    const [selected, setSelected] = useState(selectedProp);
    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        if (selected === undefined && !!selectedProp) {
            if (!onlyClickable) {
                setSelected(selectedProp);
            }
        }
    }, [selectedProp]);

    const handleOnSelect = (item: IItemType) => {
        if (item.items && item.items.length > 0) {
            if (expanded.includes(item.id)) {
                setExpanded(expanded.filter((expandedId) => expandedId !== item.id));
            } else {
                setExpanded([...expanded, item.id]);
            }
        } else {
            if (!onlyClickable && !item.isOutsideLink) {
                setSelected(item.id);
            }

            onSelect(item.id);
        }
    };
    const isItemOrChildSelected = (item: IItemType | undefined): boolean => {
        if (!selected || !item) {
            return false;
        }

        if (item.id === selected) {
            return true;
        }

        if (!item.items) {
            return false;
        }

        if (item.isOutsideLink) {
            return false;
        }

        return (
            typeof item.items.find((i) => {
                if (i.id === selected) {
                    return true;
                }

                if (i.items) {
                    return isItemOrChildSelected(i);
                }

                return false;
            }) !== 'undefined'
        );
    };
    const isItemExpanded = (item: IItemType | undefined): boolean => expanded.includes(item?.id || '');
    const moreOrLessIcon = (isExpandable: boolean, isExpanded: boolean) => {
        if (!isExpandable) return;

        return isExpanded ? <ExpandLessIcon /> : <KeyboardArrowRightIcon />;
    };
    const generateListItems = (items: IItemType[] | undefined, subitem = false) =>
        items?.map(({ display = true, ...item }) => {
            const currentOrChildIsSelected = isItemOrChildSelected(item);

            return (
                <UserPermission key={item.id} id={item.resource} mode={Mode.READ} setAllowed={item.setAllowed}>
                    <Box sx={{ display: display ? 'block' : 'none' }}>
                        <StyledItem data-testid={item.id}>
                            <StyledButton
                                isPinned={isPinned}
                                isSubitem={subitem}
                                hasSelectedSubitem={!!item.items && currentOrChildIsSelected}
                                selected={selected === item.id || (showOnlyIcons && currentOrChildIsSelected)}
                                onClick={() => handleOnSelect(item)}
                            >
                                {item.icon && <StyledIcon isPinned={isPinned}>{item.icon}</StyledIcon>}

                                {!isPinned && <MuiListItemText primary={item.name} />}

                                {!isPinned &&
                                    moreOrLessIcon(
                                        item.items !== undefined,
                                        currentOrChildIsSelected || isItemExpanded(item)
                                    )}
                            </StyledButton>
                        </StyledItem>

                        {item && item.items && !isPinned && (
                            <Collapse
                                in={!showOnlyIcons && (currentOrChildIsSelected || isItemExpanded(item))}
                                timeout="auto"
                                unmountOnExit
                            >
                                {generateListItems(item.items, true)}
                            </Collapse>
                        )}
                    </Box>
                </UserPermission>
            );
        });

    return <MuiList {...rest}>{generateListItems(rest.items, false)}</MuiList>;
};

export default Menu;
