import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IUserCUModel, IUserModel, IUserSelectModel } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';

type IProps = {
    isEdit: boolean;
    user?: IUserModel | IUserSelectModel | IUserCUModel;
};

const StyledTitle = styled(Box)`
    text-align: center;
`;

const StyledName = styled(Typography)(
    ({ theme }) => `
        display: inline-block;
        color: ${theme.palette?.info.main};
        margin-left: ${theme.spacing(0.5)}
    `
);

export default function Title({ isEdit, user }: IProps) {
    const { t } = useAppTranslation();

    return (
        <StyledTitle data-testid="shiftPlanDayForm-title">
            {isEdit ? t('title.assignedShiftTo', 'Assigned Shift to') : t('title.assignShiftTo', 'Assign Shift to')}
            <StyledName variant="inherit" data-testid="title-content">
                {serializeUser(user)}
            </StyledName>
        </StyledTitle>
    );
}
