import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '@/data/hooks';
import { isSidebarPinned } from '@/data/Sidebar/SidebarReducer';
import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const StyledLayout = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isPinned'
})<{ isPinned?: boolean }>(
    ({ isPinned, theme }) => `
        min-height: 100vh;
        display: grid;
        grid-template: ${theme.layout.headerHeight}px calc(100vh - ${
            theme.layout.headerHeight + theme.layout.footerHeight
        }px) ${theme.layout.footerHeight}px / 54px 206px calc(100vw - (54px + 206px));
        grid-template-areas:
            'header header header'
            ${isPinned ? "'sidebar content content'" : "'sidebar sidebar content'"}
            ${isPinned ? "'sidebar footer footer'" : "'sidebar sidebar footer'"};
    `
);

const MainLayout = () => {
    const isPinned = useAppSelector(isSidebarPinned);

    return (
        <StyledLayout isPinned={isPinned}>
            <Header />
            <Content />
            <Sidebar />
            <Footer />
        </StyledLayout>
    );
};

export default MainLayout;
