import { useMemo } from 'react';
import { DateTimeType } from '@/helpers/date/DateHelper';
import useSchedulerColumnTitles from '@/hooks/scheduler/useSchedulerColumnTitles';

const useSchedulerTitles = (isDayMode: boolean, from: DateTimeType, to: DateTimeType) => {
    const start = Date.now();
    const columnTitles = useSchedulerColumnTitles(isDayMode, from, to);

    return useMemo(() => {
        const result = isDayMode ? columnTitles.filter((_, index) => index % 4 === 0) : columnTitles;

        const time = Date.now() - start;

        if (time > 5) {
            console.warn('useSchedulerTitles is very slow - ', time);
        }

        return result;
    }, [isDayMode, from.toISOString(), to.toISOString()]);
};

export default useSchedulerTitles;
