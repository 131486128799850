import { ISchedulePlanDayShiftExtendedModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import ShiftAsLabel from '@/forms/ShiftPlanDayForm/ShiftAsLabel';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import config from '@/utils/config';
import { serializeUser } from '@/utils/UserHelper';
import Table from '@/wrappers/Table';

type IProps = {
    shiftTradeOfferUser: ISchedulePlanDayShiftExtendedModel;
    shiftTradeUser: ISchedulePlanDayShiftExtendedModel;
};

function TradeDetail({ shiftTradeOfferUser, shiftTradeUser }: IProps) {
    const { t } = useAppTranslation();

    return (
        <Table
            name="shiftsToTrade"
            columns={[
                {
                    id: 'employee',
                    label: t('header.employee', 'Employee'),
                    access: (rowData) => serializeUser(rowData.user)
                },
                {
                    id: 'shift',
                    label: t('header.shiftType', 'Shift Type'),
                    access: (rowData) => (
                        <ShiftAsLabel
                            color={rowData.shift.color}
                            background={rowData.shift.background}
                            text={rowData.shift.abbreviation}
                            from={rowData.shift_start}
                            to={rowData.shift_end}
                        />
                    )
                },
                {
                    id: 'date',
                    label: t('header.date', 'Date'),
                    access: (rowData) =>
                        DateHelper.formatDate(
                            DateHelper.fromDateTimeString(rowData.shift_start),
                            config.date.inputFormat
                        )
                }
            ]}
            rows={[shiftTradeUser, shiftTradeOfferUser]}
        />
    );
}

export default TradeDetail;
