import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsPhoneOutlinedIcon from '@mui/icons-material/SettingsPhoneOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/data/hooks';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import {
    IRouteId,
    IRouteUrlBlankId,
    IRouteUrlId,
    routeMapping,
    routeUrlBlankMapping,
    routeUrlMapping
} from '@/utils/routes';
import Menu from '@/wrappers/Menu';

const StyledSidebar = styled('aside')(
    ({ theme }) => `
    display: grid;
    grid-area: sidebar;
    padding-block: ${theme.spacing(2)};
    overflow: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 0 2px;
        background-color: ${theme.palette.primary.main};
        border-radius: ${theme.shape.borderRadius}px;
    }
`
);
const StyledMenu = styled(Menu)(
    ({ theme }) => `
        color: ${theme.palette.primary.main}
    `
);
const StyledDot = styled('div')<{ selected?: boolean }>(
    ({ theme, selected }) => `
        width: 5px;
        height: 5px;
        background: ${selected ? theme.palette.common.white : theme.palette.primary.main};
        border-radius: 50%;
    `
);

const Sidebar = () => {
    const { t } = useAppTranslation();
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const [selected, setSelected] = useState(undefined as string | undefined);
    const navigate = useNavigate();
    const location = useLocation();
    const handleSelect = (id: IRouteId | IRouteUrlId | IRouteUrlBlankId) => {
        if (routeUrlMapping[id]) {
            // window.location.href = routeUrlMapping[id];
        } else if (routeUrlBlankMapping[id]) {
            // window.open(routeUrlBlankMapping[id], '_blank');
        } else if (routeMapping[id]) {
            navigate(routeMapping[id]);
        }
    };

    useEffect(() => {
        const path = location.pathname;
        const routeId = Object.keys(routeMapping)
            .filter((id) => id !== 'mainDashboard')
            .find((id) => path.includes(routeMapping[id]));

        if (routeId) {
            setSelected(routeId);
        } else {
            setSelected('mainDashboard');
        }
    }, [location]);

    return (
        <StyledSidebar data-testid="sidebar">
            <StyledMenu
                showOnlyIcons={false}
                selected={selected}
                onSelect={(id) => handleSelect(id as IRouteId)}
                items={[
                    {
                        id: 'mainDashboard',
                        name: t('sidebar.dashboard', 'Dashboard'),
                        resource: [PermissionsEnum.Schedules, PermissionsEnum.SchedulePlans, PermissionsEnum.Breaks],
                        icon: <HomeOutlinedIcon />
                    },
                    {
                        id: 'mainSchedule',
                        name: t('sidebar.schedule', 'Schedule'),
                        resource: PermissionsEnum.SchedulePlans,
                        icon: <DateRangeOutlinedIcon />
                    },
                    // {
                    //     id: 'mainUsersRequest',
                    //     name: t('sidebar.usersRequest', 'Users Request'),
                    //     resource: PermissionsEnum.UserToRequests,
                    //     icon: <PsychologyAltOutlinedIcon />
                    // },

                    {
                        id: 'mainReports',
                        name: t('sidebar.reports', 'Reports'),
                        resource: [PermissionsEnum.Reports],
                        icon: <QueryStatsIcon />,
                        items: [
                            {
                                id: 'mainCoverageToRequire',
                                name: t('sidebar.coverageToRequire', 'Coverage/Require'),
                                resource: [PermissionsEnum.Reports],
                                icon: <EqualizerIcon />
                            },
                            {
                                id: 'mainPredictedTimeToRealTime',
                                name: t('sidebar.predictedTimeToRealTime', 'Predicted Time / Real Time'),
                                resource: [PermissionsEnum.Reports],
                                icon: <InsightsOutlinedIcon />
                            },
                            {
                                id: 'mainPredictedOperatorsCountToRealOperatorsCount',
                                name: t(
                                    'sidebar.predictedOperatorsCountToRealOperatorsCount',
                                    'Predicted Operators Count/ Real Operators Count'
                                ),
                                resource: [PermissionsEnum.Reports],
                                icon: <SsidChartOutlinedIcon />
                            },
                            {
                                id: 'mainRealAverageDurationToRealDuration',
                                name: t(
                                    'sidebar.realAverageDurationToRealDuration',
                                    'Real Average Duration / Real Duration'
                                ),
                                resource: [PermissionsEnum.Reports],
                                icon: <StackedLineChartOutlinedIcon />
                            }
                        ]
                    },
                    {
                        id: 'mainUsersCategory',
                        name: t('sidebar.users', 'Users'),
                        resource: [PermissionsEnum.Users, PermissionsEnum.Workplaces],
                        icon: <PeopleAltOutlinedIcon />,
                        items: [
                            {
                                id: 'mainUsers',
                                name: t('sidebar.employees', 'Employees'),
                                resource: PermissionsEnum.Users,
                                icon: <StyledDot selected={selected === 'mainUsers'} />
                            },
                            {
                                id: 'mainWorkplaces',
                                name: t('sidebar.workplaces', 'Workplaces'),
                                resource: PermissionsEnum.Workplaces,
                                icon: <StyledDot selected={selected === 'mainWorkplaces'} />
                            }
                            // {
                            //     id: 'mainAgentGroups',
                            //     name: 'Agent Groups',
                            //     icon: <StyledDot />
                            // }
                        ]
                    },
                    // { id: 'mainForecast', name: 'Forecast', icon: <ContentPasteOutlinedIcon /> },
                    {
                        id: 'mainAdministration',
                        name: t('sidebar.administration', 'Administration'),
                        resource: [
                            PermissionsEnum.Skills,
                            PermissionsEnum.Contracts,
                            PermissionsEnum.Periods,
                            PermissionsEnum.Needs,
                            PermissionsEnum.Shifts,
                            PermissionsEnum.Breaks,
                            PermissionsEnum.Queues,
                            PermissionsEnum.SettingsItems
                        ],
                        icon: <EngineeringOutlinedIcon />,
                        items: [
                            {
                                id: 'mainAdministrationUsers',
                                name: t('sidebar.users', 'Users'),
                                resource: [PermissionsEnum.Skills, PermissionsEnum.Contracts],
                                icon: <StyledDot />,
                                items: [
                                    // Roles & Permission should be hidden for this moment
                                    // {
                                    //     id: 'mainRoles',
                                    //     name: 'Roles',
                                    //     icon: <span />
                                    // },
                                    // {
                                    //     id: 'mainPermissions',
                                    //     name: 'Permissions',
                                    //     icon: <span />
                                    // },
                                    {
                                        id: 'mainSkills',
                                        name: t('sidebar.skills', 'Skills'),
                                        resource: PermissionsEnum.Skills,
                                        icon: <span />
                                    },
                                    {
                                        id: 'mainContracts',
                                        name: t('sidebar.contracts', 'Contracts'),
                                        resource: PermissionsEnum.Contracts,
                                        icon: <span />
                                    }
                                ]
                            },
                            {
                                id: 'mainAdministrationSchedule',
                                name: t('sidebar.schedule', 'Schedule'),
                                resource: [
                                    PermissionsEnum.Periods,
                                    PermissionsEnum.Needs,
                                    PermissionsEnum.Shifts,
                                    PermissionsEnum.Breaks
                                ],
                                icon: <StyledDot />,
                                items: [
                                    {
                                        id: 'mainPeriods',
                                        name: t('sidebar.periods', 'Periods'),
                                        resource: PermissionsEnum.Periods,
                                        icon: <span />
                                    },
                                    {
                                        id: 'mainNeeds',
                                        name: t('sidebar.needs', 'Needs'),
                                        resource: PermissionsEnum.Needs,
                                        icon: <span />
                                    },
                                    {
                                        id: 'mainRequestTypes',
                                        name: t('sidebar.requestTypes', 'Request Types'),
                                        resource: PermissionsEnum.RequestTypes,
                                        icon: <span />
                                    },
                                    // {
                                    //     id: 'mainTimeoffs',
                                    //     name: 'Time offs',
                                    //     permissionName: 'TimeOffs',
                                    //     icon: <span />
                                    // },
                                    {
                                        id: 'mainShifts',
                                        name: t('sidebar.shifts', 'Shifts'),
                                        resource: PermissionsEnum.Shifts,
                                        icon: <span />
                                    },
                                    {
                                        id: 'mainBreaks',
                                        name: t('sidebar.breaks', 'Breaks'),
                                        resource: PermissionsEnum.Breaks,
                                        icon: <span />
                                    }
                                ]
                            },
                            {
                                id: 'mainAdministrationIntegrations',
                                name: t('sidebar.integrations', 'Integrations'),
                                resource: [PermissionsEnum.Queues, PermissionsEnum.SettingsItems],
                                icon: <StyledDot />,
                                items: [
                                    {
                                        id: 'mainCallCenters',
                                        name: t('sidebar.callCenters', 'Call Centers'),
                                        resource: [PermissionsEnum.CallCenters],
                                        icon: <SettingsPhoneOutlinedIcon fontSize={'small'} />
                                    },
                                    {
                                        id: 'mainIntegratedApplication',
                                        name: t('sidebar.applications', 'Applications'),
                                        resource: [PermissionsEnum.SettingsItems],
                                        icon: <span />
                                    },
                                    {
                                        id: 'mainQueues',
                                        name: t('sidebar.queues', 'Queues'),
                                        resource: PermissionsEnum.Queues,
                                        icon: <span />
                                    }
                                ]
                            }
                        ]
                    },

                    {
                        id: 'mainSettings',
                        name: t('sidebar.settings', 'Settings'),
                        resource: [PermissionsEnum.ApplicationSettingsItems],
                        icon: <SettingsOutlinedIcon />
                    },
                    {
                        id: 'mainResource',
                        name: t('sidebar.resource', 'Resources'),
                        resource: [],
                        display: isUserAdmin,
                        setAllowed: true,
                        icon: <IntegrationInstructionsOutlinedIcon />,
                        items: [
                            {
                                id: 'mainSwagger',
                                name: t('sidebar.swagger', 'Swagger'),
                                resource: [],
                                setAllowed: true,
                                icon: <StyledDot selected={selected === 'mainSwagger'} />,
                                isOutsideLink: true
                            }
                        ]
                    }
                ]}
            />
        </StyledSidebar>
    );
};

export default Sidebar;
