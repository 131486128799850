import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICollapseCustomProps } from '@/wrappers/SnackbarComponents/CollapseSnackbar';
import { IRootState } from '../store';
import { connectToWS } from './NotificationActions';

type INotificationBase = {
    id: number;
    created_at: number;
    autoHideDuration?: number;
    context?: string;
    values?: { [name: string]: string | number };
    defaultMessage?: string;
    message?: string;
};
type INotificationA = {
    variant: 'default' | 'error' | 'success' | 'warning' | 'info';
    message: string;
    autoHideDuration?: number;
};

type INotificationB = {
    variant: 'default' | 'error' | 'success' | 'warning' | 'info';
    context: string;
    defaultMessage: string;
    autoHideDuration?: number;
    values?: { [name: string]: string | number };
};

export type INotificationCollapse = {
    variant: 'collapse';
    autoHideDuration?: number;
    customProps: ICollapseCustomProps;
};

type INotification = INotificationA | INotificationB | INotificationCollapse;

type INotificationAdapter = INotificationBase & INotification;

type IPeriodsReducerState = {
    connected: boolean;
};

const initialState: IPeriodsReducerState = {
    connected: false
};
const adapter = createEntityAdapter<INotificationAdapter>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.created_at < b.created_at ? 1 : -1)
});
const notificationSlice = createSlice({
    name: 'notification',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        addNotification: (state, action: PayloadAction<INotification>) => {
            const now = Date.now();

            adapter.addOne(
                state,
                Object.assign(
                    {
                        id: now,
                        variant: undefined,
                        message: undefined,
                        values: undefined,
                        defaultMessage: undefined,
                        autoHideDuration: undefined,
                        context: undefined,
                        created_at: now
                    },
                    action.payload
                ) as INotificationAdapter
            );
        },
        removeAllNotification: (state) => {
            adapter.removeAll(state);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(connectToWS.fulfilled, (state) => {
            state.connected = true;
        });
    }
});

const getState = (state: IRootState) => state[notificationSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default notificationSlice;

export const isConnected = (state: IRootState) => getState(state).connected;

export const { addNotification, removeAllNotification } = notificationSlice.actions;

export const notificationList = adapterSelectors.selectAll;
