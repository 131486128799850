import { IListResponseWithPaging } from '@/data/ApiResponse';
import { IPaging } from '@/data/Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '@/data/request';
import { ISettingsFromBeModel } from '@/data/SettingsItems/SettingsItemModels';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/settings-items', paging || {});

    return fetchGet<IListResponseWithPaging<ISettingsFromBeModel>>(url);
}

export function fetchById<T extends ISettingsFromBeModel>(id: SettingsTypesEnum, display = true) {
    return fetchGet<{ status: number; settings?: T }>(`/api/settings-items/${id}`, display);
}

export function remove(key: SettingsTypesEnum) {
    return fetchDelete(`/api/settings-items/${key}`);
}

export function create({ application_key, ...rest }: ISettingsFromBeModel) {
    return fetchPost<ISettingsFromBeModel>('/api/settings-items/', { ...rest, application_key: application_key });
}

export function update(id: SettingsTypesEnum, data: ISettingsFromBeModel) {
    return fetchPatch<ISettingsFromBeModel>(`/api/settings-items/${id}`, data);
}

export function azureAuthenticateUrl() {
    return fetchGet<{ url?: string }>('/api/settings-items/azure_authenticate_url');
}
