import Typography from '@mui/material/Typography';
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useAppSelector } from '@/data/hooks';
import { ISchedulePlanPredictedTimeForecastAccuracyReport } from '@/data/Reports/ReportModels';
import { isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading } from '@/data/Reports/ReportSlice';
import DateHelper from '@/helpers/date/DateHelper';
import formatSecondsToTimeFormat from '@/helpers/format/formatSecondsToTimeFormat';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import LinearProgress from '@/wrappers/LinearProgress';

type IProps = {
    data?: ISchedulePlanPredictedTimeForecastAccuracyReport[];
    colorPredictedDurationDifference?: string;
    colorRealDurationDifference?: string;
    width?: number;
    height?: number;
    timeZone?: string;
};

const SchedulePlanReportPredictedDurationToRealDurationChart = ({
    data = [],
    colorPredictedDurationDifference = '#CA6E0E',
    colorRealDurationDifference = '#7159D8',
    timeZone,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const isLoading = useAppSelector(isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading);
    const formatter = useLocalizeTimeFormatter('medium');
    const xAxisDateFormatter = useLocalizeDateFormatter({
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateTickFormatter = (value: Date) => xAxisDateFormatter.format(value);
    const YAxisTickFormatter = (value: string) =>
        formatSecondsToTimeFormat(Math.abs(parseFloat(value.toString())), formatter);

    return (
        <>
            <Typography variant="subtitle2">
                {t(
                    'label.schedulePlanAdherencePredictedDurationToRealDuration',
                    'Schedule Plan Reports Predicted Duration/Real Duration'
                )}
            </Typography>
            {isLoading && <LinearProgress />}
            <ResponsiveContainer width={props.width} height={'50%'}>
                <AreaChart
                    syncId="areaChartDuration"
                    data={data.map((item) => ({
                        name: DateHelper.fromDateTimeString(item.start).toDate(),
                        realDuration: item.real_duration,
                        predictedDuration: item.predicted_duration
                    }))}
                    margin={{
                        top: 15,
                        right: 30,
                        left: 10,
                        bottom: 60
                    }}
                >
                    <defs>
                        <linearGradient id="colorPredictedDurationDifference" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorPredictedDurationDifference} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={colorPredictedDurationDifference} stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id="colorRealDurationDifference" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorRealDurationDifference} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={colorRealDurationDifference} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        angle={270}
                        tickMargin={40}
                        interval={95}
                        height={45}
                        tickFormatter={dateTickFormatter}
                    />
                    <ReferenceLine y={0} stroke="#000" />
                    <YAxis
                        domain={[0, 'dataMax + 1']}
                        label={{ value: t('label.time', 'Seconds'), position: 'bottom', offset: 20 }}
                        tickFormatter={YAxisTickFormatter}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                        labelFormatter={(value) => dateTimeFormatter.format(value)}
                        formatter={(value, name) => [
                            formatSecondsToTimeFormat(Math.abs(parseFloat(value.toString())), formatter),
                            t(`label.${name}`, name.toString())
                        ]}
                    />
                    <Area
                        type="monotone"
                        dataKey="predictedDuration"
                        stroke={colorPredictedDurationDifference}
                        fill="url(#colorPredictedDurationDifference)"
                    />
                    <Area
                        type="monotone"
                        dataKey="realDuration"
                        stroke={colorRealDurationDifference}
                        fill="url(#colorRealDurationDifference)"
                    />
                    <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                        formatter={(value) => <>{t(`label.${value}`, value)}</>}
                        wrapperStyle={{
                            paddingTop: '12px'
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default SchedulePlanReportPredictedDurationToRealDurationChart;
