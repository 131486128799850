import ShiftPlanDayApproveForm from '@/forms/ShiftPlanDayApproveForm';
import ShiftPlanDayForm from '@/forms/ShiftPlanDayForm';
import { IShiftPlanDayFormProps } from '@/forms/ShiftPlanDayForm/ShiftPlanDayForm';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import { DateTimeType } from '@/helpers/date/DateHelper';
import { ICellDataType } from '@/helpers/schedule';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import { AvailableScheduleTypes } from '@/modules/Scheduler/Scheduler';

export type ISchedulerTableCellShiftProps = Pick<IShiftPlanDayFormProps, 'recommendedSkills'> & {
    id: number | null;
    date: DateTimeType;
    type: AvailableScheduleTypes;
    userId: number | null;
    schedulePlanId: number | null;
    shiftJoin: Pick<ArrayElement<ICellDataType<Date>['shift_joins']>, 'id' | 'from' | 'to'> | null;
    mode: ISchedulerCalendarHeaderProps['mode'];
    // onRenderButtonClick: (onClick: (e: MouseEvent) => void, isEmpty?: boolean) => ReactElement;
    isSchedulePlanClosed?: boolean;
    onClose: () => void;
};

const SchedulerTableBodyCellDayForm = ({
    id,
    mode,
    schedulePlanId,
    shiftJoin,
    date,
    recommendedSkills,
    type,
    userId,
    isSchedulePlanClosed = false,
    onClose
}: ISchedulerTableCellShiftProps) => (
    <>
        {type === 'shiftTrades' && shiftJoin && (
            <ShiftPlanDayApproveForm
                id={id ?? undefined}
                open={true}
                schedulePlanId={schedulePlanId}
                onClose={onClose}
            />
        )}
        {type === 'shifts' || type === 'table' ? (
            <ShiftPlanDayForm
                open={true}
                mode={mode}
                type={type}
                schedulePlanId={schedulePlanId}
                userId={userId}
                id={id ?? undefined}
                day={date}
                isSchedulePlanClosed={isSchedulePlanClosed}
                recommendedSkills={recommendedSkills ?? []}
                onClose={onClose}
            />
        ) : (
            <></>
        )}
    </>
);

export default SchedulerTableBodyCellDayForm;
