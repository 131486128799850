import { FieldsGenerator, IFieldsGeneratorProps, ISupportedFieldType, IValueType } from '@/base/FormGenerator';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

type IProps = RequiredByFieldsGenerator;

const Settings = ({
    clearErrors,
    control,
    fullWidth,
    getFieldState,
    getValues,
    initialValues,
    register,
    setValue,
    watch
}: IProps) => {
    const { t } = useAppTranslation();

    const fields: ISupportedFieldType[] = [
        {
            type: 'time',
            props: {
                label: t('label.remindTime', 'Remind Time'),
                name: 'settings.remind_time',
                width: 12,
                disableEmpty: false
            }
        },
        {
            type: 'switch',
            props: {
                name: 'settings.trade_compare_level_of_competence',
                label: t('label.tradeCompareLevelOfCompetence', 'Trade compare level of competence'),
                width: 6,
                validation: {
                    deps: 'settings.trade_compare_level_of_competence_difference'
                }
            }
        },
        {
            type: 'textField',
            display: ({ settings }) =>
                (settings as { [name: string]: IValueType }).trade_compare_level_of_competence === true,
            props: {
                type: 'number',
                name: 'settings.trade_compare_level_of_competence_difference',
                label: t(
                    'label.tradeCompareLevelOfCompetenceDifference',
                    'Trade compare level of competence difference'
                ),
                required: true,
                width: 6,
                validation: {
                    deps: 'settings.trade_compare_level_of_competence_difference'
                }
            }
        },
        {
            type: 'switch',
            props: {
                name: 'settings.automatic_approve_trade',
                label: t('label.automaticApproveTrade', 'Automatic approve trade'),
                width: 6,
                validation: {
                    deps: 'settings.automatic_approve_trade_level_of_competence_difference'
                }
            }
        },
        {
            type: 'textField',
            display: ({ settings }) => (settings as { [name: string]: IValueType }).automatic_approve_trade === true,
            props: {
                type: 'number',
                name: 'settings.automatic_approve_trade_level_of_competence_difference',
                label: t(
                    'label.automaticApproveTradeLevelOfCompetenceDifference',
                    'Automatic approve trade level of competence difference'
                ),
                required: true,
                width: 6,
                validation: {
                    deps: 'settings.automatic_approve_trade'
                }
            }
        },
        {
            type: 'switch',
            props: {
                name: 'settings.enable_work_from_home',
                label: t('label.enableWorkFromHome', 'Enable work from home'),
                width: 6,
                validation: {
                    deps: ['settings.number_of_seats_min', 'settings.number_of_seats_max']
                }
            }
        },
        {
            type: 'textField',
            display: ({ settings }) => (settings as { [name: string]: IValueType }).enable_work_from_home === true,
            props: {
                required: true,
                name: 'settings.number_of_seats_min',
                label: t('label.numberOfSeatsMin', 'Number of Seats Min'),
                type: 'number',
                width: 6
            }
        },
        {
            type: 'textField',
            display: ({ settings }) => (settings as { [name: string]: IValueType }).enable_work_from_home === true,
            props: {
                required: true,
                name: 'settings.number_of_seats_max',
                label: t('label.numberOfSeatsMax', 'Number of Seats Max'),
                type: 'number',
                width: 6
            }
        },
        {
            type: 'switch',
            props: {
                name: 'settings.disable_empty_shifts',
                label: t('label.disableEmptyShifts', 'Disable empty shifts'),
                width: 12
            }
        },
        {
            type: 'textField',
            props: {
                name: 'settings.shift_distance',
                label: t('label.shift_distance', 'Distance between shifts'),
                type: 'number',
                width: 4
            }
        },
        {
            type: 'textField',
            props: {
                name: 'settings.after_night_shift',
                label: t('label.distanceAfterNightShift', 'Distance after night shift'),
                type: 'number',
                width: 4
            }
        },
        {
            type: 'textField',
            props: {
                name: 'settings.before_night_shift',
                label: t('label.distanceBeforeNightShift', 'Distance before night shift'),
                type: 'number',
                width: 4
            }
        },
        {
            type: 'textField',
            props: {
                name: 'settings.external_connection_id',
                label: t('label.externalConnectionId', 'External Connection ID'),
                type: 'text',
                width: 4
            }
        }
    ] as ISupportedFieldType[];

    return (
        <FieldsGenerator
            control={control}
            register={register}
            getFieldState={getFieldState}
            getValues={getValues}
            initialValues={initialValues}
            watch={watch}
            setValue={setValue}
            clearErrors={clearErrors}
            fullWidth={fullWidth}
            fields={fields}
        />
    );
};

export default Settings;
