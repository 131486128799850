import Box from '@mui/material/Box';
import { useCallback, useRef, useState } from 'react';
import FormGenerator, { ICallableRef } from '@/base/FormGenerator';
import { ICuProps } from '@/components/CuForm';
import ErrorBoundary from '@/components/ErrorBoundary';
import UserPermission, { Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { ISchedulePlanDayShiftModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { schedulePlanAssignedShiftStatus } from '@/data/SchedulePlans/SchedulePlanSlice';
import { selectShiftTradeOfferBySchedulePlanDayIdUserToApprove2 } from '@/data/ShiftTradeOffers/ShiftTradeOfferSlice';
import { approveTrade, rejectTrade } from '@/data/ShiftTrades/ShiftTradeActions';
import { selectShiftTradesToApprove, shiftTradeIdBySchedulePlanDayShiftId } from '@/data/ShiftTrades/ShiftTradeSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import LinearProgress from '@/wrappers/LinearProgress';
import TradeDetail from './TradeDetail';

type IProps = Omit<ICuProps<ISchedulePlanDayShiftModel>, 'resource'> & {
    open?: boolean;
    schedulePlanId: number | null;
};

const ShiftPlanDayApproveForm = ({ id, open = false, openButtonRender, schedulePlanId, onClose, ...rest }: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();

    const formRef = useRef<ICallableRef>(null);
    const [isOpen, setIsOpen] = useState(open);
    const assignedShiftIsInProgress = useAppSelector(schedulePlanAssignedShiftStatus) === 'loading';
    const tradesToApprove = useAppSelector(selectShiftTradesToApprove);
    const tradeId = useAppSelector((state) => (id ? shiftTradeIdBySchedulePlanDayShiftId(state, id) : null));
    const trade = tradeId ? tradesToApprove[tradeId] : null;
    const offersToApprove = useAppSelector(selectShiftTradeOfferBySchedulePlanDayIdUserToApprove2);
    const offer = id ? offersToApprove[id] ?? null : null;

    const isValid = true;

    const isEdit = typeof id !== 'undefined';

    const setLoadedData = () => {};

    const handleOpen = useCallback(() => {
        if (formRef.current) {
            setLoadedData();
        }

        setIsOpen(true);
    }, [formRef.current]);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }, [formRef.current]);
    const handleReject = useCallback(() => {
        if (trade) {
            dispatch(rejectTrade(trade.id));
        } else if (offer) {
            dispatch(rejectTrade(offer.schedule_plan_day_shift_trade_id));
        }

        handleClose();
    }, [trade, offer, handleClose]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
        handleClose();
    }, [handleClose]);
    const handleApprove = useCallback(() => {
        if (trade) {
            dispatch(approveTrade(trade.id));
        } else if (offer) {
            dispatch(approveTrade(offer.schedule_plan_day_shift_trade_id));
        }

        handleClose();
    }, [trade, offer, handleClose]);

    const name = 'shiftPlanDayForm';

    const isLoaded = !assignedShiftIsInProgress;

    const shiftTradeUser =
        offer?.schedule_plan_day_shift_trade.schedule_plan_day_shift ?? trade?.selected_offer.schedule_plan_day_shift;
    const shiftTradeOfferUser = offer?.schedule_plan_day_shift ?? trade?.schedule_plan_day_shift;

    return schedulePlanId === null || !(shiftTradeUser && shiftTradeOfferUser) ? (
        <>{openButtonRender ? openButtonRender(() => {}) : ''}</>
    ) : (
        <ErrorBoundary>
            <UserPermission id={PermissionsEnum.Shifts} mode={Mode.UPDATE}>
                <FormGenerator
                    {...rest}
                    id={id ? `${id}` : undefined}
                    name={name}
                    isEdit={isEdit}
                    justIcon
                    openForm={isOpen}
                    title={<Box>{t('title.shiftTrade', 'Shift Trade')}</Box>}
                    subTitle={<Box>{t('subTitle.shiftTradeDescription', 'Shift Trade Description')}</Box>}
                    maxWidth="lg"
                    displayAsModal={false}
                    displayAsSidebar
                    fields={[
                        {
                            type: 'html',
                            props: {
                                name: 'loader',
                                render: () => (
                                    <LinearProgress
                                        hidden={isLoaded}
                                        sx={{
                                            transitionProperty: 'display',
                                            transitionDelay: '250ms'
                                        }}
                                    />
                                )
                            }
                        },
                        {
                            type: 'html',
                            props: {
                                name: 'shift_id',
                                render: () => (
                                    <TradeDetail
                                        shiftTradeUser={shiftTradeUser}
                                        shiftTradeOfferUser={shiftTradeOfferUser}
                                    />
                                )
                            }
                        }
                    ]}
                    actions={[
                        {
                            type: 'loadingButton',
                            props: {
                                type: 'button',
                                name: 'reject',
                                disabled: assignedShiftIsInProgress,
                                loading: false,
                                onClick: handleReject,
                                variant: 'outlined',
                                color: 'error',
                                children: t('label.reject', 'Reject')
                            }
                        },
                        {
                            type: 'button',
                            props: {
                                type: 'button',
                                name: 'cancel',
                                disabled: false,
                                variant: 'text',
                                onClick: handleCancel,
                                children: t('label.cancel', 'Cancel'),
                                sx: {
                                    padding: 0
                                }
                            }
                        },
                        {
                            type: 'loadingButton',
                            props: {
                                type: 'submit',
                                name: 'success',
                                color: 'success',
                                disabled: !isValid,
                                loading: assignedShiftIsInProgress,
                                variant: 'contained',
                                children: t('label.approve', 'Approve')
                            }
                        }
                    ]}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    onSubmit={handleApprove}
                />
            </UserPermission>
        </ErrorBoundary>
    );
};

export default ShiftPlanDayApproveForm;
