import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { memo } from 'react';

export type ICheckboxProps = Omit<MuiCheckboxProps, 'name' | 'onChange' | 'checked'> & {
    name: string;
    onChange?: (isChecked: boolean) => void;
};

const Checkbox = ({ value, onChange, ...rest }: ICheckboxProps) => (
    <MuiCheckbox
        {...rest}
        checked={!!value}
        onChange={(e) => !rest.readOnly && onChange && onChange(e.target.checked)}
        data-testid="checkbox"
    />
);

export default memo(Checkbox);
