import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';
import TableContainer, { TableContainerProps as MuiTableContainerProps } from '@mui/material/TableContainer';
import TableHead, { TableHeadProps as MuiTableHeadProps } from '@mui/material/TableHead';
import TableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow';
import { ReactElement, ReactNode } from 'react';

type IOverridableColumnProps = Omit<MuiTableCellProps, 'id' | 'children'>;
type IOverridableRowProps = Omit<MuiTableRowProps, 'children'>;
export type IProps<ROW> = {
    columns: IOverridableColumnProps &
        {
            id: string;
            label: ReactElement | string;
            access: (row: ROW, columnId: string) => ReactNode;
        }[];
    rows: ROW[];
    name: ReactNode;
    showHeader?: boolean;
    containerProps?: Omit<MuiTableContainerProps, 'children'>;
    headerProps?: Omit<MuiTableHeadProps, 'children'>;
    columnProps?: IOverridableColumnProps | ((columnId: string) => IOverridableColumnProps);
    rowProps?: IOverridableRowProps | ((columnId: string) => IOverridableRowProps);
};

const Table = <ROW extends { id: string | number }>({
    containerProps,
    columns,
    rows,
    columnProps,
    headerProps,
    rowProps,
    showHeader = true,
    ...rest
}: IProps<ROW>) => (
    <TableContainer {...rest} {...containerProps}>
        <MuiTable>
            {showHeader && (
                <TableHead {...headerProps}>
                    <TableRow>
                        {columns.map(({ access, label, ...columnRest }) => (
                            <TableCell {...columnRest} key={columnRest.id}>
                                {label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                {rows.map((row) => {
                    if (typeof rowProps === 'function') {
                        rowProps = rowProps(typeof row.id === 'string' ? row.id : row.id.toString());
                    }

                    return (
                        <TableRow {...rowProps} key={row.id}>
                            {columns.map(({ id, access, ...columnRest }) => {
                                let columnPropsValue;

                                if (typeof columnProps === 'function') {
                                    columnPropsValue = columnProps(id);
                                } else {
                                    columnPropsValue = columnProps;
                                }

                                return (
                                    <TableCell
                                        component="td"
                                        {...columnPropsValue}
                                        {...columnRest}
                                        key={`${row.id}_${id}`}
                                    >
                                        {access(row, id)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    </TableContainer>
);

export default Table;
