import { createContext, ReactNode } from 'react';

type IProps = {
    children: ReactNode;
    container: HTMLDivElement | null;
    offsetTop: number;
};

export const VisibilityOnlyRenderContext = createContext<{ container: null | HTMLDivElement; offsetTop: number }>({
    container: null,
    offsetTop: 0
});

export const VisibilityOnlyRenderContextProvider = ({ container, children, offsetTop }: IProps) => (
    <VisibilityOnlyRenderContext.Provider value={{ container, offsetTop }}>
        {children}
    </VisibilityOnlyRenderContext.Provider>
);
