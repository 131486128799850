import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import DateHelper from '@/helpers/date/DateHelper';
import useLocalizeTime from '@/hooks/useLocalizeTime';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';

const StyledText = styled(Box)(
    ({ theme }) => `
        display: inline;
        margin-right: ${theme.spacing(0.5)};
`
);
const StyledWrapper = styled(Box)(
    ({ theme }) => `
        padding: ${theme.spacing(1)};
        border-radius: ${theme.shape.borderRadius}px;
        width: max-content;
        text-align: center;
`
);

type IProps = IAbbreviationValue & {
    from: string;
    to: string;
    timeZone?: string;
};

export default function ShiftAsLabel({ text, color, background, from, to, timeZone }: IProps) {
    const dateTimeFrom = DateHelper.fromDateTimeString(from),
        dateTimeTo = DateHelper.fromDateTimeString(to);

    const startValue = useLocalizeTime(dateTimeFrom, timeZone),
        endValue = useLocalizeTime(dateTimeTo, timeZone);

    return (
        <StyledWrapper
            sx={{
                color,
                background
            }}
        >
            <StyledText>{text}</StyledText>
            {startValue} - {endValue}
        </StyledWrapper>
    );
}
