import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { ISkillModel } from '@/data/Skills/SkillModels';
import DateHelper from '@/helpers/date/DateHelper';
import handleIconReplaces from '@/utils/icons/handleIconReplaces';
import TimeRange from '@/wrappers/Datatable/CellViews/TimeRange';
import { ITimeRangeOrTimeAndLengthValueType } from '@/wrappers/TimeRangeOrTimeAndLength';

export type ISkillsOnShiftProps = {
    timeZone: string;
    data: { skill: ISkillModel; skillId: string; range: ITimeRangeOrTimeAndLengthValueType }[];
};

const StyledItem = styled(Box)`
    background-color: white;
    align-items: center;
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1;
`;
const StyledConnector = styled('hr')`
    margin: 0;
    width: 100%;
    position: absolute;
    top: 50%;
`;
const StyledText = styled(Box)`
    display: inline-block;
`;
const StyledWrapper = styled(Box)`
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    position: relative;
    min-height: 52px;
`;

export default function SkillsOnShift({ data = [], timeZone }: ISkillsOnShiftProps) {
    const toRender = data.filter((row) => row.skillId !== '' && row.skill && row.range.start && row.range.duration);

    return (
        <StyledWrapper>
            {toRender.map((row, index) => (
                <StyledItem key={index}>
                    {row.skill?.icon && (
                        <Icon className="icon" sx={{ marginRight: 1, color: row.skill.color }}>
                            {handleIconReplaces(row.skill.icon)}
                        </Icon>
                    )}
                    <StyledText>
                        <Typography variant="subtitle1">{row.skill.name}</Typography>
                        <Typography variant="caption" sx={{ whiteSpace: 'noWrap' }}>
                            {row.range.start && row.range.duration && (
                                <TimeRange
                                    start={row.range.start}
                                    end={DateHelper.addHours(row.range.start, row.range.duration)}
                                    timeZone={timeZone}
                                />
                            )}
                        </Typography>
                    </StyledText>
                </StyledItem>
            ))}
            <StyledConnector />
        </StyledWrapper>
    );
}
