import { useAppSelector } from '@/data/hooks';
import { skillsForSelect } from '@/data/Skills/SkillSlice';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { IUserCUSkill } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import CrudInlineOperationDatatable from '@/wrappers/CrudInlineDatatable';
import Icon from '@/wrappers/Datatable/CellViews/Icon';

type ISkills = {
    data: IUserCUSkill[];
    handleData: (value: IUserCUSkill[]) => void;
};

const SkillsTab = ({ data, handleData }: ISkills) => {
    const { t } = useAppTranslation();
    const skillsForSelectData = useAppSelector(skillsForSelect);
    const isSystemUser = useAppSelector(isSignedUserUser);

    return (
        <CrudInlineOperationDatatable<IUserCUSkill>
            labelAddButton={t('label.addSkill', 'Add Skill')}
            header={[
                {
                    field: 'skill_id',
                    title: t('header.skillName', 'Skill Name'),
                    options: skillsForSelectData,
                    validate: (rowData) => (rowData.skill_id ? true : { isValid: false }),
                    render: (rowData) => {
                        const skill = skillsForSelectData.find((item) => `${item.id}` === `${rowData.skill_id}`);

                        return <Icon {...skill} />;
                    }
                },
                {
                    field: 'level_of_competence',
                    title: t('header.levelOfCompetence', 'Level of Competence'),
                    type: 'numeric',
                    validate: (rowData) =>
                        rowData.level_of_competence ? true : { isValid: false, helperText: 'It cannot be empty' }
                }
            ]}
            data={data}
            onRowAdd={isSystemUser ? undefined : async (newData) => handleData([...data, newData])}
            onRowRemove={
                isSystemUser
                    ? undefined
                    : async (oldData) => handleData(data.filter((row) => row.skill_id !== oldData?.skill_id))
            }
            onRowUpdate={
                isSystemUser
                    ? undefined
                    : async (newData, oldData) =>
                          handleData(data.map((item) => (item.skill_id === oldData?.skill_id ? newData : item)))
            }
        />
    );
};

export default SkillsTab;
