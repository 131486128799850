import { MutableRefObject } from 'react';
import { useAppSelector } from '@/data/hooks';
import { ISchedulerUserToRequestModel } from '@/data/UserToRequests/UserToRequestModels';
import { userToRequestAll } from '@/data/UserToRequests/UserToRequestSlice';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import {
    IMergedColumn,
    ISimpleColumn
} from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRow';
import CellContentWrapper from '@/modules/Scheduler/components/SchedulerRequestBody/CellContent/CellContentWrapper';
import { GridMergedData, RequestEmptyCell, RequestNotEmptyCell } from '@/modules/Scheduler/StyledParts';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';

export type IRenderAble = {
    uId: string;
    id: number | string;
    end: DateTimeType;
    start: DateTimeType;
    width: number;
};

export type IEmptyRenderAble = IRenderAble & {
    type: 'break';
};

export type IRequestRenderAble = IRenderAble & {
    type: 'request';
    request: ISchedulerUserToRequestModel;
};

export type IRootRenderAble<T> = {
    uId: string;
    type: 'merged' | 'simple';
    columnStart: number;
    end: DateTimeType;
    start: DateTimeType;
    width: number;
    data: T;
};

export type IMergeRow = {
    uId: string;
    end: DateTimeType;
    start: DateTimeType;
    data: (IEmptyRenderAble | IRequestRenderAble)[];
};

export type IMergedRenderAble = IRootRenderAble<IMergeRow[]> & {
    type: 'merged';
};

export type ISimpleRenderAble = IRootRenderAble<IEmptyRenderAble | IRequestRenderAble> & {
    type: 'simple';
};

export type IResolved = {
    uId: string;
    id: number | string;
    start: DateTimeType;
    end: DateTimeType;
    toMerge: boolean;
    items: {
        id: number | string;
        start: DateTimeType;
        end: DateTimeType;
        request: ISchedulerUserToRequestModel;
    }[];
};

export type ISchedulerRequestBodyDataRowsCellCallableRef = Record<number, HTMLDivElement>;

export type ISchedulerRequestBodyCellProps = {
    columnData: ISimpleColumn | IMergedColumn;
    from: DateTimeType;
    schedulePlanId: number;
    timeZone: string;
    onLoadingStart?: () => void;
    onLoadingFinished?: () => void;
    innerRef?: MutableRefObject<ISchedulerRequestBodyDataRowsCellCallableRef>;
};

const SchedulerRequestBodyDataRowsCell = ({
    columnData,
    from,
    schedulePlanId,
    timeZone,
    innerRef,
    onLoadingStart,
    onLoadingFinished
}: ISchedulerRequestBodyCellProps) => {
    const requestsDataList = useAppSelector(userToRequestAll);

    return (
        <>
            {columnData.type === 'simple' &&
                (columnData.data.type === 'empty' ? (
                    <RequestEmptyCell
                        columnStartFromDate={DateHelper.toDate(from)}
                        columnStartToDate={columnData.from}
                        width={columnData.data.width}
                    />
                ) : (
                    <RequestNotEmptyCell
                        ref={
                            innerRef &&
                            requestsDataList?.find((item) => item.id === columnData.data.id)?.state ===
                                UserToRequestsStateEnum.CREATED
                                ? (el: HTMLDivElement) => {
                                      const innerRefIndex = parseInt(
                                          columnData.data.from.getTime().toString() + columnData.data.id
                                      );

                                      if (
                                          !el ||
                                          requestsDataList?.find((item) => item.id === columnData.data.id)?.state !==
                                              UserToRequestsStateEnum.CREATED
                                      ) {
                                          delete innerRef.current[innerRefIndex];

                                          return;
                                      }

                                      innerRef.current[innerRefIndex] = el;
                                  }
                                : undefined
                        }
                        key={columnData.uId}
                        columnStartFromDate={DateHelper.toDate(from)}
                        columnStartToDate={columnData.from}
                        width={columnData.data.width}
                    >
                        <CellContentWrapper
                            request={columnData.data}
                            timeZone={timeZone}
                            schedulePlanId={schedulePlanId}
                            onLoadingStart={onLoadingStart}
                            onLoadingFinished={onLoadingFinished}
                        />
                    </RequestNotEmptyCell>
                ))}
            {columnData.type === 'merged' && (
                <RequestNotEmptyCell
                    key={columnData.uId}
                    columnStartFromDate={DateHelper.toDate(from)}
                    columnStartToDate={columnData.from}
                    columnEndFromDate={columnData.from}
                    columnEndToDate={columnData.to}
                >
                    {columnData.data.map((row) => (
                        <GridMergedData
                            key={`${columnData.uId}_${row.uId}`}
                            fromDate={columnData.from}
                            toDate={columnData.to}
                        >
                            {row.data.map((mergedItem) =>
                                mergedItem.type === 'empty' ? (
                                    <RequestEmptyCell
                                        key={mergedItem.uId}
                                        columnStartFromDate={columnData.from}
                                        columnStartToDate={mergedItem.from}
                                        width={mergedItem.width}
                                    />
                                ) : (
                                    <RequestNotEmptyCell
                                        ref={
                                            innerRef
                                                ? (el: HTMLDivElement) => {
                                                      const innerRefIndex = parseInt(
                                                          mergedItem.from.getTime().toString() + mergedItem.id
                                                      );

                                                      if (
                                                          !el ||
                                                          requestsDataList?.find((item) => item.id === mergedItem.id)
                                                              ?.state !== UserToRequestsStateEnum.CREATED
                                                      ) {
                                                          delete innerRef.current[innerRefIndex];

                                                          return;
                                                      }

                                                      innerRef.current[innerRefIndex] = el;
                                                  }
                                                : undefined
                                        }
                                        key={mergedItem.uId}
                                        columnStartFromDate={columnData.from}
                                        columnStartToDate={mergedItem.from}
                                        columnEndFromDate={mergedItem.from}
                                        columnEndToDate={mergedItem.to}
                                    >
                                        <CellContentWrapper
                                            request={mergedItem}
                                            timeZone={timeZone}
                                            schedulePlanId={schedulePlanId}
                                            onLoadingStart={onLoadingStart}
                                            onLoadingFinished={onLoadingFinished}
                                        />
                                    </RequestNotEmptyCell>
                                )
                            )}
                        </GridMergedData>
                    ))}
                </RequestNotEmptyCell>
            )}
        </>
    );
};

export default SchedulerRequestBodyDataRowsCell;
