enum IntegrationsSettingsMicrosoftItemEnum {
    host = 'host',
    username = 'username',
    authenticateDomain = 'authenticate_domain',
    downloadDomain = 'download_domain',
    setDownloadSettings = 'set_download_settings',
    tls = 'tls',
    port = 'port',
    password = 'password',
    base = 'base',
    filter = 'filter'
}

export default IntegrationsSettingsMicrosoftItemEnum;
