import { DateTimeType } from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeTime from '@/hooks/useLocalizeTime';

type IProps = {
    start: DateTimeType | Date | string;
    end: DateTimeType | Date | string | null;
    timeZone?: string;
};

const TimeRange = ({ start, end, timeZone }: IProps) => {
    const { t } = useAppTranslation();
    const startValue = useLocalizeTime(start, timeZone);
    const endValue = useLocalizeTime(end, timeZone);

    return (
        <>
            {startValue} - {endValue ?? t('label.now', 'Now')}
        </>
    );
};

export default TimeRange;
