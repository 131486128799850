import { IListResponse } from '@/data/ApiResponse';
import { IApplicationsSettingsFromBeModel } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import { fetchDelete, fetchGet, fetchPatch, fetchPost } from '@/data/request';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';

export function fetchList() {
    return fetchGet<IListResponse<IApplicationsSettingsFromBeModel>>('/api/application-settings-items');
}

export function fetchById<T extends IApplicationsSettingsFromBeModel>(
    id: ApplicationSettingsItemTypesEnum,
    display = true
) {
    return fetchGet<T>(`/api/application-settings-items/${id}`, display);
}

export function remove(key: ApplicationSettingsItemTypesEnum) {
    return fetchDelete(`/api/application-settings-items/${key}`);
}

export function create(data: IApplicationsSettingsFromBeModel) {
    return fetchPost<IApplicationsSettingsFromBeModel>('/api/application-settings-items/', data);
}

export function update(id: ApplicationSettingsItemTypesEnum, data: IApplicationsSettingsFromBeModel) {
    return fetchPatch<IApplicationsSettingsFromBeModel>(`/api/application-settings-items/${id}`, data);
}
