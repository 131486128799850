import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo } from 'react';
import FormGenerator, { ISupportedFieldType } from '@/base/FormGenerator';
import { getRequestApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { isPlanClosed } from '@/data/SchedulePlans/SchedulePlanSlice';
import { fetchShiftById } from '@/data/Shifts/ShiftActions';
import { shiftById } from '@/data/Shifts/ShiftSlice';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { skillEntities } from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import { isSignedUserAdmin, signedUser } from '@/data/System/SystemReducer';
import { userById } from '@/data/Users/UserSlice';
import { patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import { ISchedulerUserToRequestModel } from '@/data/UserToRequests/UserToRequestModels';
import { getUsersFundForInsertedVacation } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import {
    exceededFundByRequestTypeIdAndDateTimeStartAndEnd,
    getUserToVacationFundKey,
    isSomeVacationFundLoading
} from '@/data/UserToVacationFunds/UserToVacationFundSlice';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import SkillsOnShift from '@/forms/ShiftPlanDayForm/SkillsOnShift';
import { ISkillsOnShiftProps } from '@/forms/ShiftPlanDayForm/SkillsOnShift/SkillsOnShift';
import DateHelper from '@/helpers/date/DateHelper';
import getDurationFromTimeStartAndTimeEnd from '@/helpers/date/getDurationFromTimeStartAndTimeEnd';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import ShiftAsTimelineView, { IShiftAsTimelineViewProps } from '@/modules/Scheduler/ShiftAsTimelineView';
import config from '@/utils/config';
import ApplicationSettingsRequestItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsRequestItemEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { serializeUser } from '@/utils/UserHelper';
import Box from '@/wrappers/Box';
import { ITimeRangeOrTimeAndLengthValueType } from '@/wrappers/TimeRangeOrTimeAndLength';

type IProps = {
    request: ISchedulerUserToRequestModel;
    formOpen: boolean;
    schedulePlanId: number;
    timeZone: string;
    onFormClose: () => void;
    onLoadingStart?: () => void;
    onLoadingFinished?: () => void;
};

const StyledFieldWrapper = styled(Box)`
    padding: 5px;
    border-color: black;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1px;
`;

const SchedulerRequestForm = ({
    request,
    schedulePlanId,
    formOpen,
    onFormClose,
    timeZone,
    onLoadingStart,
    onLoadingFinished
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateFormatter = useLocalizeDateFormatter({ timeZone });
    const sourceShiftsData = useAppSelector((state: IRootState) =>
        request.schedule_plan_day_shift ? shiftById(state, request.schedule_plan_day_shift.shift_id) : undefined
    );
    const requestedShiftData = useAppSelector((state: IRootState) =>
        request.shift_id ? shiftById(state, request.shift_id) : undefined
    );
    const breakEntitiesData = useAppSelector(breakEntities);
    const skillEntitiesData = useAppSelector(skillEntities);
    const isClosed = useAppSelector((state) => isPlanClosed(state, schedulePlanId));
    const signedUserId = useAppSelector(signedUser)?.id;
    const subordinateUserIds = useAppSelector((state) => userById(state, signedUserId))?.subordinate;
    const signedUserIsAdmin = useAppSelector(isSignedUserAdmin);
    const requestSettingsData = useAppSelector(getRequestApplicationsSettings);

    const isAbleToExceedFund = getSettingsItemBooleanValueByKey(
        requestSettingsData,
        ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund
    );

    const isTimeOff = useMemo(
        () => request.request_type.type === RequestTypeEnum.times_off,
        [request.request_type.type]
    );

    const usersExceededFundList = useAppSelector((state) =>
        exceededFundByRequestTypeIdAndDateTimeStartAndEnd(
            state,
            request.user_id ?? null,
            request?.request_type_id ?? null,
            request?.datetime_from ?? null,
            request?.datetime_to ?? null
        )
    );
    const isFundsLoading = useAppSelector((state) =>
        isSomeVacationFundLoading(state, request?.datetime_from ?? undefined, request?.datetime_to ?? undefined)
    );

    const isValid = useMemo(
        () =>
            !isFundsLoading &&
            !((usersExceededFundList?.length ?? 0) > 0 && !isAbleToExceedFund) &&
            (signedUserIsAdmin || subordinateUserIds?.includes(request.user_id)),
        [usersExceededFundList, isAbleToExceedFund, isFundsLoading]
    );

    useEffect(() => {
        if (
            request.user_id &&
            request?.request_type_id &&
            isTimeOff &&
            request?.datetime_from &&
            request?.datetime_to
        ) {
            dispatch(
                getUsersFundForInsertedVacation({
                    userId: request.user_id,
                    requestTypeId: request?.request_type_id,
                    vacationStart: DateHelper.formatISO(request?.datetime_from),
                    vacationEnd: DateHelper.formatISO(request?.datetime_to)
                })
            );
        }
    }, [request.user_id, request?.request_type_id, request?.datetime_from, request?.datetime_to]);

    useEffect(() => {
        if (request.schedule_plan_day_shift) {
            dispatch(fetchShiftById(request.schedule_plan_day_shift.shift_id));
        }
    }, [request]);

    const handleClose = useCallback(() => {
        onFormClose();
    }, [formOpen]);

    const handleApprove = useCallback(() => {
        if (isValid) {
            if (onLoadingStart) {
                onLoadingStart();
            }

            dispatch(
                patchStateToRequest({
                    id: request.id,
                    schedulePlanId: schedulePlanId,
                    value: UserToRequestsStateEnum.ACCEPTED,
                    onLoadingFinished: onLoadingFinished
                })
            );
            onFormClose();
        }
    }, [request, isValid]);

    const handleReject = useCallback(() => {
        if (onLoadingStart) {
            onLoadingStart();
        }

        dispatch(
            patchStateToRequest({
                id: request.id,
                schedulePlanId: schedulePlanId,
                value: UserToRequestsStateEnum.REJECTED,
                onLoadingFinished: onLoadingFinished
            })
        );
        onFormClose();
    }, [request]);

    const renderInformationRow = (title: string, text: string, isFirst?: boolean) => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'stretch',
                justifyContent: 'space-between',
                alignItems: 'stretch'
            }}
        >
            {!isFirst && <Divider />}
            <Box>
                <Typography gutterBottom variant="body1">
                    <strong>{title}</strong>:
                </Typography>
            </Box>
            <Typography color="text.secondary" component="span">
                {text}
            </Typography>
        </Box>
    );

    const renderShiftAsTimelineView = (
        label: string,
        defaultLabelValue: string,
        shiftStart: string,
        shiftEnd: string,
        abr: { text: string; background: string; color: string },
        breaks: IShiftAsTimelineViewProps['breaks'],
        skills: { skill?: ISkillModel; skillId: string; range: ITimeRangeOrTimeAndLengthValueType }[]
    ) => (
        <StyledFieldWrapper key={`shift_${label}`}>
            <Typography variant="h6">
                {t(label, defaultLabelValue, {
                    date: dateFormatter.format(DateHelper.fromDateTimeString(shiftStart).toDate())
                })}
            </Typography>
            <ShiftAsTimelineView
                shiftStart={DateHelper.fromDateTimeString(shiftStart)}
                shiftEnd={DateHelper.fromDateTimeString(shiftEnd)}
                abbr={abr}
                breaks={breaks}
                timeZone={timeZone}
            />
            <SkillsOnShift
                data={skills.filter((item) => typeof item.skill !== 'undefined') as ISkillsOnShiftProps['data']}
                timeZone={timeZone}
            />
        </StyledFieldWrapper>
    );

    return (
        <FormGenerator
            name="request"
            maxWidth={'xl'}
            displayAsModal={false}
            displayAsSidebar={true}
            openForm={formOpen}
            onClose={onFormClose}
            openButtonValue={undefined}
            openButtonRender={undefined}
            title={`${t('label.request', 'Request')}: ${request.request_type.name}`}
            fields={[
                ...((config.isDevelop
                    ? [
                          {
                              type: 'html',
                              props: {
                                  name: 'requestId',
                                  render: () => <>request.id: {request.id}</>
                              }
                          }
                      ]
                    : []) as ISupportedFieldType[]),
                ...((request.schedule_plan_day_shift && sourceShiftsData
                    ? [
                          {
                              type: 'html',
                              props: {
                                  name: 'sourceShiftDetail',
                                  render: () =>
                                      request.schedule_plan_day_shift && sourceShiftsData ? (
                                          renderShiftAsTimelineView(
                                              'label.sourceShiftAt',
                                              'Source Shift At {{date}}',
                                              request.schedule_plan_day_shift.shift_start,
                                              request.schedule_plan_day_shift.shift_end,
                                              {
                                                  text: sourceShiftsData.abbreviation,
                                                  background: sourceShiftsData.background,
                                                  color: sourceShiftsData.color
                                              },
                                              request.schedule_plan_day_shift?.schedule_plan_day_shift_breaks?.map(
                                                  (breakData) => ({
                                                      id: breakData.id,
                                                      break_id: breakData.break_id,
                                                      start: DateHelper.fromDateTimeString(breakData.start),
                                                      breakDuration: breakData.break.duration,
                                                      breakBackground: breakData.break.background,
                                                      required: breakData.break.required,
                                                      used: breakData.used
                                                  })
                                              ) ?? [],
                                              request.schedule_plan_day_shift?.schedule_plan_day_shift_skills?.map(
                                                  (skillData) => ({
                                                      skill: skillEntitiesData[skillData.skill_id],
                                                      skillId: skillData.skill_id.toString(),
                                                      range: {
                                                          isRange: true,
                                                          start: DateHelper.fromDateTimeString(skillData.start),
                                                          duration: getDurationFromTimeStartAndTimeEnd(
                                                              DateHelper.fromDateTimeString(skillData.start).toDate(),
                                                              DateHelper.fromDateTimeString(skillData.end).toDate()
                                                          )
                                                      }
                                                  })
                                              ) ?? []
                                          )
                                      ) : (
                                          <></>
                                      )
                              }
                          }
                      ]
                    : []) as ISupportedFieldType[]),
                ...((requestedShiftData
                    ? [
                          {
                              type: 'html',
                              props: {
                                  name: 'requestedShiftDetail',
                                  render: () => {
                                      const skills = request.schedule_plan_day_shift?.schedule_plan_day_shift_skills;
                                      const skill = skills ? skills[0] : undefined;

                                      return requestedShiftData ? (
                                          renderShiftAsTimelineView(
                                              'label.requestedShiftAt',
                                              'Requested Shift At {{date}}',
                                              DateHelper.fromTimeStringAndLocalDate(
                                                  requestedShiftData.time_start,
                                                  request.datetime_from,
                                                  timeZone
                                              ).toISOString(),
                                              DateHelper.addHours(
                                                  DateHelper.fromTimeStringAndLocalDate(
                                                      requestedShiftData.time_start,
                                                      request.datetime_from,
                                                      timeZone
                                                  ),
                                                  requestedShiftData.duration
                                              ).toISOString(),
                                              {
                                                  text: requestedShiftData.abbreviation,
                                                  background: requestedShiftData.background,
                                                  color: requestedShiftData.color
                                              },
                                              requestedShiftData.shift_items?.map((breakData) => ({
                                                  id: breakData.id,
                                                  break_id: breakData.break_id,
                                                  start: DateHelper.fromDateTimeString(breakData.start),
                                                  breakDuration: breakData.duration,
                                                  breakBackground:
                                                      breakEntitiesData[breakData.break_id]?.background ?? '#fffffff',
                                                  required: false, // todo dodelat vazbu na breaku
                                                  used: false
                                              })) ?? [],
                                              skill
                                                  ? [
                                                        {
                                                            skill: skillEntitiesData[skill.skill_id],
                                                            skillId: skill.skill_id.toString(),
                                                            range: {
                                                                isRange: true,
                                                                start: DateHelper.fromTimeStringAndLocalDate(
                                                                    requestedShiftData.time_start,
                                                                    request.datetime_from,
                                                                    timeZone
                                                                ),
                                                                duration: requestedShiftData.duration
                                                            }
                                                        }
                                                    ]
                                                  : []
                                          )
                                      ) : (
                                          <></>
                                      );
                                  }
                              }
                          }
                      ]
                    : []) as ISupportedFieldType[]),
                {
                    type: 'html',
                    props: {
                        name: 'information',
                        render: () => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {renderInformationRow(t('label.user', 'User'), serializeUser(request.user), true)}
                                {request.request_type.type !== RequestTypeEnum.available &&
                                    renderInformationRow(
                                        t('label.state', 'State'),
                                        t(`enums.userToRequestsStateEnum.${request.state}`, request.state)
                                    )}
                                {request.request_type.name &&
                                    renderInformationRow(
                                        t('label.nameOfRequestType', 'Name Of Request Type'),
                                        t(
                                            `enums.requestTypeEnum.${request.request_type.type}`,
                                            request.request_type.type
                                        )
                                    )}
                                {request.description &&
                                    renderInformationRow(t('label.description', 'Description'), request.description)}
                                {request.request_type.description &&
                                    renderInformationRow(
                                        t('label.descriptionOfRequestType', 'Description Of Request Type'),
                                        request.request_type.description
                                    )}
                                {renderInformationRow(
                                    t('label.fromDate', 'From Date'),
                                    dateTimeFormatter.format(request.datetime_from.toDate())
                                )}
                                {renderInformationRow(
                                    t('label.toDate', 'To Date'),
                                    dateTimeFormatter.format(request.datetime_to.toDate())
                                )}
                                {request.request_type.type !== RequestTypeEnum.available &&
                                    request.state_changed_by_user &&
                                    renderInformationRow(
                                        t('label.stateChangedBy', 'State Changed By'),
                                        serializeUser(request.state_changed_by_user)
                                    )}
                                {request.request_type.type !== RequestTypeEnum.available &&
                                    request.state_changed_by_user_at &&
                                    renderInformationRow(
                                        t('label.stateChangedAt', 'State Changed At'),
                                        dateTimeFormatter.format(request.state_changed_by_user_at.toDate())
                                    )}
                            </Box>
                        )
                    }
                },
                {
                    type: 'custom',
                    display: () => {
                        return (
                            request.state === UserToRequestsStateEnum.CREATED &&
                            (isFundsLoading || (usersExceededFundList?.length ?? 0) > 0)
                        );
                    },
                    props: {
                        name: 'errorMessage',
                        values: {},
                        render: () => (
                            <>
                                <Divider />
                                {isFundsLoading ? (
                                    <Skeleton animation="wave" variant="rectangular" width="100%" height="2em" />
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {usersExceededFundList?.map((fund) => {
                                            return (
                                                <Typography
                                                    key={getUserToVacationFundKey(fund)}
                                                    color="error.dark"
                                                    variant="subtitle1"
                                                >
                                                    {t(
                                                        'message.error.vacationFundForYearWillBeExceeded',
                                                        'For {{year}} vacation fund will be exhausted by: {{exceeded}}',
                                                        {
                                                            year: fund.year,
                                                            exceeded: DateHelper.getMinutesInHumanFormat(
                                                                ((fund.exhausted ?? 0) - (fund.fund ?? 0)) * 60
                                                            )
                                                        }
                                                    )}
                                                </Typography>
                                            );
                                        })}
                                    </Box>
                                )}
                            </>
                        )
                    }
                }
            ]}
            actions={[
                {
                    type: 'button',
                    props: {
                        type: 'button',
                        name: 'cancel',
                        variant: 'outlined',
                        onClick: handleClose,
                        children: t('label.close', 'Close')
                    }
                },
                {
                    type: 'button',
                    display: () =>
                        !isClosed &&
                        (request.state === UserToRequestsStateEnum.CREATED ||
                            (request.state === UserToRequestsStateEnum.ACCEPTED &&
                                [RequestTypeEnum.times_off, RequestTypeEnum.available].some(
                                    (item) => item == request.request_type.type
                                ))),
                    props: {
                        type: 'button',
                        disabled: !subordinateUserIds?.includes(request.user_id),
                        name: 'reject',
                        variant: 'contained',
                        color: 'error',
                        onClick: handleReject,
                        children: t('label.reject', 'Reject')
                    }
                },
                {
                    type: 'button',
                    display: () => !isClosed && request.state === UserToRequestsStateEnum.CREATED,
                    props: {
                        type: 'button',
                        disabled: !isValid,
                        name: 'approve',
                        variant: 'contained',
                        color: 'success',
                        onClick: handleApprove,
                        children: t('label.approve', 'Approve')
                    }
                }
            ]}
        />
    );
};

export default SchedulerRequestForm;
