import EditIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { OpenButton } from '@/base/FormGenerator/FormGenerator';
import CrudDatatable from '@/components/CrudDatatable';
import { Mode } from '@/components/UserPermision';
import { useAppSelector } from '@/data/hooks';
import { fetchSchedulePlans, removeSchedulePlan } from '@/data/SchedulePlans/SchedulePlanActions';
import {
    isSchedulePlanListInProgress,
    schedulePlanPaging,
    schedulePlanRemovingStatus,
    selectSchedulePlanList
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import { scheduleStateToColor } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { mainSchedulePlansDetail } from '@/utils/routes';
import Chip from '@/wrappers/Chip';
import Switch from '@/wrappers/Switch';

const StyledIconButton = styled(IconButton)(
    ({ theme }) => `
        color: ${theme.palette.info.main};
    `
);

export default function SchedulesListPage() {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const inProgress = useAppSelector(isSchedulePlanListInProgress);
    const data = useAppSelector(selectSchedulePlanList);

    return (
        <LayoutOfPage title={t('title.schedulePlansList', 'Schedule Plans List')} data-testid="scheduler-list-page">
            <CrudDatatable<ArrayElement<typeof data>>
                name="schedule"
                resource={PermissionsEnum.SchedulePlans}
                editResourceMode={{
                    mode: [Mode.UPDATE, Mode.READ],
                    operator: 'OR'
                }}
                nameOfEntity={(item) => item.name}
                formRender={(id, _, __, rowData) =>
                    id ? (
                        <>
                            <StyledIconButton
                                title={t('label.edit', 'Edit')}
                                data-testid={`openDialog-schedulePlan-${id}_openButton`}
                                onClick={() =>
                                    navigate(
                                        mainSchedulePlansDetail({
                                            isNew: 0,
                                            id: id,
                                            workplaceId: rowData?.workplace_id ?? '',
                                            periodId: rowData?.period_id ?? ''
                                        })
                                    )
                                }
                            >
                                <EditIcon data-testid="edit" />
                            </StyledIconButton>
                        </>
                    ) : (
                        <OpenButton
                            nameWithId="schedulePlan_openButton"
                            openButtonValue={t('label.addSchedulePlan', 'Add schedule plan')}
                            isEdit={false}
                            justIcon={false}
                            onClick={() =>
                                navigate(
                                    mainSchedulePlansDetail({
                                        isNew: 1
                                    })
                                )
                            }
                        />
                    )
                }
                header={[
                    {
                        title: t('header.scheduleName', 'Schedule Name'),
                        field: 'name',
                        render: (rowData) => rowData.name
                    },
                    {
                        title: t('header.periodName', 'Period Name'),
                        field: 'Periods.name',
                        render: (rowData) => rowData.period.name
                    },
                    {
                        title: t('header.workplace', 'Workplace'),
                        field: 'Workplaces.name',
                        render: (rowData) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                name={`workplace-${rowData.workplace_id}`}
                                label={rowData.workplace.name}
                            />
                        )
                    },
                    {
                        title: t('header.state', 'State'),
                        field: 'state',
                        render: (rowData) => (
                            <Chip
                                variant="outlined"
                                color={scheduleStateToColor(rowData.state, rowData.optimal)}
                                name={`state-${rowData.id}`}
                                label={rowData.state}
                            />
                        )
                    },
                    {
                        title: t('header.published', 'Published'),
                        field: 'published',
                        render: (rowData) => (
                            <Switch name={`published-${rowData.id}`} value={rowData.published} readOnly={true} />
                        )
                    },
                    {
                        title: t('header.createdAt', 'Created At'),
                        type: 'date',
                        field: 'created'
                    },
                    {
                        title: t('header.modifiedAt', 'Modified At'),
                        type: 'date',
                        field: 'modified'
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(schedulePlanPaging)}
                removingStatus={useAppSelector(schedulePlanRemovingStatus)}
                onFetchList={fetchSchedulePlans}
                onRemove={removeSchedulePlan}
            />
        </LayoutOfPage>
    );
}
