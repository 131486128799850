import Box from '@mui/material/Box';
import { styled, SxProps } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

type IDrawerActions = {
    sx?: SxProps;
};

const StyledActions = styled(Box)(
    ({ theme }) => `
        padding-block: ${theme.spacing(2)};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${theme.spacing(1)};
    `
);

const Actions = ({ sx, children }: PropsWithChildren<IDrawerActions>) =>
    children ? <StyledActions sx={sx}>{children}</StyledActions> : null;

export default Actions;
