import { useCallback, useRef, useState } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { modifyNeedsOfSchedulePlan } from '@/data/SchedulePlans/SchedulePlanActions';
import {
    selectPeriodOfPlan,
    selectSkillsUsedByPlanId,
    selectTimeZoneOfPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button/Button';
import { IDateTimeRangePickerProps } from '@/wrappers/DateTimeRangePicker/DateTimeRangePicker';
import DialogActions from '@/wrappers/Dialog/Actions';
import DialogContainer from '@/wrappers/Dialog/Container';
import DialogContent from '@/wrappers/Dialog/Content';
import DialogTitle from '@/wrappers/Dialog/Title';
import LoadingButton from '@/wrappers/LoadingButton';

type IProps = {
    from: DateTimeType | null;
    open: boolean;
    setOpen: (open: boolean) => void;
    schedulePlanId: number;
    isPlanLocked: boolean;
    to: DateTimeType | null;
    onClose: () => void;
};

const ModifyNeedsDialog = ({ from, open, setOpen, onClose, schedulePlanId, isPlanLocked, to }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const period = useAppSelector((state) => selectPeriodOfPlan(state, schedulePlanId));
    const timeZone = useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId));
    const [inProgress, setInProgress] = useState(false);
    const formRef = useRef<HTMLFormElement | null>(null);
    const skillsInPlan = useAppSelector((state) => selectSkillsUsedByPlanId(state, schedulePlanId));

    const handleClickOnSave = useCallback(() => {
        if (schedulePlanId && !isPlanLocked) {
            setInProgress(true);
            (formRef.current?.querySelector('button[type="submit"]') as HTMLButtonElement)?.click();
        }
    }, [isPlanLocked, schedulePlanId]);
    const handleOnSubmit = useCallback(
        (values: IOutputValueType) => {
            dispatch(
                modifyNeedsOfSchedulePlan({
                    from: (values.dateTimeRange as IDateTimeRangePickerProps['value'])?.start ?? from,
                    changeTo:
                        values.changeByPercentage !== false
                            ? parseInt(`${values.changeToPercentage}`) / 100
                            : parseInt(`${values.changeToValue}`),
                    isPercentage: values.changeByPercentage !== false,
                    schedulePlanId,
                    skillIds: (values.skillIds as string[]).map((skillId) => parseInt(`${skillId}`)),
                    to: (values.dateTimeRange as IDateTimeRangePickerProps['value'])?.end ?? to
                })
            ).then(() => {
                setInProgress(false);
                onClose();
            });
        },
        [from, to, schedulePlanId]
    );

    return (
        <DialogContainer
            data-testid="schedulerMenu_modify_needs"
            open={open}
            onClick={(event) => {
                event.stopPropagation();
            }}
            onClose={onClose}
        >
            <DialogTitle>{t('label.modifyNeeds', 'Modify Needs')}</DialogTitle>
            <DialogContent>
                <FormGenerator
                    ref={formRef}
                    name="modifyNeeds"
                    actions={[
                        {
                            type: 'button',
                            props: {
                                name: 'submit',
                                type: 'submit',
                                variant: 'contained',
                                children: 'Save employee',
                                hidden: true
                            }
                        }
                    ]}
                    displayAsSidebar={false}
                    displayAsModal={false}
                    fields={[
                        {
                            type: 'dateTimeRange',
                            props: {
                                name: 'fromTo',
                                label: {
                                    start: t('label.from', 'From'),
                                    end: t('label.end', 'End')
                                },
                                minDate: period?.period_start
                                    ? DateHelper.fromDateTimeString(period.period_start, timeZone ?? undefined)
                                    : undefined,
                                maxDate: period?.period_end
                                    ? DateHelper.fromDateTimeString(period.period_end, timeZone ?? undefined)
                                    : undefined,
                                minutesStep: 15,
                                value: {
                                    start: from,
                                    end: to
                                }
                            }
                        },
                        {
                            type: 'multiSelect',
                            props: {
                                name: 'skillIds',
                                label: t('label.skills', 'Skills'),
                                options:
                                    skillsInPlan?.map((skillInPlan) => ({
                                        id: `${skillInPlan.id}`,
                                        label: skillInPlan.name
                                    })) ?? []
                            }
                        },
                        {
                            type: 'switch',
                            props: {
                                name: 'changeByPercentage',
                                label: t('label.changeByPercentage', 'Change by Percentage'),
                                value: true,
                                validation: {
                                    deps: ['changeToPercentage', 'changeToValue']
                                }
                            }
                        },
                        {
                            type: 'textField',
                            display: ({ changeByPercentage }) => changeByPercentage !== false,
                            props: {
                                name: 'changeToPercentage',
                                label: t('label.changeTo', 'Change To'),
                                type: 'number',
                                InputProps: {
                                    endAdornment: '%',
                                    inputProps: {
                                        min: 0
                                    }
                                },
                                value: 100,
                                validation: {
                                    deps: ['changeByPercentage']
                                }
                            }
                        },
                        {
                            type: 'textField',
                            display: ({ changeByPercentage }) => changeByPercentage === false,
                            props: {
                                name: 'changeToValue',
                                label: t('label.changeTo', 'Change To'),
                                type: 'number',
                                value: 0,
                                validation: {
                                    deps: ['changeByPercentage']
                                }
                            }
                        }
                    ]}
                    onSubmit={handleOnSubmit}
                />
            </DialogContent>
            <DialogActions>
                <Button name="no" variant="outlined" onClick={onClose}>
                    {t('label.cancel', 'Cancel')}
                </Button>
                <LoadingButton
                    name="save"
                    variant="contained"
                    color="primary"
                    loading={inProgress}
                    onClick={handleClickOnSave}
                >
                    {t('label.save', 'Save')}
                </LoadingButton>
            </DialogActions>
        </DialogContainer>
    );
};

export default ModifyNeedsDialog;
