import namesOfWeekDays from '@/helpers/date/namesOfWeekDays';

export const bitTest = (num: number, bit: number) => (num >> bit) % 2 !== 0;
export const dayTest = (num: number, day: number) => bitTest(num, (day + 1) % 7);
export const bitSet = (num: number, bit: number, clearBit: boolean = false) =>
    clearBit ? num & ~(1 << bit) : num | (1 << bit);
export const daySet = (num: number, day: number, isSelected: boolean = false) =>
    Math.abs(bitSet(num, (day + 1) % 7, !isSelected));

export const daysArrayOfIndexIntoDayBitArray = (values: string[] | number[] | null): number[] =>
    [0, 0, 0, 0, 0, 0, 0].map((_, index) =>
        values?.some((item) => {
            if (typeof item === 'string') {
                item = parseInt(item);
            }

            return (item + 1) % 7 == index;
        })
            ? 1
            : 0
    );

export const daysArrayOfIndexIntoValue = (values: string[] | number[] | null) => {
    const bitArray = daysArrayOfIndexIntoDayBitArray(values);
    let number = 0;

    bitArray?.map((value, index) => {
        number = bitSet(number, index, value === 0);
    });

    return number;
};
export const getBitBooleanArrayFromValue = (value: number) => namesOfWeekDays.map((day) => bitTest(value || 0, day.id));
export const getDayBitBooleanArrayFromValue = (value: number) =>
    namesOfWeekDays.map((day) => dayTest(value || 0, day.id));
