import dayjsInstance from 'dayjs';
import { useMemo } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';

const useLocalizeMinutesRange = () => {
    const { i18n } = useAppTranslation();

    return useMemo(() => {
        const minutesRange: { id: string; label: string }[] = [];

        for (let duration = 15; duration <= 240; duration += 15) {
            const obj = dayjsInstance.duration(duration, 'minutes');

            const onlyHours = obj.hours();
            const onlyMinutes = obj.minutes();

            const label = `${onlyHours}:${('00' + onlyMinutes).slice(-2)}`;

            minutesRange.push({
                id: duration.toString(),
                label: label
            });
        }

        return minutesRange;
    }, [i18n.resolvedLanguage, i18n.language]);
};

export default useLocalizeMinutesRange;
