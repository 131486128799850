export const MAIN_URL = '/app';

type IUrlWithParams = (params: Record<string, string | number>) => string;

export const login = (full = true) => `/${full ? 'login' : ''}`;
export const welcome = () => '/welcome';
export const resetPassword = (withMainURL = false) => `${withMainURL ? MAIN_URL : ''}/resetPassword`;
export const mainActivities = () => `${MAIN_URL}/activities`;
export const mainDashboard = () => MAIN_URL;
export const mainCallCenters = () => `${MAIN_URL}/callCenters`;
export const mainContracts = () => `${MAIN_URL}/contracts`;
export const mainAgentGroups = () => `${MAIN_URL}/agentGroups`;
export const mainRoles = () => `${MAIN_URL}/roles`;
export const mainPermissions = () => `${MAIN_URL}/permissions`;
export const mainQueues = () => `${MAIN_URL}/queues`;
export const mainCoverageToRequire = () => `${MAIN_URL}/coverageToRequire`;
export const mainPredictedTimeToRealTime = () => `${MAIN_URL}/predictedTimeToRealTime`;
export const mainPredictedOperatorsCountToRealOperatorsCount = () =>
    `${MAIN_URL}/predictedOperatorsCountToRealOperatorsCount`;
export const mainRealAverageDurationToRealDuration = () => `${MAIN_URL}/realAverageDurationToRealDuration`;
export const mainSkills = () => `${MAIN_URL}/skills`;
export const mainUsers = () => `${MAIN_URL}/users`;
export const mainUsersCreate = () => `${MAIN_URL}/users/new`;
export const mainUsersClone: IUrlWithParams = ({ id }) => `${MAIN_URL}/users/clone/${id}`;
export const mainUsersDetail: IUrlWithParams = ({ id }) => `${MAIN_URL}/users/${id}`;
export const mainWorkplaces = () => `${MAIN_URL}/workplaces`;
export const mainSettings = () => `${MAIN_URL}/settings`;
export const mainIntegratedApplication = () => `${MAIN_URL}/integratedApplication`;
export const mainPeriods = () => `${MAIN_URL}/periods`;
export const mainNeeds = () => `${MAIN_URL}/needs`;
export const mainRequestTypes = () => `${MAIN_URL}/requestTypes`;
export const mainUsersRequest = () => `${MAIN_URL}/usersRequest`;
export const mainTimeoffs = () => `${MAIN_URL}/timeoffs`;
export const mainShifts = () => `${MAIN_URL}/shifts`;
export const mainSwagger = () => '/swagger';
export const mainSchedulePlans = () => `${MAIN_URL}/schedulePlans`;
export const mainSchedulePlansDetail: IUrlWithParams = ({ isNew, ...rest }) => {
    const urlParams = Object.keys(rest)
        .map((paramName) => `${paramName}=${rest[paramName] || ''}`)
        .join('&');

    return `${mainSchedulePlans()}/${typeof isNew === 'string' ? isNew : isNew ? 'new' : 'edit'}${
        urlParams.length > 0 ? `?${urlParams}` : ''
    }`;
};
export const mainBreaks = () => `${MAIN_URL}/breaks`;

export const routeUrlMapping = {} as { [name: string]: string };

export const routeUrlBlankMapping = {
    mainSwagger: mainSwagger()
} as { [name: string]: string };

export const routeWithoutAuthMapping = {
    resetPassword: resetPassword()
};

export const routeMapping = {
    login: login(),
    mainActivities: mainActivities(),
    mainCallCenters: mainCallCenters(),
    mainContracts: mainContracts(),
    mainDashboard: mainDashboard(),
    mainAgentGroups: mainAgentGroups(),
    mainBreaks: mainBreaks(),
    mainNeeds: mainNeeds(),
    mainPeriods: mainPeriods(),
    mainPermissions: mainPermissions(),
    mainQueues: mainQueues(),
    mainCoverageToRequire: mainCoverageToRequire(),
    mainPredictedTimeToRealTime: mainPredictedTimeToRealTime(),
    mainPredictedOperatorsCountToRealOperatorsCount: mainPredictedOperatorsCountToRealOperatorsCount(),
    mainRealAverageDurationToRealDuration: mainRealAverageDurationToRealDuration(),
    mainRequestTypes: mainRequestTypes(),
    mainUsersRequest: mainUsersRequest(),
    mainRoles: mainRoles(),
    mainSchedule: mainSchedulePlans(),
    mainSkills: mainSkills(),
    mainShifts: mainShifts(),
    mainTimeoffs: mainTimeoffs(),
    mainUsers: mainUsers(),
    mainWorkplaces: mainWorkplaces(),
    mainSettings: mainSettings(),
    mainIntegratedApplication: mainIntegratedApplication()
} as { [name: string]: string };

export const removeUrlPrefix = (prefix: string, url: string): string => {
    let result = url;

    if (url.startsWith(prefix)) {
        result = url.substring(prefix.length);
    }

    return result;
};

export type IRouteId = keyof typeof routeMapping;
export type IRouteUrlId = keyof typeof routeUrlMapping;
export type IRouteUrlBlankId = keyof typeof routeUrlBlankMapping;
