import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Fragment, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@/data/hooks';
import { selectSchedulePlanDayShiftsBySchedulePlanById } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import {
    getPreparedBodyOfPlan,
    schedulePlanAssignedUsersBySchedulePlanId,
    selectUsersWithShiftByPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { selectShiftIdsWithTradeToApprove } from '@/data/ShiftTrades/ShiftTradeSlice';
import { isSignedUserAdmin, signedUser } from '@/data/System/SystemReducer';
import { IUserModel } from '@/data/Users/UserModels';
import DateHelper from '@/helpers/date/DateHelper';
import useSchedulerTitles from '@/hooks/scheduler/useSchedulerTitles';
import SchedulerCalendarDataRow, {
    IMergedColumn,
    ISimpleColumn
} from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRow';
import { ISchedulerCalendarDataRowCellProps } from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRowCell';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import { GridRootData, StyledUserSideBar } from '@/modules/Scheduler/StyledParts';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import { serializeUser } from '@/utils/UserHelper';
import { StyledBodyUserIcon } from '../../StyledTableCell';

type ISchedulerTableBodyProps = Pick<ISchedulerCalendarHeaderProps, 'from' | 'mode' | 'to'> & {
    isCollapsed: boolean;
    selectedRequirements: number[] | null;
    schedulePlanId: number;
    timeZone: string;
    onLoadingFinished: () => void;
    onLoadingStart: () => void;
} & Pick<ISchedulerCalendarDataRowCellProps, 'onClick'>;

const SchedulerTradeBody = ({
    from,
    isCollapsed,
    mode,
    selectedRequirements,
    schedulePlanId,
    to,
    timeZone,
    onClick,
    onLoadingFinished,
    onLoadingStart
}: PropsWithChildren<ISchedulerTableBodyProps>) => {
    const preparedBody = useAppSelector((state) => getPreparedBodyOfPlan(state, schedulePlanId));
    const alreadyAssignedUsers = useAppSelector((state) =>
        schedulePlanAssignedUsersBySchedulePlanId(state, schedulePlanId)
    );
    const topRow = useSchedulerTitles(mode === SchedulePlanWidthTypeEnum.Day, from, to);
    const assignedShiftIdsWithTrade = useAppSelector(selectShiftIdsWithTradeToApprove);
    const schedulePlanDayShiftsByPlan = useAppSelector((state) =>
        selectSchedulePlanDayShiftsBySchedulePlanById(state, schedulePlanId)
    );
    const usersWithShift = useAppSelector((state) => selectUsersWithShiftByPlan(state, schedulePlanId));
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const workerInstance = useMemo(() => new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' }), []);
    const [users, setUsers] = useState<(IUserModel & { columns: (ISimpleColumn<Date> | IMergedColumn<Date>)[] })[]>([]);

    useEffect(() => {
        onLoadingStart();

        return () => {
            workerInstance.terminate();
        };
    }, []);
    useEffect(() => {
        if (window.Worker) {
            workerInstance.onmessage = (
                e: MessageEvent<{
                    users: (IUserModel & { columns: (ISimpleColumn<Date> | IMergedColumn<Date>)[] })[];
                }>
            ) => {
                setUsers(e.data.users);
                setTimeout(onLoadingFinished, 500);
            };
        }
    }, [workerInstance]);
    useEffect(() => {
        onLoadingStart();
        if (window.Worker) {
            workerInstance.postMessage({
                from: from.toISOString(),
                to: (mode === SchedulePlanWidthTypeEnum.Day ? to : DateHelper.addDays(to, 1)).toISOString(),
                isDayMode: mode === SchedulePlanWidthTypeEnum.Day,
                usersWithShift,
                alreadyAssignedUsers,
                schedulePlanDayShiftsByPlan,
                preparedBody,
                allowedShifts: assignedShiftIdsWithTrade,
                timeZoneOffset:
                    from.utcOffset() - DateHelper.fromDateString(DateHelper.formatDate(from), 'local').utcOffset()
            });
        }
    }, [
        from.toISOString(),
        (mode === SchedulePlanWidthTypeEnum.Day ? to : DateHelper.addDays(to, 1)).toISOString(),
        mode === SchedulePlanWidthTypeEnum.Day,
        usersWithShift,
        alreadyAssignedUsers,
        schedulePlanDayShiftsByPlan,
        preparedBody,
        assignedShiftIdsWithTrade
    ]);

    const flooredNowDate = DateHelper.getUTCStartOfTheDay(
        DateHelper.subtractMinutesByTimezone(DateHelper.now(), timeZone),
        true
    ).toDate();
    const signedUserData = useAppSelector(signedUser);

    const noRole = !selectedRequirements?.some((id) => id < 0) ?? false;
    const noSkill = !selectedRequirements?.some((id) => id >= 0) ?? false;
    const multiply = 4 * (mode === SchedulePlanWidthTypeEnum.Day ? 1 : 24);

    return (
        <>
            {users
                .filter((user) => {
                    if (selectedRequirements === null || selectedRequirements?.length === 0) {
                        return true;
                    } else {
                        const hasSelectedSkill =
                            (noSkill ||
                                selectedRequirements?.some(
                                    (reqId) => user.user_to_skills?.some((join) => join.skill_id === reqId)
                                )) ??
                            false;
                        const hasSelectedRole =
                            (noRole ||
                                selectedRequirements?.some(
                                    (reqId) => user.user_to_roles?.some((join) => join.role_id === -reqId)
                                )) ??
                            false;

                        return hasSelectedSkill && hasSelectedRole;
                    }
                })
                .slice()
                .sort((a, b) => a.last_name.localeCompare(b.last_name))
                .map((user, rowIndex) => {
                    const userName = serializeUser(user);

                    return (
                        <Fragment key={`row-${user.id}`}>
                            <StyledUserSideBar
                                isCurrentUser={signedUserData?.id === user.id}
                                rowIndex={rowIndex}
                                isLast={users.length - 1 === rowIndex}
                            >
                                <Box>
                                    {isCollapsed ? (
                                        <Avatar
                                            alt={userName}
                                            sx={{
                                                width: '30px',
                                                height: '30px'
                                            }}
                                        >
                                            {[user.first_name, user.middle_name, user.last_name]
                                                .filter((part) => !!part)
                                                .map((part) => part?.substring(0, 1))
                                                .join('')}
                                        </Avatar>
                                    ) : (
                                        <>
                                            <StyledBodyUserIcon />
                                            {userName}
                                        </>
                                    )}
                                </Box>
                            </StyledUserSideBar>
                            <GridRootData
                                countOfColumns={topRow.length * multiply}
                                isLast={users.length - 1 === rowIndex}
                                rowIndex={rowIndex}
                            >
                                {schedulePlanId ? (
                                    <SchedulerCalendarDataRow
                                        key={user.id}
                                        from={from}
                                        mode={mode}
                                        columns={user.columns}
                                        flooredNowDate={flooredNowDate}
                                        isCurrentUser={signedUserData?.id === user.id}
                                        isUserAdmin={isUserAdmin}
                                        rowIndex={rowIndex + 1}
                                        schedulePlanId={schedulePlanId}
                                        timeZone={timeZone}
                                        to={mode === SchedulePlanWidthTypeEnum.Day ? to : DateHelper.addDays(to, 1)}
                                        type="shiftTrades"
                                        userId={user.id}
                                        userName={userName}
                                        onClick={onClick}
                                    />
                                ) : (
                                    <Box key={`${user.id}_${rowIndex}`} className="placeholderCell" />
                                )}
                            </GridRootData>
                        </Fragment>
                    );
                })}
        </>
    );
};

export default SchedulerTradeBody;
