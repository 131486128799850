import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { forwardRef, SyntheticEvent } from 'react';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';

export type ISelectOption = {
    id: string;
    label: string;
    custom?: { [key: string]: unknown };
};
export type ISelectProps = Omit<
    MuiAutocompleteProps<ISelectOption, false, boolean | undefined, false>,
    | 'multiple'
    | 'name'
    | 'getOptionLabel'
    | 'getOptionId'
    | 'renderInput'
    | 'renderOption'
    | 'onChange'
    | 'defaultValue'
    | 'value'
> &
    Pick<ITextFieldProps, 'name' | 'label' | 'disabled' | 'readOnly' | 'required'> & {
        value?: string | number;
        error?: boolean;
        helperText?: string;
        options: ISelectOption[];
        disabledInput?: boolean;
        onChange?: (selected: string | null, event?: SyntheticEvent) => void;
    };

const Select = forwardRef<HTMLDivElement, ISelectProps>(function Inner(
    { name, label, value, disabled, required, readOnly, error, helperText, onChange, ...rest },
    ref
) {
    const parsedValue = value ? `${value}` : undefined;
    const { disabledInput, ...props } = rest;

    return (
        <Autocomplete
            {...props}
            autoHighlight
            value={props.options.find((option) => option.id === `${parsedValue}`) ?? null}
            getOptionLabel={(option) => option.label}
            renderOption={(renderProps, option) => (
                <Box component="li" data-testid="select-item" {...renderProps}>
                    {option.label}
                </Box>
            )}
            disabled={disabled}
            readOnly={readOnly}
            isOptionEqualToValue={(option, selected) => option.id === selected.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    ref={ref}
                    name={name}
                    data-testid={`select_${name}`}
                    placeholder={props.placeholder}
                    label={label}
                    disabled={!!disabledInput || disabled}
                    required={required}
                    error={error}
                    helperText={helperText}
                    readOnly={readOnly}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: typeof props.placeholder === 'string' ? !!props.placeholder : undefined
                    }}
                />
            )}
            onChange={(event, selected) => onChange && onChange(selected?.id ?? null, event)}
        />
    );
});

export default Select;
