type ISortHandler = (a: string | number | boolean, b: string | number | boolean) => number;

const handleSort: ISortHandler = (prev, next) => {
    if (+prev < +next) return -1;

    if (+prev > +next) return 1;

    return 0;
};

export default handleSort;
