import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, remove, update } from './ActivityApi';
import { IActivityCUModel } from './ActivityModels';
import { activityPaging } from './ActivitySlice';

export const fetchActivities = createAsyncThunk('activities/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = activityPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchActivityById = createAsyncThunk('activities/byId', async (args: number) => {
    return await fetchById(args);
});

export const createActivity = createAsyncThunk('activities/create', async (args: IActivityCUModel) => {
    return await create(args);
});

export const updateActivity = createAsyncThunk(
    'activities/update',
    async (args: { id: number; data: IActivityCUModel }) => {
        return await update(args.id, args.data);
    }
);

export const removeActivity = createAsyncThunk(
    'activities/remove',
    async (args: { id: number; onSuccess?: () => void }) => {
        await remove(args.id).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);
