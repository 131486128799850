import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppTranslation from '@/hooks/useAppTranslation';
import UserDetail from '@/modules/UserDetail';
import { ControlRef } from '@/modules/UserDetail/UserDetail';
import { mainUsers } from '@/utils/routes';
import LayoutOfPage from './LayoutOfPage';

export default function UsersCreatePage() {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const goToList = useCallback(() => navigate(mainUsers()), []);
    const formRef = useRef<ControlRef>(null);
    const handleSave = () => formRef.current?.submitForm();

    return (
        <LayoutOfPage
            title={t('title.newEmployee', 'New Employee')}
            actionBack={{
                display: true,
                onClick: goToList
            }}
            actionSave={{
                display: true,
                onClick: handleSave
            }}
        >
            <UserDetail ref={formRef} onSaveFinished={goToList} />
        </LayoutOfPage>
    );
}
