import { useMemo } from 'react';
import { getDayNames } from '@/helpers/date/namesOfWeekDays';
import useAppTranslation from '@/hooks/useAppTranslation';
import { defaultLng } from '@/utils/i18next';

const useTranslatedDays = (formats: 'long' | 'short' | 'narrow' | undefined = 'short') => {
    const { i18n } = useAppTranslation();

    return useMemo(
        () =>
            getDayNames(i18n.resolvedLanguage ?? i18n.language ?? defaultLng, formats).map((day, index) => ({
                id: index,
                name: day
            })),
        [i18n.resolvedLanguage, i18n.language]
    );
};

export default useTranslatedDays;
