import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { createUser, fetchUserById, updateUser } from '@/data/Users/UserActions';
import filterIncludeValue from '@/helpers/array/filterIncludeValue';
import sortEntities from '@/helpers/array/sortEntities';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createContract,
    fetchContractById,
    fetchContracts,
    fetchContractsForSelect,
    removeContract,
    updateContract
} from './ContractActions';
import { IContractModel, IContractSelectModel } from './ContractModels';

type IContractReducerState = {
    selectItems: IContractSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IContractReducerState = {
    selectItems: [],
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IContractModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const contractSlice = createSlice({
    name: 'contracts',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
            adapter.upsertMany(
                state,
                action.payload.users
                    .flatMap(({ user_to_contracts }) => user_to_contracts)
                    .map(({ contract }) => contract)
            );
        });
        builder
            .addCase(fetchContracts.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchContracts.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchContracts.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchContractsForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchContractsForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchContractsForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchContractById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchContractById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchContractById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createContract.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createContract.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name
                    } as IContractSelectModel);
                }
            })
            .addCase(createContract.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeContract.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeContract.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg.id);
                }
            })
            .addCase(removeContract.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_contracts.map(({ contract }) => contract)
                );
            })
            .addCase(createUser.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_contracts.map(({ contract }) => contract)
                );
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.user_to_contracts.map(({ contract }) => contract)
                );
            })
            .addCase(updateContract.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateContract.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? {
                                  id: action.payload.id,
                                  name: action.payload.name
                              }
                            : item
                    );
                }
            })
            .addCase(updateContract.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[contractSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default contractSlice;
export const isContractListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const contractPaging = (state: IRootState) => getState(state).paging;
export const isContractByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const contractList = adapterSelectors.selectAll;
export const selectFilteredContracts = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => getState(state).paging,
    (state: IRootState) => adapterSelectors.selectTotal(state),
    (entities, filter, total) => {
        const page = Math.min(filter.page - 1, Math.floor(total / filter.limit));

        return Object.values(entities)
            .filter((entity) => filterIncludeValue(filter.search, [entity.name]))
            .sort(sortEntities(`${filter.sort}`, `${filter.direction}`))
            .slice(page * filter.limit, (page + 1) * filter.limit);
    }
);
export const contractEntities = adapterSelectors.selectEntities;
export const contractById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) : undefined;
export const contractsForSelect = (state: IRootState) => getState(state).selectItems;
export const contractSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const contractCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const contractUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const contractRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = contractSlice.actions;
