import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { defaultPaging, IPaging } from '@/data/Paging';
import { detachShiftFromDay } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import { ISchedulePlanDayShiftExtendedModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { selectSchedulePlanDayShiftEntities } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { deleteShiftTrade } from '@/data/ShiftTrades/ShiftTradeActions';
import { IShiftTradeExtendedModel, IShiftTradeModel } from '@/data/ShiftTrades/ShiftTradeModels';
import {
    isActiveShiftTradeFilter,
    selectShiftTrades,
    selectShiftTradesToApprove,
    shiftTrades
} from '@/data/ShiftTrades/ShiftTradeSlice';
import { IRootState } from '@/data/store';
import {
    agreeWithOfferedShift,
    fetchShiftTradeOffersByAssignedShift,
    removeOfferedShiftTrade,
    selectOfferedShiftTrade
} from './ShiftTradeOfferActions';
import { IShiftTradeOfferExtendedModel, IShiftTradeOfferModel } from './ShiftTradeOfferModels';

type IShiftTradeOfferState = {
    paging: IPaging;
    offeredTradesStatus: IRequestState;
    selectingTradeStatus: IRequestState;
    offersForAssignedShift: Record<number, number[]>;
};

const initialState: IShiftTradeOfferState = {
    offersForAssignedShift: {},
    paging: defaultPaging('id'),
    offeredTradesStatus: 'idle',
    selectingTradeStatus: 'idle'
};

const adapter = createEntityAdapter<IShiftTradeOfferModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.created.localeCompare(b.created)
});

const filterOffers = (input: (IShiftTradeOfferModel | null)[]) =>
    input.filter((item) => typeof item?.id === 'number') as IShiftTradeOfferModel[];

const shiftTradeOfferSlice = createSlice({
    name: 'shiftTradeOffers',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
            adapter.upsertMany(
                state,
                filterOffers(
                    action.payload.schedule_plan_day_shifts.flatMap(
                        ({ schedule_plan_day_shift_trade_offers = [] }) => schedule_plan_day_shift_trade_offers
                    )
                )
            );
        });
        builder.addCase(detachShiftFromDay.fulfilled, (state, action) => {
            adapter.removeMany(
                state,
                (Object.values(state.entities) as IShiftTradeOfferModel[])
                    .filter(({ schedule_plan_day_shift_id }) => schedule_plan_day_shift_id === action.meta.arg.id)
                    .map(({ id }) => id)
            );
        });
        builder.addCase(deleteShiftTrade.fulfilled, (state, action) => {
            adapter.removeMany(
                state,
                (Object.values(state.entities) as IShiftTradeOfferModel[])
                    .filter(
                        ({ schedule_plan_day_shift_trade_id }) =>
                            schedule_plan_day_shift_trade_id === action.meta.arg.id
                    )
                    .map(({ id }) => id)
            );
        });
        builder
            .addCase(fetchShiftTradeOffersByAssignedShift.pending, (state) => {
                state.offeredTradesStatus = 'loading';
            })
            .addCase(fetchShiftTradeOffersByAssignedShift.fulfilled, (state, action) => {
                state.offeredTradesStatus = 'idle';

                const offers = action.payload.data.map(
                    ({ schedule_plan_day_shift, schedule_plan_day_shift_trade, ...item }) => item
                );

                if (offers.length) {
                    adapter.upsertMany(state, offers);
                    state.offersForAssignedShift[action.meta.arg] = offers.map(({ id }) => id);
                }
            })
            .addCase(fetchShiftTradeOffersByAssignedShift.rejected, (state) => {
                state.offeredTradesStatus = 'failed';
            })
            .addCase(selectOfferedShiftTrade.pending, (state) => {
                state.selectingTradeStatus = 'loading';
            })
            .addCase(selectOfferedShiftTrade.fulfilled, (state, action) => {
                state.selectingTradeStatus = 'idle';

                adapter.addOne(state, action.payload);
            })
            .addCase(selectOfferedShiftTrade.rejected, (state) => {
                state.selectingTradeStatus = 'failed';
            })
            .addCase(agreeWithOfferedShift.pending, (state) => {
                state.selectingTradeStatus = 'loading';
            })
            .addCase(agreeWithOfferedShift.fulfilled, (state, action) => {
                state.selectingTradeStatus = 'idle';
                if (!action.payload || !!action.payload.plan) {
                    adapter.updateOne(state, {
                        id: action.meta.arg.shiftTradeOfferId,
                        changes: {
                            state: 'ACCEPTED'
                        }
                    });
                } else {
                    adapter.removeOne(state, action.meta.arg.shiftTradeOfferId);
                }
            })
            .addCase(agreeWithOfferedShift.rejected, (state) => {
                state.selectingTradeStatus = 'failed';
            })
            .addCase(removeOfferedShiftTrade.pending, (state) => {
                state.selectingTradeStatus = 'loading';
            })
            .addCase(removeOfferedShiftTrade.fulfilled, (state, action) => {
                state.selectingTradeStatus = 'idle';

                adapter.removeMany(
                    state,
                    (Object.values(state.entities) as IShiftTradeOfferModel[])
                        .filter(
                            ({ id, schedule_plan_day_shift_trade_id }) =>
                                action.meta.arg.tradeId === schedule_plan_day_shift_trade_id &&
                                action.meta.arg.offerId === id
                        )
                        .map(({ id }) => id)
                );
            })
            .addCase(removeOfferedShiftTrade.rejected, (state) => {
                state.selectingTradeStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[shiftTradeOfferSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);
const activeFilter = (entity: IShiftTradeOfferModel) => entity.state !== 'REJECTED';
const filterBySchedulePlanDayShiftId = (entity: IShiftTradeOfferModel, search: number) =>
    entity.schedule_plan_day_shift_id === search && activeFilter(entity);

export default shiftTradeOfferSlice;

export const shiftTradeOfferById = adapterSelectors.selectById;
export const offeredShiftTradeOffersStatus = (state: IRootState) => getState(state).offeredTradesStatus;
export const shiftTradeOffersSelectingStatus = (state: IRootState) => getState(state).selectingTradeStatus;
export const selectShiftTradeOffers = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => selectShiftTrades(state),
    (state: IRootState) => selectSchedulePlanDayShiftEntities(state),
    (shiftTradeOffers, shiftTradeRecord, schedulePlanDayShifts) => {
        const result: Record<
            number,
            | (IShiftTradeOfferModel & {
                  schedule_plan_day_shift: ISchedulePlanDayShiftExtendedModel;
                  shift_trade: IShiftTradeModel & { schedule_plan_day_shift: ISchedulePlanDayShiftExtendedModel };
              })
            | undefined
        > = {};

        shiftTradeOffers.forEach((shiftTradeOffer) => {
            const shiftTrade = shiftTradeRecord[shiftTradeOffer.schedule_plan_day_shift_trade_id];
            const schedulePlanDayShift = schedulePlanDayShifts[shiftTradeOffer.schedule_plan_day_shift_id];

            if (
                shiftTrade &&
                schedulePlanDayShift &&
                schedulePlanDayShift.shift_id !== null &&
                schedulePlanDayShift.shift_end !== null &&
                schedulePlanDayShift.shift !== null &&
                typeof schedulePlanDayShift.user_id === 'number' &&
                isActiveShiftTradeFilter(shiftTrade.state)
            ) {
                result[shiftTradeOffer.id] = {
                    ...shiftTradeOffer,
                    schedule_plan_day_shift: schedulePlanDayShift as ISchedulePlanDayShiftExtendedModel,
                    shift_trade: shiftTrade
                };
            }
        });

        return result;
    }
);
export const isAnyShiftTradeOfferActive = (state: IRootState, schedulePlanDayShiftId: number) => {
    const offers = selectShiftTradeOffers(state);

    return adapterSelectors
        .selectAll(state)
        .filter((item) => filterBySchedulePlanDayShiftId(item, schedulePlanDayShiftId))
        .map((item) => offers[item.id])
        .some((item) => item && isActiveShiftTradeFilter(item.shift_trade.state));
};
/**
 * Accepted Shift Trade Offers by Trade ID.
 */
export const selectAcceptedShiftTradeOffers = createSelector(
    adapterSelectors.selectAll,
    selectShiftTradeOffers,
    (state: IRootState) => selectShiftTrades(state),
    (shiftTradeOffers, shiftTradeOfferEntities, shiftTradeEntities) => {
        const result: typeof shiftTradeOfferEntities = {};

        shiftTradeOffers
            .filter((shiftTradeOffer) => shiftTradeOffer.state === 'ACCEPTED')
            .forEach((shiftTradeOffer) => {
                const trade = shiftTradeEntities[shiftTradeOffer.schedule_plan_day_shift_trade_id];
                const entity = shiftTradeOfferEntities[shiftTradeOffer.id];

                if (entity && trade) {
                    result[shiftTradeOffer.schedule_plan_day_shift_trade_id] = {
                        ...entity,
                        shift_trade: trade
                    };
                }
            });

        return result;
    }
);
export const selectShiftTradeOfferBySchedulePlanDayIdUserToApprove2 = createSelector(
    (state: IRootState) => selectAcceptedShiftTradeOffers(state),
    (state: IRootState) => selectShiftTradesToApprove(state),
    (offers, trades) => {
        const result: Record<
            number,
            IShiftTradeOfferExtendedModel & { schedule_plan_day_shift_trade: IShiftTradeExtendedModel }
        > = {};

        Object.values(offers).forEach((offer) => {
            const trade = offer ? trades[offer.schedule_plan_day_shift_trade_id] ?? null : null;

            if (offer && trade) {
                result[offer.schedule_plan_day_shift_id] = {
                    ...offer,
                    schedule_plan_day_shift_trade: trade
                };
            }
        });

        return result;
    }
);
/**
 * Offered Shift Trade Offers by Schedule Plan Day User ID.
 */
export const selectOfferedShiftTradeOffers = createSelector(
    (state: IRootState) => getState(state).offersForAssignedShift,
    selectShiftTradeOffers,
    (offeredShiftTradeOffers, shiftTradeOfferEntities) => {
        const result: Record<
            number,
            | (IShiftTradeOfferModel & {
                  schedule_plan_day_shift: ISchedulePlanDayShiftExtendedModel;
                  shift_trade: IShiftTradeModel;
              })[]
            | undefined
        > = {};

        Object.keys(offeredShiftTradeOffers)
            .map((id) => parseInt(id))
            .forEach((schedulePlanDayShiftId) => {
                const offeredIds = offeredShiftTradeOffers[schedulePlanDayShiftId];

                result[schedulePlanDayShiftId] = offeredIds
                    .map((id) => shiftTradeOfferEntities[id])
                    .filter((item) => !!item) as (IShiftTradeOfferModel & {
                    schedule_plan_day_shift: ISchedulePlanDayShiftExtendedModel;
                    shift_trade: IShiftTradeModel;
                })[];
            });

        return result;
    }
);
export const selectActiveShiftTradeOffersByAssignedShift = createSelector(
    adapterSelectors.selectAll,
    selectShiftTradeOffers,
    (shiftTradeOffers, shiftTradeOfferEntities) => {
        const result: typeof shiftTradeOfferEntities = {};

        shiftTradeOffers.filter(activeFilter).forEach((shiftTradeOffer) => {
            result[shiftTradeOffer.schedule_plan_day_shift_id] = shiftTradeOfferEntities[shiftTradeOffer.id];
        });

        return result;
    }
);

export const selectSelectedShiftTradeOffersByAssignedShift = createSelector(
    (state: IRootState) => shiftTrades(state),
    selectAcceptedShiftTradeOffers,
    (shiftTradeList, shiftTradeOffers) => {
        const result: typeof shiftTradeOffers = {};

        shiftTradeList
            .filter(({ state }) => state === 'SELECTED')
            .forEach((shiftTrade) => {
                const offer = shiftTradeOffers[shiftTrade.id] ?? null;

                if (offer) {
                    result[shiftTrade.schedule_plan_day_shift_id] = offer;
                }
            });

        return result;
    }
);
