import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import filterIncludeValue from '@/helpers/array/filterIncludeValue';
import sortEntities from '@/helpers/array/sortEntities';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createRequestType,
    fetchRequestTypeById,
    fetchRequestTypes,
    removeTypeRequest,
    updateTypeRequest
} from './RequestTypeActions';
import { IRequestTypeModel } from './RequestTypeModels';

type IState = {
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IState = {
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IRequestTypeModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const requestTypeSlice = createSlice({
    name: 'requestTypes',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRequestTypes.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchRequestTypes.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchRequestTypes.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchRequestTypeById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchRequestTypeById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchRequestTypeById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createRequestType.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createRequestType.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createRequestType.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeTypeRequest.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeTypeRequest.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(removeTypeRequest.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateTypeRequest.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateTypeRequest.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateTypeRequest.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[requestTypeSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default requestTypeSlice;
export const isRequestTypesListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const requestPaging = (state: IRootState) => getState(state).paging;
export const selectFilteredRequestTypes = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => getState(state).paging,
    (state: IRootState) => adapterSelectors.selectTotal(state),
    (entities, filter, total) => {
        const page = Math.min(filter.page - 1, Math.floor(total / filter.limit));

        return Object.values(entities)
            .filter((entity) => filterIncludeValue(filter.search, [entity.name]))
            .sort(sortEntities(`${filter.sort}`, `${filter.direction}`))
            .slice(page * filter.limit, (page + 1) * filter.limit);
    }
);
export const requestTypeById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) : undefined;
export const requestTypeAll = (state: IRootState) => adapterSelectors.selectAll(state);
export const requestTypeSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const requestTypeCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const requestTypeUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const requestTypeRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = requestTypeSlice.actions;
