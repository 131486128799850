import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ErrorBoundary from '@/components/ErrorBoundary';
import Scheduler, { ISchedulerProps } from '@/components/modules/Scheduler/Scheduler';
import UserPermission, { Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import {
    fetchSchedulePlanById,
    findSchedulePlan,
    openSchedulePlan,
    publishPlan
} from '@/data/SchedulePlans/SchedulePlanActions';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import {
    clearNewSchedulePlanData,
    findSchedulePlanByPeriodAndWorkplace,
    newSchedulePlanData,
    selectTimeZoneOfPlan,
    stateOfPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { isSignedUserAdmin, signedUser } from '@/data/System/SystemReducer';
import ScheduleForm from '@/forms/ScheduleForm';
import DateHelper from '@/helpers/date/DateHelper';
import { IFilterChanged, scheduleStateToColor } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import ErrorsDialog from '@/modules/Scheduler/ErrorsDialog';
import LayoutOfPage, { StyledHeaderButton } from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import { mainSchedulePlans, mainSchedulePlansDetail } from '@/utils/routes';
import { serializeUser } from '@/utils/UserHelper';
import Chip from '@/wrappers/Chip';

const StyledSubtitleBox = styled(Box)`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: start;
    padding: 0;
    gap: 10px;
    margin-bottom: 12px;
    margin-top: 10px;
`;

export default function SchedulePlansDetailPage() {
    const { t } = useAppTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const paramIsNew = params.isNew ?? 'new';
    const schedulePlanId = searchParams.get('id');
    const type = searchParams.get('type') ?? 'shifts';
    const workplaceId = searchParams.get('workplaceId');
    const periodId = searchParams.get('periodId');
    const displayMode = searchParams.get('displayMode');
    const fromDate = searchParams.get('from');
    const toDate = searchParams.get('to');
    const isNew = paramIsNew === 'new';
    const parsedId = parseInt(schedulePlanId ?? '');
    const parsedPeriodId = parseInt(periodId ?? '');
    const parsedWorkplaceId = parseInt(workplaceId ?? '');
    const schedulePlan = useAppSelector((state) =>
        findSchedulePlanByPeriodAndWorkplace(state, parsedPeriodId, parsedWorkplaceId)
    );
    const timeZone =
        useAppSelector((state) => selectTimeZoneOfPlan(state, parsedId)) ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone: timeZone });
    const stateOfSchedulePlan = useAppSelector((state) => stateOfPlan(state, parsedId));
    const isClosed = stateOfSchedulePlan === SchedulePlanStateEnum.Closed;
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const signedUserData = useAppSelector(signedUser);
    const isUserToAbleOpenThePlan =
        isUserAdmin || (isClosed && signedUserData?.id === schedulePlan?.state_changed_by_id);
    const newScheduleData = useAppSelector(newSchedulePlanData);
    const goToList = useCallback(() => navigate(mainSchedulePlans()), []);
    const handleFilterChanged = useCallback(
        (changedValues: IFilterChanged) => {
            let navigateParams: Record<string, string | number> = {
                id: `${schedulePlanId ?? ''}`,
                isNew: isNew ? 'new' : 'edit',
                workplaceId: `${workplaceId || ''}`,
                periodId: `${periodId || ''}`,
                displayMode: `${displayMode || ''}`,
                from: `${fromDate || ''}`,
                to: `${toDate || ''}`,
                type: `${type || ''}`
            };

            let isChanged = false;

            changedValues.forEach((param) => {
                if (navigateParams[param.name] !== `${param.value || ''}`) {
                    navigateParams = {
                        ...navigateParams,
                        [param.name]: param.value || ''
                    };
                    isChanged = true;
                }
            });

            const changedParamNames = changedValues.map((item) => item.name) as string[];

            if (
                changedParamNames.includes('id') &&
                ((changedParamNames.includes('periodId') && !changedParamNames.includes('workplaceId')) ||
                    (!changedParamNames.includes('periodId') && changedParamNames.includes('workplaceId')))
            ) {
                delete changedParamNames[changedParamNames.indexOf('id')];
            }

            if (changedParamNames.includes('periodId')) {
                delete navigateParams.from;
                delete navigateParams.to;
                delete navigateParams.date;
            }

            if (
                !changedParamNames.includes('id') &&
                (navigateParams.periodId !== periodId || navigateParams.workplaceId !== workplaceId)
            ) {
                navigateParams.id = '';
            }

            Object.keys(navigateParams).forEach((paramName) => {
                if (!changedParamNames.includes(paramName) && !navigateParams[paramName]) {
                    delete navigateParams[paramName];
                }
            });

            if (isChanged) {
                navigate(mainSchedulePlansDetail(navigateParams));
            }
        },
        [isNew, schedulePlanId, workplaceId, periodId, displayMode, fromDate, toDate, type]
    );

    const handleSchedulePlanSelected = useCallback(
        (id: string) => {
            if (Number.isInteger(parsedWorkplaceId) && Number.isInteger(parsedWorkplaceId)) {
                handleFilterChanged([
                    { name: 'id', value: id },
                    { name: 'isNew', value: 'edit' },
                    {
                        name: 'periodId',
                        value: `${parsedPeriodId}`
                    },
                    { name: 'workplaceId', value: `${parsedWorkplaceId}` }
                ]);
            }
        },
        [parsedPeriodId, parsedWorkplaceId]
    );
    const findPlan = useCallback(
        (id: number | null) => {
            if (id) {
                dispatch(fetchSchedulePlanById(id)).then(() => {
                    handleSchedulePlanSelected(id.toString());
                });
            } else if (Number.isInteger(parsedPeriodId) && Number.isInteger(parsedWorkplaceId)) {
                dispatch(findSchedulePlan({ periodId: parsedPeriodId, workplaceId: parsedWorkplaceId }))
                    .unwrap()
                    .then((data) => {
                        if (data.length) {
                            handleSchedulePlanSelected(data[0].id.toString());
                        } else if (!isNew) {
                            handleFilterChanged([{ name: 'isNew', value: 'new' }]);
                        }
                    });
            }
        },
        [parsedId, parsedPeriodId, parsedWorkplaceId]
    );
    const handlePublishPlans = useCallback(() => {
        if (schedulePlan) {
            dispatch(publishPlan(schedulePlan.id)).unwrap().then(goToList);
        }
    }, [schedulePlan]);

    const handleOpenPlans = useCallback(() => {
        if (schedulePlan && isClosed) {
            dispatch(openSchedulePlan(schedulePlan.id));
        }
    }, [schedulePlan]);

    useEffect(() => {
        if (['new', 'edit'].includes(paramIsNew)) {
            findPlan(Number.isNaN(parsedId) ? null : parsedId);
        } else {
            goToList();
        }

        dispatch(clearNewSchedulePlanData());

        return () => {
            dispatch(clearNewSchedulePlanData());
        };
    }, []);
    useEffect(() => {
        if (!schedulePlanId) {
            findPlan(null);
        }
    }, [workplaceId, periodId, schedulePlanId]);

    const isValid = newScheduleData.abbreviation !== '';

    const chipTitle = useMemo(() => {
        switch (stateOfSchedulePlan) {
            case SchedulePlanStateEnum.Error:
                return schedulePlan?.message;
            default:
                if (schedulePlan?.state_changed_at && schedulePlan?.state_changed_by_user) {
                    return t('message.info.stateWasChangeAtBy', 'State was change at {{time}} by: {{user}}', {
                        time: dateTimeFormatter.format(
                            DateHelper.fromDateTimeString(schedulePlan.state_changed_at).toDate()
                        ),
                        user: serializeUser(schedulePlan.state_changed_by_user)
                    });
                } else if (schedulePlan?.state_changed_at) {
                    return t('message.info.stateWasChangeAtBySystem', 'State was change at {{time}} by system', {
                        time: dateTimeFormatter.format(
                            DateHelper.fromDateTimeString(schedulePlan.state_changed_at).toDate()
                        )
                    });
                } else {
                    return '';
                }
        }
    }, [
        stateOfSchedulePlan,
        schedulePlan?.message,
        schedulePlan?.state_changed_by_user,
        schedulePlan?.state_changed_at,
        timeZone
    ]);

    return (
        <LayoutOfPage
            fullHeight={true}
            title={
                <StyledSubtitleBox>
                    {schedulePlan?.name ?? newScheduleData.name}{' '}
                    {schedulePlan && (
                        <Chip
                            variant="outlined"
                            color={scheduleStateToColor(schedulePlan.state, schedulePlan.optimal)}
                            name={`state-${schedulePlan.id}`}
                            label={schedulePlan.state}
                            data-testid="schedulePlanState"
                            title={chipTitle}
                            data-testlocked={schedulePlan.state === 'Locked'}
                        />
                    )}
                    {parsedId ? <ErrorsDialog schedulePlanId={parsedId} /> : <></>}
                </StyledSubtitleBox>
            }
            actionBack={{
                size: 'medium',
                display: true,
                onClick: goToList
            }}
            actionButtonsBeforeSave={[
                ...(!isClosed
                    ? [
                          <ScheduleForm
                              key="schedule-form"
                              displayAsSidebar
                              id={schedulePlan?.id}
                              periodId={parsedPeriodId}
                              workplaceId={parsedWorkplaceId}
                              isSchedulePlanClosed={isClosed}
                              onSuccess={() => handleSchedulePlanSelected(schedulePlan?.id?.toString() ?? '')}
                          />
                      ]
                    : []),
                ...(isClosed && isUserToAbleOpenThePlan
                    ? [
                          <UserPermission key="open" id={PermissionsEnum.SchedulePlans} mode={Mode.UPDATE}>
                              <StyledHeaderButton
                                  name="open"
                                  color="info"
                                  variant="contained"
                                  onClick={handleOpenPlans}
                              >
                                  {t('label.open', 'Open')}
                              </StyledHeaderButton>
                          </UserPermission>
                      ]
                    : [])
            ]}
            actionSave={{
                display: isNew,
                disabled: !isValid,
                onClick: () => {}
            }}
            actionButtonsAfterSave={
                !isNew && !isClosed
                    ? [
                          <UserPermission key="publish" id={PermissionsEnum.SchedulePlans} mode={Mode.UPDATE}>
                              <StyledHeaderButton
                                  name="publish"
                                  color="success"
                                  variant="contained"
                                  onClick={handlePublishPlans}
                              >
                                  {t('label.publish', 'Publish')}
                              </StyledHeaderButton>
                          </UserPermission>
                      ]
                    : []
            }
        >
            <ErrorBoundary>
                <Scheduler
                    isSchedulePlanClosed={isClosed}
                    isNew={isNew}
                    type={!isNew ? (type as ISchedulerProps['type']) : 'shifts'}
                    schedulePlanId={parsedId ?? undefined}
                    canCalculate={isValid}
                    displayMode={
                        [
                            SchedulePlanWidthTypeEnum.Day,
                            SchedulePlanWidthTypeEnum.Week,
                            SchedulePlanWidthTypeEnum.Period,
                            SchedulePlanWidthTypeEnum.Custom
                        ].includes((displayMode as SchedulePlanWidthTypeEnum) || '')
                            ? (displayMode as ISchedulerProps['displayMode'])
                            : undefined
                    }
                    workplaceId={parseInt(workplaceId || '') || undefined}
                    periodId={parseInt(periodId || '') || undefined}
                    fromDate={fromDate}
                    toDate={toDate}
                    onFilterChanged={handleFilterChanged}
                    onSchedulePlanCreated={handleSchedulePlanSelected}
                />
            </ErrorBoundary>
        </LayoutOfPage>
    );
}
