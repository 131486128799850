import { AlertColor } from '@mui/material/Alert';

const getResponseStatusVariant = (code?: number): AlertColor | undefined => {
    if (!code) {
        return undefined;
    }

    switch (Math.floor(code / 100)) {
        case 2: {
            return 'success';
        }
        case 4: {
            return 'warning';
        }
        case 5: {
            return 'error';
        }
        default: {
            return 'info';
        }
    }
};

export default getResponseStatusVariant;
