import CrudDatatable from '@/components/CrudDatatable';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { fetchBreaks, removeBreak, updateBreak } from '@/data/Breaks/BreakActions';
import { IBreakModel } from '@/data/Breaks/BreakModels';
import {
    breakPaging,
    breakRemovingStatus,
    isBreaksListInProgress,
    selectFilteredBreaks
} from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { getPermissionsList } from '@/data/System/SystemReducer';
import BreakForm from '@/forms/BreakForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Switch from '@/wrappers/Switch';

export default function BreaksListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const inProgress = useAppSelector(isBreaksListInProgress);
    const data = useAppSelector(selectFilteredBreaks);
    const permissionsList = useAppSelector(getPermissionsList);
    const isUserToAllowedUpdateOrCreate = isUserAllowed(
        {
            id: PermissionsEnum.Breaks,
            mode: [Mode.CREATE, Mode.UPDATE],
            operator: 'OR'
        },
        permissionsList
    );

    return (
        <LayoutOfPage title={t('title.breaksList', 'Breaks List')}>
            <CrudDatatable<IBreakModel>
                name="break"
                resource={PermissionsEnum.Breaks}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <BreakForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { title: t('header.breakName', 'Break Name'), field: 'name' },
                    {
                        title: t('header.duration', 'Duration'),
                        field: 'duration',
                        type: 'datetime',
                        render: (rowData: IBreakModel) => DateHelper.getMinutesInHumanFormat(rowData.duration)
                    },
                    {
                        title: t('header.active', 'Active'),
                        field: 'active',
                        sorting: false,
                        render: (item: IBreakModel) => (
                            <Switch
                                name={item.name}
                                value={item.active}
                                title={
                                    isUserToAllowedUpdateOrCreate
                                        ? item.active
                                            ? t('label.deactivate', 'Deactivate')
                                            : t('label.activate', 'Activate')
                                        : undefined
                                }
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: item.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        title: t('header.paid', 'Paid'),
                        field: 'paid',
                        sorting: false,
                        render: (rowData: IBreakModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.paid}
                                title={
                                    isUserToAllowedUpdateOrCreate
                                        ? rowData.paid
                                            ? t('label.setUnpaid', 'Set Unpaid')
                                            : t('label.setPaid', 'Set Paid')
                                        : undefined
                                }
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: rowData.id,
                                            data: {
                                                paid: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        title: t('header.mandatory', 'Mandatory'),
                        field: 'required',
                        sorting: false,
                        render: (rowData: IBreakModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.required}
                                title={
                                    isUserToAllowedUpdateOrCreate
                                        ? rowData.paid
                                            ? t('label.setOptional', 'Set Optional')
                                            : t('label.setMandatory', 'Set Mandatory')
                                        : undefined
                                }
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: rowData.id,
                                            data: {
                                                required: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(breakPaging)}
                removingStatus={useAppSelector(breakRemovingStatus)}
                onFetchList={fetchBreaks}
                onRemove={removeBreak}
            />
        </LayoutOfPage>
    );
}
