import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { updatePaging } from '@/data/Breaks/BreakSlice';
import { createCallCenter, updateCallCenter } from '@/data/CallCenters/CallCenterActions';
import { ICallCenterModel } from '@/data/CallCenters/CallCenterModels';
import {
    callCenterById,
    callCenterCreatingStatus,
    callCenterPaging,
    callCenterUpdatingStatus
} from '@/data/CallCenters/CallCenterSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Tooltip from '@/wrappers/Tooltip';

type IProps = Omit<ICuProps<ICallCenterModel>, 'resource'>;

const handleData = (values: IOutputValueType) => ({
    name: values.name as string,
    url: values.url as string,
    token: values.token as string
});

const CallCenterForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => callCenterById(state, id));
    const paging = useAppSelector(callCenterPaging);

    const handleCreate = useCallback((values: IOutputValueType) => createCallCenter(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateCallCenter({ id: id!, data: handleData(values) }),
        [id]
    );

    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    const hasToken = !!loadedData?.token;

    return (
        <CuForm
            {...props}
            id={id}
            name="callCenter"
            resource={PermissionsEnum.CallCenters}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            maxWidth="lg"
            items={[
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'url',
                        label: t('label.url', 'URL'),
                        value: loadedData?.url
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        required: true,
                        color: hasToken ? 'warning' : undefined,
                        name: 'token',
                        label: t('label.token', 'Token'),
                        value: loadedData?.token,
                        InputProps: {
                            endAdornment: hasToken ? (
                                <InputAdornment position="start">
                                    <Tooltip
                                        color="primary"
                                        title={t(
                                            'message.info.encryptedValue',
                                            'The value in the field is encrypted and therefore cannot be copied and reused. If the value needs to be modified, it needs to be saved entirely again.'
                                        )}
                                    >
                                        <ContentPasteOffOutlinedIcon />
                                    </Tooltip>
                                </InputAdornment>
                            ) : undefined
                        }
                    }
                }
            ]}
            updatingStatus={useAppSelector(callCenterUpdatingStatus)}
            creatingStatus={useAppSelector(callCenterCreatingStatus)}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default CallCenterForm;
