import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Control, Controller, UseFormClearErrors, UseFormGetFieldState, UseFormRegister } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { message } from '@/utils/validations';
import Abbreviation, { IAbbreviationValue } from '@/wrappers/Abbreviation';
import BottomNavigationPicker from '@/wrappers/BottomNavigationPicker';
import Button from '@/wrappers/Button/Button';
import Checkbox from '@/wrappers/Checkbox/Checkbox';
import ColorPicker from '@/wrappers/ColorPicker';
import DatePicker from '@/wrappers/DatePicker';
import DateRangePicker, { IDateRangePickerProps } from '@/wrappers/DateRangePicker';
import DateTimeRangePicker from '@/wrappers/DateTimeRangePicker';
import IconPicker from '@/wrappers/IconPicker';
import LoadingButton from '@/wrappers/LoadingButton';
import MultiSelect from '@/wrappers/MultiSelect';
import Password from '@/wrappers/Password';
import Select from '@/wrappers/Select';
import Slider from '@/wrappers/Slider';
import Switch from '@/wrappers/Switch';
import TextField from '@/wrappers/TextField';
import TimePicker from '@/wrappers/TimePicker';
import TimeRangeOrTimeAndLength from '@/wrappers/TimeRangeOrTimeAndLength';
import TimeRangePicker from '@/wrappers/TimeRangePicker';
import TimezoneSelect from '@/wrappers/TimezoneSelect';
import Tooltip from '@/wrappers/Tooltip';
import TransferList from '@/wrappers/TransferList';
import WeekdaysPicker from '@/wrappers/WeekdaysPicker';
import { ISimpleFieldTypes } from './FieldTypes';
import FieldWrapper from './FieldWrapper';
import {
    callableValidationOfRequired,
    getContentByFiledName,
    getErrorByFieldName,
    getOptions,
    isRequired,
    ISupportedValueType
} from './utils';

export type ISimpleFieldRendererProps = {
    hidden?: boolean;
    control: Control;
    disabled?: boolean;
    register: UseFormRegister<FieldValues>;
    getFieldState: UseFormGetFieldState<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    initialValues: ISupportedValueType;
    watch: UseFormWatch<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
    shouldUnregister?: boolean;
    fullName: string;
    fullWidth: boolean;
    input: ISimpleFieldTypes;
    /** Used only by {@link MultiRowGenerator}. */
    parentName?: string;
    readOnly?: boolean;
    /** Used only by {@link MultiRowGenerator}. */
    rowIndex?: number;
};

const SimpleFieldRenderer = ({
    clearErrors,
    control,
    register,
    getFieldState,
    getValues,
    initialValues,
    watch,
    setValue,
    fullName,
    fullWidth,
    input,
    shouldUnregister = true,
    hidden = false,
    parentName,
    readOnly,
    rowIndex
}: ISimpleFieldRendererProps) => {
    switch (input.type) {
        case 'abbreviation': {
            return (
                <FieldWrapper type={input.type} isDisplayed={true} fullWidth={fullWidth} width={input.props.width}>
                    <Abbreviation
                        {...input.props}
                        control={control}
                        currentBackgroundColor={watch(`${fullName}.background`)}
                        currentColor={watch(`${fullName}.color`)}
                        name={fullName}
                        readOnly={input.props.readOnly || readOnly}
                        value={getContentByFiledName(fullName, initialValues) as IAbbreviationValue}
                    />
                </FieldWrapper>
            );
        }
        case 'button':
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation
                    }}
                    render={() => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;

                        const button = (
                            <Button
                                data-testid={fullName}
                                {...input.props}
                                disabled={
                                    input.props.readOnlyNoEffect
                                        ? input.props.disabled
                                        : input.props.disabled || readOnly
                                }
                                name={fullName}
                            />
                        );

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                                sx={input.props.sx}
                            >
                                {input.props.title ? (
                                    <Tooltip color="primary" title={input.props.title}>
                                        {button}
                                    </Tooltip>
                                ) : (
                                    button
                                )}
                            </FieldWrapper>
                        );
                    }}
                />
            );
        case 'custom': {
            const shouldDisplay = input.display ? input.display(watch()) : true;

            return (
                <Box hidden={!shouldDisplay} sx={{ width: '100%' }}>
                    {input.props.render({
                        clearErrors,
                        control,
                        register,
                        initialValues,
                        getFieldState,
                        getValues,
                        setValue,
                        watch,
                        fullName,
                        parentName,
                        readOnly,
                        rowIndex,
                        disabled: !shouldDisplay
                    })}
                </Box>
            );
        }
        case 'colorPicker': {
            return (
                <FieldWrapper type={input.type} isDisplayed={true} fullWidth={fullWidth} width={input.props.width}>
                    <ColorPicker
                        {...input.props}
                        control={control}
                        data-testid={`${fullName}-colorPicker`}
                        readOnly={input.props.readOnly || readOnly}
                    />
                </FieldWrapper>
            );
        }
        case 'checkBox': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{ ...input.props.validation }}
                    render={({ field }) => (
                        <FormControlLabel
                            label={input.props.label}
                            control={
                                <Checkbox {...input.props} {...field} readOnly={input.props.readOnly || readOnly} />
                            }
                        />
                    )}
                />
            );
        }
        case 'date': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: input.props.required,
                                shouldDisplay: !hidden,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const error = getErrorByFieldName(errors, field.name);
                        const isDisplayed = input.display ? input.display(getValues()) : true;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <DatePicker
                                    {...input.props}
                                    {...field}
                                    required={isRequired(
                                        getValues,
                                        input.props.required,
                                        undefined,
                                        undefined,
                                        rowIndex
                                    )}
                                    readOnly={input.props.readOnly || readOnly}
                                    inputFormat={input.props.inputFormat}
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                    onChange={(newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(newValue);
                                        }

                                        field.onChange(newValue);
                                    }}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'dateRange': {
            const { name: fieldNameStart } = register(`${fullName}.start`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.start`],
                required: isRequired(getValues, input.props.required, undefined, undefined, rowIndex)
                    ? message.required
                    : undefined,
                shouldUnregister: shouldUnregister
            });
            const { name: fieldNameEnd } = register(`${fullName}.end`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.end`],
                required:
                    isRequired(getValues, input.props.required, undefined, undefined, rowIndex) &&
                    input.props.optionalEnd !== true
                        ? message.required
                        : undefined,
                shouldUnregister: shouldUnregister
            });

            const isDisplayed = input.display ? input.display(getValues()) : true;
            const startFieldState = getFieldState(fieldNameStart);
            const endFieldState = getFieldState(fieldNameEnd);

            const hasStartError = !!startFieldState.error;
            const hasEndError = !!endFieldState.error;

            const watchStart = watch(fieldNameStart);
            const watchEnd = watch(fieldNameEnd);
            const initialDatePickerValue = getContentByFiledName(
                fullName,
                initialValues
            ) as IDateRangePickerProps['value'];

            return (
                <FieldWrapper
                    type="dateRange"
                    fullWidth={fullWidth}
                    width={input.props.width}
                    isDisplayed={isDisplayed}
                >
                    <DateRangePicker
                        {...input.props}
                        required={isRequired(getValues, input.props.required, undefined, undefined, rowIndex)}
                        error={{ start: hasStartError, end: hasEndError }}
                        helperText={{
                            start: `${
                                (hasStartError ? startFieldState.error?.message : input.props.helperText?.start) ?? ''
                            }`,
                            end: `${(hasEndError ? endFieldState.error?.message : input.props.helperText?.end) ?? ''}`
                        }}
                        name={fullName}
                        hidden={hidden}
                        value={{
                            start:
                                watchStart || watchStart === null ? watchStart : initialDatePickerValue?.start ?? null,
                            end: watchEnd || watchEnd === null ? watchEnd : initialDatePickerValue?.end ?? null
                        }}
                        inputFormat={input.props.inputFormat}
                        readOnly={input.props.readOnly || readOnly}
                        onChange={(value) => {
                            setValue(fieldNameStart, value.start);
                            setValue(fieldNameEnd, value.end);
                        }}
                    />
                </FieldWrapper>
            );
        }
        case 'dateTimeRange': {
            const { name: fieldNameStart } = register(`${fullName}.start`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.start`],
                required: isRequired(getValues, input.props.required, undefined, undefined, rowIndex)
                    ? message.required
                    : undefined,
                shouldUnregister: shouldUnregister
            });
            const { name: fieldNameEnd } = register(`${fullName}.end`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.end`],
                required:
                    isRequired(getValues, input.props.required, undefined, undefined, rowIndex) &&
                    input.props.optionalEnd !== true
                        ? message.required
                        : undefined,
                shouldUnregister: shouldUnregister
            });

            const isDisplayed = input.display ? input.display(getValues()) : true;
            const startFieldState = getFieldState(fieldNameStart);
            const endFieldState = getFieldState(fieldNameEnd);

            const hasStartError = !!startFieldState.error;
            const hasEndError = !!endFieldState.error;

            return (
                <FieldWrapper
                    type="dateRange"
                    fullWidth={fullWidth}
                    width={input.props.width}
                    isDisplayed={isDisplayed}
                >
                    <DateTimeRangePicker
                        {...input.props}
                        required={isRequired(getValues, input.props.required, undefined, undefined, rowIndex)}
                        error={{ start: hasStartError, end: hasEndError }}
                        helperText={{
                            start: `${
                                (hasStartError ? startFieldState.error?.message : input.props.helperText?.start) ?? ''
                            }`,
                            end: `${(hasEndError ? endFieldState.error?.message : input.props.helperText?.end) ?? ''}`
                        }}
                        name={fullName}
                        hidden={hidden}
                        value={{
                            start:
                                watch(fieldNameStart) ??
                                (getContentByFiledName(fullName, initialValues) as IDateRangePickerProps['value'])
                                    ?.start ??
                                null,
                            end:
                                watch(fieldNameEnd) ??
                                (getContentByFiledName(fullName, initialValues) as IDateRangePickerProps['value'])
                                    ?.end ??
                                null
                        }}
                        readOnly={input.props.readOnly || readOnly}
                        onChange={(value) => {
                            if (input.props.onChange) {
                                input.props.onChange(value);
                            }

                            setValue(fieldNameStart, value.start);
                            setValue(fieldNameEnd, value.end);
                        }}
                    />
                </FieldWrapper>
            );
        }
        case 'weekdays': {
            return (
                <Controller
                    control={control}
                    name={fullName}
                    shouldUnregister
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={input.props.validation}
                    render={({ field }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <WeekdaysPicker
                                    {...input.props}
                                    name={field.name}
                                    onChange={field.onChange}
                                    readOnly={input.props.readOnly || readOnly}
                                    value={
                                        field.value ??
                                        (getContentByFiledName(fullName, initialValues)
                                            ? +(getContentByFiledName(fullName, initialValues) as number)
                                            : undefined)
                                    }
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'html': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister
                    render={() => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                {input.props.render()}
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'iconPicker': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{ ...input.props.validation, required: input.props.required ? message.required : undefined }}
                    render={({ field, formState: { errors } }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const error = getErrorByFieldName(errors, field.name);

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <IconPicker
                                    {...input.props}
                                    {...field}
                                    readOnly={input.props.readOnly || readOnly}
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'bottomNavigation': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation
                    }}
                    render={({ field }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const isDisabled =
                            typeof input.props.disabled === 'function'
                                ? input.props.disabled(getValues(), rowIndex ?? null)
                                : Boolean(input.props.disabled ?? false);
                        const showLabels = input.showLabels ? input.showLabels : true;

                        return (
                            <FieldWrapper
                                type="loadingButton"
                                fullWidth={fullWidth}
                                width={input.props.width}
                                isDisplayed={isDisplayed}
                            >
                                <BottomNavigationPicker
                                    {...input.props}
                                    {...field}
                                    showLabels={showLabels}
                                    disabled={isDisabled || readOnly}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'loadingButton':
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation
                    }}
                    render={() => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const isDisabled =
                            typeof input.props.disabled === 'function'
                                ? input.props.disabled(getValues(), rowIndex ?? null)
                                : Boolean(input.props.disabled ?? false);

                        return (
                            <FieldWrapper
                                type="loadingButton"
                                fullWidth={fullWidth}
                                width={input.props.width}
                                isDisplayed={isDisplayed}
                            >
                                <LoadingButton
                                    {...input.props}
                                    disabled={input.props.readOnlyNoEffect ? isDisabled : isDisabled || readOnly}
                                    name={fullName}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        //     case 'multiChipSelect': {
        //         return (
        //             <MultiChipSelect
        //                 {...input.propsSelect}
        //                 name={fullName}
        //                 label={input.props.label}
        //                 disabled={input.props.disabled}
        //                 readOnly={input.props.readOnly || readOnly}
        //                 values={((values[fullName] || []) as string[]).map((value) => input.propsChip.valuesMapping(value))}
        //                 options={
        //                     typeof input.propsSelect.options === 'function'
        //                         ? input.propsSelect.options(transformedValues)
        //                         : input.propsSelect.options
        //                 }
        //                 onAdd={(id) => {
        //                     if (id) {
        //                         // handleUpdateField(fullName, (current = []) => [
        //                         //     ...(current as string[]).filter((currentId) => currentId !== id),
        //                         //     id
        //                         // ]);
        //                     }
        //                 }}
        //                 onRemove={(id) => {
        //                     // handleUpdateField(fullName, (current) =>
        //                     //     (current as string[]).filter((currentId) => currentId !== id)
        //                     // );
        //                 }}
        //             />
        //         );
        //     }
        case 'multiSelect': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: input.props.required,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const error = getErrorByFieldName(errors, field.name);
                        const isDisplayed = input.display ? input.display(getValues()) : true;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <MultiSelect
                                    data-testid={`multiselect-${field.name}`}
                                    {...input.props}
                                    {...field}
                                    onChange={(newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(newValue);
                                        }

                                        field.onChange(newValue);
                                    }}
                                    label={input.props.label}
                                    disabled={input.props.disabled}
                                    required={isRequired(
                                        getValues,
                                        input.props.required,
                                        input.display,
                                        input.props.disabled,
                                        rowIndex
                                    )}
                                    readOnly={input.props.readOnly || readOnly}
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'newLine':
            return <></>;
        case 'password': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: hidden ? false : input.props.required,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const error = getErrorByFieldName(errors, field.name);

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <Password
                                    {...input.props}
                                    {...field}
                                    required={
                                        !hidden &&
                                        isRequired(
                                            getValues,
                                            input.props.required,
                                            isDisplayed,
                                            input.props.disabled,
                                            rowIndex
                                        )
                                    }
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'timezone': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: hidden ? false : input.props.required,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const error = getErrorByFieldName(errors, field.name);
                        const value =
                            typeof input.props.value === 'function'
                                ? input.props.value(getValues() ?? {}, rowIndex ?? null)
                                : field.value;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <TimezoneSelect
                                    {...input.props}
                                    {...field}
                                    value={value}
                                    onChange={(newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(newValue ?? null);
                                        }

                                        field.onChange(newValue);
                                    }}
                                    readOnly={input.props.readOnly || readOnly}
                                    placeholder={input.props.placeholder}
                                    required={
                                        !hidden &&
                                        isRequired(
                                            getValues,
                                            input.props.required,
                                            isDisplayed,
                                            input.props.disabled,
                                            rowIndex
                                        )
                                    }
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'select': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: hidden ? false : input.props.required,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const error = getErrorByFieldName(errors, field.name);
                        const value =
                            typeof input.props.value === 'function'
                                ? input.props.value(getValues() ?? {}, rowIndex ?? null)
                                : field.value;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <Select
                                    {...input.props}
                                    {...field}
                                    value={value}
                                    options={getOptions(getValues, input.props.options, rowIndex)}
                                    onChange={(newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(newValue);
                                        }

                                        field.onChange(newValue);
                                    }}
                                    readOnly={input.props.readOnly || readOnly}
                                    placeholder={input.props.placeholder}
                                    required={
                                        !hidden &&
                                        isRequired(
                                            getValues,
                                            input.props.required,
                                            isDisplayed,
                                            input.props.disabled,
                                            rowIndex
                                        )
                                    }
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'slider': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: !hidden,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field }) => {
                        const currentValues =
                            typeof input.props.disabled === 'function' || typeof input.display === 'function'
                                ? getValues()
                                : {};
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const disabled =
                            typeof input.props.disabled === 'function'
                                ? input.props.disabled(currentValues, rowIndex ?? null)
                                : input.props.disabled;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <Slider
                                    {...input.props}
                                    {...field}
                                    disabled={disabled}
                                    onChange={(event, newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(event, newValue);
                                        }

                                        field.onChange(newValue);
                                    }}
                                    readOnly={input.props.readOnly || readOnly}
                                    placeholder={input.props.placeholder}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'switch': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation
                    }}
                    render={({ field }) => {
                        const currentValues =
                            typeof input.props.disabled === 'function' || typeof input.display === 'function'
                                ? getValues()
                                : {};

                        const isDisplayed = input.display
                            ? input.display((parentName ? getValues(parentName) : getValues()) ?? {})
                            : true;

                        const disabled =
                            typeof input.props.disabled === 'function'
                                ? input.props.disabled(currentValues, rowIndex ?? null)
                                : input.props.disabled;

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <Switch
                                    {...input.props}
                                    {...field}
                                    disabled={disabled}
                                    readOnly={input.props.readOnly || readOnly}
                                    onChange={(newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(newValue);
                                        }

                                        field.onChange(newValue);
                                    }}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'time': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...input.props.validation,
                        required: input.props.required ? message.required : undefined
                    }}
                    render={({ field, formState: { errors } }) => {
                        const error = getErrorByFieldName(errors, field.name);
                        const currentValues =
                            typeof input.props.disabled === 'function' || typeof input.display === 'function'
                                ? (parentName ? getValues(parentName) : getValues()) ?? {}
                                : {};

                        const disabled =
                            typeof input.props.disabled === 'function'
                                ? input.props.disabled(currentValues, rowIndex ?? null)
                                : input.props.disabled;

                        return (
                            <FieldWrapper type={input.type} isDisplayed fullWidth={fullWidth} width={input.props.width}>
                                <TimePicker
                                    {...input.props}
                                    {...field}
                                    value={
                                        typeof input.props.value === 'function'
                                            ? input.props.value(
                                                  (parentName ? getValues(parentName) : getValues()) ?? {},
                                                  rowIndex ?? null
                                              )
                                            : field.value
                                    }
                                    minTime={
                                        typeof input.props.minTime === 'function'
                                            ? input.props.minTime(
                                                  (parentName ? getValues(parentName) : getValues()) ?? {},
                                                  rowIndex ?? null
                                              )
                                            : input.props.minTime
                                    }
                                    maxTime={
                                        typeof input.props.maxTime === 'function'
                                            ? input.props.maxTime(
                                                  (parentName ? getValues(parentName) : getValues()) ?? {},
                                                  rowIndex ?? null
                                              )
                                            : input.props.maxTime
                                    }
                                    disabled={disabled}
                                    required={isRequired(
                                        getValues,
                                        input.props.required,
                                        undefined,
                                        undefined,
                                        rowIndex
                                    )}
                                    readOnly={input.props.readOnly || readOnly}
                                    inputFormat={input.props.inputFormat}
                                    error={!!error}
                                    helperText={error?.message || input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'timeRange': {
            const { name: fieldNameStart } = register(`${fullName}.start`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.start`],
                required: input.props.required ? message.required : undefined,
                shouldUnregister: shouldUnregister
            });
            const { name: fieldNameDuration } = register(`${fullName}.duration`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.duration`],
                required: input.props.required ? message.required : undefined,
                shouldUnregister: shouldUnregister
            });

            const startFieldState = getFieldState(fieldNameStart);
            const endFieldState = getFieldState(fieldNameDuration);

            const hasStartError = !!startFieldState.error;
            const hasEndError = !!endFieldState.error;

            return (
                <FieldWrapper type={input.type} fullWidth={fullWidth} isDisplayed width={input.props.width}>
                    <TimeRangePicker
                        {...input.props}
                        error={{ start: hasStartError, end: hasEndError }}
                        helperText={{
                            start: `${
                                (hasStartError ? startFieldState.error?.message : input.props.helperText?.start) ?? ''
                            }`,
                            end: `${(hasEndError ? endFieldState.error?.message : input.props.helperText?.end) ?? ''}`
                        }}
                        name={fullName}
                        value={{
                            start: watch(fieldNameStart),
                            duration: watch(fieldNameDuration) ?? 0
                        }}
                        inputFormat={input.props.inputFormat}
                        readOnly={input.props.readOnly || readOnly}
                        onChange={(newValue) => {
                            clearErrors([fieldNameStart, fieldNameDuration]);
                            setValue(fieldNameStart, newValue.start);
                            setValue(fieldNameDuration, newValue.duration);
                        }}
                    />
                </FieldWrapper>
            );
        }
        case 'timeRangeOrTimeAndLength': {
            const { name: fieldNameTime } = register(`${fullName}.start`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.start`],
                required: input.props.required ? message.required : undefined,
                shouldUnregister: shouldUnregister
            });
            const { name: fieldNameDuration } = register(`${fullName}.duration`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.duration`],
                required: input.props.required ? message.required : undefined,
                shouldUnregister: shouldUnregister
            });
            const { name: fieldNameIsRange } = register(`${fullName}.isRange`, {
                disabled: readOnly,
                value: initialValues[`${fullName}.isRange`],
                shouldUnregister: shouldUnregister
            });

            const startFieldState = getFieldState(fieldNameTime);
            const endFieldState = getFieldState(fieldNameDuration);

            const hasStartError = !!startFieldState.error;
            const hasDurationError = !!endFieldState.error;

            const values = getValues(fullName);

            return (
                <FieldWrapper type={input.type} isDisplayed fullWidth={fullWidth} width={input.props.width}>
                    <TimeRangeOrTimeAndLength
                        {...input.props}
                        required={isRequired(getValues, input.props.required, undefined, undefined, rowIndex)}
                        name={fullName}
                        readOnly={input.props.readOnly || readOnly}
                        value={values ?? getContentByFiledName(fullName, initialValues)}
                        error={{ time: hasStartError, duration: hasDurationError }}
                        helperText={{
                            time: `${
                                (hasStartError ? startFieldState.error?.message : input.props.helperText?.time) ?? ''
                            }`,
                            duration: `${
                                (hasDurationError ? endFieldState.error?.message : input.props.helperText?.duration) ??
                                ''
                            }`
                        }}
                        minStart={
                            typeof input.props.minStart === 'function'
                                ? input.props.minStart(getValues(), rowIndex ?? null)
                                : input.props.minStart
                        }
                        maxDuration={
                            typeof input.props.maxDuration === 'function'
                                ? input.props.maxDuration(getValues(), rowIndex ?? null)
                                : input.props.maxDuration
                        }
                        onChange={(newValue) => {
                            clearErrors([fieldNameTime, fieldNameDuration]);
                            setValue(fieldNameTime, newValue.start);
                            setValue(fieldNameDuration, newValue.duration);
                            setValue(fieldNameIsRange, newValue.isRange);
                        }}
                    />
                </FieldWrapper>
            );
        }
        case 'textArea': {
            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues) ?? ''}
                    rules={{
                        ...input.props.validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: hidden ? false : input.props.required,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                                // required: isRequired(getValues, input.props.required) ? message.required : undefined
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const isDisplayed = input.display ? input.display(getValues()) : true;
                        const error = getErrorByFieldName(errors, field.name);

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={input.props.width}
                            >
                                <TextField
                                    {...input.props}
                                    {...field}
                                    required={isRequired(
                                        getValues,
                                        input.props.required,
                                        undefined,
                                        undefined,
                                        rowIndex
                                    )}
                                    value={field.value ?? ''}
                                    multiline
                                    data-testid={`text_area_field_${fullName}`}
                                    minRows={input.props.rows || input.props.minRows || 3}
                                    readOnly={input.props.readOnly || readOnly}
                                    error={!!error}
                                    helperText={error ? error.message : input.props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'textField': {
            const { sx, validation, width, ...props } = input.props;

            return (
                <Controller
                    name={fullName}
                    control={control}
                    shouldUnregister={shouldUnregister}
                    defaultValue={getContentByFiledName(fullName, initialValues)}
                    rules={{
                        ...validation,
                        validate: (currentValue) =>
                            callableValidationOfRequired({
                                currentValue,
                                getValues,
                                required: hidden ? false : input.props.required,
                                shouldDisplay: input.display,
                                shouldBeDisabled: input.props.disabled,
                                rowIndex
                            })
                    }}
                    render={({ field, formState: { errors } }) => {
                        const currentValues =
                            typeof props.disabled === 'function' || typeof input.display === 'function'
                                ? parentName
                                    ? getValues(parentName)
                                    : getValues()
                                : {};
                        const isDisplayed = input.display ? input.display(currentValues) : true;
                        const disabled =
                            typeof props.disabled === 'function'
                                ? props.disabled(currentValues, rowIndex ?? null)
                                : props.disabled;
                        const error = getErrorByFieldName(errors, field.name);

                        return (
                            <FieldWrapper
                                type={input.type}
                                isDisplayed={isDisplayed}
                                fullWidth={fullWidth}
                                width={width}
                                sx={sx}
                            >
                                <TextField
                                    data-testid={`text_field_${fullName}`}
                                    {...props}
                                    {...field}
                                    onChange={(event, newValue) => {
                                        if (input.props.onChange) {
                                            input.props.onChange(event, newValue);
                                        }

                                        field.onChange(newValue);
                                    }}
                                    disabled={disabled}
                                    readOnly={props.readOnly || readOnly}
                                    required={
                                        !hidden &&
                                        isRequired(getValues, input.props.required, isDisplayed, disabled, rowIndex)
                                    }
                                    error={!!error}
                                    helperText={error ? error.message : props.helperText}
                                />
                            </FieldWrapper>
                        );
                    }}
                />
            );
        }
        case 'transferList': {
            const { name: fieldName } = register(fullName, {
                disabled: readOnly,
                value: getContentByFiledName(fullName, initialValues) ?? [],
                shouldUnregister: true
            });
            const isDisplayed = input.display ? input.display(getValues()) : true;

            return (
                <FieldWrapper
                    type={'multiRowInputs'}
                    isDisplayed={isDisplayed}
                    fullWidth={fullWidth}
                    width={input.props.width}
                >
                    <TransferList
                        {...input.props}
                        values={(getContentByFiledName(fullName, initialValues) as string[]) ?? []}
                        onChange={(newValue) => {
                            setValue(fieldName, newValue);

                            if (input.props.onChange) {
                                input.props.onChange(newValue);
                            }
                        }}
                    />
                </FieldWrapper>
            );
        }
        default:
            console.error(input);
            new Error('Not supported');
    }

    return <></>;
};

export default SimpleFieldRenderer;
