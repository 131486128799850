import { Action, configureStore, PreloadedState, Reducer, ThunkAction } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { logout } from '@/data/System/SystemActions';
import handleErrorMiddleware from './handleErrorMiddleware';
import persist from './persist';
import reducer from './reducer';

const persistConfig = {
    key: '3793e2860a819bff34a08edfc0c3833a',
    version: 1,
    storage,
    whitelist: persist,
    debug: process.env.NODE_ENV === 'development'
};
const persistedReducer = persistReducer(persistConfig, reducer);
const persistMiddlewareConfig = {
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
};

const rootReducer: Reducer = (state, action) => {
    if (action.type === logout.type) {
        return persistedReducer(undefined, action);
    }

    return persistedReducer(state, action);
};

export const createStore = (preloadedState?: PreloadedState<IRootState>) =>
    configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware(persistMiddlewareConfig).prepend(handleErrorMiddleware) /*.concat(logger)*/,
        devTools: process.env.NODE_ENV === 'development'
    });

export const store = createStore();
export const persistedStore = persistStore(store);
export type IAppDispatch = IAppStore['dispatch'];
export type IRootState = ReturnType<typeof reducer>;
export type IAppStore = ReturnType<typeof createStore>;
export type IAppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, Action<string>>;
