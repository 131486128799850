import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './RoleApi';
import { IRoleCUModel } from './RoleModels';
import { rolePaging, roleSelectValuesStatus, rolesForSelect } from './RoleSlice';

export const fetchRoles = createAsyncThunk('roles/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = rolePaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchRolesForSelect = createAsyncThunk(
    'roles/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (roleSelectValuesStatus(state) !== 'idle' || rolesForSelect(state).length) {
                return false;
            }
        }
    }
);

export const fetchRoleById = createAsyncThunk('roles/byId', async (args: number) => {
    return await fetchById(args);
});

export const createRole = createAsyncThunk('roles/create', async (args: IRoleCUModel) => {
    return await create(args);
});

export const updateRole = createAsyncThunk('roles/update', async (args: { id: number; data: IRoleCUModel }) => {
    return await update(args.id, args.data);
});

export const removeRole = createAsyncThunk('roles/remove', async (args: { id: number; onSuccess?: () => void }) => {
    await remove(args.id).then(() => args.onSuccess && args.onSuccess());

    return args.id;
});
