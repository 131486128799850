import { createContext, ReactNode, useState } from 'react';
import { Socket } from 'socket.io-client';
import { IClientToServerEvents, IServerToClientEvents } from '@/data/Notification/NotificationModels';

type IProps = {
    socket?: Socket<IServerToClientEvents>;
    children: ReactNode;
};
type IContext = [
    Socket<IServerToClientEvents, IClientToServerEvents> | null,
    (socket: Socket<IServerToClientEvents, IClientToServerEvents> | null) => void
];

export const WebSocketContext = createContext<IContext>([null, () => {}]);

const WebSocketContextProvider = ({ children, socket: socketValue }: IProps) => {
    const [socket, setSocket] = useState<Socket<IServerToClientEvents, IClientToServerEvents> | null>(
        socketValue ?? null
    );

    return <WebSocketContext.Provider value={[socket, setSocket]}>{children}</WebSocketContext.Provider>;
};

export default WebSocketContextProvider;
