import { forwardRef, useEffect, useState } from 'react';
import { useTimezoneSelect, ITimezoneOption, allTimezones } from 'react-timezone-select';
import DateHelper from '@/helpers/date/DateHelper';
import Select, { ISelectProps } from '@/wrappers/Select';

export type ITimezoneSelectProps = Omit<ISelectProps, 'options' | 'ref'> & {
    value?: string;
    onChange?: (value: ITimezoneSelectProps['value']) => void;
};

const TimezoneSelect = forwardRef<HTMLDivElement, ITimezoneSelectProps>(function Inner(
    { value, onChange, ...selectProps }: ITimezoneSelectProps,
    ref
) {
    const { options, parseTimezone } = useTimezoneSelect({
        labelStyle: 'original',
        timezones: allTimezones,
        currentDatetime: DateHelper.now().toDate()
    });
    const [parsedValue, setParsedValue] = useState<ITimezoneOption | null>(value ? parseTimezone(value) : null);

    useEffect(() => {
        setParsedValue(value ? parseTimezone(value) : null);
    }, [value]);

    return (
        <Select
            ref={ref}
            {...selectProps}
            onChange={(e) => {
                if (onChange) {
                    onChange(e);
                }

                setParsedValue(e ? parseTimezone(e) : null);
            }}
            value={parsedValue ? parsedValue.value : undefined}
            options={options.map((option) => ({
                id: option.value,
                label: option.label
            }))}
        />
    );
});

export default TimezoneSelect;
