import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { StyledEmptyCollapseButton } from '@/modules/Scheduler/StyledParts';

type IProps = Omit<BoxProps, 'onClick'> &
    (
        | {
              withCollapse: true;
              onClick: () => void;
              isCollapsed: boolean;
          }
        | { withCollapse: false; isCollapsed?: boolean; onClick?: () => void }
    );

const LeftTopCorner = ({ isCollapsed = false, withCollapse = false, onClick, ...rest }: IProps) => (
    <StyledEmptyCollapseButton {...rest}>
        {withCollapse ? (
            <IconButton onClick={onClick}>{isCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}</IconButton>
        ) : null}
    </StyledEmptyCollapseButton>
);

export default LeftTopCorner;
