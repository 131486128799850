import { SerializedError } from '@reduxjs/toolkit';
import i18next from 'i18next';
import config from '@/utils/config';
import { IFilters } from './Filters';

export const getHeaders = (headers: HeadersInit): HeadersInit => {
    let result = {
        ...headers,
        'Accept-Language': i18next.language == 'cs' ? 'cs_cz' : i18next.language
    } as HeadersInit;
    const token = localStorage.getItem('token');

    if (token && token.length > 0) {
        result = {
            ...result,
            JWT: `Bearer ${token}`
        };
    }

    return result;
};

const urlBuilderWithParams = (
    urlPath: string,
    params: Record<string, IFilters | string | number | boolean | string[] | number[]>
): string => {
    try {
        const url = new URL(`${config.apiServer()}${urlPath}`);
        const skippedParameters = ['columns', 'count', 'current', 'pageCount', 'filters'];

        if (Object.keys(params).includes('noPaging') && params.noPaging) {
            skippedParameters.push('limit', 'page');
        }

        Object.keys(params).forEach((arg) => {
            const value = params[arg];

            if (!skippedParameters.includes(arg)) {
                if (Array.isArray(value) && value.length) {
                    value.forEach((val, index) => {
                        url.searchParams.append(`${arg}[${index}]`, `${val}`);
                    });
                } else if (arg !== 'search' || !!value) {
                    url.searchParams.append(arg, `${value}`);
                }
            } else if (arg === 'filters') {
                Object.keys(value).forEach((filter) => {
                    const filterData = (value as IFilters)[filter];

                    if (filterData.min) {
                        url.searchParams.append(`${arg}[${filter}][min]`, `${filterData.min}`);
                    }

                    if (filterData.max) {
                        url.searchParams.append(`${arg}[${filter}][max]`, `${filterData.max}`);
                    }

                    if (filterData.values) {
                        url.searchParams.append(`${arg}[${filter}][list]`, `${filterData.values.join(',')}`);
                    }
                });
            }
        });

        return url.href;
    } catch (e) {
        console.error(e);
    }

    return '';
};

const handleErrorResponse = async (response: Response, display = true): Promise<SerializedError> => {
    const message = await response.json();

    const code = display ? response.status ?? 400 : 0;

    return { code: `${code}`, message: display ? `[${code}] ${message.msg}` : message.msg };
};

const fetchGet = async <T>(url: string, handleNotFound = true): Promise<T> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: getHeaders({
                'Content-Type': 'application/json'
            })
        });

        if (!response.ok) {
            const error = await handleErrorResponse(response, handleNotFound);

            return Promise.reject(error);
        }

        return (response.status === 204 ? Promise.resolve({}) : response.json()) as Promise<T>;
    } catch (e) {
        return Promise.reject('Unexpected error in GET request');
    }
};
const fetchDownloadFile = async <T>(url: string, handleNotFound = true): Promise<T> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: getHeaders({})
        });

        if (!response.ok) {
            const error = await handleErrorResponse(response, handleNotFound);

            return Promise.reject(error);
        }

        response.blob().then((blob) => {
            const fileUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const disposition = response.headers.get('content-disposition');
            let filename = '';

            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);

                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            a.href = fileUrl;
            a.download = filename.length == 0 ? (Math.random() + 1).toString(36).substring(7) : filename;
            a.click();
        });

        return Promise.resolve({}) as Promise<T>;
    } catch (e) {
        return Promise.reject('Unexpected error in GET request');
    }
};

const fetchPost = async <T = {}>(url: string, data?: {}, displayError = true): Promise<T> => {
    const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders({
            'Content-Type': 'application/json'
        }),
        body: data ? JSON.stringify(data) : '{}'
    });

    if (!response.ok) {
        const error = await handleErrorResponse(response, displayError);

        return Promise.reject(error);
    }

    if (response.status === 204) {
        return Promise.resolve({} as T);
    } else {
        return (await response.json()) as Promise<T>;
    }
};

const fetchPatch = async <T>(url: string, data: {}, handleNotFound = true): Promise<T> => {
    const response = await fetch(url, {
        method: 'PATCH',
        headers: getHeaders({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        const error = await handleErrorResponse(response, handleNotFound);

        return Promise.reject(error);
    }

    return (await response.json()) as Promise<T>;
};

const fetchDelete = async <T = boolean>(url: string, data?: {}): Promise<T> => {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: getHeaders({
            'Content-Type': 'application/json'
        }),
        body: data ? JSON.stringify(data) : ''
    });

    if (!response.ok) {
        const error = await handleErrorResponse(response);

        return Promise.reject(error);
    }

    if (response.status === 204) {
        return true as T;
    }

    return (await response.json()) as Promise<T>;
};

export { fetchDelete, fetchGet, fetchDownloadFile, fetchPost, fetchPatch, urlBuilderWithParams };
