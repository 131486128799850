import { createAsyncThunk } from '@reduxjs/toolkit';
import { create, fetchList, remove, update } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemApi';
import {
    IApplicationsSettingsFromBeModel,
    IApplicationsSettingsModel
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import {
    getReverseTransformSettingsItemValuesList,
    getTransformSettingItemsValue
} from '@/forms/IntegratedApplicationForm/utils';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';

const transformSettingItems = (
    values: IApplicationsSettingsFromBeModel['value']
): IApplicationsSettingsModel['value'] =>
    values.map(({ type, value_number, value_text, value_bool, ...rest }) => ({
        ...rest,
        value: getTransformSettingItemsValue(type, value_number, value_text, value_bool)
    })) as IApplicationsSettingsModel['value'];

const reverseTransformSettingsItem = (
    values: IApplicationsSettingsModel['value']
): IApplicationsSettingsFromBeModel['value'] =>
    values.map(({ value, ...rest }) => ({
        ...rest,
        ...getReverseTransformSettingsItemValuesList(value)
    })) as IApplicationsSettingsFromBeModel['value'];

export const fetchApplicationsSettingsItems = createAsyncThunk('applicationsSettingsItems/list', async () => {
    const response = await fetchList();

    return {
        ...response,
        data: response.data.map(({ value, ...rest }) => {
            return {
                ...rest,
                value: transformSettingItems(value ?? [])
            };
        }) as IApplicationsSettingsModel[]
    };
});

export const removeApplicationSetting = createAsyncThunk(
    'applicationsSettingsItems/remove',
    async (args: { id: string; onSuccess?: () => void }) => {
        await remove(args.id as ApplicationSettingsItemTypesEnum).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);

export const createApplicationSetting = createAsyncThunk('setting/create', async (args: IApplicationsSettingsModel) => {
    const response = await create({
        application_key: args.application_key as ApplicationSettingsItemTypesEnum,
        value: reverseTransformSettingsItem(args.value)
    } as IApplicationsSettingsFromBeModel);

    return {
        ...response,
        value: transformSettingItems(response.value)
    } as IApplicationsSettingsModel;
});

export const updateApplicationSetting = createAsyncThunk(
    'setting/update',
    async (args: { id: IApplicationsSettingsModel['application_key']; data: IApplicationsSettingsModel }) => {
        const response = await update(args.id, {
            application_key: args.id as ApplicationSettingsItemTypesEnum,
            value: reverseTransformSettingsItem(args.data.value)
        } as IApplicationsSettingsFromBeModel);

        return {
            ...response,
            value: transformSettingItems(response.value)
        } as IApplicationsSettingsModel;
    }
);
