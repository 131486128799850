import {
    DatePicker as DesktopDatePicker,
    DatePickerProps as DesktopDatePickerProps
} from '@mui/x-date-pickers/DatePicker';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { Dayjs } from 'dayjs';
import { forwardRef, memo, useCallback } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import config from '@/utils/config';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';
import DatePickerErrorMessages from './DatePickerErrorMessages';

export type IDatePickerProps = Omit<
    DesktopDatePickerProps<Dayjs, Dayjs>,
    'label' | 'name' | 'onError' | 'onChange' | 'renderInput'
> &
    Pick<ITextFieldProps, 'name' | 'required' | 'size'> & {
        label?: string;
        error?: boolean;
        helperText?: string;
        onError?: (message: string) => void;
        onChange?: (value: Dayjs | null) => void;
    };

const DatePicker = forwardRef<HTMLDivElement, IDatePickerProps>(function Inner(
    {
        inputFormat = config.date.inputFormat,
        name,
        value = null,
        required,
        label,
        disabled,
        readOnly,
        size,
        error,
        helperText,
        onError,
        onChange,
        ...rest
    },
    ref
) {
    const handleError = (reason: DateValidationError) => {
        if (!value) return;

        if (onError) onError(DatePickerErrorMessages(reason));
    };

    const handleOnChange = useCallback(
        (newValue: DateTimeType | null) => {
            if (onChange && (newValue === null || DateHelper.isValid(newValue))) {
                onChange(newValue);
            }
        },
        [onChange]
    );

    return (
        <DesktopDatePicker
            {...rest}
            ref={ref}
            disabled={disabled || readOnly}
            label={label}
            value={value}
            onError={handleError}
            onChange={handleOnChange}
            renderInput={({ onChange: onChangeValue, ...restField }) => (
                <TextField
                    {...restField}
                    disabled={disabled}
                    readOnly
                    data-testid={`date_picker_${name}`}
                    required={required}
                    label={label}
                    size={size}
                    name={name}
                    error={error}
                    helperText={helperText}
                    onBlur={(e) => {
                        if (e.target.value && !DateHelper.isStrictValid(e.target.value, inputFormat)) {
                            handleError('invalidDate');
                        }
                    }}
                    onChange={(e) => e && onChangeValue && onChangeValue(e)}
                />
            )}
        />
    );
});

export default memo(DatePicker);
