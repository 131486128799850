import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchPeriodById } from '@/data/Periods/PeriodActions';
import { fetchRequestTypes } from '@/data/RequestTypes/RequestTypeActions';
import { fetchSchedulePlanById, removeSchedulePlanFromStore } from '@/data/SchedulePlans/SchedulePlanActions';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import {
    isInProgressSelector,
    nameOfPlan,
    selectPeriodOfPlan,
    selectTimeZoneOfPlan,
    stateOfPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { fetchSkillsForSelect } from '@/data/Skills/SkillActions';
import { fetchUserHours } from '@/data/Summaries/SummariesActions';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import { fetchWorkplaceById } from '@/data/Workplaces/WorkplaceActions';
import UserToRequestForm from '@/forms/UserToRequestForm';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { getValidFromToDates, IFilterChanged } from '@/helpers/schedule';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SchedulerHeaderMenu from '@/modules/Scheduler/HeaderMenu/SchedulerHeaderMenu';
import SchedulerRequest from '@/modules/Scheduler/Request';
import { SchedulerContextProvider } from '@/modules/Scheduler/SchedulerContext';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import ComponentLoader from '@/wrappers/ComponentLoader';
import SchedulerCalendar, { ISchedulerCalendarProps } from './Calendar';
import SchedulerFilterPanel from './SchedulerFilterPanel';
import SchedulerTable from './Table';

export type AvailableScheduleTypes = 'shifts' | 'shiftTrades' | 'requests' | 'table';
export type IChildSchedulerTypes = {
    schedulePlanId: number | null;
    from: DateTimeType | null;
    to: DateTimeType | null;
};
export type ISchedulerProps = Pick<
    ISchedulerCalendarProps,
    'canCalculate' | 'isSchedulePlanClosed' | 'isNew' | 'onSchedulePlanCreated'
> & {
    type?: AvailableScheduleTypes;
    schedulePlanId: number | null;
    periodId?: number;
    workplaceId?: number;
    fromDate: string | null;
    toDate: string | null;
    dashboardView?: boolean;
    onFilterChanged: (changedValues: IFilterChanged) => void;
    displayMode: ISchedulerCalendarHeaderProps['mode'];
};

const StyledContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;
const StyledContainerHeader = styled(CardHeader)`
    padding-bottom: 0;

    .MuiCardHeader-action {
        margin-top: 8px;
        padding-right: 1em;
        display: flex;
        gap: 0.5em;
    }

    .formGenerator {
        .items > * {
            &:first-of-type {
                padding-left: 0;
            }

            padding-top: 0;
            padding-bottom: 0;
        }
    }
`;

function SchedulerInner({
    type = 'shifts',
    schedulePlanId,
    workplaceId,
    periodId,
    displayMode,
    onFilterChanged,
    fromDate,
    toDate,
    dashboardView = false,
    isNew,
    isSchedulePlanClosed = false,
    ...rest
}: ISchedulerProps) {
    const dispatch = useAppDispatch();
    const tableHeaderRef = useRef<HTMLDivElement>(null);
    const [selectedRequirements, setSelectedRequirements] = useState<number[] | null>(null);
    const [isBodyLoaded, setIsBodyLoaded] = useState(isNew || !['shifts', 'shiftTrades'].includes(type));
    const timeZone = useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId));
    const period = useAppSelector((state) => selectPeriodOfPlan(state, schedulePlanId));
    const planName = useAppSelector((state) => nameOfPlan(state, schedulePlanId));
    const isInProgressData = useAppSelector((state) => isInProgressSelector(state, schedulePlanId));
    const stateOfSchedulePlan = useAppSelector((state) => stateOfPlan(state, schedulePlanId));
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const isNeedFetchSchedulePlanData = stateOfSchedulePlan === null;
    const isNotSchedulePlanReady =
        !schedulePlanId ||
        !(
            stateOfSchedulePlan &&
            [
                SchedulePlanStateEnum.Draft,
                SchedulePlanStateEnum.Prepared,
                SchedulePlanStateEnum.Error,
                SchedulePlanStateEnum.Calculated,
                SchedulePlanStateEnum.Closed
            ].includes(stateOfSchedulePlan as SchedulePlanStateEnum)
        );

    const [validFromDate, validToDate] = useMemo(
        () => getValidFromToDates(displayMode, fromDate, toDate, period, timeZone ?? undefined),
        [displayMode, fromDate, toDate, period, timeZone]
    );

    useEffect(() => {
        dispatch(fetchSkillsForSelect({ search: '' }));
        dispatch(fetchApplicationsSettingsItems());
        dispatch(fetchRequestTypes({ noPaging: true }));

        return () => {
            if (schedulePlanId) {
                dispatch(removeSchedulePlanFromStore(schedulePlanId));
            }
        };
    }, []);

    useEffect(() => {
        if (workplaceId) {
            dispatch(fetchWorkplaceById(workplaceId));
        }
    }, [workplaceId]);

    useEffect(() => {
        if (periodId) {
            dispatch(fetchPeriodById(periodId));
        }
    }, [periodId]);

    useEffect(() => {
        if (workplaceId && periodId && schedulePlanId) {
            dispatch(fetchSchedulePlanById(schedulePlanId));
            dispatch(fetchUserHours({ workplaceId, periodId }));
        }

        setSelectedRequirements(null);
    }, [periodId, workplaceId, schedulePlanId, isNeedFetchSchedulePlanData]);

    const handleLoadingStart = useCallback(() => setIsBodyLoaded(false), []);
    const handleLoadingFinished = useCallback(() => setIsBodyLoaded(true), []);

    return (
        <StyledContainer
            data-testid="schedulerContainer"
            sx={type === 'table' ? { height: dashboardView ? 'calc(100% - 40px)' : '100%' } : { maxHeight: '100%' }}
        >
            <SchedulerContextProvider compactMode={false}>
                <ComponentLoader inProgress={isInProgressData || !isBodyLoaded} data-testid="schedulerComponentLoader">
                    <StyledContainerHeader
                        ref={tableHeaderRef}
                        title={
                            <SchedulerFilterPanel
                                dashboardView={dashboardView}
                                displayMode={displayMode}
                                from={validFromDate ? DateHelper.getInstanceOf(validFromDate) : null}
                                isPlanReady={!isNotSchedulePlanReady}
                                periodId={periodId ?? null}
                                timeZone={timeZone}
                                to={validToDate ? DateHelper.getInstanceOf(validToDate) : null}
                                schedulePlanId={schedulePlanId}
                                workplaceId={workplaceId}
                                type={type}
                                onFilterChanged={(values) =>
                                    onFilterChanged([
                                        ...values,
                                        ...((schedulePlanId
                                            ? [
                                                  {
                                                      name: 'id',
                                                      value: schedulePlanId.toString()
                                                  }
                                              ]
                                            : []) as IFilterChanged)
                                    ])
                                }
                                onGoToPlan={(newSchedulePlanId, newWorkplaceId, newPeriodId) =>
                                    schedulePlanId &&
                                    onFilterChanged([
                                        {
                                            name: 'id',
                                            value: `${newSchedulePlanId}`
                                        },
                                        { name: 'workplaceId', value: `${newWorkplaceId}` },
                                        {
                                            name: 'periodId',
                                            value: `${newPeriodId}`
                                        }
                                    ])
                                }
                            />
                        }
                        action={
                            <>
                                {type === 'requests' && isUserAdmin && (
                                    <UserToRequestForm
                                        key="userToRequests"
                                        displayAsSidebar={true}
                                        workplaceId={workplaceId}
                                        onlyFields={false}
                                    />
                                )}
                                <SchedulerHeaderMenu
                                    from={validFromDate}
                                    isPlanLocked={isNotSchedulePlanReady}
                                    isSchedulePlanClosed={isSchedulePlanClosed}
                                    isSchedulePlanDraft={stateOfSchedulePlan === SchedulePlanStateEnum.Draft}
                                    scheduleName={planName}
                                    schedulePlanId={schedulePlanId}
                                    type={type}
                                    to={validToDate}
                                    onChangeType={(newType) => {
                                        onFilterChanged([
                                            ...([
                                                {
                                                    name: 'type',
                                                    value: newType
                                                }
                                            ] as IFilterChanged),
                                            ...((newType === 'table' && displayMode === SchedulePlanWidthTypeEnum.Day
                                                ? [
                                                      {
                                                          name: 'displayMode',
                                                          value: SchedulePlanWidthTypeEnum.Week
                                                      }
                                                  ]
                                                : []) as IFilterChanged),
                                            ...((schedulePlanId
                                                ? [
                                                      {
                                                          name: 'id',
                                                          value: schedulePlanId.toString()
                                                      }
                                                  ]
                                                : []) as IFilterChanged)
                                        ]);
                                    }}
                                />
                            </>
                        }
                    />
                    <CardContent
                        sx={{
                            padding: 0,
                            display: !isNew ? 'inline-grid' : 'flex',
                            ...((type === 'table' && dashboardView) || (type !== 'table' && !isNew)
                                ? {
                                      height: '100%',
                                      overflow: 'auto'
                                  }
                                : {
                                      overflow: 'auto'
                                  })
                        }}
                    >
                        <DndProvider backend={HTML5Backend}>
                            {['shifts', 'shiftTrades'].includes(type) ? (
                                <SchedulerCalendar
                                    {...rest}
                                    displayMode={displayMode}
                                    from={validFromDate}
                                    isNew={isNew}
                                    isSchedulePlanClosed={isSchedulePlanClosed}
                                    periodId={periodId ?? null}
                                    selectedRequirements={selectedRequirements}
                                    schedulePlanId={schedulePlanId}
                                    to={validToDate}
                                    type={type}
                                    workplaceId={workplaceId ?? null}
                                    onLoadingStart={handleLoadingStart}
                                    onLoadingFinished={handleLoadingFinished}
                                    onSelectRequirements={setSelectedRequirements}
                                />
                            ) : type === 'table' ? (
                                <SchedulerTable
                                    from={validFromDate}
                                    schedulePlanId={schedulePlanId}
                                    to={validToDate}
                                    isSchedulePlanClosed={isSchedulePlanClosed}
                                />
                            ) : type === 'requests' ? (
                                <SchedulerRequest
                                    from={validFromDate}
                                    schedulePlanId={schedulePlanId ?? null}
                                    to={validToDate}
                                    displayMode={displayMode}
                                    onLoadingStart={handleLoadingStart}
                                    onLoadingFinished={handleLoadingFinished}
                                />
                            ) : (
                                <></>
                            )}
                        </DndProvider>
                    </CardContent>
                </ComponentLoader>
            </SchedulerContextProvider>
        </StyledContainer>
    );
}

SchedulerInner.defaultProps = {
    displayMode: SchedulePlanWidthTypeEnum.Period
};

export default memo(SchedulerInner);
