type IPaging = {
    count: number;
    current: number;
    limit: number;
    page: number;
    pageCount: number;
    sort: string;
    direction: 'asc' | 'desc';
    search: string;
    noPaging: boolean;
};

export const defaultPaging = (sortBy: string): IPaging => ({
    count: 0,
    current: 0,
    limit: 10,
    page: 1,
    pageCount: 1,
    sort: sortBy,
    direction: 'asc',
    search: '',
    noPaging: false
});

export type { IPaging };
