import { createAsyncThunk } from '@reduxjs/toolkit';
import { sync, syncBreaks, syncSchedules } from '@/data/Google/GoogleApi';
import { IGoogleSyncModel } from '@/data/Google/GoogleModels';
import { addNotification } from '@/data/Notification/NotificationSlice';

export const syncGoogle = createAsyncThunk(
    'google/synchronizeToGoogle',
    async (schedulePlanId: number | null, { dispatch }) => {
        if (schedulePlanId) {
            return await sync(schedulePlanId)
                .then(() => {
                    dispatch(
                        addNotification({
                            variant: 'success',
                            context: 'message.status.synchronized',
                            defaultMessage: 'Synchronized'
                        })
                    );
                })
                .catch((error) =>
                    dispatch(
                        addNotification({
                            message: error.message,
                            variant: 'error'
                        })
                    )
                );
        }
    },
    {
        condition: (schedulePlanId) => schedulePlanId !== null && Number.isInteger(schedulePlanId)
    }
);
export const syncSchedulesGooglePlan = createAsyncThunk(
    'google/synchronizeScheduleToGoogle',
    async (data: IGoogleSyncModel) => {
        return await syncSchedules(data);
    }
);
export const syncBreaksGooglePlan = createAsyncThunk(
    'google/synchronizeBreaksToGoogle',
    async (data: IGoogleSyncModel) => {
        return await syncBreaks(data);
    }
);
