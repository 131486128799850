import { useMemo } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { ICell } from '@/helpers/schedule';

export const generateColumns = (
    isDayMode: boolean,
    fromMoment: DateTimeType,
    toMoment: DateTimeType
): ICell<DateTimeType>[] => {
    const result: ICell<DateTimeType>[] = [];

    if (isDayMode) {
        let from = DateHelper.clone(fromMoment);
        let index = 0;

        while (DateHelper.isBefore(from, toMoment)) {
            const to = DateHelper.addMinutes(from, 15);

            result.push({
                id: index,
                from,
                to
            });
            from = to;

            index++;
        }
    } else {
        let from = DateHelper.clone(fromMoment);
        const countOfDays = DateHelper.getDifferenceAsDays(fromMoment, toMoment) + 1;

        for (let day = 0; day < countOfDays; day++) {
            const to = DateHelper.addDays(from, 1);

            result.push({
                id: day,
                from,
                to
            });
            from = to;
        }
    }

    return result;
};

const useSchedulerColumnTitles = (isDayMode: boolean, from: DateTimeType | null, to: DateTimeType | null) => {
    return useMemo(
        () => (from && to ? generateColumns(isDayMode, from, to) : []),
        [isDayMode, from?.toISOString(), to?.toISOString()]
    );
};

export default useSchedulerColumnTitles;
