import { IListResponse } from '@/data/ApiResponse';
import { fetchDelete, fetchGet, fetchPost } from '@/data/request';
import { ISchedulePlanDayShiftModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { IShiftModel } from '@/data/Shifts/ShiftModels';
import { IShiftTradeOfferModel } from '@/data/ShiftTradeOffers/ShiftTradeOfferModels';
import { IShiftTradeModel } from '@/data/ShiftTrades/ShiftTradeModels';
import { IUserFromBeModel } from '@/data/Users/UserModels';

export function selectOfferedShiftById(tradeId: number, schedulePlanDyUserId: number) {
    return fetchPost<IShiftTradeOfferModel>(`/api/shift-trades/${tradeId}/select-trade/${schedulePlanDyUserId}`, {});
}

export function deleteSelectOfferedShifts(tradeId: number, offerId: number) {
    return fetchDelete(`/api/shift-trades/${tradeId}/select-trade/${offerId}`);
}

export function fetchOffersBySchedulePlanDayShiftId(schedulePlanDayShiftId: number) {
    return fetchGet<
        IListResponse<
            IShiftTradeOfferModel & {
                schedule_plan_day_shift: ISchedulePlanDayShiftModel & {
                    shift: IShiftModel;
                    user: IUserFromBeModel;
                };
                schedule_plan_day_shift_trade: IShiftTradeModel;
            }
        >
    >(`/api/shift-trades/assigned-shifts/${schedulePlanDayShiftId}/offers`);
}

export function agreeWithOffers(tradeId: number, offerId: number) {
    return fetchPost<IShiftTradeModel>(`/api/shift-trades/${tradeId}/agree-with-offers/${offerId}`, {});
}
