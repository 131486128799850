import Box from '@mui/material/Box';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import { ICellDataType } from '@/helpers/schedule';
import SchedulerBodyTooltip from '@/modules/Scheduler/components/SchedulerBodyTooltip';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import ShiftAsText from '../../../ShiftAsText';

export type ISchedulerTableCellContentProps = ArrayElement<ICellDataType<Date>['vacations']> & {
    mode: ISchedulerCalendarHeaderProps['mode'];
    timeZone: string;
};

const SchedulerCellVacation = ({ from, id, mode, timeZone, to }: ISchedulerTableCellContentProps) => {
    const abbreviation = 'PTO';
    const color = '#FFFFFF';
    const backgroundColor = '#808080';

    return (
        <SchedulerBodyTooltip columnFrom={from} columnTo={to} mode={SchedulePlanWidthTypeEnum.Week} timeZone={timeZone}>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor,
                    color,
                    display: 'inline-flex',
                    height: '100%',
                    textAlign: 'center',
                    width: '100%'
                }}
                data-testid={`userToVacations_${id}`}
            >
                <ShiftAsText
                    simpleView={true}
                    mode={mode}
                    skills={[]}
                    abbr={{ text: abbreviation, color }}
                    inStore={false}
                    shiftEnd={null}
                    shiftName={null}
                    shiftStart={null}
                    timeZone={timeZone}
                />
            </Box>
        </SchedulerBodyTooltip>
    );
};

export default SchedulerCellVacation;
