import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

export type ISnackbarProps = SnackbarProps & {
    severity?: AlertColor;
};

const Alert = (props: ISnackbarProps) => (
    <Snackbar {...props}>
        <MuiAlert severity={props.severity ?? 'info'}>{props.message}</MuiAlert>
    </Snackbar>
);

export default Alert;
