type IRegex = {
    [key: string]: RegExp;
};

const regex: IRegex = {
    name: /^[éěëščřžýáäíïťďňöúůüÿÉĚËŠČŘŽÝÁÄÍÏŤĎŇÖÚŮÜŸ/\-\sA-Za-z0-9]+$/,
    text: /^[éěëščřžýáäíïťďňöúůüÿÉĚËŠČŘŽÝÁÄÍÏŤĎŇÖÚŮÜŸ/\-,.?!'"@\w\s\d]+$/,
    number: /^\d+$/,
    password: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
    phone: /^(\+?(42[01])\s?)?([1-9]{1}[0-9]{2}(\s?[0-9]{3}){2})$/,
    email: /^("([ !#-[\]-~]|\\[ -~])+"|[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*)@([0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)+[a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF])?$/i
};

export default regex;
