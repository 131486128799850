import { IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPost, fetchPatch, urlBuilderWithParams } from '../request';
import { IActivityCUModel, IActivityModel } from './ActivityModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/activities', paging || {});

    return fetchGet<IListResponseWithPaging<IActivityModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IActivityModel>(`/api/activities/${id}`);
}
export function create(data: IActivityCUModel) {
    return fetchPost<IActivityModel>('/api/activities/', data);
}
export function update(id: number, data: IActivityCUModel) {
    return fetchPatch<IActivityModel>(`/api/activities/${id}`, data);
}
export function remove(id: number) {
    return fetchDelete(`/api/activities/${id}`);
}
