import { createSlice } from '@reduxjs/toolkit';
import RoleEnum from '@/data/System/RoleEnum';
import { IRequestState } from '../ApiRequest';
import { IRootState } from '../store';
import { refreshToken, signIn, signInWithToken } from './SystemActions';
import { ILoginPermissionsResponseModel, IUserModel } from './UserModel';

export type ISystemReducerState = {
    signInStatus: IRequestState;
    refreshTokenStatus: IRequestState;
    signedUser?: IUserModel;
    roles: number[];
    permissions: ILoginPermissionsResponseModel[];
};

const initialState: ISystemReducerState = {
    signInStatus: 'idle',
    refreshTokenStatus: 'idle',
    signedUser: undefined,
    roles: [],
    permissions: []
};

const systemReducer = createSlice({
    name: 'system',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.signInStatus = 'loading';
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.signInStatus = 'idle';
                state.refreshTokenStatus = 'passed';
                state.permissions = action.payload.permissions;
                state.roles = action.payload.roles;
                state.signedUser = action.payload.token;
            })
            .addCase(signIn.rejected, (state) => {
                state.signInStatus = 'failed';
            })
            .addCase(signInWithToken.pending, (state) => {
                state.signInStatus = 'loading';
            })
            .addCase(signInWithToken.fulfilled, (state, action) => {
                state.signInStatus = 'idle';
                state.refreshTokenStatus = 'passed';
                state.permissions = action.payload.permissions;
                state.roles = action.payload.roles;
                state.signedUser = action.payload.token;
            })
            .addCase(signInWithToken.rejected, (state) => {
                state.signInStatus = 'failed';
            })
            .addCase(refreshToken.pending, (state) => {
                state.refreshTokenStatus = 'loading';
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.refreshTokenStatus = 'passed';
                state.roles = action.payload.roles;
                state.signedUser = action.payload.token;
                state.permissions = action.payload.permissions;
            })
            .addCase(refreshToken.rejected, (state) => {
                state.refreshTokenStatus = 'failed';
            });
    }
});

export const systemInitialState = systemReducer.getInitialState();
export default systemReducer.reducer;
export const isSigned = (state: IRootState) => typeof state.system.signedUser !== 'undefined';
export const isSignedUserAdmin = (state: IRootState) =>
    state.system.roles.some(
        (item) => item === RoleEnum.Admin || item === RoleEnum.SystemAdmin || item === RoleEnum.PlannerForWorkplace
    );

export const isSignedUserSystemAdmin = (state: IRootState) =>
    state.system.roles.some((item) => item === RoleEnum.SystemAdmin);

export const isSignedUserUser = (state: IRootState) =>
    state.system.roles.some((item) => item === RoleEnum.Agent || item === RoleEnum.OnlyAttendance);

export const isUserAllowedStartAttendance = (state: IRootState) =>
    state.system.roles.some((item) => item === RoleEnum.OnlyAttendance);
export const signedUser = (state: IRootState) => state.system.signedUser;
export const isSigningInProgress = (state: IRootState) => state.system.signInStatus === 'loading';
export const refreshTokenStatus = (state: IRootState) => state.system.refreshTokenStatus;
export const getPermissionsList = (state: IRootState) => state.system.permissions;
export const hasRole = (state: IRootState, role?: RoleEnum) => role && state.system.roles.includes(role);
