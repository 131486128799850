import { IListResponseWithPaging, IListResponse } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchDownloadFile, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import {
    IQueueCUModel,
    IQueueTrainModel,
    IQueueModel,
    IQueueSelectModel,
    IQueuePullDataFromExternalModel,
    IQueueAggregateModel,
    IQueueCsvFileTempleData
} from './QueueModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/queues', paging || {});

    return fetchGet<IListResponseWithPaging<IQueueModel>>(url);
}

export function train(data: IQueueTrainModel) {
    return fetchPost('/api/prediction/train', data);
}

export function aggregate(data: IQueueAggregateModel) {
    const url = urlBuilderWithParams('/api/queues/aggregate', data);

    return fetchGet(url);
}
export function pullDataFromExternalSource(data: IQueuePullDataFromExternalModel) {
    const url = urlBuilderWithParams('/api/queues/pullData', data);

    return fetchGet(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/queues/select', { search, fields });

    return fetchGet<IListResponse<IQueueSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IQueueModel>(`/api/queues/${id}`);
}

export function create(data: IQueueCUModel) {
    return fetchPost<IQueueModel>('/api/queues/', data);
}

export function update(id: number, data: IQueueCUModel) {
    return fetchPatch<IQueueModel>(`/api/queues/${id}`, data);
}
//TODO: čeká na refactor backandu
// export function partialUpdate(id: number, data: Partial<IQueueCUModel>) {
//     return fetchPatch<IQueueModel>(`/api/queues/${id}`, data);
// }

export function uploadCsvFilesTempleData() {
    return fetchGet<IQueueCsvFileTempleData>('/api/queues/getTempleData');
}

export function uploadCsvFilesTempleFile() {
    return fetchDownloadFile('/api/queues/getTempleFile');
}

export function removeAggregatedData({ queueId, ...args }: { queueId: number; dateFrom: string; dateTo: string }) {
    const url = urlBuilderWithParams(`/api/queues/removeAggregatedData/${queueId}`, args);

    return fetchDelete(url);
}

export function remove(id: number) {
    return fetchDelete(`/api/queues/${id}`);
}
