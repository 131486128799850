import { styled } from '@mui/material/styles';
import useAppTranslation from '@/hooks/useAppTranslation';

const fullYear = new Date().getFullYear();

const StyledFooter = styled('footer')(
    ({ theme }) => `
        padding-inline: ${theme.spacing(3)};
        display: grid;
        grid-area: footer;
        align-items: center;
`
);

const Footer = () => {
    const { t } = useAppTranslation();

    return (
        <StyledFooter data-testid="footer">
            &copy;
            {t('label.1poweredBy2', '{{year}} Powered by {{company}}', { year: fullYear, company: 'Daktela' })}
        </StyledFooter>
    );
};

export default Footer;
