import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import useDebounce from '@/hooks/useDebounce';
import TextField from '@/wrappers/TextField';
import { IItemType } from '@/wrappers/TransferList';
type IContent = {
    alreadySorted: boolean;
    items: IItemType[];
    checked: string[];
    handleToggle: (id: string) => void;
};

const StyledContent = styled(Paper)`
    height: 100%;
`;
const StyledList = styled(List<'div'>)(
    ({ theme }) => `
    height: 100%;
    overflow: auto;
    padding: 0;
    background: ${theme.palette.background.default};

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 0 2px;
        background-color: ${theme.palette.primary.main};
        border-radius: ${theme.shape.borderRadius}px;
    }
`
);
const StyledItem = styled(ListItemButton)`
    padding: 0;
`;

const Content = ({ alreadySorted, items, checked, handleToggle }: IContent) => {
    const [searchedValue, setSearchedValue] = useState('');

    const debouncedValue = useDebounce(searchedValue);

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{ display: 'grid', gridTemplateRows: 'auto 210px', height: '100%', overflow: 'auto' }}
        >
            <Grid item>
                <TextField
                    name="search"
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                    value={null}
                    variant="standard"
                    onChange={(_event, value) => setSearchedValue(value.toLowerCase())}
                />
            </Grid>
            <Grid item sx={{ height: '100%' }}>
                <StyledContent>
                    <StyledList component="div" role="list">
                        {(alreadySorted ? items : items.sort((a, b) => a.sortableValue.localeCompare(b.sortableValue)))
                            .filter((item) => item.sortableValue.toLowerCase().includes(debouncedValue))
                            .map((item) =>
                                !item.isDivider ? (
                                    <StyledItem
                                        key={item.id}
                                        role="listitem"
                                        onClick={() => handleToggle(item.id)}
                                        data-testid={`transferItem-${item.id}`}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={checked.indexOf(item.id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': `transfer-list-item-${item.id}-label`
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={`transfer-list-item-${item.id}-label`}
                                            primary={item.content ?? item.sortableValue}
                                        />
                                    </StyledItem>
                                ) : (
                                    <Divider key={item.id} />
                                )
                            )}
                    </StyledList>
                </StyledContent>
            </Grid>
        </Grid>
    );
};

export default Content;
