import { createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { fetchCategoriesList, fetchQueuesList } from '@/data/Daktela/DaktelaActions';
import { IDaktelaCategories, IDaktelaQueues } from '@/data/Daktela/DaktelaModels';
import { IRootState } from '@/data/store';

type IDaktelaSliceState = {
    categoriesListStatus: IRequestState;
    categoriesList: IDaktelaCategories[];
    queuesListStatus: IRequestState;
    queuesList: IDaktelaQueues[];
};

const initialState: IDaktelaSliceState = {
    categoriesListStatus: 'idle',
    categoriesList: [],
    queuesListStatus: 'idle',
    queuesList: []
};

const daktelaSlice = createSlice({
    name: 'daktela',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesList.pending, (state) => {
                state.categoriesListStatus = 'loading';
            })
            .addCase(fetchCategoriesList.fulfilled, (state, action) => {
                state.categoriesListStatus = 'idle';
                state.categoriesList = action.payload.data;
            })
            .addCase(fetchCategoriesList.rejected, (state) => {
                state.categoriesListStatus = 'failed';
            })
            .addCase(fetchQueuesList.pending, (state) => {
                state.queuesListStatus = 'loading';
            })
            .addCase(fetchQueuesList.fulfilled, (state, action) => {
                state.queuesListStatus = 'idle';
                state.queuesList = action.payload.data;
            })
            .addCase(fetchQueuesList.rejected, (state) => {
                state.queuesListStatus = 'failed';
            });
    }
});

export default daktelaSlice;

const getState = (state: IRootState) => state[daktelaSlice.name];

export const categoriesListStatus = (state: IRootState) => getState(state).categoriesListStatus;
export const categoriesList = (state: IRootState) => getState(state).categoriesList;
export const isCategoriesListLoaded = (state: IRootState) => categoriesListStatus(state) !== 'loading';

export const queuesListStatus = (state: IRootState) => getState(state).queuesListStatus;
export const queuesList = (state: IRootState) => getState(state).queuesList;
export const isQueuesListLoaded = (state: IRootState) => queuesListStatus(state) !== 'loading';
