import { createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { IRootState } from '../store';
import { syncBreaksGooglePlan, syncSchedulesGooglePlan } from './GoogleActions';

export type IGoogleSlice = {
    synchronizeScheduleStatus: IRequestState;
    synchronizeBreakStatus: IRequestState;
};

const initialState: IGoogleSlice = {
    synchronizeScheduleStatus: 'idle',
    synchronizeBreakStatus: 'idle'
};

const googleSlice = createSlice({
    name: 'google',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(syncSchedulesGooglePlan.pending, (state) => {
            state.synchronizeScheduleStatus = 'loading';
        });
        builder.addCase(syncSchedulesGooglePlan.fulfilled, (state) => {
            state.synchronizeScheduleStatus = 'idle';
        });
        builder.addCase(syncSchedulesGooglePlan.rejected, (state) => {
            state.synchronizeScheduleStatus = 'failed';
        });
        builder.addCase(syncBreaksGooglePlan.pending, (state) => {
            state.synchronizeBreakStatus = 'loading';
        });
        builder.addCase(syncBreaksGooglePlan.fulfilled, (state) => {
            state.synchronizeBreakStatus = 'idle';
        });
        builder.addCase(syncBreaksGooglePlan.rejected, (state) => {
            state.synchronizeBreakStatus = 'failed';
        });
    }
});

export default googleSlice;

const getState = (state: IRootState) => state[googleSlice.name];

export const googleSynchronizeScheduleStatus = (state: IRootState) => getState(state).synchronizeScheduleStatus;
export const googleSynchronizeBreakStatus = (state: IRootState) => getState(state).synchronizeBreakStatus;
