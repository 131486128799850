import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import MaterialTable, {
    Options,
    Column,
    DetailPanel,
    MaterialTableProps,
    MTableAction,
    MTableBodyRow,
    MTableCell
} from 'material-table';
import * as React from 'react';
import { MouseEvent, ReactElement, ReactNode, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { IPaging } from '@/data/Paging';
import DateHelper from '@/helpers/date/DateHelper';
import handleFilter from '@/helpers/select/filter';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import ComponentLoader from '@/wrappers/ComponentLoader';
import DatePicker from '@/wrappers/DatePicker';
import DateTimePicker from '@/wrappers/DateTimePicker';
import Select, { ISelectProps } from '@/wrappers/Select';
import { IProps } from '@/wrappers/Table/Table';
import TextField from '@/wrappers/TextField';
import Tooltip from '@/wrappers/Tooltip';
import WeekdaysMultiSelect from '@/wrappers/WeekdaysMultiSelect/WeekdaysMultiSelect';
import Weekdays from './CellViews/Weekdays';
import Toolbar, { IAction } from './Toolbar';

type IFilter<P> = {
    type: 'select';
    props: P;
};
type ISelectFilter = IFilter<ISelectProps> & {
    type: 'select';
};
type ISupportedFilterTypes = ISelectFilter;
type IOption = {
    id: string | number;
    name: string;
};

export type IDatatableProps<RowType extends object> = {
    name?: string;
    actionsInHeader?: ReactElement;
    editable?: MaterialTableProps<RowType>['editable'];
    searchPlaceHolder?: string;
    noRecordMessage?: string;
    rowStyle?: Options<RowType>['rowStyle'];
    title?: string;
    hasTitle?: boolean;
    hasSelection?: boolean;
    isDraggable?: boolean;
    hasSorting?: boolean;
    hasPaginating?: boolean;
    hasSearch?: boolean;
    isLoading?: boolean;
    truncateCells?: string[];
    onPagingChanged?: (page: number, perPage: number) => void;
    onSortingChanged?: (columnName: string, direction: 'asc' | 'desc') => void;
    onSearchChanged?: (searchText: string) => void;
    rowWrapper?: (rowData: RowType, openButton: (onClick: (event: MouseEvent) => void) => ReactElement) => ReactElement;
    paging?: IPaging;
    detailPanel?:
        | ((rowData: RowType) => React.ReactNode)
        | (DetailPanel<RowType> | ((rowData: RowType) => DetailPanel<RowType>))[];
    simplePaging?: boolean;
    filterEnabled?: boolean;
    labelAddButton?: ReactNode;
    header: (Omit<Column<RowType>, 'field' | 'type' | 'lookup'> & {
        field: string;
        sortField?: string;
        options?: IOption[];
        filter?: ISupportedFilterTypes;
        settings?: {
            unfiltered?: boolean;
        };
        type?: 'days' | 'year' | Column<RowType>['type'];
        inputProps?: Partial<StandardInputProps> | unknown;
    })[];
    actions?: IAction<RowType>[];
    data: RowType[];
};

function FilterBar() {
    return <Paper>Filter</Paper>;
}

export function transformTableColumnToHeaderColumns<ROW, T extends ROW & object>(
    columns: IProps<ROW>['columns']
): IDatatableProps<T>['header'] {
    return (
        columns?.map(({ access, id, label }) => ({
            field: id,
            render: access,
            title: label
        })) ?? []
    );
}

const StyledWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        .MuiTableBody-root {
            td:first-of-type {
                .PrivateSwitchBase-root {
                    margin-left: ${theme.spacing(2)} !important;
                }
            }
        }
        .MuiTablePagination-root {
            border: none;
        }
    `
);
const StyledCell = styled(MTableCell)(
    ({ theme }) => `
        padding: ${theme.spacing(0.25)} ${theme.spacing(2)};
    `
);
const StyledTruncate = styled(Box)`
    max-width: 70ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export default function Datatable<RowType extends object>({
    name = '',
    noRecordMessage,
    hasTitle = true,
    hasSearch = true,
    hasSelection = true,
    isDraggable = true,
    hasSorting = true,
    hasPaginating = true,
    isLoading = false,
    simplePaging = false,
    filterEnabled,
    labelAddButton,
    header,
    searchPlaceHolder,
    actionsInHeader,
    rowWrapper,
    ...props
}: IDatatableProps<RowType>) {
    const { t } = useAppTranslation();
    const formatter = useLocalizeDateFormatter({});
    const [openFilters, setOpenFilters] = useState(false);

    return (
        <StyledWrapper>
            {openFilters ? (
                <TransitionGroup>
                    <Collapse>
                        <FilterBar />
                    </Collapse>
                </TransitionGroup>
            ) : null}
            <MaterialTable<RowType>
                localization={{
                    body: {
                        emptyDataSourceMessage:
                            noRecordMessage ?? t('datatable.emptyDataSourceMessage', 'No records to display')
                    },
                    pagination: {
                        labelRowsPerPage: simplePaging ? '' : t('datatable.rowsPerPage', 'Rows per page'),
                        labelRowsSelect: t('datatable.rows', 'Rows', { count: props?.paging?.limit ?? 10 }),
                        labelDisplayedRows: simplePaging
                            ? ''
                            : t('datatable.labelDisplayedRows', '{from}-{to} of {count}'),
                        firstTooltip: t('datatable.firstTooltip', 'First Page'),
                        lastTooltip: t('datatable.lastTooltip', 'Last Page'),
                        previousTooltip: t('datatable.previousTooltip', 'Previous Page'),
                        nextTooltip: t('datatable.nextTooltip', 'Next Page')
                    }
                }}
                isLoading={isLoading}
                title={props.title || ''}
                columns={header.map((column) => {
                    const newColumn = column;

                    if (column.sortField ?? column.field === props.paging?.sort) {
                        newColumn.defaultSort = props.paging?.direction;
                    }

                    if (column.options) {
                        let lookup: Record<number, string> = {};

                        column.options.forEach((option) => {
                            lookup = Object.assign(lookup, { [option.id]: option.name });
                        });

                        delete newColumn.options;
                        (newColumn as Column<RowType>).lookup = lookup;
                    }

                    if ((column.type === 'date' || column.type === 'datetime') && !column.dateSetting?.locale) {
                        column.dateSetting = { locale: formatter.resolvedOptions().locale };
                    }

                    if (column.type === 'days') {
                        column.type = undefined;
                        column.editComponent = (rowData) => (
                            <WeekdaysMultiSelect
                                name={column.field}
                                label={typeof column.title === 'string' ? column.title : ''}
                                value={rowData.value}
                                onChange={rowData.onChange}
                            />
                        );
                        column.render = (rowData) => (
                            <Weekdays
                                days={Object.getOwnPropertyDescriptor(rowData, column.field)?.value ?? 0}
                                holidays={false}
                            />
                        );
                    }

                    if (column.type === 'year') {
                        column.type = undefined;

                        column.editComponent = (editComponentProps) => (
                            <DatePicker
                                name="year"
                                value={DateHelper.fromOptionalYear(editComponentProps.value)}
                                label={t('label.year', 'Year')}
                                openTo="year"
                                onChange={(e) => {
                                    editComponentProps.onChange(e?.year());
                                }}
                                views={['year']}
                                minDate={DateHelper.now().subtract(1, 'year')}
                            />
                        );

                        column.render = (rowData) => Object.getOwnPropertyDescriptor(rowData, column.field)?.value;
                    }

                    return newColumn as Column<RowType>;
                })}
                actions={props.actions}
                data={props.data.map((row) => Object.assign({}, row))}
                options={{
                    rowStyle: props.rowStyle,
                    detailPanelColumnStyle: {
                        width: 'min-content',
                        borderRight: '1px solid rgb(224, 224, 224)'
                    },
                    draggable: isDraggable,
                    emptyRowsWhenPaging: false,
                    selection: hasSelection,
                    showTitle: hasTitle,
                    search: hasSearch && hasTitle !== false,
                    sorting: hasSorting,
                    thirdSortClick: true,
                    paging: hasPaginating,
                    pageSize: props.paging?.limit || 10,
                    actionsColumnIndex: -1,
                    searchFieldVariant: 'outlined',
                    searchFieldAlignment: 'left',
                    headerStyle: {
                        height: '40px',
                        padding: '0 16px',
                        background: 'rgba(93, 16, 73, 0.04)',
                        borderTop: '1px solid rgb(224, 224, 224)'
                    },
                    pageSizeOptions: simplePaging ? [] : undefined
                }}
                onChangePage={props.onPagingChanged}
                onOrderChange={(columnIndex, direction) =>
                    props.onSortingChanged &&
                    props.onSortingChanged(
                        columnIndex === -1
                            ? props.paging?.sort || header[0].sortField || header[0].field
                            : header[columnIndex].sortField || header[columnIndex].field,
                        direction
                    )
                }
                onChangeRowsPerPage={(perPage: number) => props.onPagingChanged && props.onPagingChanged(0, perPage)}
                onSearchChange={props.onSearchChanged}
                detailPanel={props.detailPanel ? props.detailPanel : undefined}
                page={props.paging?.page ? props.paging?.page - 1 : undefined}
                totalCount={props.paging?.count || props.data.length || 1}
                editable={props.editable}
                onRowClick={(event, rowData, togglePanel) => {
                    if (togglePanel && props.detailPanel) {
                        togglePanel();
                    }
                }}
                components={{
                    Toolbar: ({
                        selectedRows,
                        actions,
                        onSearchChanged: handleSearchChangedToolbar,
                        search,
                        showTitle,
                        title,
                        ...rest
                    }) =>
                        hasSearch || hasTitle !== false || !!props.editable || !!actionsInHeader ? (
                            <Toolbar<RowType>
                                {...rest}
                                onSearchChanged={handleSearchChangedToolbar}
                                selectedRows={selectedRows}
                                actions={actions}
                                title={title}
                                showTitle={showTitle}
                                search={search}
                                searchText={props.paging?.search}
                                labelAddButton={labelAddButton}
                                isFilterEnabled={filterEnabled ?? !!header.find((headerCell) => !!headerCell.filter)}
                                onFilterClicked={() => setOpenFilters((prev) => !prev)}
                                searchPlaceHolder={searchPlaceHolder}
                                customActions={actionsInHeader}
                            />
                        ) : (
                            <></>
                        ),
                    Container: (containerProps) => (
                        <Box {...containerProps} component={Paper} data-testid={`datable${name || ''}`} />
                    ),
                    OverlayLoading: () => (
                        <ComponentLoader inProgress={true} data-testid={`datable${name || ''}Loader`} />
                    ),
                    Pagination: (paginationProps) => {
                        return <TablePagination {...paginationProps} />;
                    },
                    Row: ({ data, ...rowProps }) =>
                        rowWrapper ? (
                            rowWrapper(data as RowType, (onClick) => (
                                <MTableBodyRow
                                    {...rowProps}
                                    data={data}
                                    data-testid={`datatable${name || ''}-row-${data.id}`}
                                    onRowClick={onClick}
                                />
                            ))
                        ) : (
                            <MTableBodyRow
                                {...rowProps}
                                data={data}
                                data-testid={`datatable${name || ''}-row-${data.id}`}
                            />
                        ),
                    Action: (actionProps) => {
                        return (
                            <Box data-testid={actionProps.action.tooltip ?? 'm_table_action'}>
                                <MTableAction {...actionProps} />
                            </Box>
                        );
                    },
                    Cell: ({ columnDef, rowData, value, ...restCellProps }) =>
                        props.truncateCells && props.truncateCells?.find((item) => item === columnDef.field) ? (
                            <StyledCell
                                data-testid={`datatable${name}-row-${rowData.id}-col-${columnDef.field}`}
                                columnDef={columnDef}
                                rowData={rowData}
                                {...restCellProps}
                            >
                                <Tooltip
                                    color="primary"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title={value || ''}
                                    arrow
                                >
                                    <StyledTruncate>{value}</StyledTruncate>
                                </Tooltip>
                            </StyledCell>
                        ) : (
                            <StyledCell
                                data-testid={`datatable${name || ''}-row-${rowData?.id ?? 'null'}-col-${
                                    columnDef.field
                                }`}
                                columnDef={columnDef}
                                rowData={rowData}
                                value={value}
                                {...restCellProps}
                            />
                        ),
                    EditField: ({ columnDef, value, onChange, ...rest }) => {
                        if (columnDef.lookup) {
                            const list = Object.keys(columnDef.lookup);
                            const { field } = columnDef;
                            const items = props.data as { [key: string]: string }[];
                            const isEdit = Object.keys(rest.rowData).length > 0;
                            const selected = isEdit
                                ? items.filter((item) => item[field] !== value).map((item) => `${item[field]}`)
                                : items.map((item) => `${item[field]}`);
                            const result = columnDef.settings?.unfiltered ? list : handleFilter(list, selected);

                            return (
                                <Select
                                    label=""
                                    name={columnDef.name ?? columnDef.field}
                                    value={`${value}`}
                                    size="small"
                                    sx={{ display: 'flex' }}
                                    options={result.map((id) => ({
                                        id,
                                        label: columnDef.lookup[id]
                                    }))}
                                    onChange={onChange}
                                />
                            );
                        }

                        if (columnDef.type === 'date') {
                            return <DatePicker name={columnDef.field} value={value} size="small" onChange={onChange} />;
                        }

                        if (columnDef.type === 'datetime') {
                            return (
                                <DateTimePicker
                                    name={columnDef.field}
                                    value={value}
                                    size="small"
                                    onChange={onChange()}
                                />
                            );
                        }

                        return (
                            <TextField
                                name={columnDef.name ?? columnDef.field}
                                value={`${value}`}
                                data-testid={columnDef.name ?? columnDef.field}
                                type={columnDef.type === 'numeric' ? 'number' : columnDef.type || 'string'}
                                size="small"
                                sx={{ display: 'flex' }}
                                onChange={(_, newValue) => {
                                    onChange(newValue);
                                }}
                                InputProps={columnDef.inputProps}
                            />
                        );
                    }
                }}
                data-testid="datatable"
            />
        </StyledWrapper>
    );
}
