import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Action } from 'material-table';
import { ElementType, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import useDebounce from '@/hooks/useDebounce';
import Button from '@/wrappers/Button';
import OutlinedInput from '@/wrappers/OutlinedInput';

export type IAction<RowType extends object> = Pick<Action<RowType>, 'position' | 'tooltip' | 'onClick' | 'icon'> & {
    label?: string;
};

type IToolbarProps<RowType extends object> = {
    actions: IAction<RowType>[];
    title: string;
    showTitle: boolean;
    search: boolean;
};
type IDataTableToolBarProps<RowType extends object> = IToolbarProps<RowType> & {
    isFilterEnabled?: boolean;
    selectedRows?: RowType[];
    customActions?: ReactNode;
    labelAddButton?: ReactNode;
    searchPlaceHolder?: string;
    onFilterClicked?: () => void;
    searchText?: string;
    onSearchChanged?: (value: string) => void;
    components: { [name: string]: ElementType };
};

const Toolbar = <RowType extends object>(props: IDataTableToolBarProps<RowType>) => {
    const { onSearchChanged = () => {}, searchText, selectedRows = [], ...rest } = props;
    const { t } = useAppTranslation();
    const addButton = rest.actions.find((action) => action.position === 'toolbar' && action.tooltip === 'Add');
    const showTitle = rest.showTitle && rest.title;
    const [searchValue, setSearchValue] = useState(searchText || '');
    const debounced = useDebounce(searchValue, 500);
    const lastThrottleSearchValue = useRef(debounced);
    const numberOfSelectedRows = selectedRows.length ?? 0;
    const hasSelectedRows = numberOfSelectedRows > 0;

    useEffect(() => {
        if (onSearchChanged && lastThrottleSearchValue.current !== debounced) {
            onSearchChanged(debounced);
        }
    }, [debounced]);

    const handleSearchChanged = useCallback((newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }, []);

    const handleClearSearch = useCallback(() => {
        setSearchValue('');
    }, []);

    return (
        <AppBar position="relative" color="transparent" style={{ boxShadow: 'none' }}>
            <MuiToolbar
                sx={{
                    width: '100%',
                    padding: '16px !important',
                    justifyContent: !props.search && !showTitle ? 'flex-end' : 'flex-start'
                }}
            >
                {showTitle && (
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography key={'title'} variant="h5" component="span">
                            {props.title}
                        </Typography>
                    </Box>
                )}

                {props.search && (
                    <Box sx={{ flexGrow: showTitle ? undefined : 1 }}>
                        <FormControl>
                            <OutlinedInput
                                data-testid="search"
                                size="small"
                                placeholder={props.searchPlaceHolder || t('label.search', 'Search')}
                                onChange={handleSearchChanged}
                                name="search"
                                value={searchValue}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    searchValue && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="clear search text"
                                                onClick={handleClearSearch}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            />
                        </FormControl>
                    </Box>
                )}
                {hasSelectedRows && (
                    <Box sx={{ padding: '0 16px' }}>
                        <Typography key={'selectedRows'} variant="subtitle1" component="span">
                            {t('label.1rowsSelected', '{{rows}} row(s) was selected', { rows: numberOfSelectedRows })}
                        </Typography>
                    </Box>
                )}
                <Box sx={{ padding: '0 16px', display: 'flex', alignItems: 'center' }}>
                    <props.components.Actions
                        actions={props.actions.filter(({ position }) => position === 'toolbarOnSelect')}
                        data={props.selectedRows}
                        components={props.components}
                    />
                </Box>

                {props.isFilterEnabled && (
                    <Button key={'filter'} name="filter" onClick={props.onFilterClicked}>
                        Filter
                    </Button>
                )}

                {props.customActions}

                {addButton && (
                    <Button name="add" variant="contained" onClick={(e) => addButton.onClick(e, [])}>
                        {props.labelAddButton || addButton.tooltip}
                    </Button>
                )}
            </MuiToolbar>
        </AppBar>
    );
};

export default Toolbar;
