import Box from '@mui/material/Box';
import { forwardRef } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { useAppDispatch } from '@/data/hooks';
import { trainDaktelaQueue } from '@/data/Queues/QueueActions';
import useAppTranslation from '@/hooks/useAppTranslation';

export type IProps = {
    queueId?: number;
    queueName?: string;
    onSubmit?: () => void;
};

const Train = forwardRef<HTMLFormElement, IProps>(function TrainInner({ queueId, queueName, onSubmit }, ref) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const trainFieldsOptionsList = [
        {
            id: 'avg_sum_duration',
            label: t('label.averageSumDuration', 'Aggregate Summarization Duration')
        },
        {
            id: 'avg_waiting_time',
            label: t('label.averageWaitingTime', 'Average Waiting Time')
        },
        {
            id: 'avg_ringing_time',
            label: t('label.averageRingingTime', 'Average Ringing Time')
        },
        {
            id: 'avg_hold_time',
            label: t('label.averageHoldTime', 'Average Hold Time')
        },
        {
            id: 'avg_orig_pos',
            label: t('label.averageOriginalPosition', 'Average Original Position')
        },
        {
            id: 'omnichannel_count',
            label: t('label.channelCount', 'Channel Count')
        },
        {
            id: 'averaged_omnichannel_count',
            label: t('label.averageChannelCount', 'Average Channel Count')
        },
        {
            id: 'operators_count',
            label: t('label.averageOperatorsCount', 'Average Operators Count Aggregate')
        }
    ];

    const handleSubmit = (values: IOutputValueType) => {
        if (queueId) {
            dispatch(trainDaktelaQueue({ queue_id: queueId, field: values.trainField as string }));

            if (onSubmit) {
                onSubmit();
            }
        }
    };

    return (
        <FormGenerator
            ref={ref}
            id={queueId ? `train_${queueId}` : 'train'}
            name="train"
            className="train"
            fields={[
                {
                    type: 'select',
                    props: {
                        name: 'trainField',
                        label: t('label.trainField', 'Train Field'),
                        value: undefined,
                        required: true,
                        options: trainFieldsOptionsList
                    }
                },
                {
                    type: 'custom',
                    props: {
                        name: 'message',
                        values: {},
                        render: ({ watch }) => {
                            const trainField = watch('trainField') ?? '';

                            return trainField ? (
                                <Box>
                                    {t(
                                        'message.question.doYouWantToTrainWithField',
                                        'Do you want to train queue {{name}} model with field {{field}}?',
                                        {
                                            name: queueName ?? '',
                                            field:
                                                trainFieldsOptionsList.find(
                                                    (trainFieldOption) => trainFieldOption.id == trainField
                                                )?.label ?? ''
                                        }
                                    )}
                                </Box>
                            ) : (
                                <></>
                            );
                        },
                        validation: {
                            deps: ['trainField']
                        }
                    }
                }
            ]}
            actions={[
                {
                    type: 'button',
                    props: {
                        name: 'submit',
                        type: 'submit',
                        variant: 'contained',
                        children: 'Train',
                        hidden: true
                    }
                }
            ]}
            onSubmit={handleSubmit}
            displayAsModal={false}
        />
    );
});

export default Train;
