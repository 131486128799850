import Typography from '@mui/material/Typography';
import { Tooltip, XAxis, YAxis, Area, AreaChart, ReferenceLine, CartesianGrid, ResponsiveContainer } from 'recharts';
import { useAppSelector } from '@/data/hooks';
import { ISchedulePlanCoverageRequireForecastAccuracyReport } from '@/data/Reports/ReportModels';
import { isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading } from '@/data/Reports/ReportSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import LinearProgress from '@/wrappers/LinearProgress';

type IProps = {
    data?: ISchedulePlanCoverageRequireForecastAccuracyReport[];
    colorCoverage?: string;
    colorRequired?: string;
    width?: number;
    height?: number;
    timeZone?: string;
};

const SchedulePlanReportCoverageRequiredDiffChart = ({
    data = [],
    colorRequired = '#D82134',
    colorCoverage = '#29CA54',
    timeZone,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const isLoading = useAppSelector(isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading);
    const xAxisDateFormatter = useLocalizeDateFormatter({
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });

    const dateTickFormatter = (value: Date) => xAxisDateFormatter.format(value);

    const tickMin = Math.abs(Math.min(...data.map((minItem) => minItem.coverage - minItem.required)));
    const tickMax = Math.max(...data.map((maxItem) => maxItem.coverage - maxItem.required));
    const tickCount = Math.round((2 * Math.round((tickMin + 1) / 2) + 2 * Math.round((tickMax + 1) / 2)) / 2);

    return (
        <>
            <Typography variant="subtitle2">
                {t(
                    'label.schedulePlanAdherenceCoverageToRequired',
                    'Schedule Plan Reports Coverage/Required Difference'
                )}
            </Typography>
            {isLoading && <LinearProgress />}
            <ResponsiveContainer width={props.width} height={'50%'}>
                <AreaChart
                    syncId="areaChartCoverageToRequire"
                    data={
                        isLoading
                            ? undefined
                            : data.map((item) => {
                                  const diff = item.coverage - item.required;

                                  return {
                                      name: DateHelper.fromDateTimeString(item.start).toDate(),
                                      coverageDiff: diff >= 0 ? diff : 0,
                                      requiredDiff: diff < 0 ? diff : 0
                                  };
                              })
                    }
                    margin={{
                        top: 15,
                        right: 30,
                        left: 10,
                        bottom: 60
                    }}
                >
                    <defs>
                        <linearGradient id="colorDiffCoverage" x1="0" y1="1" x2="0" y2="0">
                            <stop offset="5%" stopColor={colorCoverage} stopOpacity={0.1} />
                            <stop offset="35%" stopColor={colorCoverage} stopOpacity={0.8} />
                        </linearGradient>
                        <linearGradient id="colorDiffRequired" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorRequired} stopOpacity={0.1} />
                            <stop offset="35%" stopColor={colorRequired} stopOpacity={0.8} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        angle={270}
                        tickMargin={40}
                        interval={95}
                        height={45}
                        tickFormatter={dateTickFormatter}
                    />
                    <YAxis
                        domain={([dataMin, dataMax]) => {
                            const max = 2 * Math.round(dataMax / 2);
                            const min = 2 * Math.round(Math.abs(dataMin) / 2);

                            return [-min, max];
                        }}
                        interval={0}
                        tickCount={tickCount}
                        label={{
                            value: t('label.operators', 'Operators'),
                            angle: -90,
                            position: 'insideBottomLeft'
                        }}
                    />
                    <CartesianGrid strokeDasharray="4 4" />
                    <ReferenceLine y={0} stroke={'#000'} />
                    <Area
                        type="monotone"
                        dataKey="requiredDiff"
                        stroke={colorRequired}
                        fill="url(#colorDiffRequired)"
                    />
                    <Area
                        type="monotone"
                        dataKey="coverageDiff"
                        stroke={colorCoverage}
                        fill="url(#colorDiffCoverage)"
                    />
                    <Tooltip
                        labelFormatter={(value) => dateTimeFormatter.format(value)}
                        formatter={(value, name) => [
                            Math.abs(parseFloat(value.toString())),
                            t(`label.${name}`, name.toString())
                        ]}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default SchedulePlanReportCoverageRequiredDiffChart;
