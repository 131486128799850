import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { MouseEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AttendanceButton from '@/components/layouts/Header/Appbar/AttendanceButton';
import { WebSocketContext } from '@/components/providers/WebSocketContextProvider';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { logout } from '@/data/System/SystemActions';
import { signedUser } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';
import LanguageMenu from '@/wrappers/LanguageMenu/LanguageMenu';

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
`;
const StyledBox = styled(Box)`
    margin-left: auto;
`;

const ContentBar = () => {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const signedUserData = useAppSelector(signedUser);
    const [socket] = useContext(WebSocketContext);
    const settings = [{ id: 'logout', title: t('label.logout', 'Logout') }];
    const dispatch = useAppDispatch();
    const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);
    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (id: string) => {
        setAnchorElUser(null);

        if (id === 'logout') {
            dispatch(logout(socket));
            navigate('/');
        }
    };
    const menuId = 'primary-search-account-menu';

    return (
        <StyledToolbar data-testid="appbar">
            <StyledBox>
                <AttendanceButton />
                {signedUserData ? serializeUser(signedUserData) : <></>}
                <LanguageMenu />
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                    color="inherit"
                    data-testid="button-menu"
                >
                    <Avatar />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    keepMounted
                    sx={{ mt: '45px' }}
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={!!anchorElUser}
                    onClose={handleCloseUserMenu}
                    data-testid="menu"
                >
                    {settings.map(({ id, title }) => (
                        <MenuItem key={id} onClick={() => handleCloseUserMenu(id)}>
                            <Typography textAlign="center">{title}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </StyledBox>
        </StyledToolbar>
    );
};

export default ContentBar;
