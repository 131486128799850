import { IUserSelectModel } from '@/data/Users/UserModels';

function serializeUser<T extends Omit<IUserSelectModel, 'id'>>(user?: T, firstNameFirst = false): string {
    return user
        ? (firstNameFirst
              ? [user.first_name, user.middle_name, user.last_name]
              : [user.last_name, user.middle_name, user.first_name]
          )
              .filter((part) => !!part)
              .join(' ')
        : '';
}

function serializeListOfUsers<T extends Omit<IUserSelectModel, 'id'>>(listOfUsers?: T[]): string {
    return listOfUsers ? listOfUsers.map((user) => serializeUser(user)).join(', ') : '';
}

export { serializeUser, serializeListOfUsers };
