import { useMemo } from 'react';
import { useAppSelector } from '@/data/hooks';
import { skillsForSelect } from '@/data/Skills/SkillSlice';
import { IUserSkills } from '@/data/Summaries/SummariesModels';
import { isUserSkillsLoading, userSkillsList } from '@/data/Summaries/SummariesSlice';
import { usersForSelect } from '@/data/Users/UserSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';
import Datatable from '@/wrappers/Datatable';

const Skills = () => {
    const { t } = useAppTranslation();
    const usersData = useAppSelector(usersForSelect);
    const summarizationUserData = useAppSelector(userSkillsList);
    const skillsData = useAppSelector(skillsForSelect);

    const generateSkills = useMemo(
        () =>
            skillsData
                .filter((skill) =>
                    summarizationUserData.some((summaryUser) =>
                        summaryUser.summary.some((summary) => summary.skill_id === skill.id)
                    )
                )
                .map((skill) => ({
                    title: skill.name,
                    field: `skill_${skill.id}`,
                    render: (rowdData: IUserSkills) =>
                        rowdData.summary.find((entity) => entity.skill_id === skill.id)?.hours ?? 0
                })),
        [skillsData, summarizationUserData]
    );

    return (
        <Datatable
            header={[
                {
                    title: t('label.user', 'User'),
                    field: 'name',
                    render: (rowData) => serializeUser(usersData.find((user) => user.id === rowData.user_id))
                },
                ...generateSkills
            ]}
            data={summarizationUserData}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            hasSorting={false}
            hasPaginating={false}
            isLoading={useAppSelector(isUserSkillsLoading)}
        />
    );
};

export default Skills;
