import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

const StyledPageHeading = styled('h2')`
    margin-top: 0;
    margin-bottom: 0;
`;
const StyledPageHeadingTitle = styled('span')`
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    margin-top: 10px;
    color: rgba(58, 53, 65, 0.68);
`;
const StyledPageHeadingContainer = styled(Box)`
    margin-top: 0;
`;

export type IPageHeading = PropsWithChildren<{
    error?: boolean;
    subTitle?: string;
}>;

const PageHeading = ({ children, subTitle, error = false, ...rest }: IPageHeading) => {
    const theme = useTheme();

    return (
        <StyledPageHeadingContainer {...rest}>
            <StyledPageHeading>{children}</StyledPageHeading>
            <StyledPageHeadingTitle sx={{ color: error ? theme.palette.error.main : theme.palette.text.secondary }}>
                {subTitle}
            </StyledPageHeadingTitle>
        </StyledPageHeadingContainer>
    );
};

export default PageHeading;
