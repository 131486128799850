import { IListResponseWithPaging, IListResponse } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IPeriodCUModel, IPeriodModel, IPeriodSelectModel } from './PeriodModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/periods', paging || {});

    return fetchGet<IListResponseWithPaging<IPeriodModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/periods/select', { search, fields });

    return fetchGet<IListResponse<IPeriodSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IPeriodModel>(`/api/periods/${id}`);
}

export function create(data: IPeriodCUModel) {
    return fetchPost<IPeriodModel>('/api/periods/', data);
}

export function update(id: number, data: IPeriodCUModel) {
    return fetchPatch<IPeriodModel>(`/api/periods/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/periods/${id}`);
}
