import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { forwardRef, memo } from 'react';

export type IButtonProps = Omit<MuiButtonProps, 'ref' | 'name'> & {
    name: string;
    justIcon?: boolean;
};

const Button = forwardRef<HTMLButtonElement, IButtonProps>(function ButtonInner({ hidden, justIcon, ...props }, ref) {
    return justIcon ? (
        <IconButton {...props} ref={ref} style={{ display: hidden ? 'none' : '' }} />
    ) : (
        <MuiButton {...props} ref={ref} style={{ display: hidden ? 'none' : '' }} />
    );
});

export default memo(Button);
