import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { Socket } from 'socket.io-client';
import { setAlert } from '@/data/Alert/AlertSlice';
import { IClientToServerEvents, IServerToClientEvents } from '@/data/Notification/NotificationModels';
import UserSourceEnum from '@/utils/enums/UserSourceEnum';
import {
    fetchNewToken,
    fetchSignIn,
    fetchSignInWithToken,
    resetPassword,
    resetPasswordByToken,
    sendEmailToResetPassword
} from './SystemApi';
import {
    ILoginPermissionsResponseModel,
    IResetPassword,
    IResetPasswordByToken,
    ISendEmailToResetPassword,
    IUserModel
} from './UserModel';

const getTimeoutForRefreshToken = (tokenGeneratedAt: number, tokenExpiredAt: number): number => {
    return (tokenExpiredAt - tokenGeneratedAt) * 1000 * 0.9;
};
let refreshTimeout: string | number | NodeJS.Timeout | null | undefined = null;

type IUserInToken = { iat: number; exp: number; iss: string } & IUserModel;

export const refreshToken = createAsyncThunk('system/refreshToken', async (_, thunkAPI) => {
    const token = localStorage.getItem('token');

    if (token && token.length > 0) {
        const response = await fetchNewToken();

        if (response.status < 300 && response.data) {
            const {
                token: newToken,
                permissions,
                attendance_plans: attendancePlans,
                language: language
            } = response.data;

            localStorage.setItem('token', newToken);

            const newPermissions: ILoginPermissionsResponseModel[] = Object.keys(permissions).map((name) => ({
                id: name,
                permission: permissions[name]
            }));

            const {
                user_to_roles: userToRoles,
                ...parsedToken
            }: IUserInToken & {
                user_to_roles: { role_id: number }[];
            } = jwtDecode(newToken);
            const timeout = getTimeoutForRefreshToken(parsedToken.iat, parsedToken.exp);

            refreshTimeout = setTimeout(() => thunkAPI.dispatch(refreshToken()), timeout);

            return {
                token: parsedToken,
                roles: userToRoles.map(({ role_id }) => role_id),
                permissions: newPermissions,
                language: language,
                schedulePlans: attendancePlans?.map((item) => ({ ...item, exists: item.exists === 1 })) ?? []
            };
        }
    }

    localStorage.removeItem('token');

    return Promise.reject('[SKIP] Invalid token');
});

export const signIn = createAsyncThunk('system/signIn', async (args: { login: string; password: string }, thunkAPI) => {
    const { token, permissions, schedulePlans, language } = await fetchSignIn(args.login, args.password).then(
        async (response) => ({
            token: response.token,
            permissions: response.permissions,
            language: response.language,
            schedulePlans: response.attendance_plans?.map((item) => ({ ...item, exists: item.exists === 1 })) ?? []
        })
    );

    const newPermissions: ILoginPermissionsResponseModel[] = Object.keys(permissions).map((name) => ({
        id: name,
        permission: permissions[name]
    }));

    const { user_to_roles: userToRoles, ...parsedToken }: IUserInToken & { user_to_roles: { role_id: number }[] } =
        jwtDecode(token);
    const timeout = getTimeoutForRefreshToken(parsedToken.iat, parsedToken.exp);

    refreshTimeout = setTimeout(() => thunkAPI.dispatch(refreshToken()), timeout);
    if (!parsedToken.iss || parsedToken.iss !== 'wfo') {
        return Promise.reject('Not authentificated');
    }

    localStorage.setItem('token', token);

    return {
        token: parsedToken,
        roles: userToRoles.map(({ role_id }) => role_id),
        permissions: newPermissions,
        language: language,
        schedulePlans
    };
});

export const signInWithToken = createAsyncThunk(
    'system/signInWithToken',
    async (args: { token: string; type: UserSourceEnum; login?: string }, thunkAPI) => {
        const { token, permissions, schedulePlans } = await fetchSignInWithToken(
            args.token,
            args.type,
            args.login
        ).then(async (response) => ({
            token: response.token,
            permissions: response.permissions,
            schedulePlans: response.attendance_plans?.map((item) => ({ ...item, exists: item.exists === 1 })) ?? []
        }));

        const newPermissions: ILoginPermissionsResponseModel[] = Object.keys(permissions).map((name) => ({
            id: name,
            permission: permissions[name]
        }));

        const { user_to_roles: userToRoles, ...parsedToken }: IUserInToken & { user_to_roles: { role_id: number }[] } =
            jwtDecode(token);
        const timeout = getTimeoutForRefreshToken(parsedToken.iat, parsedToken.exp);

        refreshTimeout = setTimeout(() => thunkAPI.dispatch(refreshToken()), timeout);
        if (!parsedToken.iss || parsedToken.iss !== 'wfo') {
            return Promise.reject('Not authentificated');
        }

        localStorage.setItem('token', token);

        return {
            token: parsedToken,
            roles: userToRoles.map(({ role_id }) => role_id),
            permissions: newPermissions,
            schedulePlans
        };
    }
);

export const logout = createAction(
    'system/signOut',
    (socket: Socket<IServerToClientEvents, IClientToServerEvents> | null) => {
        if (refreshTimeout !== null) {
            clearTimeout(refreshTimeout);
            refreshTimeout = null;
        }

        localStorage.removeItem('token');

        socket?.emit('logout');

        return { payload: true };
    }
);

export const sendEmailToResetUserPassword = createAsyncThunk(
    'system/sendEmailToResetPassword',
    async (args: ISendEmailToResetPassword, { dispatch }) => {
        return await sendEmailToResetPassword(args).then(() => {
            dispatch(
                setAlert({
                    status: 200,
                    context: 'message.status.emailWasSend',
                    defaultMessage: 'Email Was Send'
                })
            );
        });
    }
);

export const resetUserPasswordByToken = createAsyncThunk(
    'system/resetPasswordByToken',
    async (args: IResetPasswordByToken, thunkAPI) => {
        const response = resetPasswordByToken(args);

        response.then(() => {
            thunkAPI.dispatch(
                setAlert({
                    status: 200,
                    context: 'message.status.passwordWasReset',
                    defaultMessage: 'Password Was Reset'
                })
            );
        });

        return await response;
    }
);

export const resetUserPassword = createAsyncThunk('system/resetPassword', async (args: IResetPassword, thunkAPI) => {
    const response = resetPassword(args);

    response.then(() => {
        thunkAPI.dispatch(
            setAlert({
                status: 200,
                context: 'message.status.passwordWasReset',
                defaultMessage: 'Password Was Reset'
            })
        );
    });

    return await response;
});
