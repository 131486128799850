import * as Icons from '@mui/icons-material';
import { useMemo } from 'react';
import handleIconReplaces from '@/utils/icons/handleIconReplaces';

const useIcons = () => {
    const keys = useMemo(
        () =>
            Object.keys(Icons).filter(
                (item) =>
                    !item.includes('Outlined') &&
                    !item.includes('Rounded') &&
                    !item.includes('Sharp') &&
                    !item.includes('TwoTone')
            ),
        []
    );
    const icons = useMemo(
        () => [
            { id: '', label: '' },
            ...keys.map((key) => ({
                id: handleIconReplaces(key),
                label: key
            }))
        ],
        []
    );

    return icons;
};

export default useIcons;
