import CrudDatatable from '@/components/CrudDatatable';
import { useAppSelector } from '@/data/hooks';
import { fetchPeriods, removePeriod } from '@/data/Periods/PeriodActions';
import { IPeriodModel } from '@/data/Periods/PeriodModels';
import {
    isPeriodsListInProgress,
    periodPaging,
    periodRemovingStatus,
    selectFilteredPeriods
} from '@/data/Periods/PeriodSlice';
import PeriodForm from '@/forms/PeriodForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import DateRange from '@/wrappers/Datatable/CellViews/DateRange';

export default function PeriodsListPage() {
    const { t } = useAppTranslation();
    const inProgress = useAppSelector(isPeriodsListInProgress);
    const data = useAppSelector(selectFilteredPeriods);

    return (
        <LayoutOfPage title={t('title.periodsList', 'Periods List')}>
            <CrudDatatable<IPeriodModel>
                name="period"
                resource={PermissionsEnum.Periods}
                truncateCells={['description']}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <PeriodForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { title: t('header.periodName', 'Period Name'), field: 'name' },
                    { title: t('header.description', 'Description'), field: 'description' },
                    {
                        title: t('header.period', 'Period'),
                        field: 'period',
                        type: 'date',
                        sorting: false,
                        render: (rowData: IPeriodModel) => (
                            <DateRange start={rowData.period_start} end={rowData.period_end} />
                        )
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(periodPaging)}
                removingStatus={useAppSelector(periodRemovingStatus)}
                onFetchList={fetchPeriods}
                onRemove={removePeriod}
            />
        </LayoutOfPage>
    );
}
