import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import {
    getReportSchedulePlanId,
    isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading,
    isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading,
    setReportSchedulePlanId
} from '@/data/Reports/ReportSlice';
import { fetchSchedulePlansForSelect } from '@/data/SchedulePlans/SchedulePlanActions';
import { schedulePlanList } from '@/data/SchedulePlans/SchedulePlanSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import Box from '@/wrappers/Box';
import Select from '@/wrappers/Select';

type IProps = {
    isCoverageToRequire?: boolean;
    sx?: SxProps<Theme>;
};

const StyledNoScheduleBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    order: 1,
    alignSelf: 'stretch'
});

export const StyledNoScheduleBoxMessage = () => {
    const { t } = useAppTranslation();

    return (
        <StyledNoScheduleBox>
            <Typography variant="h6">
                {t('message.info.selectSchedulePlanForReport', 'Select schedule plan to display report')}
            </Typography>
        </StyledNoScheduleBox>
    );
};

const ReportSchedulePlanSelect = ({ isCoverageToRequire = false, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const schedulePlanDataList = useAppSelector(schedulePlanList);
    const schedulePlanId = useAppSelector(getReportSchedulePlanId);
    const isLoading = useAppSelector(isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading);
    const isPredictedTimeDataLoading = useAppSelector(isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading);

    useEffect(() => {
        dispatch(fetchSchedulePlansForSelect({ search: '' }));
    }, []);

    return (
        <Select
            name="schedulePlanId"
            disabled={isCoverageToRequire ? isLoading : isPredictedTimeDataLoading}
            label={t('label.schedulePlan', 'Schedule Plan')}
            options={schedulePlanDataList.map((schedule) => ({
                id: schedule.id.toString(),
                label: schedule.name
            }))}
            onChange={(value) => dispatch(setReportSchedulePlanId({ schedulePlanId: value ? parseInt(value) : null }))}
            value={schedulePlanId ?? undefined}
            sx={props.sx}
        />
    );
};

export default ReportSchedulePlanSelect;
