enum IntegrationsSettingsMicrosoftItemKeyMapEnum {
    name = 'name',
    middleName = 'middle_name',
    surname = 'surname',
    login = 'login',
    email = 'email',
    personalNumber = 'personal_number',
    phoneNumber = 'phone_number',
    userExternalID = 'user_external_id',
    roleExternalId = 'role_external_id',
    contractExternalID = 'contract_external_id',
    workplaceExternalID = 'workplace_external_id'
}

export default IntegrationsSettingsMicrosoftItemKeyMapEnum;
