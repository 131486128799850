import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import { createTimeoff, fetchTimeoffById, fetchTimeoffs, removeTimeoff, updateTimeoff } from './TimeoffActions';
import { ITimeoffModel } from './TimeoffModels';

type ITimeoffReducerState = {
    paging: IPaging;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: ITimeoffReducerState = {
    paging: defaultPaging('name'),
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<ITimeoffModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const timeoffReducer = createSlice({
    name: 'timeoffs',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTimeoffs.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchTimeoffs.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchTimeoffs.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchTimeoffById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchTimeoffById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchTimeoffById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createTimeoff.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createTimeoff.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createTimeoff.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeTimeoff.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeTimeoff.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(removeTimeoff.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateTimeoff.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateTimeoff.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateTimeoff.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

export const timeoffsInitialState = timeoffReducer.getInitialState();

const getState = (state: IRootState) => state[timeoffReducer.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default timeoffReducer.reducer;
export const isTimeoffsListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const timeoffPaging = (state: IRootState) => getState(state).paging;
export const isTimeoffsByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const timeoffList = adapterSelectors.selectAll;
export const timeoffById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) : undefined;
export const timeoffCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const timeoffUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const timeoffRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = timeoffReducer.actions;
