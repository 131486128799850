import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { ISchedulePlanModel } from '@/data/SchedulePlans/SchedulePlanModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button';
import DropDownMenu from '@/wrappers/DropDownMenu/DropDownMenu';

type IPrevNextPlanButton = {
    enabled: boolean;
    isPrev: boolean;
    plans: Omit<ISchedulePlanModel, 'next_schedule_plans'>[];
    onSelect: (id: string) => void;
};

const PrevNextPlanButton = ({ enabled, isPrev, plans, onSelect }: IPrevNextPlanButton) => {
    const { t } = useAppTranslation();

    return (
        <>
            {plans.length > 1 ? (
                <DropDownMenu
                    onClick={onSelect}
                    options={plans.map((item) => ({
                        id: `${item.id}`,
                        label: item.name
                    }))}
                >
                    <Button
                        name={`${isPrev ? 'prev' : 'next'}-plan`}
                        disabled={!enabled}
                        title={t(
                            `label.select${isPrev ? 'Prev' : 'Next'}Plan`,
                            `Select ${isPrev ? 'Prev' : 'Next'} Plan`
                        )}
                    >
                        {isPrev ? <UndoIcon /> : <RedoIcon />}
                    </Button>
                </DropDownMenu>
            ) : (
                <Button
                    name={`${isPrev ? 'prev' : 'next'}-plan`}
                    disabled={plans.length === 0 || !enabled}
                    title={t('label.goToPrevPlan', 'Go to Prev Plan')}
                    onClick={() => onSelect(`${plans[0].id}`)}
                >
                    {isPrev ? <UndoIcon /> : <RedoIcon />}
                </Button>
            )}
        </>
    );
};

export default PrevNextPlanButton;
