import Box from '@mui/material/Box';
import theme from '@/assets/theme';
import CrudDatatable from '@/components/CrudDatatable';
import { fetchContracts, removeContract } from '@/data/Contracts/ContractActions';
import { IContractModel } from '@/data/Contracts/ContractModels';
import {
    contractPaging,
    contractRemovingStatus,
    isContractListInProgress,
    selectFilteredContracts
} from '@/data/Contracts/ContractSlice';
import { useAppSelector } from '@/data/hooks';
import ContractForm from '@/forms/ContractForm';
import { normalizeFloatNumber } from '@/helpers/number';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Switch from '@/wrappers/Switch';
import Table from '@/wrappers/Table';

export default function ContractsListPage() {
    const { t } = useAppTranslation();
    const inProgress = useAppSelector(isContractListInProgress);
    const data = useAppSelector(selectFilteredContracts);

    return (
        <LayoutOfPage title={t('title.contractsList', 'Contracts List')}>
            <CrudDatatable<IContractModel>
                name="contract"
                resource={PermissionsEnum.Contracts}
                searchPlaceHolder={t('label.searchContract', 'Search Contract')}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <ContractForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { title: t('header.contractName', 'Contract Name'), field: 'name' },
                    {
                        title: t('header.holidaysAllowed', 'Holidays Allowed'),
                        field: 'holidays_allowed',
                        render: (rowData) => (
                            <Switch name={rowData.name} value={rowData.holidays_allowed} readOnly={true} />
                        )
                    },
                    {
                        title: t('header.nightShiftsAllowed', 'Night Shifts Allowed'),
                        field: 'night_shifts_allowed',
                        render: (rowData) => (
                            <Switch name={rowData.name} value={rowData.night_shifts_allowed} readOnly={true} />
                        )
                    }
                ]}
                detailPanel={[
                    {
                        render: (rowData) => {
                            return (
                                <Table
                                    name={`detail_of_contract_${rowData.id}`}
                                    rows={[
                                        {
                                            id: t('label.dayHoursLimit', 'Day Hours Limit'),
                                            value: rowData.day_hours_limit,
                                            id2: t('label.weekHoursLimit', 'Week Hours Limit'),
                                            value2: rowData.week_hours_limit,
                                            id3: t('label.numberOfHoursPerDay', 'Number Of Hours Per Day'),
                                            value3: rowData.number_of_hours_per_day
                                        },
                                        {
                                            id: t('label.minContinuousRestPerWeek', 'Min Continuous Rest Per Week'),
                                            value: rowData.min_continuous_rest_per_week,
                                            id2: t('label.minBreakHoursPerDay', 'Min Break Hours Per Day'),
                                            value2: rowData.min_break_hours_per_day,
                                            id3: t(
                                                'label.minBreakHoursPerDaySplitted',
                                                'Min Break Hours Per Day Splitted'
                                            ),
                                            value3: rowData.min_break_hours_per_day_splitted
                                        },
                                        {
                                            id: t('label.breakAtLeastAfterHours', 'Break At Least After Hours'),
                                            value: rowData.break_at_least_after_hours,
                                            id2: t('label.hoursLimit', 'Hours Limit'),
                                            value2: rowData.month_hours_limit
                                        }
                                    ]}
                                    columns={[
                                        {
                                            id: 'id',
                                            label: 'id',
                                            access: (subRowData) =>
                                                subRowData.id ? <Box>{subRowData.id}:</Box> : <></>
                                        },
                                        {
                                            id: 'value',
                                            label: 'value',
                                            access: (subRowData) =>
                                                subRowData.value === 0 || subRowData.value ? (
                                                    <Box>{normalizeFloatNumber(subRowData.value)}</Box>
                                                ) : (
                                                    <></>
                                                )
                                        },
                                        {
                                            id: 'id2',
                                            label: 'id2',
                                            access: (subRowData) =>
                                                subRowData.id2 ? <Box>{subRowData.id2}:</Box> : <></>
                                        },
                                        {
                                            id: 'value2',
                                            label: 'value2',
                                            access: (subRowData) =>
                                                subRowData.value2 === 0 || subRowData.value2 ? (
                                                    <Box>{normalizeFloatNumber(subRowData.value2)}</Box>
                                                ) : (
                                                    <></>
                                                )
                                        },
                                        {
                                            id: 'id3',
                                            label: 'id3',
                                            access: (subRowData) =>
                                                subRowData.id3 ? <Box>{subRowData.id3}:</Box> : <></>
                                        },
                                        {
                                            id: 'value3',
                                            label: 'value3',
                                            access: (subRowData) =>
                                                subRowData.value3 === 0 || subRowData.value3 ? (
                                                    <Box>{normalizeFloatNumber(subRowData.value3)}</Box>
                                                ) : (
                                                    <></>
                                                )
                                        }
                                    ]}
                                    columnProps={(columnId) =>
                                        columnId === 'id' || columnId === 'id2' || columnId === 'id3'
                                            ? { width: '20%' }
                                            : {}
                                    }
                                    rowProps={{
                                        sx: {
                                            backgroundColor: theme.palette.grey.A200,
                                            borderTop: '1px solid ' + theme.palette.grey.A700
                                        }
                                    }}
                                    showHeader={false}
                                />
                            );
                        }
                    }
                ]}
                hasSelection={false}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(contractPaging)}
                removingStatus={useAppSelector(contractRemovingStatus)}
                onFetchList={fetchContracts}
                onRemove={removeContract}
            />
        </LayoutOfPage>
    );
}
