import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { Action, DetailPanel } from 'material-table';
import { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import UserPermission, { isUserAllowed, Mode } from '@/components/UserPermision';
import { IRequestState } from '@/data/ApiRequest';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { IPaging } from '@/data/Paging';
import { getPermissionsList } from '@/data/System/SystemReducer';
import formatSnakeToPascal from '@/helpers/format/formatSnakeToPascal';
import useAppTranslation from '@/hooks/useAppTranslation';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';
import RemoveDialog from '@/wrappers/RemoveDialog';

type IRemoveActionArgs<IDType = number> = {
    id: IDType;
    onSuccess?: () => void;
};

type IBaseProps<RowType extends { id: IDType }, IDType = number> = Pick<IDatatableProps<RowType>, 'noRecordMessage'> & {
    resource?: PermissionsEnumType | PermissionsEnumType[];
    name: string;
    nameOfEntity: (row: RowType) => string;
    rowResource?: (row: RowType) => PermissionsEnumType | PermissionsEnumType[] | undefined;
    formRender: (
        id?: IDType,
        justIcon?: boolean,
        openButtonValue?: string,
        rowData?: RowType,
        renderButton?: (onClick: (event: MouseEvent) => void) => ReactElement
    ) => ReactElement;
    header: IDatatableProps<RowType>['header'];
    data: IDatatableProps<RowType>['data'];
    isLoading?: boolean;
    disableRemove?: boolean;
    disabled?: boolean;
    editByRowClick?: boolean;
    hasSelection?: boolean;
    paging?: IPaging;
    detailPanel?:
        | ((rowData: RowType) => React.ReactNode)
        | (DetailPanel<RowType> | ((rowData: RowType) => DetailPanel<RowType>))[];
    updatingStatus?: IRequestState;
    removingStatus?: IRequestState;
    titleOfActionHeader?: string;
    searchPlaceHolder?: string;
    dialogs?: (id?: IDType, rowData?: RowType) => ReactElement;
    actions?: (id?: IDType, rowData?: RowType) => ReactElement;
    toolbarActions?: IToolbarActions<RowType, IDType>[];
    truncateCells?: string[];
    editResourceMode?: IResourceModeProp;
};

type IAsyncProps<RowType extends { id: IDType }, IDType = number> = IBaseProps<RowType, IDType> & {
    onFetchList: (paging: Partial<IPaging>) => AsyncThunkAction<unknown, Partial<IPaging>, {}>;
    onRemove: (args: IRemoveActionArgs<IDType>) => AsyncThunkAction<IDType, IRemoveActionArgs<IDType>, {}>;
    handleRemove?: undefined;
};
type IHandleProps<RowType extends { id: IDType }, IDType = number> = IBaseProps<RowType, IDType> & {
    handleRemove?: (id: IDType) => void;
    onFetchList?: undefined;
    onRemove?: undefined;
};

type IProps<RowType extends { id: IDType }, IDType = number> =
    | IAsyncProps<RowType, IDType>
    | IHandleProps<RowType, IDType>;

type IToolbarActions<RowType extends { id: IDType }, IDType = number> = Action<RowType> & {
    resource?: IResourceModeProp & { id: PermissionsEnumType | PermissionsEnumType[] };
};

type IResourceModeProp = {
    mode: Mode | Mode[];
    operator?: 'AND' | 'OR';
};

const StyledButtonGroup = styled(ButtonGroup)`
    display: flex;
    justify-content: flex-end;
`;

export default function CrudDatatable<RowType extends { id: IDType }, IDType = number>({
    updatingStatus,
    removingStatus,
    name,
    titleOfActionHeader,
    disabled = false,
    disableRemove = false,
    editByRowClick,
    editResourceMode = {
        mode: Mode.UPDATE
    },
    hasSelection = false,
    ...props
}: IProps<RowType, IDType>) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const permissionsList = useAppSelector(getPermissionsList);
    const [removingSubmitted, setRemovingSubmitted] = useState(false);

    const onPagingChanged = (page: number, perPage: number) => {
        if (props.onFetchList) {
            dispatch(props.onFetchList({ page: page + 1, limit: perPage }));
        }
    };
    const onSortingChanged = (column: string, direction: 'asc' | 'desc') => {
        if (props.onFetchList) {
            dispatch(props.onFetchList({ page: 1, sort: column, direction }));
        }
    };
    const onSearchChanged = (searchText: string) => {
        if (props.onFetchList) {
            dispatch(props.onFetchList({ search: searchText, page: 1 }));
        }
    };
    const onRemove = (id: IDType, onSuccess: () => void) => {
        setRemovingSubmitted(true);

        if (props.onRemove) {
            dispatch(props.onRemove({ id, onSuccess }));
        }

        if (props.handleRemove) {
            props.handleRemove(id);
            setRemovingSubmitted(false);
        }
    };
    const reload = () => {
        if (props.paging) {
            const page = props.data.length > 0 ? props.paging.page : props.paging.page - 1;

            if (props.onFetchList) {
                dispatch(props.onFetchList({ ...props.paging, page }));
            }
        }
    };

    useEffect(() => {
        if (props.onFetchList) {
            dispatch(props.onFetchList({ page: 1 }));
        }
    }, []);

    useEffect(() => {
        if (removingSubmitted && removingStatus === 'idle') {
            reload();
        }
    }, [removingSubmitted, removingStatus]);

    useEffect(() => {
        if (updatingStatus === 'idle') {
            reload();
        }
    }, [updatingStatus]);

    const generateSubmitActions = useCallback(
        (rowData: RowType) => {
            return (
                <>
                    {props.actions ? props.actions(rowData.id, rowData) : ''}
                    {!editByRowClick && props.formRender(rowData.id, true, undefined, rowData)}
                </>
            );
        },
        [props, editByRowClick]
    );

    const generateDeleteActions = useCallback(
        (rowData: RowType) => {
            if (!disableRemove && (props.onRemove || props.handleRemove)) {
                return (
                    <RemoveDialog<IDType>
                        justIcon
                        onAgree={(onSuccess) => onRemove(rowData.id, onSuccess)}
                        nameOfItem={props.nameOfEntity(rowData)}
                        idOfItem={rowData.id}
                    />
                );
            } else {
                return <></>;
            }
        },
        [props, disableRemove]
    );

    const actions: IDatatableProps<RowType>['header'] = [
        {
            title: t('header.actions', 'Actions'),
            field: 'actions',
            width: 'min-content',
            sorting: false,
            align: 'right',
            cellStyle: {
                //Bohužel table nereaguje na width: auto ani na min/max-height -> toto tam je proto aby to dalo co nejmenší může-> stejně to je pak table zobrazení tak to srovna tak aby to sedělo.
                width: '1%',
                cursor: props.detailPanel ? 'initial' : 'inherit',
                // borderLeft: props.detailPanel ? '1px solid rgb(224, 224, 224)' : 'inherit'
                borderLeft: '1px solid rgb(224, 224, 224)'
            },
            render: (rowData: RowType) => (
                <>
                    <StyledButtonGroup
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {props.resource ? (
                            <>
                                <UserPermission
                                    id={
                                        props.rowResource
                                            ? props.rowResource(rowData) ?? props.resource
                                            : props.resource
                                    }
                                    mode={editResourceMode?.mode}
                                    operator={editResourceMode?.operator}
                                >
                                    {generateSubmitActions(rowData)}
                                </UserPermission>
                                <UserPermission
                                    id={
                                        props.rowResource
                                            ? props.rowResource(rowData) ?? props.resource
                                            : props.resource
                                    }
                                    mode={Mode.DELETE}
                                >
                                    {generateDeleteActions(rowData)}
                                </UserPermission>
                            </>
                        ) : (
                            <>
                                {generateSubmitActions(rowData)}
                                {generateDeleteActions(rowData)}
                            </>
                        )}
                    </StyledButtonGroup>
                    {props.dialogs ? props.dialogs(rowData.id, rowData) : ''}
                </>
            )
        }
    ];

    return (
        <Datatable<RowType>
            name={formatSnakeToPascal(name)}
            actionsInHeader={
                disabled
                    ? undefined
                    : !props.resource ||
                      isUserAllowed(
                          {
                              id: props.resource,
                              mode: Mode.CREATE
                          },
                          permissionsList
                      )
                    ? props.formRender(
                          undefined,
                          undefined,
                          t(`label.add${formatSnakeToPascal(name)}`, `Add ${formatSnakeToPascal(name)}`)
                      )
                    : undefined
            }
            searchPlaceHolder={props.searchPlaceHolder}
            hasSelection={hasSelection}
            paging={props.paging}
            hasPaginating={!!props.paging}
            isLoading={props.isLoading}
            truncateCells={props.truncateCells}
            actions={props.toolbarActions?.filter((item) =>
                item.resource ? isUserAllowed(item.resource, permissionsList) : true
            )}
            detailPanel={props.detailPanel}
            rowWrapper={
                editByRowClick
                    ? (rowData, openButton) => props.formRender(rowData.id, undefined, undefined, rowData, openButton)
                    : undefined
            }
            header={[...props.header, ...(disabled ? [] : actions)]}
            data={props.data}
            noRecordMessage={props.noRecordMessage}
            onPagingChanged={onPagingChanged}
            onSortingChanged={onSortingChanged}
            onSearchChanged={onSearchChanged}
        />
    );
}
