import { styled, SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

type IProps = {
    sx?: SxProps;
};

const StyledTitle = styled(Typography<'div'>)`
    text-align: center;
`;

const Title = ({ children, ...rest }: PropsWithChildren<IProps>) =>
    children ? (
        <StyledTitle {...rest} variant="h5" component="div">
            {children}
        </StyledTitle>
    ) : null;

export default Title;
