import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '@/data/request';
import { ISchedulePlanDetail } from '@/data/SchedulePlans/SchedulePlanApi';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { IWorkplaceModel } from '@/data/Workplaces/WorkplaceModels';
import {
    ISchedulePlanDayShiftCUModel,
    ISchedulePlanDayShiftFromBEModel,
    ISchedulePlanDayShiftSkillModel
} from './SchedulePlanDayShiftModels';

export function assignShift(data: ISchedulePlanDayShiftCUModel) {
    return fetchPost<
        Omit<ISchedulePlanDayShiftFromBEModel, 'schedule_plan_day_shift_skills'> & {
            schedule_plan_day_shift_skills: (ISchedulePlanDayShiftSkillModel & { skill: ISkillModel })[];
        } & Pick<ISchedulePlanDetail, 'body' | 'roles' | 'schedule_plan_days' | 'skills'>
    >(`/api/schedule-plans/${data.schedule_plan_id}/shifts`, data);
}

export function detachShift(schedulePlanId: number, id: number) {
    return fetchDelete<Pick<ISchedulePlanDetail, 'body' | 'roles' | 'schedule_plan_days' | 'skills' | 'table_body'>>(
        `/api/schedule-plans/${schedulePlanId}/shifts/${id}`
    );
}

export function updateShift(id: number, data: ISchedulePlanDayShiftCUModel) {
    return fetchPatch<
        Pick<ISchedulePlanDetail, 'roles' | 'schedule_plan_days' | 'skills' | 'body' | 'table_body'> &
            ISchedulePlanDayShiftFromBEModel
    >(`/api/schedule-plans/${data.schedule_plan_id}/shifts/${id}`, data);
}

export function assignShiftToUser(schedulePlanDayShiftId: number, userId: number) {
    return fetchPatch<
        ISchedulePlanDayShiftFromBEModel &
            Pick<ISchedulePlanDetail, 'body' | 'roles' | 'schedule_plan_days' | 'skills' | 'table_body'>
    >(`/api/schedule-plans-assignments/${schedulePlanDayShiftId}/assignShiftToUser/${userId}`, []);
}

export function declineShiftToUser(schedulePlanDayShiftId: number) {
    return fetchPatch<ISchedulePlanDayShiftFromBEModel>(
        `/api/schedule-plans-assignments/${schedulePlanDayShiftId}/declineShiftToUser`,
        []
    );
}

export function fetchCalendarList(dateFrom: string, dateTo: string) {
    const url = urlBuilderWithParams('/api/schedule-plans/calendar', {
        date_from: dateFrom,
        date_to: dateTo
    });

    return fetchGet<
        (ISchedulePlanDayShiftFromBEModel & {
            schedule_plan: {
                workplace: Pick<IWorkplaceModel, 'time_zone'>;
            };
        })[]
    >(url);
}
