import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { MouseEvent, useCallback, useState } from 'react';
import { useAppDispatch } from '@/data/hooks';
import { setLanguage } from '@/data/Languages/LanguageReducer';
import formatSnakeToPascal from '@/helpers/format/formatSnakeToPascal';
import useAppTranslation from '@/hooks/useAppTranslation';
import cs from '@/images/languages/cs.png';
import en from '@/images/languages/en-GB.png';
import config from '@/utils/config';
import { defaultLng } from '@/utils/i18next';

const StyledMenuItem = styled(Box)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        gap: ${theme.spacing(1.5)};
    `
);

const LanguageMenu = () => {
    const { t, i18n } = useAppTranslation();
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleOpenLanguageMenu = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    const handleClosureLanguageMenu = useCallback((id: string) => {
        i18n.changeLanguage(id, () => dispatch(setLanguage({ language: id })));

        handleClose();
    }, []);

    const languageList = config.languages;
    const resolvedLanguage = i18n.language ?? defaultLng;

    const getImage = useCallback((id: string) => {
        if (id === 'cs') {
            return cs;
        }

        return en;
    }, []);

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                data-testid="button-language-menu"
                onClick={handleOpenLanguageMenu}
            >
                <Avatar src={getImage(resolvedLanguage)}>{resolvedLanguage.toUpperCase()}</Avatar>
            </IconButton>
            <Menu
                onClose={handleClose}
                id="menu-language"
                open={!!anchorEl}
                anchorEl={anchorEl}
                data-testid={'select-option-language-menu'}
            >
                {languageList.map((id) => (
                    <MenuItem
                        data-testid={`select-option-language-${id}`}
                        disabled={resolvedLanguage === id}
                        key={id}
                        onClick={() => handleClosureLanguageMenu(id)}
                    >
                        <StyledMenuItem>
                            <Avatar src={getImage(id)}>{id.toUpperCase()}</Avatar>
                            <Typography textAlign="center">
                                {t(`label.language${formatSnakeToPascal(id)}`, id)}
                            </Typography>
                        </StyledMenuItem>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default LanguageMenu;
