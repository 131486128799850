import { styled } from '@mui/material/styles';
import AppBar from './Appbar';
import Sidebar from './Sidebar';

const StyledHeader = styled('header')`
    display: grid;
    grid-area: header;
    grid-template-columns: 260px auto;
`;

const Header = () => (
    <StyledHeader>
        <Sidebar />
        <AppBar />
    </StyledHeader>
);

export default Header;
