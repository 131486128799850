import { ICallable } from '@connect-soft/react-file-upload';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueueById, fetchUploadCsvFilesTempleFile } from '@/data/Queues/QueueActions';
import { isQueueCsvFileTempleFileInProgress, queueById } from '@/data/Queues/QueueSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import OmnichannelEnum from '@/utils/enums/OmnichannelEnum';
import Button from '@/wrappers/Button/Button';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';
import AggregateDaktelaQueue from '@/wrappers/QueuesPredictorsProcessDialogs/Items/AggregateDaktelaQueue';
import DeleteAggregationData from '@/wrappers/QueuesPredictorsProcessDialogs/Items/DeleteAggregationData';
import PullDataFromExternalSource from '@/wrappers/QueuesPredictorsProcessDialogs/Items/PullDataFromExternalSource';
import Train from '@/wrappers/QueuesPredictorsProcessDialogs/Items/Train';
import UploadCsv from '@/wrappers/QueuesPredictorsProcessDialogs/Items/uploadCsv';
import Select from '@/wrappers/Select';

type IProps = {
    queueId?: number;
};

const StyledTypeFilter = styled(Box)`
    padding: 8px;
    width: 100%;
`;
const StyledFormContent = styled(Box)`
    padding-top: 1em;
    width: 100%;
`;

const StyledTitle = styled(Box)`
    padding-top: 1em;
    width: 100%;
`;

export default function QueuesPredictorsProcessDialog({ queueId }: IProps) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const queue = useAppSelector((state) => queueById(state, queueId));
    const isCsvFileTempleInProgress = useAppSelector(isQueueCsvFileTempleFileInProgress);
    const formRef = useRef<HTMLFormElement>(null);
    const fileRef = useRef<ICallable>(null);
    const dialogRef = useRef<{ close: () => void }>(null);
    const [id, setId] = useState<string | null>(null);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        if (queueId) {
            dispatch(fetchQueueById(queueId));
        }
    }, []);

    const handleGetCsvFileTemple = useCallback(() => {
        dispatch(fetchUploadCsvFilesTempleFile());
    }, []);

    const handleSubmitDialog = useCallback(() => {
        if (id === 'uploadCsv') {
            fileRef.current?.sendFiles();
            setInProgress(true);
        } else {
            (formRef.current?.querySelector('button[type="submit"]') as HTMLButtonElement)?.click();
        }
    }, [id, fileRef.current, formRef.current]);

    const handleClose = useCallback(() => {
        dialogRef.current?.close();
    }, [dialogRef.current]);
    const handleCloseDialog = useCallback(() => {
        setInProgress(false);
    }, [dialogRef.current]);

    const typeOfQueuesPredictorsProcessOptionsList = [
        {
            id: 'pullDataFromExternalSource',
            label: t('label.pullDataFromExternalSource', 'Pull Data From External Source'),
            content: (
                <PullDataFromExternalSource
                    ref={formRef}
                    queueId={queueId}
                    queueName={queue?.name}
                    onSubmit={handleClose}
                />
            )
        },
        {
            id: 'aggregateDaktelaQueue',
            label: t('label.aggregateDaktelaQueue', 'Aggregate Daktela Queue'),
            content: (
                <AggregateDaktelaQueue
                    ref={formRef}
                    queueId={queue?.id}
                    queueName={queue?.name}
                    onSubmit={handleClose}
                />
            )
        },
        {
            id: 'train',
            label: t('label.train', 'Train'),
            content: <Train ref={formRef} queueId={queue?.id} queueName={queue?.name} onSubmit={handleClose} />
        },
        ...(queue?.queue_to_types?.some((item) => {
            return item.daktela_type === OmnichannelEnum.csv_file;
        })
            ? [
                  {
                      id: 'uploadCsv',
                      label: t('label.uploadCsv', 'Upload CSV'),
                      content: <UploadCsv ref={fileRef} queue={queue} onSubmit={handleClose} />
                  }
              ]
            : []),
        {
            id: 'deleteAggregationData',
            label: t('label.deleteAggregationData', 'Delete Aggregation Data'),
            content: (
                <DeleteAggregationData
                    ref={formRef}
                    queueId={queue?.id}
                    queueName={queue?.name}
                    onSubmit={handleClose}
                />
            )
        }
    ];

    return (
        <Dialog
            name="queuePredictorsProcessDialog"
            openButtonValue={undefined}
            title={
                <StyledTitle>
                    {t('label.queuePredictorsProcessDialog', 'Queue {{name}} Predictors Process Dialog', {
                        name: queue?.name ?? ''
                    })}
                </StyledTitle>
            }
            innerRef={dialogRef}
            maxWidth="md"
            fullWidth
            isPossibleClose={!inProgress}
            openButton={(onClick: (event?: MouseEvent) => void) => (
                <IconButton
                    title={t('label.queuesPredictorsProcessDialog', 'Queues Predictors Process Dialog')}
                    onClick={onClick}
                >
                    <MemoryOutlinedIcon />
                </IconButton>
            )}
            actions={(onClose) => (
                <>
                    <Button name="no" variant="outlined" onClick={onClose} disabled={inProgress}>
                        {t('label.close', 'Close')}
                    </Button>
                    {id === 'uploadCsv' && (
                        <LoadingButton
                            loading={isCsvFileTempleInProgress}
                            name="submit"
                            variant="outlined"
                            color="primary"
                            disabled={inProgress}
                            onClick={handleGetCsvFileTemple}
                        >
                            {t('label.downloadCsvTempleFile', 'Download Csv Temple File')}
                        </LoadingButton>
                    )}
                    <LoadingButton
                        disabled={id !== 'uploadCsv' && !id}
                        loading={inProgress}
                        name="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitDialog}
                    >
                        {t('label.send', 'Send')}
                    </LoadingButton>
                </>
            )}
            onClose={handleCloseDialog}
        >
            <StyledTypeFilter>
                <Select
                    label={t('label.typeOfQueuesPredictorsProcess', 'Type Of Queues Predictors Process')}
                    options={typeOfQueuesPredictorsProcessOptionsList.map(({ content, ...item }) => item)}
                    name={'typeOfQueuesPredictorsProcess'}
                    value={id ?? undefined}
                    disabled={inProgress}
                    onChange={(value) => {
                        setId(value);
                    }}
                />
            </StyledTypeFilter>
            <StyledFormContent>
                {typeOfQueuesPredictorsProcessOptionsList.find((item) => item.id === id)?.content}
            </StyledFormContent>
        </Dialog>
    );
}
