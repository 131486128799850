import loadConfig from '@/utils/envConfig';
import languages from '@/utils/languages';

const { appLocation, nodeEnv, version } = loadConfig();

const config = {
    isDevelop: nodeEnv === 'development',
    env: nodeEnv,
    subDir: appLocation + '/',
    version: version ?? 'v0.0-develop',
    apiServer: () => window.location.protocol + '//' + window.location.host,
    date: {
        inputFormat: 'DD/MM/YYYY',
        momentFormat: 'DD/MM/YYYY',
        dbFormat: 'YYYY-MM-DD',
        calendar: {
            headerFormat: 'ddd DD/MM'
        }
    },
    time: {
        inputFormat: 'HH:mm',
        dbFormat: 'HH:mm:ss',
        calendar: {
            eventFormat: 'HH:mm'
        }
    },
    dateTime: {
        format: 'YYYY-MM-DD HH:mm',
        inputFormat: 'DD/MM/YYYY HH:mm',
        formatWithSeconds: 'YYYY-MM-DD HH:mm:ss'
    },
    languages: languages
};

export default config;
