import Add from '@mui/icons-material/Add';
import Stack, { StackProps as MuiStackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Chip, { IChipProps } from '@/wrappers/Chip';
import Tooltip from '@/wrappers/Tooltip';

export type IChipStackValue = Pick<IChipProps, 'color'> & {
    id: string;
    label: string;
    title?: string;
    avatar?: IChipProps['avatar'];
};
export type IChipStackProps = MuiStackProps &
    Pick<IChipProps, 'variant'> & {
        name: string;
        overflowHeight?: string;
        onAdd?: () => void;
        onRemove?: (id: string) => void;
        values: IChipStackValue[];
        noAdd?: boolean;
        noDelete?: boolean;
        disabled?: boolean;
        readOnly?: boolean;
    };

const StyledStack = styled(Stack, {
    shouldForwardProp: (propName) => 'overflowHeight' !== propName
})<{ overflowHeight?: string }>(({ theme, overflowHeight }) => ({
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    ...(overflowHeight && {
        maxHeight: overflowHeight,
        overflowX: 'hidden',
        overflowY: 'auto',
        ['&::-webkit-scrollbar-thumb']: {
            backgroundColor: theme.palette.primary.main + ' !important',
            borderRadius: `${theme.shape.borderRadius}px`,
            margin: '0 2px'
        },
        ['&::-webkit-scrollbar']: {
            width: '8px',
            height: '8px'
        }
    })
}));

const ChipStack = ({
    className = '',
    onAdd,
    onRemove,
    noAdd,
    noDelete,
    values,
    disabled,
    readOnly,
    variant,
    overflowHeight,
    ...props
}: IChipStackProps) => (
    <StyledStack {...props} overflowHeight={overflowHeight} className={`${className} chipStack`}>
        {values.map(({ id, ...rest }) => {
            const chip = (
                <Chip
                    {...rest}
                    key={`chip_${id}`}
                    name={id.toString()}
                    variant={variant}
                    disabled={disabled}
                    onDelete={readOnly !== true && noDelete !== true && onRemove ? () => onRemove(id) : undefined}
                />
            );

            return rest.title ? (
                <Tooltip title={rest.title} key={`tooltip_${id}`} color="primary">
                    {chip}
                </Tooltip>
            ) : (
                chip
            );
        })}
        {!disabled && !readOnly && !noAdd && onAdd ? (
            <Chip name="add" variant={variant} color="primary" avatar={<Add color="inherit" />} disabled={disabled} />
        ) : null}
    </StyledStack>
);

export default ChipStack;
