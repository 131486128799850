import MuiOutlinedInput, { OutlinedInputProps as MuiOutlinedInputProps } from '@mui/material/OutlinedInput';

export type IOutlinedInputProps = Omit<MuiOutlinedInputProps, 'name' | 'onChange' | 'ref'> & {
    name: string;
    onChange?: (value: string) => void;
};

const OutlinedInput = ({ name, onChange, ...rest }: IOutlinedInputProps) => (
    <MuiOutlinedInput {...rest} name={name} onChange={(e) => onChange && onChange(e.target.value)} />
);

export default OutlinedInput;
