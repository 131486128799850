import { IListResponse } from '@/data/ApiResponse';
import { fetchDelete, fetchGet, fetchPost } from '@/data/request';
import { ISchedulePlanDayShiftFromBEModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { ISchedulePlanDetail } from '@/data/SchedulePlans/SchedulePlanApi';
import { IUserFromBeModel } from '@/data/Users/UserModels';
import { IShiftTradeCUModel, IShiftTradeExtendedModel, IShiftTradeModel } from './ShiftTradeModels';

export function fetchById(id: number) {
    return fetchGet<IShiftTradeModel>(`/api/shift-trades/${id}`);
}

export function create(data: IShiftTradeCUModel) {
    return fetchPost<IShiftTradeExtendedModel>('/api/shift-trades', data);
}

export function approve(tradeId: number) {
    return fetchPost(`/api/shift-trades/${tradeId}/approve`, {});
}

export function reject(tradeId: number) {
    return fetchPost(`/api/shift-trades/${tradeId}/reject`, {});
}

export function remove(id: number) {
    return fetchDelete(`/api/shift-trades/${id}`);
}

export function fetchTradesBySchedulePlanDayShiftId(schedulePlanDayShiftId: number) {
    return fetchGet<
        IListResponse<
            IShiftTradeModel & {
                schedule_plan_day_shift: Omit<
                    ISchedulePlanDayShiftFromBEModel<Omit<IUserFromBeModel, 'superior' | 'subordinate'>>,
                    'schedule_plan_day_shift_trades'
                >;
            }
        >
    >(`/api/shift-trades/assigned-shifts/${schedulePlanDayShiftId}/trades`);
}

export function acceptTradeWithoutCounterOffer(id: number) {
    return fetchPost<Pick<ISchedulePlanDetail, 'body' | 'roles' | 'schedule_plan_days' | 'skills'>>(
        `/api/shift-trades/${id}/accept-trade-without-counteroffer`
    );
}
