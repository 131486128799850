import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getEvolutionAlgorithmBlocksParametersSettingItems } from '@/data/SettingsItems/SettingsItemSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const EvolutionAlgorithmBlocksParameters = ({ ...rest }: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getEvolutionAlgorithmBlocksParametersSettingItems);
    const { t } = useAppTranslation();

    return (
        <FieldsGenerator
            {...rest}
            name="evolutionAlgorithmBlocksParametersData"
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'thread_count',
                        type: 'number',
                        label: t('label.threadCount', 'Thread Count'),
                        value: getSettingsItemValueByKey(data, 'thread_count')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'max_generations',
                        type: 'number',
                        label: t('label.maxGenerations', 'Max Generations'),
                        value: getSettingsItemValueByKey(data, 'max_generations')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'population_size',
                        type: 'number',
                        label: t('label.populationSize', 'Population Size'),
                        value: getSettingsItemValueByKey(data, 'population_size')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'limit_generations_without_improvement',
                        type: 'number',
                        label: t(
                            'label.limit_generations_without_improvement',
                            'Limit Generations Without Improvement'
                        ),
                        value: getSettingsItemValueByKey(data, 'limit_generations_without_improvement')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'simulated_annealing_period',
                        type: 'number',
                        label: t('label.simulated_annealing_period', 'Simulated Annealing Period'),
                        value: getSettingsItemValueByKey(data, 'simulated_annealing_period')
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'reversed_generating',
                        label: t('label.reversed_generating', 'Reversed Generating'),
                        value: (getSettingsItemValueByKey(data, 'reversed_generating') as boolean | undefined) ?? false
                    }
                }
            ]}
        />
    );
};

export default EvolutionAlgorithmBlocksParameters;
