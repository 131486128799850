import CrudDatatable from '@/components/CrudDatatable';
import { fetchCallCenters, removeCallCenter } from '@/data/CallCenters/CallCenterActions';
import {
    callCenterPaging,
    callCenterRemovingStatus,
    isCallCenterInProgress,
    selectFilteredCallCenters
} from '@/data/CallCenters/CallCenterSlice';
import { useAppSelector } from '@/data/hooks';
import CallCenterForm from '@/forms/CallCentersForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

export default function CallCentersListPage() {
    const { t } = useAppTranslation();
    const isInProgress = useAppSelector(isCallCenterInProgress);
    const entities = useAppSelector(selectFilteredCallCenters);
    const data = Object.values(entities);

    return (
        <LayoutOfPage title={t('title.callCentersList', 'Call Centers List')}>
            <CrudDatatable
                name="callCenter"
                resource={PermissionsEnum.CallCenters}
                nameOfEntity={(item) => `${item.id}`}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <CallCenterForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        title: t('header.name', 'Name'),
                        field: 'name'
                    },
                    {
                        title: t('header.url', 'URL'),
                        field: 'url'
                    }
                ]}
                data={data}
                isLoading={isInProgress}
                paging={useAppSelector(callCenterPaging)}
                removingStatus={useAppSelector(callCenterRemovingStatus)}
                onFetchList={fetchCallCenters}
                onRemove={removeCallCenter}
                hasSelection={false}
            />
        </LayoutOfPage>
    );
}
