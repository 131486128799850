import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { forwardRef, MouseEvent, useCallback, useEffect, useState } from 'react';
import useDebounce from '@/hooks/useDebounce';

export type IProps = Omit<TooltipProps, 'color'> & {
    color?: 'primary' | 'white';
    wrapperStyles?: BoxProps['sx'];
    wrapperProps?: Omit<BoxProps, 'ref' | 'sx'>;
    withBoxWrapper?: boolean;
    maxWidth?: string;
};

const StyledTooltip = styled(
    ({ className, children, ...props }: IProps) => (
        <MuiTooltip {...props} classes={{ popper: className }}>
            {children}
        </MuiTooltip>
    ),
    { shouldForwardProp: (propName) => !['color', 'maxWidth'].includes(propName as string) }
)(
    ({ theme, color, maxWidth }) => `
        & .${tooltipClasses.arrow} {
            color: ${color === 'primary' ? theme.palette.primary.main : theme.palette.common.white};
        }

        & .${tooltipClasses.tooltip} {
            max-width: ${maxWidth ? maxWidth : '20vw'};
            padding: ${theme.spacing(1)};
            background-color: ${color === 'primary' ? theme.palette.primary.main : theme.palette.common.white};
            color: ${color === 'primary' ? theme.palette.common.white : theme.palette.text.primary};
            box-shadow: ${theme.shadows[8]};
            font-size: ${theme.typography.fontSize}px;
            font-weight: ${theme.typography.fontWeightMedium};
        }
    `
);

const Tooltip = forwardRef<HTMLDivElement, IProps>(function TooltipInner(
    {
        children,
        disableHoverListener = false,
        open = false,
        withBoxWrapper = true,
        wrapperProps,
        wrapperStyles,
        ...rest
    },
    ref
) {
    const [element, setElement] = useState<MouseEvent<HTMLDivElement> | null>(null);

    const handleOpen = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            if (!disableHoverListener) {
                setElement(e);
            }
        },
        [disableHoverListener]
    );

    const lazyElement = useDebounce(element, 150);

    useEffect(() => {
        if (disableHoverListener && element !== null) {
            setElement(null);
        }
    }, [disableHoverListener]);

    useEffect(() => {
        setElement(null);
    }, []);

    return (
        <StyledTooltip
            {...rest}
            open={lazyElement !== null && !disableHoverListener}
            onMouseEnter={handleOpen}
            onMouseLeave={() => setElement(null)}
        >
            {withBoxWrapper ? (
                <Box data-name="tooltip-wrapper" {...wrapperProps} ref={ref} sx={wrapperStyles}>
                    {children}
                </Box>
            ) : (
                children
            )}
        </StyledTooltip>
    );
});

export default Tooltip;
