import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, forwardRef, useCallback } from 'react';

export type ISwitchProps = Omit<MuiSwitchProps, 'name' | 'onChange' | 'checked' | 'defaultValue' | 'defaultChecked'> & {
    name: string;
    value?: boolean;
    label?: string;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    onChange?: (newValue: boolean) => void;
};

const Switch = forwardRef<HTMLButtonElement, ISwitchProps>(function Inner(
    {
        label,
        title,
        value = false,
        labelPlacement = 'end',
        color = 'success',
        disabled,
        hidden,
        readOnly,
        onChange,
        ...rest
    },
    ref
) {
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!readOnly && onChange) {
                onChange(e.target.checked);
            }
        },
        [readOnly, onChange]
    );
    const muiSwitch = (
        <MuiSwitch
            {...rest}
            ref={ref}
            data-testid={`switch_value_${rest.name}`}
            checked={value}
            color={color}
            disabled={disabled ?? readOnly}
            readOnly={readOnly}
            sx={hidden ? { display: 'none' } : undefined}
            onClick={(event) => event.stopPropagation()}
            onChange={handleChange}
        />
    );

    const formControlLabel = (
        <FormControlLabel value={value} control={muiSwitch} label={label} labelPlacement={labelPlacement} />
    );

    const tooltipFormControlLabel = title ? (
        <Tooltip data-testid={`switch_${rest.name}`} title={title} sx={hidden ? { display: 'none' } : undefined}>
            {formControlLabel}
        </Tooltip>
    ) : (
        formControlLabel
    );

    return label ? (
        <FormGroup
            aria-label={label}
            data-testid={`switch_${rest.name}`}
            row
            sx={hidden ? { display: 'none' } : undefined}
        >
            {tooltipFormControlLabel}
        </FormGroup>
    ) : title ? (
        <Tooltip data-testid={`switch_${rest.name}`} title={title} sx={hidden ? { display: 'none' } : undefined}>
            {muiSwitch}
        </Tooltip>
    ) : (
        muiSwitch
    );
});

export default Switch;
