import { IApplicationSettingItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import {
    ISettingItems,
    ISettingsItemValue,
    ISettingsType,
    ISettingsValueFromBeAbstractModel
} from '@/data/SettingsItems/SettingsItemModels';
import ApplicationSettingsRequestItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsRequestItemEnum';
import ApplicationSettingsSchedulePlanItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsSchedulePlanItemEnum';
import IntegrationsSettingsMicrosoftItemEnum from '@/utils/enums/IntegrationsSettings/IntegrationsSettingsMicrosoftItemEnum';

export const getSettingsItemValueByKey = <T extends ISettingItems | IApplicationSettingItems>(
    items: T[],
    key: string | ISettingsKeyEnumsList
): T['value'] | undefined => {
    const settingsItem = items.find((item) => item.key === key);

    return settingsItem ? (settingsItem.value === '' ? undefined : settingsItem.value) ?? undefined : undefined;
};

type ISettingsKeyEnumsList =
    | ApplicationSettingsSchedulePlanItemEnum
    | ApplicationSettingsRequestItemEnum
    | IntegrationsSettingsMicrosoftItemEnum;

export const getSettingsItemBooleanValueByKey = <T extends ISettingItems | IApplicationSettingItems>(
    items: T[],
    key: ISettingsKeyEnumsList
): boolean | undefined => {
    const settingsItem = items.find((item) => item.key === key);

    if (settingsItem) {
        if (typeof settingsItem.value == 'boolean') {
            return settingsItem.value;
        }

        if (typeof settingsItem.value == 'string') {
            return settingsItem.value == '1' ? true : settingsItem.value == '0' ? false : undefined;
        }

        if (typeof settingsItem.value == 'number') {
            return settingsItem.value == 1 ? true : settingsItem.value == 0 ? false : undefined;
        }
    }

    return undefined;
};

export function getTransformSettingItemsValue(
    type: ISettingsType['type'],
    value_number: number | null,
    value_text: string | null,
    value_bool: boolean | null
): ISettingsItemValue['value'] {
    return (
        type === 'NUMBER' ? value_number : type === 'BOOL' ? value_bool : value_text
    ) as ISettingsItemValue['value'];
}
export function getReverseTransformSettingsItemValuesList(
    value: ISettingsItemValue['value']
): ISettingsValueFromBeAbstractModel {
    const type = typeof value === 'boolean' ? 'BOOL' : typeof value === 'number' ? 'NUMBER' : 'TEXT';

    return {
        type,
        value_bool: type === 'BOOL' ? Boolean(value) : null,
        value_number: type === 'NUMBER' ? parseInt(`${value}`) : null,
        value_text: type === 'TEXT' ? (typeof value === 'undefined' || value === null ? null : `${value}`) : null
    };
}
