import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import useAppTranslation from '@/hooks/useAppTranslation';
import ValidationEnum from '@/utils/enums/ValidationEnum';

type IProps = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const Forecasts = ({
    clearErrors,
    control,
    fullWidth,
    getFieldState,
    getValues,
    initialValues,
    register,
    setValue,
    watch
}: IProps) => {
    const { t } = useAppTranslation();

    const validationOptions = Object.keys(ValidationEnum).map((key) => ({
        id: ValidationEnum[key as keyof typeof ValidationEnum],
        sortableValue: t(`enums.validation.${key}`, key)
    }));

    return (
        <FieldsGenerator
            control={control}
            register={register}
            getFieldState={getFieldState}
            getValues={getValues}
            initialValues={initialValues}
            watch={watch}
            setValue={setValue}
            clearErrors={clearErrors}
            fullWidth={fullWidth}
            fields={[
                {
                    type: 'timeRangeOrTimeAndLength',
                    props: {
                        name: 'nightTimeStartEnd',
                        label: {
                            start: t('label.nightTimeStart', 'Night Time Start'),
                            end: t('label.nightTimeEnd', 'Night Time End'),
                            length: t('label.length', 'Length'),
                            range: t('label.range', 'Range')
                        },
                        minDuration: 0
                    }
                },
                {
                    type: 'transferList',
                    props: {
                        name: 'selectedDisabledValidations',
                        items: validationOptions
                    }
                }
            ]}
        />
    );
};

export default Forecasts;
