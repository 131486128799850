import Typography from '@mui/material/Typography';
import { Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAppSelector } from '@/data/hooks';
import { ISchedulePlanPredictedTimeForecastAccuracyReport } from '@/data/Reports/ReportModels';
import { isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading } from '@/data/Reports/ReportSlice';
import DateHelper from '@/helpers/date/DateHelper';
import formatSecondsToTimeFormat from '@/helpers/format/formatSecondsToTimeFormat';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import LinearProgress from '@/wrappers/LinearProgress';

type IProps = {
    data?: ISchedulePlanPredictedTimeForecastAccuracyReport[];
    colorRealAverageDurationDifference?: string;
    colorRealDurationDifference?: string;
    width?: number;
    height?: number;
    timeZone?: string;
};

const SchedulePlanReportAverageDurationToDurationChartDiff = ({
    data = [],
    colorRealAverageDurationDifference = '#CA6E0E',
    colorRealDurationDifference = '#7159D8',
    timeZone,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const formatter = useLocalizeTimeFormatter('medium');
    const isLoading = useAppSelector(isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading);
    const xAxisDateFormatter = useLocalizeDateFormatter({
        timeZone,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateTickFormatter = (value: Date) => xAxisDateFormatter.format(value);
    const YAxisTickFormatter = (value: string) =>
        formatSecondsToTimeFormat(Math.abs(parseFloat(value.toString())), formatter);

    return (
        <>
            <Typography variant="subtitle2">
                {t(
                    'label.schedulePlanReportAverageDurationToRealDurationDifference',
                    'Schedule Plan Report Average Duration/Real Duration Difference'
                )}
            </Typography>
            {isLoading && <LinearProgress />}
            <ResponsiveContainer width={props.width} height={'50%'}>
                <AreaChart
                    syncId="areaAverageDurationToRealDuration"
                    data={data.map((item) => {
                        const diff = item.real_avg_duration - item.real_duration;

                        return {
                            name: DateHelper.fromDateTimeString(item.start).toDate(),
                            realAverageDurationDifference: diff >= 0 ? diff : 0,
                            realDurationDifference: diff < 0 ? diff : 0
                        };
                    })}
                    margin={{
                        top: 15,
                        right: 30,
                        left: 10,
                        bottom: 40
                    }}
                >
                    <defs>
                        <linearGradient id="colorRealAverageDurationDifference" x1="0" y1="1" x2="0" y2="0">
                            <stop offset="5%" stopColor={colorRealAverageDurationDifference} stopOpacity={0.1} />
                            <stop offset="35%" stopColor={colorRealAverageDurationDifference} stopOpacity={0.8} />
                        </linearGradient>
                        <linearGradient id="colorRealDurationDifference2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colorRealDurationDifference} stopOpacity={0.1} />
                            <stop offset="35%" stopColor={colorRealDurationDifference} stopOpacity={0.8} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        angle={270}
                        tickMargin={40}
                        interval={95}
                        height={45}
                        tickFormatter={dateTickFormatter}
                    />
                    <YAxis
                        domain={([dataMin, dataMax]) => {
                            const max = 2 * Math.round(dataMax / 2);
                            const min = 2 * Math.round(Math.abs(dataMin) / 2);

                            return [-min, max];
                        }}
                        label={{ value: t('label.time', 'Seconds'), position: 'bottom', offset: 20 }}
                        tickFormatter={YAxisTickFormatter}
                    />
                    <CartesianGrid strokeDasharray="4 4" />
                    <ReferenceLine y={0} stroke="#000" />
                    <Area
                        type="monotone"
                        dataKey="realAverageDurationDifference"
                        stroke={colorRealAverageDurationDifference}
                        fill="url(#colorRealAverageDurationDifference)"
                    />
                    <Area
                        type="monotone"
                        dataKey="realDurationDifference"
                        stroke={colorRealDurationDifference}
                        fill="url(#colorRealDurationDifference2)"
                    />
                    <Tooltip
                        labelFormatter={(value) => dateTimeFormatter.format(value)}
                        formatter={(value, name) => {
                            return [
                                formatSecondsToTimeFormat(Math.abs(parseFloat(value.toString())), formatter),
                                t(`label.${name}`, name.toString())
                            ];
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default SchedulePlanReportAverageDurationToDurationChartDiff;
