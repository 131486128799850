import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { forwardRef, useCallback, useState } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import { ISelectOption } from '@/wrappers/Select';

export type IMultiSelectProps = Omit<SelectProps, 'name' | 'label' | 'defaultValue' | 'onChange'> & {
    name: string;
    label: string;
    options: ISelectOption[];
    value?: string[];
    error?: boolean;
    helperText?: string;
    onChange?: (value: string[] | null) => void;
};

const StyledControl = styled(FormControl)`
    width: 100%;
`;
const StyledChipGroup = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(1)};
    `
);
const StyledChip = styled(Chip)`
    background: rgba(112, 50, 160, 0.08);
`;

const MultiSelect = forwardRef<HTMLDivElement, IMultiSelectProps>(function Inner(
    {
        name,
        label,
        disabled,
        required,
        readOnly,
        options,
        value = [],
        size = 'small',
        error,
        helperText,
        onChange,
        ...props
    },
    ref
) {
    const { t } = useAppTranslation();
    const [data, setData] = useState<string[]>(value);
    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { value: newValue } = event.target;
        const filter = options.filter((option) => (newValue as string[]).find((item) => option.id === item));

        setData(value as string[]);

        if (onChange) onChange(filter.map((item) => item.id));
    };

    const handleClearClick = useCallback(() => {
        setData([]);
        if (onChange) onChange([]);
    }, []);

    return (
        <StyledControl variant="outlined" size={size} data-testid="component-multiselect" error={error}>
            <InputLabel id={`multiselect-${name}`}>{`${label || name}${required ? ' *' : ''}`}</InputLabel>
            <Select<string[]>
                {...props}
                ref={ref}
                data-testid={`multiselect-menu-${name}`}
                name={name}
                labelId={`multiselect-${name}`}
                multiple
                disabled={disabled}
                readOnly={readOnly}
                value={data}
                error={error}
                onChange={handleChange}
                input={<OutlinedInput label={label || name} />}
                endAdornment={
                    !readOnly ? (
                        <IconButton
                            sx={{
                                marginRight: 4,
                                width: 2,
                                height: 2,
                                display: data.length > 0 ? '' : 'none'
                            }}
                            title={t('label.clear', 'Clear')}
                            onClick={handleClearClick}
                        >
                            <ClearIcon />
                        </IconButton>
                    ) : undefined
                }
                renderValue={(selected) => (
                    <StyledChipGroup>
                        {selected
                            .map(
                                (item) =>
                                    options.find((optionItem) => optionItem.id == item) ?? { id: item, label: item }
                            )
                            .sort((prev, next) => prev.label.localeCompare(next.label))
                            .map((item) => (
                                <StyledChip key={`key_${item?.id}`} label={item?.label} size={size} />
                            ))}
                    </StyledChipGroup>
                )}
            >
                {options?.map((item) => (
                    <MenuItem
                        data-testid={`multiselect-option-value-${item.custom?.testId ?? item.label}`}
                        key={item.id}
                        value={item.id}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </StyledControl>
    );
});

export default MultiSelect;
