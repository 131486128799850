import { useMemo } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';

const useLocalizeTime = (time: DateTimeType | Date | string | null, timeZone?: string) => {
    const formatter = useLocalizeTimeFormatter(undefined, timeZone);

    return useMemo(() => {
        if (time) {
            const obj = DateHelper.fromOptionalTime(time);

            if (obj?.isValid()) return formatter.format(obj?.toDate());
        }

        return null;
    }, [time]);
};

export default useLocalizeTime;
