import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { getErrorByFieldName } from '@/base/FormGenerator/utils';
import { normalize } from '@/helpers/color';
import useDebounce from '@/hooks/useDebounce';
import { message } from '@/utils/validations';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';

export type IColorPickerProps = Omit<ITextFieldProps, 'onChange'> & {
    control: Control;
    label?: string;
    value?: string;
    disableAlpha?: boolean;
    validation?: RegisterOptions;
};

const StyledAdornment = styled('div', {
    shouldForwardProp: (props) => props !== 'background'
})<{ background?: string }>(
    ({ theme, background }) => `
        width: ${theme.spacing(3)};
        min-width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
        min-height: ${theme.spacing(3)};
        margin: ${theme.spacing(1)};
        padding: 0;
        background-color: ${background};
        border-radius: ${theme.shape.borderRadius}px;
    `
);
const StyledButton = styled(Button, {
    shouldForwardProp: (props) => props !== 'background'
})<{ background?: string }>(
    ({ theme, background }) => `
        width: ${theme.spacing(3)};
        min-width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
        min-height: ${theme.spacing(3)};
        margin: ${theme.spacing(1)} 0;
        padding: 0;
        background-color: ${background};
        background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%);
        background-size: 8px 8px;
        background-position: 0 0, 4px 0, 4px -4px, 0px 4px;
        background-clip: content-box;
        border-radius: ${theme.shape.borderRadius}px;
        border: 1px solid rgba(0,0,0,0.15);
    `
);
const StyledButtonContent = styled('div', {
    shouldForwardProp: (props) => props !== 'background'
})<{ background?: string }>(
    ({ theme, background }) => `
        width: 100%;
        height: 100%;
        background-color: ${background};
        border-radius: ${theme.shape.borderRadius}px;
    `
);

const ColorPicker = ({
    control,
    name,
    label,
    value = '#000000',
    disableAlpha,
    readOnly,
    ...rest
}: IColorPickerProps) => {
    const [element, setElement] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setElement(event.currentTarget);
    const handleClose = () => setElement(null);

    const [innerEvent, setInnerEvent] = useState<{
        value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null;
        handler: (event: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    }>({
        value: null,
        handler: () => {}
    });
    const debounced = useDebounce(innerEvent.value, 200);

    useEffect(() => {
        if (innerEvent.value) {
            innerEvent.handler(innerEvent.value);
        }
    }, [debounced]);

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={normalize(value)}
            shouldUnregister
            rules={{ ...rest.validation, required: rest.required ? message.required : undefined }}
            render={({ field, formState: { errors } }) => {
                const error = getErrorByFieldName(errors, field.name);

                return (
                    <>
                        <TextField
                            {...rest}
                            {...field}
                            label={label}
                            data-testid={`color_picker_${name}`}
                            onChange={(event) => setInnerEvent({ value: event, handler: field.onChange })}
                            error={!!error}
                            helperText={error ? error.message : rest.helperText}
                            InputProps={{
                                endAdornment: readOnly ? (
                                    <StyledAdornment background={field.value} />
                                ) : (
                                    <StyledButton
                                        disableElevation
                                        background={field.value}
                                        variant="contained"
                                        onClick={handleClick}
                                    >
                                        <StyledButtonContent background={field.value} />
                                    </StyledButton>
                                )
                            }}
                        />
                        <Popover
                            open={!!element}
                            anchorEl={element}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            sx={{ top: '5px' }}
                            onClose={handleClose}
                        >
                            <Box data-testid={`${label}-colorPickerPopoverBox`} sx={{ padding: 1.5 }}>
                                <HexAlphaColorPicker
                                    color={field.value}
                                    onChange={(event) =>
                                        setInnerEvent({
                                            value: event,
                                            handler: field.onChange
                                        })
                                    }
                                />
                            </Box>
                        </Popover>
                    </>
                );
            }}
        />
    );
};

export default ColorPicker;
