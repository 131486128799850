import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import MuiIcon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { IUserModel } from '@/data/Users/UserModels';
import handleIconReplaces from '@/utils/icons/handleIconReplaces';
import { serializeUser } from '@/utils/UserHelper';

export type IUserSkillWrapperProps = {
    user: Omit<IUserModel, 'user_to_contracts' | 'user_to_requests' | 'user_to_workplaces' | 'user_to_vacation_fund'>;
};

const StyledUser = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledUserDetail = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const StyledSkillInName = styled(Box)`
    display: flex;
    flex-direction: row;
`;

const StyledSkill = styled(Box)`
    display: flex;
    align-items: center;
`;

const UserSkillWrapper = (props: PropsWithChildren<IUserSkillWrapperProps>) => {
    return (
        <>
            <StyledUser>
                <StyledUserDetail>
                    {serializeUser(props.user)}
                    <StyledSkillInName>
                        {props.user?.user_to_skills?.map(({ skill }) => (
                            <StyledSkill key={skill.id}>
                                {skill?.icon && (
                                    <MuiIcon
                                        title={skill?.name}
                                        sx={{
                                            marginRight: 1,
                                            color: skill.color + ' !important'
                                        }}
                                    >
                                        {handleIconReplaces(skill?.icon)}
                                    </MuiIcon>
                                )}
                            </StyledSkill>
                        ))}
                    </StyledSkillInName>
                </StyledUserDetail>
                <PersonOutlineOutlinedIcon />
            </StyledUser>
        </>
    );
};

export default UserSkillWrapper;
