import { useMemo } from 'react';
import { IRoleModel } from '@/data/Roles/RoleModels';
import { ISchedulePlanDayOptModel } from '@/data/SchedulePlanDays/SchedulePlanDayModels';
import { ISkillModel } from '@/data/Skills/SkillModels';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { generateHeaderColumnsData } from '@/helpers/schedule';
import useSchedulerColumnTitles from '@/hooks/scheduler/useSchedulerColumnTitles';

const useSchedulerRequirements = (
    isDayMode: boolean,
    from: DateTimeType | null,
    to: DateTimeType | null,
    schedulePlanDays: ISchedulePlanDayOptModel[],
    skills: ISkillModel[] | null,
    roles: IRoleModel[] | null
) => {
    const columnTitles = useSchedulerColumnTitles(isDayMode, from, to);

    return useMemo(() => {
        if (!from || !to) {
            return [];
        }

        const tempTo = isDayMode ? to : DateHelper.addDays(to, 1);
        const schedulePlanDaysTransformed = schedulePlanDays
            .map((item) => ({
                ...item,
                from: DateHelper.fromDateTimeString(item.start)
            }))
            .filter((item) => DateHelper.isBetween(item.from, from, tempTo, '[)'));

        const result = [
            ...(roles ?? [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ id, ...role }) => ({
                    ...role,
                    id: -id,
                    isSkill: false
                })),
            ...(skills ?? [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((skill) => ({
                    ...skill,
                    isSkill: true
                }))
        ]
            .map(({ id, name, isSkill, icon, color }) => ({
                id,
                name,
                isSkill,
                icon,
                color,
                ...generateHeaderColumnsData(
                    isDayMode,
                    Math.abs(id),
                    isSkill,
                    columnTitles,
                    schedulePlanDaysTransformed
                )
            }))
            .filter(({ hasAnyRequirements }) => hasAnyRequirements);

        return result.length > 0 ? result : null;
    }, [
        isDayMode,
        from?.toISOString(),
        to?.toISOString(),
        JSON.stringify([columnTitles, roles, skills, schedulePlanDays])
    ]);
};

export default useSchedulerRequirements;
