import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { removeContract } from '@/data/Contracts/ContractActions';
import { IContractModel } from '@/data/Contracts/ContractModels';
import { contractEntities } from '@/data/Contracts/ContractSlice';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { IRootState } from '@/data/store';
import { createUser, fetchUserById, removeUser, updateUser } from '@/data/Users/UserActions';
import { IUserToContractModel } from '@/data/UserToContracts/UserToContractModels';

type IState = {
    updatingStatus: IRequestState;
};

const initialState: IState = {
    updatingStatus: 'idle'
};

const adapter = createEntityAdapter<IUserToContractModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.id < b.id ? 1 : -1)
});

const userToContractSlice = createSlice({
    name: 'userToContracts',
    initialState: adapter.getInitialState<IState>(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                const userIds = action.payload.workplace.user_to_workplaces.map(({ user_id }) => user_id);

                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item && userIds.includes(item.user_id)
                        ) as IUserToContractModel[]
                    ).map(({ id }) => id)
                );
                adapter.upsertMany(
                    state,
                    action.payload.users
                        .flatMap(({ user_to_contracts }) => user_to_contracts)
                        .map(({ contract, ...entity }) => entity)
                );
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_contracts.map(({ contract, ...item }) => item)
                );
            })
            .addCase(createUser.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_contracts.map(({ contract, ...item }) => item)
                );
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (Object.values(state.entities) as IUserToContractModel[]).map(({ id }) => id)
                );
                adapter.upsertMany(
                    state,
                    action.payload.user_to_contracts.map(({ contract, ...item }) => item)
                );
            })
            .addCase(removeContract.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.contract_id === action.meta.arg.id
                        ) as IUserToContractModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.user_id === action.meta.arg.id
                        ) as IUserToContractModel[]
                    ).map(({ id }) => id)
                );
            });
    }
});

const getState = (state: IRootState) => state[userToContractSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userToContractSlice;

export const selectUserToContracts = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => contractEntities(state),
    (userToContracts, contracts) => {
        const result: (IUserToContractModel & { contract: IContractModel })[] = [];

        userToContracts.forEach((item) => {
            const contract = contracts[item.contract_id];

            if (contract) {
                result.push({
                    ...item,
                    contract
                });
            }
        });

        return result;
    }
);
