import { styled } from '@mui/material/styles';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { ChangeEvent, forwardRef, useCallback } from 'react';

export type ITextFieldProps = Omit<MuiTextFieldProps, 'name' | 'onChange' | 'ref' | 'defaultValue' | 'value'> & {
    name: string;
    readOnly?: boolean;
    value?: MuiTextFieldProps['value'] | null;
    onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => void;
};

const StyledTextField = styled(MuiTextField)(({ hidden }) => ({
    ...(hidden && { display: 'none' })
}));

const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(function Inner(
    { hidden, InputProps, required = false, size = 'small', autoComplete = 'off', readOnly, value, onChange, ...props },
    ref
) {
    const memoizedOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!readOnly && onChange) {
                onChange(e, e.target.value);
            }
        },
        [onChange]
    );

    return (
        <StyledTextField
            {...props}
            ref={ref}
            hidden={hidden}
            required={required}
            size={size}
            value={value === null ? undefined : value ?? ''}
            onChange={memoizedOnChange}
            InputProps={{
                ...InputProps,
                required: false,
                readOnly: InputProps?.readOnly ?? readOnly ?? undefined
            }}
        />
    );
});

export default TextField;
