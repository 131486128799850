import { memo } from 'react';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import RadioGroup from '@/wrappers/RadioGroup';
import UnControlledWeekDaysPicker from './UnControlledWeekDaysPicker';

export type IWeekdaysPickerProps = {
    name: string;
    readOnly?: boolean;
    disabled?: boolean;
    required?: boolean;
    value?: number;
    multiple?: boolean;
    onChange?: (value: number) => void;
};

function WeekdaysPicker({ name, disabled, multiple, readOnly, required, value, onChange }: IWeekdaysPickerProps) {
    const translatedDays = useTranslatedDays();

    if (multiple) {
        return (
            <UnControlledWeekDaysPicker
                name={name}
                disabled={disabled}
                onChange={onChange}
                readOnly={readOnly}
                value={value}
            />
        );
    }

    return (
        <RadioGroup
            row
            labelPlacement="bottom"
            required={required}
            name={name}
            disabled={disabled}
            options={translatedDays.map((day) => ({
                id: day.id.toString(),
                label: day.name
            }))}
        />
    );
}

export default memo(WeekdaysPicker);
