import WindowSharpIcon from '@mui/icons-material/WindowSharp';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect } from 'react';
import FormGenerator from '@/base/FormGenerator';
import { WebSocketContext } from '@/components/providers/WebSocketContextProvider';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { setLanguage } from '@/data/Languages/LanguageReducer';
import { connectToWS, currentSocket } from '@/data/Notification/NotificationActions';
import { fetchAzureAuthenticateUrl } from '@/data/SettingsItems/SettingsItemActions';
import { getAzureAuthenticateUrl, isAzureEnabled } from '@/data/SettingsItems/SettingsItemSlice';
import { signIn } from '@/data/System/SystemActions';
import { isSigningInProgress } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import { resetPassword } from '@/utils/routes';

type IProps = {
    className?: string;
};

const StyledLink = styled(Link)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        font-weight: 700;
        text-decoration: none;
    `
);
const StyledLabel = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        font-weight: 400;
    `
);

export const StyledSeparator = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.text.secondary}
    `
);

const LoginForm = ({ className = '' }: IProps) => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useAppTranslation();
    const [_, setSocket] = useContext(WebSocketContext);
    const signingInProgress = useAppSelector(isSigningInProgress);
    const azureAuthenticateUrl = useAppSelector(getAzureAuthenticateUrl);
    const azureEnabled = useAppSelector(isAzureEnabled);
    const handleSubmit = (login: string, password: string) => {
        dispatch(signIn({ login, password }))
            .unwrap()
            .then((res) => {
                dispatch(connectToWS()).then(() => setSocket(currentSocket));
                i18n.changeLanguage(res.language, () => dispatch(setLanguage({ language: res.language })));
            });
    };

    useEffect(() => {
        dispatch(fetchAzureAuthenticateUrl());
    });

    const handleAzureSign = useCallback(() => {
        if (azureEnabled) {
            window.open(azureAuthenticateUrl, '_self');
        }
    }, [azureAuthenticateUrl, azureEnabled]);

    return (
        <FormGenerator
            className={className}
            name="login"
            displayAsModal={false}
            displayAsSidebar={false}
            onSubmit={(values) => handleSubmit(values.login as string, values.password as string)}
            fields={[
                {
                    type: 'textField',
                    props: {
                        id: 'username',
                        required: true,
                        name: 'login',
                        label: t('label.emailOrUsername', 'E-mail or username'),
                        type: 'string',
                        size: 'medium'
                    }
                },
                {
                    type: 'password',
                    props: {
                        id: 'password',
                        required: true,
                        name: 'password',
                        label: t('label.password', 'Password')
                    }
                }
            ]}
            actions={[
                {
                    type: 'html',
                    props: {
                        name: 'service',
                        render: () => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Checkbox name="remember" />}
                                    label={
                                        <StyledLabel variant="subtitle2">
                                            {t('label.rememberMe', 'Remember Me')}
                                        </StyledLabel>
                                    }
                                />
                                <StyledLink href={resetPassword()} variant="subtitle2">
                                    {t('label.resetPassword', 'Reset Password')}
                                </StyledLink>
                            </Box>
                        )
                    }
                },
                {
                    type: 'button',
                    props: {
                        id: 'submit-signin',
                        type: 'submit',
                        name: 'default',
                        disabled: signingInProgress,
                        variant: 'contained',
                        children: t('label.signIn', 'Sign In'),
                        size: 'large',
                        sx: (theme) => ({
                            marginTop: '1rem',
                            background: theme.custom?.primary.gradient,
                            fontWeight: 700
                        })
                    }
                },
                {
                    type: 'html',
                    display: () => azureEnabled,
                    props: {
                        name: 'or',
                        render: () => <StyledSeparator variant="subtitle2">Or</StyledSeparator>
                    }
                },
                {
                    type: 'button',
                    display: () => azureEnabled,
                    props: {
                        id: 'submit-signin-azure',
                        type: 'button',
                        name: 'azure',
                        onClick: handleAzureSign,
                        disabled: signingInProgress,
                        variant: 'outlined',
                        startIcon: <WindowSharpIcon />,
                        children: t('label.signInWithAzure', 'Sign In With Azure'),
                        size: 'large'
                    }
                }
            ]}
        />
    );
};

export default LoginForm;
