import { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import { message } from '@/utils/validations';

const DateTimePickerErrorMessages = (reason: DateTimeValidationError) => {
    switch (reason) {
        case 'invalidDate':
            return message.datetime.invalid;
        case 'minDate':
            return message.datetime.minDate;
        case 'maxDate':
            return message.datetime.maxDate;
        case 'minTime':
            return message.datetime.minTime;
        case 'maxTime':
            return message.datetime.maxTime;
        default:
            return '';
    }
};

export default DateTimePickerErrorMessages;
