import { useCallback, useEffect } from 'react';
import { ISwitchItem } from '@/base/FormGenerator/FieldTypes';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueuesForSelect } from '@/data/Queues/QueueActions';
import { queuesForSelect } from '@/data/Queues/QueueSlice';
import { createSkill, updateSkill } from '@/data/Skills/SkillActions';
import { ISkillModel } from '@/data/Skills/SkillModels';
import {
    skillById,
    skillCreatingStatus,
    skillPaging,
    skillUpdatingStatus,
    updatePaging
} from '@/data/Skills/SkillSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';

type IProps = Omit<ICuProps<ISkillModel>, 'resource'>;

const SkillForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => skillById(state, id));
    const paging = useAppSelector(skillPaging);
    const queuesList = useAppSelector(queuesForSelect) ?? [];
    const handleData = (values: IOutputValueType) => ({
        color: values.color as string,
        icon: values.icon as string,
        name: values.name as string,
        workingHours: values.workingHours as number,
        allow_to_combine: values.allow_to_combine as boolean,
        allow_work_from_home: values.allow_work_from_home as boolean,
        only_work_from_home: values.allow_work_from_home === true ? (values.only_work_from_home as boolean) : false,
        queue_ids: values.queue_ids as string[]
    });
    const handleCreate = useCallback((values: IOutputValueType) => createSkill(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateSkill({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    useEffect(() => {
        dispatch(fetchQueuesForSelect({ search: '' }));
    }, []);

    return (
        <>
            <CuForm
                {...props}
                id={id}
                name="skill"
                resource={PermissionsEnum.Skills}
                creatingStatus={useAppSelector(skillCreatingStatus)}
                updatingStatus={useAppSelector(skillUpdatingStatus)}
                justIcon={justIcon}
                displayAsModal={displayAsModal}
                displayAsSidebar={displayAsSidebar}
                items={[
                    {
                        type: 'switch',
                        props: {
                            name: 'allow_to_combine',
                            label: t('label.allowToCombine', 'Allow to combine'),
                            value: loadedData?.allow_to_combine ?? false,
                            validation: {
                                deps: 'queue_to_types'
                            },
                            width: 4
                        }
                    },
                    {
                        type: 'switch',
                        props: {
                            name: 'allow_work_from_home',
                            label: t('label.allowWorkFromHome', 'Allow work from Home'),
                            value: loadedData?.allow_work_from_home ?? false,
                            width: 4,
                            validation: {
                                deps: 'only_work_from_home'
                            }
                        }
                    },
                    {
                        type: 'switch',
                        props: {
                            name: 'only_work_from_home',
                            label: t('label.onlyWorkFromHome', 'Only work from Home'),
                            disabled: ({ allow_work_from_home }) => allow_work_from_home !== true,
                            value: loadedData?.only_work_from_home ?? false,
                            width: 4,
                            validation: {
                                deps: 'allow_work_from_home'
                            }
                        }
                    } as ISwitchItem,
                    {
                        type: 'textField',
                        props: {
                            required: true,
                            name: 'name',
                            label: t('label.name', 'Name'),
                            value: loadedData?.name,
                            validation: {
                                pattern: {
                                    value: regex.text,
                                    message: message.text
                                }
                            }
                        }
                    },
                    {
                        type: 'iconPicker',
                        props: {
                            required: true,
                            name: 'icon',
                            label: t('label.icon', 'Icon'),
                            value: loadedData?.icon || '',
                            width: 6
                        }
                    },
                    {
                        type: 'colorPicker',
                        props: {
                            name: 'color',
                            label: t('label.color', 'Color'),
                            value: loadedData?.color || '#000000',
                            width: 6
                        }
                    },
                    {
                        type: 'multiSelect',
                        props: {
                            name: 'queue_ids',
                            label: t('label.queues', 'Queues'),
                            value:
                                loadedData?.queue_items?.map(({ queue_id }) => {
                                    const queue = queuesList.find((entity) => entity.id === queue_id);

                                    return `${queue?.id ?? queue_id}`;
                                }) ?? [],
                            options: queuesList
                                .map((queue) => ({
                                    id: `${queue.id}`,
                                    label: queue.name
                                }))
                                .sort((prev, next) => prev.label.localeCompare(next.label))
                        }
                    }
                ]}
                onSubmitCreate={handleCreate}
                onSubmitUpdate={handleUpdate}
                onSuccessCreate={handleUpdatePaging}
            />
        </>
    );
};

export default SkillForm;
