export enum PermissionsControllerEnum {
    Activities = 'Activities',
    AgentGroups = 'AgentGroups',
    ApplicationSettingsItems = 'ApplicationSettingsItems',
    Breaks = 'Breaks',
    CallCenters = 'CallCenters',
    Contracts = 'Contracts',
    Google = 'Google',
    Needs = 'Needs',
    Pages = 'Pages',
    Periods = 'Periods',
    Prediction = 'Prediction',
    Queues = 'Queues',
    RequestTypes = 'RequestTypes',
    Roles = 'Roles',
    SchedulePlans = 'SchedulePlans',
    SchedulePlansAssignments = 'SchedulePlansAssignments',
    Schedules = 'Schedules',
    Settings = 'Settings',
    SettingsItems = 'SettingsItems',
    ShiftRequest = 'ShiftRequests',
    ShiftTrades = 'ShiftTrades',
    Shifts = 'Shifts',
    Skills = 'Skills',
    Status = 'Status',
    Summaries = 'Summaries',
    Reports = 'Reports',
    Timeoffs = 'Timeoffs',
    UserToRequests = 'UserToRequests',
    UserToVacationFund = 'UserToVacationFund',
    Users = 'Users',
    Workplaces = 'Workplaces'
}

export enum PermissionsSettingsItemsEnum {
    SettingsItems_SendGrid = 'SettingsItems-send_grid',
    SettingsItems_Google = 'SettingsItems-google',
    SettingsItems_DaktelaTools = 'SettingsItems-daktela_tools',
    SettingsItems_EvolutionAlgorithmParameters = 'SettingsItems-evolution_algorithm_parameters',
    SettingsItems_AzureActiveDirectoryLogin = 'SettingsItems-azure_active_directory_login',
    SettingsItems_MicrosoftActiveDirectoryLogin = 'SettingsItems-microsoft_active_directory_login',
    SettingsItems_Pinya = 'SettingsItems-pinya'
}

const PermissionsEnum = { ...PermissionsControllerEnum, ...PermissionsSettingsItemsEnum };

export type PermissionsEnumType = PermissionsSettingsItemsEnum | PermissionsControllerEnum;

export default PermissionsEnum;
