import Box from '@mui/material/Box';
import { MouseEvent, ReactNode, useState } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';

type IIconProps =
    | { justIcon?: false; openButton?: false; openButtonValue?: string; open?: false; onReject?: undefined }
    | {
          justIcon?: true;
          openButton: (onClick: (event?: MouseEvent) => void) => ReactNode;
          openButtonValue?: false;
          open?: false;
          onReject?: undefined;
      }
    | { justIcon?: false; openButton?: false; openButtonValue?: false; open?: boolean; onReject?: () => void };

type IProps = {
    title?: string;
    justIcon?: boolean;
    children?: ReactNode;
    onAgree: (onSuccess: () => void) => void;
    onClose?: () => void;
} & IIconProps;

const ConfirmDialog = (props: IProps) => {
    //TODO: do budoucna bude chtít předělat ( nechápu proč musím mít nastavené justIcon aby compoenta začala reagovat na openButton => bud předám openButton a nebo openButtonValu.)
    const { t } = useAppTranslation();
    const [inProgress, setInProgress] = useState(false);
    const dialogProps = props.justIcon
        ? {
              openButton: props.openButton,
              openButtonValue: undefined,
              open: false
          }
        : {
              openButton: undefined,
              openButtonValue: props.openButtonValue,
              open: props.open
          };
    const handleClick = (onClose: () => void) => {
        setInProgress(true);
        onClose();

        props.onAgree(() => {
            setInProgress(false);
        });
    };
    const handleRejectClick = (onClose: () => void) => {
        onClose();

        if (props.onReject) {
            props.onReject();
        }
    };

    return (
        <Dialog
            {...dialogProps}
            onClose={props.onClose}
            name="confirmDialog"
            title={props.title || t('message.question.doYouWantToConfirm', 'Do you want to confirm?')}
            actions={(onClose) => (
                <Box sx={{ display: 'flex', flex: '1', gap: 2, justifyContent: 'space-between' }}>
                    <Button name="no" variant="outlined" onClick={() => handleRejectClick(onClose)}>
                        {t('label.no', 'No')}
                    </Button>
                    <LoadingButton
                        name="confirm"
                        variant="contained"
                        color="primary"
                        loading={inProgress}
                        onClick={() => handleClick(onClose)}
                    >
                        {t('label.confirm', 'Confirm')}
                    </LoadingButton>
                </Box>
            )}
        >
            {props.children || ''}
        </Dialog>
    );
};

export default ConfirmDialog;
