import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { IUserSelectModel } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';
import TransferList from '@/wrappers/TransferList';

type IEmployees = {
    users: IUserSelectModel[];
    selectedAdministrators: string[];
    selectedEmployees: string[];
    onChangeEmployees: (value: string[]) => void;
    onChangeAdministrators: (value: string[]) => void;
};

const StyledContainer = styled(Grid)`
    padding-top: 1em;
    display: grid;
    align-items: stretch;
    gap: 30px;
`;

const Employees = ({
    users,
    selectedEmployees,
    selectedAdministrators,
    onChangeEmployees,
    onChangeAdministrators
}: IEmployees) => {
    const { t } = useAppTranslation();

    const mappedUsers = useMemo(
        () =>
            users.map((joinEntity) => ({
                id: `${joinEntity.id}`,
                content: serializeUser(joinEntity),
                sortableValue: serializeUser(joinEntity, true)
            })),
        [users]
    );
    const availableAdministrators = useMemo(
        () => mappedUsers.filter(({ id: userId }) => !selectedEmployees.includes(userId.toString())),
        [mappedUsers, selectedEmployees]
    );

    const availableEmployees = useMemo(
        () => mappedUsers.filter(({ id: userId }) => !selectedAdministrators.includes(userId.toString())),
        [mappedUsers, selectedAdministrators]
    );

    return (
        <StyledContainer>
            <Box>
                <TransferList
                    label={t('label.administrators', 'Administrators')}
                    items={availableAdministrators}
                    values={selectedAdministrators}
                    onChange={onChangeAdministrators}
                />
            </Box>
            <Box>
                <TransferList
                    label={t('label.employees', 'Employees')}
                    items={availableEmployees}
                    values={selectedEmployees}
                    onChange={onChangeEmployees}
                />
            </Box>
        </StyledContainer>
    );
};

export default Employees;
