import { createAsyncThunk } from '@reduxjs/toolkit';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { IPaging } from '@/data/Paging';
import {
    IAzureSettingFromBeModel,
    IGoogleSettingFromBeModel,
    ISettingsFromBeModel,
    ISettingsItemModel
} from '@/data/SettingsItems/SettingsItemModels';
import { IRootState } from '@/data/store';
import { getPermissionsList } from '@/data/System/SystemReducer';
import {
    getReverseTransformSettingsItemValuesList,
    getTransformSettingItemsValue
} from '@/forms/IntegratedApplicationForm/utils';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';
import { azureAuthenticateUrl, create, fetchById, fetchList, remove, update } from './SettingsItemApi';
import { getAzureAuthenticateUrl, settingsItemPaging } from './SettingsItemSlice';

const transformSettingItems = (values: ISettingsFromBeModel['value']): ISettingsItemModel['value'] =>
    values.map(({ type, value_number, value_text, value_bool, ...rest }) => ({
        ...rest,
        value: getTransformSettingItemsValue(type, value_number, value_text, value_bool)
    })) as ISettingsItemModel['value'];

const reverseTransformSettingsItem = (values: ISettingsItemModel['value']): ISettingsFromBeModel['value'] =>
    values.map(({ value, ...rest }) => ({
        ...rest,
        ...getReverseTransformSettingsItemValuesList(value)
    })) as ISettingsFromBeModel['value'];

export const fetchSettingsItems = createAsyncThunk('settingsItems/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = settingsItemPaging(thunkAPI.getState() as IRootState);
    const response = await fetchList({ ...currentPaging, ...args });

    return {
        ...response,
        data: response.data.map(({ value, ...rest }) => {
            return {
                ...rest,
                value: transformSettingItems(value ?? [])
            };
        }) as ISettingsItemModel[]
    };
});

export const fetchAzureSettings = createAsyncThunk('settingsItems/azure', async () => {
    const response = await fetchById<IAzureSettingFromBeModel>(SettingsTypesEnum.AzureActiveDirectoryLogin, false);

    return {
        ...response,
        value: response.settings && response.status < 300 ? transformSettingItems(response.settings.value) : null
    };
});

export const fetchAzureAuthenticateUrl = createAsyncThunk(
    'settingsItems/azureUrl',
    async () => {
        return await azureAuthenticateUrl();
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;
            const url = getAzureAuthenticateUrl(state);

            if (url) {
                return false;
            }
        }
    }
);

export const fetchGooglesSettings = createAsyncThunk(
    'settingsItems/google',
    async () => {
        const response = await fetchById<IGoogleSettingFromBeModel>(SettingsTypesEnum.Google, false);

        return {
            ...response,
            value: response.settings && response.status < 300 ? transformSettingItems(response.settings.value) : null
        };
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (
                !isUserAllowed(
                    {
                        id: PermissionsEnum.SettingsItems_Google,
                        mode: [Mode.CREATE, Mode.READ, Mode.UPDATE],
                        operator: 'OR'
                    },
                    getPermissionsList(state)
                )
            ) {
                return false;
            }
        }
    }
);

/**
 * Args has structure ApplicationKey_Key from entity
 */
export const removeSetting = createAsyncThunk(
    'settingsItems/remove',
    async (args: { id: string; onSuccess?: () => void }) => {
        await remove(args.id as SettingsTypesEnum).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);

export const createSetting = createAsyncThunk('setting/create', async (args: ISettingsItemModel) => {
    const response = await create({
        application_key: args.application_key as SettingsTypesEnum,
        value: reverseTransformSettingsItem(args.value)
    } as ISettingsFromBeModel);

    return {
        ...response,
        value: transformSettingItems(response.value)
    } as ISettingsItemModel;
});

export const updateSetting = createAsyncThunk(
    'setting/update',
    async (args: { id: ISettingsItemModel['application_key']; data: ISettingsItemModel }) => {
        const response = await update(args.id, {
            application_key: args.id as SettingsTypesEnum,
            value: reverseTransformSettingsItem(args.data.value)
        } as ISettingsFromBeModel);

        return {
            ...response,
            value: transformSettingItems(response.value)
        } as ISettingsItemModel;
    }
);
