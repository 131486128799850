import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from '@/data/store';

type IScheduleGridConfig = {
    displayShiftName: boolean;
    displayShiftCode: boolean;
    displaySkills: boolean;
    displayUsedHours: boolean;
    displayDateTime: boolean;
    displayDescription: boolean;
    shiftWithoutDescriptionBackgroundColor: string;
    shiftWithoutDescriptionColor: string;
};

export type ISettingsState = {
    scheduleGrid: IScheduleGridConfig;
};

const initialState: ISettingsState = {
    scheduleGrid: {
        displayShiftName: false,
        displayShiftCode: true,
        displaySkills: true,
        displayUsedHours: false,
        displayDateTime: true,
        displayDescription: false,
        shiftWithoutDescriptionBackgroundColor: '#8800F0',
        shiftWithoutDescriptionColor: '#FFFFFF'
    }
};

const settingSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        toggleDisplaySkills: (state) => {
            state.scheduleGrid.displaySkills = !state.scheduleGrid.displaySkills;
        },
        toggleDisplayUsedHoursOfUser: (state) => {
            state.scheduleGrid.displayUsedHours = !state.scheduleGrid.displayUsedHours;
        }
    }
});

const getState = (state: IRootState) => state[settingSlice.name];

export const {
    toggleDisplaySkills: scheduleGridToggleDisplaySkills,
    toggleDisplayUsedHoursOfUser: scheduleGridToggleDisplayUsedHoursOfUser
} = settingSlice.actions;
export const scheduleGridSettings = (state: IRootState) => getState(state).scheduleGrid;

export default settingSlice;
