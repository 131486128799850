/**
 * Filter selected array values in complete list of data (useful for filtering selected data in select form components)
 *
 * @param data -        complete data to be filtered
 * @param selected -    selected data to filter out from complete data
 *
 */
const handleFilter = <T extends {}>(data: T[], selected: T[]) => {
    const hash = (item: T) => Object.values(item).join(':');
    const set = selected.map(hash);

    return data.filter((item) => !set.includes(hash(item)));
};

export default handleFilter;
