import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup';
import { memo } from 'react';
import Radio, { IRadioProps } from '@/wrappers/Radio';

type IOption = {
    id: string;
    label: string;
};
type IRadioGroupProps = MuiRadioGroupProps & {
    name: string;
    required?: boolean;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    options: IOption[];
    itemProps?: IRadioProps;
    disabled?: boolean;
};

const RadioGroup = ({
    options,
    itemProps,
    disabled,
    required,
    labelPlacement = 'end',
    onChange,
    ...rest
}: IRadioGroupProps) => (
    <MuiRadioGroup
        {...rest}
        onChange={(event, value) => !disabled && onChange && onChange(event, value)}
        data-testid={`radio_group_${rest.name}`}
    >
        {options.map((option) => (
            <FormControlLabel
                disabled={disabled}
                labelPlacement={labelPlacement}
                key={option.id}
                control={<Radio {...itemProps} name={option.id} required={required || itemProps?.required} />}
                label={option.label}
                value={option.id}
            />
        ))}
    </MuiRadioGroup>
);

export default memo(RadioGroup);
