import { styled, SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

type IProps = {
    sx?: SxProps;
};

const StyledSubtitle = styled(Typography<'div'>)`
    text-align: center;
`;

const Subtitle = ({ sx, children }: PropsWithChildren<IProps>) =>
    children ? (
        <StyledSubtitle variant="body2" component="div" sx={sx} data-testid="drawerSubtitle">
            {children}
        </StyledSubtitle>
    ) : null;

export default Subtitle;
