enum OmnichannelEnum {
    calls_inbound = 'in',
    email = 'email',
    sms = 'sms',
    web_chat = 'chat',
    facebook_messenger = 'fbm',
    instagram_dm = 'igdm',
    whats_app = 'wap',
    viber = 'vbr',
    csv_file = 'csv_file'
    // web_click_to_call = 'ctc',
    // custom = 'custom'
}

export default OmnichannelEnum;
