import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import {
    isSchedulePlanByIdInLoaded,
    selectTimeZoneOfPlan,
    selectUserIdsWithShiftByPlan,
    selectUsersInPlanWithRequests
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { fetchUserToRequestsForScheduler } from '@/data/UserToRequests/UserToRequestActions';
import SchedulerCalendarHeader from '@/modules/Scheduler/components/SchedulerCalendarHeader';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SchedulerCalendarRequestBody from '@/modules/Scheduler/components/SchedulerRequestBody';
import { IChildSchedulerTypes } from '@/modules/Scheduler/Scheduler';
import { StyledWrapper } from '@/modules/Scheduler/StyledParts';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';

export type IProps = IChildSchedulerTypes & {
    displayMode: ISchedulerCalendarHeaderProps['mode'];
    onLoadingStart?: () => void;
    onLoadingFinished?: () => void;
};

const SchedulerRequest = ({ from, schedulePlanId, to, displayMode, onLoadingFinished, onLoadingStart }: IProps) => {
    const dispatch = useAppDispatch();
    const mode = displayMode != SchedulePlanWidthTypeEnum.Custom ? displayMode : SchedulePlanWidthTypeEnum.Week;
    const isLoaded = useAppSelector((state) => isSchedulePlanByIdInLoaded(state, schedulePlanId));
    const [isCollapsedSideBar, setIsCollapsedSideBar] = useState(false);
    const timeZone = useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId));
    const usersList = useAppSelector((state) => selectUsersInPlanWithRequests(state, schedulePlanId)) ?? [];
    const usersIdsList = useAppSelector((state) => selectUserIdsWithShiftByPlan(state, schedulePlanId)) ?? [];

    useEffect(() => {
        dispatch(fetchUsersForSelect({ search: '' }));
    }, []);

    const handleExpandUsersPart = useCallback(() => {
        setIsCollapsedSideBar(false);
    }, [isCollapsedSideBar]);

    const handleCollapseUsersPart = useCallback(() => {
        setIsCollapsedSideBar(true);
    }, [isCollapsedSideBar]);

    useEffect(() => {
        if (usersIdsList) {
            dispatch(
                fetchUserToRequestsForScheduler({
                    filters: {
                        'UsersToRequests.user_id': {
                            values: usersIdsList
                        }
                    }
                })
            );
        }
    }, [usersIdsList]);

    return (
        <StyledWrapper countOfRows={usersList?.length ?? 0} isRequestMode={true} isCompact={false}>
            {schedulePlanId && from && to && isLoaded && (
                <>
                    <SchedulerCalendarHeader
                        type="requests"
                        mode={mode}
                        from={from}
                        to={to}
                        schedulePlanId={schedulePlanId}
                        timeZone={timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                        isCollapsedSideBar={isCollapsedSideBar}
                        onCollapseSideBar={handleCollapseUsersPart}
                        onExpandSideBar={handleExpandUsersPart}
                    />
                    <SchedulerCalendarRequestBody
                        mode={mode}
                        isCollapsed={isCollapsedSideBar}
                        from={from}
                        to={to}
                        schedulePlanId={schedulePlanId}
                        onLoadingStart={onLoadingStart}
                        onLoadingFinished={onLoadingFinished}
                        timeZone={timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                    />
                </>
            )}
        </StyledWrapper>
    );
};

export default SchedulerRequest;
