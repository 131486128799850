import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction, { BottomNavigationActionTypeMap } from '@mui/material/BottomNavigationAction';
import { forwardRef, SyntheticEvent } from 'react';
import * as React from 'react';

export type IBottomNavigationPickerProps<D extends React.ElementType = 'button'> = {
    disabled?: boolean;
    showLabels?: boolean;
    value?: string;
    options?: BottomNavigationActionTypeMap<{}, D>['props'][];
    onChange?: (value: string | null, event?: SyntheticEvent) => void;
};

const BottomNavigationPicker = forwardRef<HTMLDivElement, IBottomNavigationPickerProps>(function Inner(
    { disabled, showLabels, value, options, onChange, ...props },
    ref
) {
    return (
        <BottomNavigation
            ref={ref}
            showLabels={showLabels}
            value={value}
            onChange={(event, navigationValue: string | null) => onChange && onChange(navigationValue ?? null, event)}
        >
            {options?.map((item) => <BottomNavigationAction key={`bottomNavigationItem-${item.label}`} {...item} />)}
        </BottomNavigation>
    );
});

export default BottomNavigationPicker;
