import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { isSidebarPinned, setSidebar } from '@/data/Sidebar/SidebarReducer';
import { ReactComponent as Logo } from '@/images/logoText.svg';

const StyledContent = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledLogo = styled(Logo)(
    ({ theme }) => `
        max-width: 120px;
        margin-left: ${theme.spacing(1)};
    `
);
const StyledButton = styled(IconButton, {
    shouldForwardProp: (props) => props !== 'isPinned'
})<{ isPinned?: boolean }>(
    ({ theme, isPinned }) => `
        color: ${theme.palette.primary.main};
        transition: transform ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut};

        ${isPinned && 'transform: rotate(-90deg);'}
    `
);

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const isPinned = useAppSelector(isSidebarPinned);
    const handleSidebar = useCallback(() => {
        dispatch(setSidebar({ isPinned: !isPinned }));
    }, [isPinned]);

    return (
        <StyledContent data-testid="sidebar">
            <StyledLogo />
            <StyledButton isPinned={isPinned} onClick={handleSidebar}>
                <MenuIcon />
            </StyledButton>
        </StyledContent>
    );
};

export default Sidebar;
