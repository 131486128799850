import { getRequestApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppSelector } from '@/data/hooks';
import RequestApplicationSettingsForm from '@/forms/ApplicationsSettingsForms/RequestApplicationSettingsForm/RequestApplicationSettingsForm';
import { StyledApplicationSettingsActionPanel } from '@/forms/ApplicationsSettingsForms/utils';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsRequestItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsRequestItemEnum';
import StackInformation from '@/wrappers/StackInformations';
import Switch from '@/wrappers/Switch';

const RequestApplicationSettingsFormViewPage = () => {
    const { t } = useAppTranslation();
    const data = useAppSelector(getRequestApplicationsSettings);

    return (
        <>
            <StyledApplicationSettingsActionPanel>
                <RequestApplicationSettingsForm />
            </StyledApplicationSettingsActionPanel>
            <StackInformation
                items={[
                    {
                        label: t(
                            'label.allowCreateNewRequestAfterExceedUsersRequestFund',
                            'Allow Create New Request After Exceed Users Request Fund'
                        ),
                        value: (
                            <Switch
                                name="allowCreateNewRequestAfterExceedUsersRequestFund"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsRequestItemEnum.allowCreateNewRequestAfterExceedUsersRequestFund
                                )}
                            />
                        )
                    },
                    {
                        label: t(
                            'label.allowCreateNewVacationAfterExceedUsersRequestFund',
                            'Allow Create New Vacation After Exceed Users Request Fund'
                        ),
                        value: (
                            <Switch
                                name="allowCreateNewVacationAfterExceedUsersRequestFund"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund
                                )}
                            />
                        )
                    }
                ]}
            />
        </>
    );
};

export default RequestApplicationSettingsFormViewPage;
