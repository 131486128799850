import Box from '@mui/material/Box';
import { Control, Controller } from 'react-hook-form';
import { getErrorByFieldName } from '@/base/FormGenerator/utils';
import { normalize } from '@/helpers/color';
import { message } from '@/utils/validations';
import ColorPicker from '@/wrappers/ColorPicker';
import TextField from '@/wrappers/TextField';

export type IAbbreviationValue = {
    text: string;
    color: string;
    background: string;
};

export type IAbbreviationProps = {
    control: Control;
    currentColor?: string;
    currentBackgroundColor?: string;
    name: string;
    label: IAbbreviationValue;
    value?: IAbbreviationValue;
    required?: boolean;
    onlyPreview?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    onChange?: (value: IAbbreviationValue) => void;
};

const Abbreviation = ({
    control,
    currentColor,
    currentBackgroundColor,
    name,
    label,
    value = { text: '', color: '', background: '' },
    required,
    readOnly,
    onlyPreview,
    disabled
}: IAbbreviationProps) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Controller
                control={control}
                name={`${name}.text`}
                shouldUnregister
                defaultValue={value.text}
                rules={{ required: required ? message.required : undefined }}
                render={({ field, formState: { errors } }) => {
                    const color = normalize(currentColor ?? value?.color);
                    const background = normalize(currentBackgroundColor ?? value?.background, 'ffffff');
                    const error = getErrorByFieldName(errors, field.name);

                    return (
                        <TextField
                            {...field}
                            data-testid={`abbreviation_${name}`}
                            label={label.text}
                            required={required}
                            readOnly={readOnly}
                            disabled={disabled}
                            error={!!error}
                            helperText={`${error?.message ?? ''}`}
                            sx={{ flexGrow: '1' }}
                            InputLabelProps={{
                                style: {
                                    background: 'white',
                                    borderRadius: '6px'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: color,
                                    backgroundColor: background,
                                    transition: 'color 0.2s linear, background 0.2s linear'
                                }
                            }}
                        />
                    );
                }}
            />
            {!onlyPreview && (
                <>
                    <Box sx={{ mx: 1 }} />
                    <ColorPicker
                        control={control}
                        name={`${name}.color`}
                        label={label.color}
                        readOnly={readOnly}
                        disabled={disabled}
                        sx={{ flexGrow: '1' }}
                        value={normalize(value?.color)}
                        required={required}
                    />
                    <Box sx={{ mx: 1 }} />
                    <ColorPicker
                        control={control}
                        name={`${name}.background`}
                        label={label.background}
                        readOnly={readOnly}
                        disabled={disabled}
                        sx={{ flexGrow: '1' }}
                        value={normalize(value?.background, 'ffffff')}
                        required={required}
                    />
                </>
            )}
        </Box>
    );
};

export default Abbreviation;
