import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { selectSchedulePlanDayShiftEntities } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { fetchSchedulePlanById, removeSchedulePlanFromStore } from '@/data/SchedulePlans/SchedulePlanActions';
import { ISchedulePlanValidationModel } from '@/data/SchedulePlanValidations/SchedulePlanValidationModels';
import { IRootState } from '@/data/store';
import { userEntities } from '@/data/Users/UserSlice';
import { getMessageByValidationCode, getTranslateOfValidationType } from '@/helpers/schedule';

const adapter = createEntityAdapter<ISchedulePlanValidationModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.id < b.id ? 1 : -1)
});

const schedulePlanValidationSlice = createSlice({
    name: 'schedulePlanValidations',
    initialState: adapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.payload.id
                        ) as ISchedulePlanValidationModel[]
                    ).map((item) => item.id)
                );
                adapter.addMany(state, action.payload.schedule_plan_validations);
            })
            .addCase(removeSchedulePlanFromStore, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter((item) => item?.schedule_plan_id === action.payload) as {
                            id: number;
                        }[]
                    ).map(({ id }) => id)
                );
            });
    }
});

const getState = (state: IRootState) => state[schedulePlanValidationSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export const selectSchedulePlanValidations = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => userEntities(state),
    (state: IRootState) => selectSchedulePlanDayShiftEntities(state),
    (validations, users, schedulePlanDayShifts) => {
        return validations
            .map((item) => ({
                ...item,
                user: item.user_id ? users[item.user_id] : null,
                assigned_shift: item.schedule_plan_day_shift_id
                    ? schedulePlanDayShifts[item.schedule_plan_day_shift_id] ?? null
                    : null
            }))
            .filter(({ assigned_shift }) => assigned_shift === null || typeof assigned_shift.user_id === 'number')
            .map((item) => ({
                ...item,
                message: getMessageByValidationCode(item),
                code: getTranslateOfValidationType(item.type)
            }));
    }
);
export const selectSchedulePlanValidationsBySchedulePlanId = (state: IRootState, id: number) => {
    const result = selectSchedulePlanValidations(state).filter((item) => item.schedule_plan_id === id);

    return result.length === 0 ? null : result;
};
export const selectErrorValidationsBySchedulePlanId = (state: IRootState, id: number) => {
    const result = selectSchedulePlanValidationsBySchedulePlanId(state, id)?.filter((item) => item.type === 'ERROR');

    return result?.length === 0 ? null : result;
};
export const schedulePlanValidationsByAssignedShiftId = (state: IRootState, id: number) => {
    const result = selectSchedulePlanValidations(state).filter((item) => item.schedule_plan_day_shift_id === id);

    return result.length === 0 ? null : result;
};

export default schedulePlanValidationSlice;
