import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { IContractCUModel, IContractModel, IContractSelectModel } from './ContractModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/contracts', paging || {});

    return fetchGet<IListResponseWithPaging<IContractModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/contracts/select', { search, fields });

    return fetchGet<IListResponse<IContractSelectModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<IContractModel>(`/api/contracts/${id}`);
}

export function create(data: IContractCUModel) {
    return fetchPost<IContractModel>('/api/contracts/', data);
}

export function update(id: number, data: IContractCUModel) {
    return fetchPatch<IContractModel>(`/api/contracts/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/contracts/${id}`);
}
