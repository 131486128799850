import { useCallback, useEffect } from 'react';
import FormGenerator from '@/base/FormGenerator';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { userById, userList, usersForSelect } from '@/data/Users/UserSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';

type IProps = {
    superiorId: number | null;
    userId: number | null;
    subordinateIds: number[];
    onChangeLeader: (number: number | null) => void;
    onChangeSubordinate: (value: number[]) => void;
};

const OrganisationStructureTab = ({
    superiorId,
    subordinateIds,
    userId,
    onChangeLeader,
    onChangeSubordinate
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const selectItems = useAppSelector(usersForSelect);
    const users = useAppSelector(userList);
    const superior = useAppSelector((state) => userById(state, superiorId ?? undefined));
    const isSystemUser = useAppSelector(isSignedUserUser);

    useEffect(() => {
        dispatch(fetchUsersForSelect({ search: '' }));
    }, []);

    const handleChangeSuperior = useCallback(
        (value: string | null) => {
            const parsed = value === null ? null : parseInt(value);

            onChangeLeader(parsed);
        },
        [onChangeLeader]
    );
    const handleChangeSubordinate = useCallback(
        (value: string[]) => {
            const parsed = value.map((item) => parseInt(item));

            onChangeSubordinate(parsed);
        },
        [onChangeSubordinate]
    );
    const subordinateIdsAsString = subordinateIds.map((item) => `${item}`);
    const otherUsers = selectItems
        .filter((item) => item.id !== userId)
        .map((item) => ({
            id: `${item.id}`,
            content: serializeUser(item),
            sortableValue: serializeUser(item, true)
        }));

    return (
        <FormGenerator
            name="organisationStructure"
            displayAsModal={false}
            displayAsSidebar={false}
            fields={[
                {
                    type: 'select',
                    props: {
                        name: 'superior_id',
                        disabled: isSystemUser,
                        label: t('label.superior', 'Superior'),
                        options: [
                            ...otherUsers,
                            ...(superior && !otherUsers.some((item) => item.id === `${superiorId}`)
                                ? [
                                      {
                                          id: `${superior.id}`,
                                          content: serializeUser(superior),
                                          sortableValue: serializeUser(superior, true)
                                      }
                                  ]
                                : [])
                        ]
                            .filter(({ id }) => !subordinateIdsAsString.includes(id))
                            .map((item) => ({
                                id: item.id,
                                label: item.sortableValue
                            })),
                        value: superiorId ? `${superiorId}` : undefined,
                        onChange: handleChangeSuperior
                    }
                },
                {
                    type: 'transferList',
                    props: {
                        name: 'subordinate_ids',
                        disabled: isSystemUser,
                        label: t('label.subordinate', 'Subordinate'),
                        values: subordinateIdsAsString,
                        items: [
                            ...otherUsers,
                            ...users
                                .filter(
                                    (item) =>
                                        subordinateIds.includes(item.id) &&
                                        !otherUsers.some((user) => user.id === `${item.id}`)
                                )
                                .map((item) => ({
                                    id: `${item.id}`,
                                    content: serializeUser(item),
                                    sortableValue: serializeUser(item, true)
                                }))
                        ].filter((item) => item.id !== `${superiorId}`),
                        onChange: handleChangeSubordinate
                    }
                }
            ]}
        />
    );
};

export default OrganisationStructureTab;
