import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '../store';

type IAlertReducerState = {
    status?: number;
    type?: 'info' | 'error';
    defaultMessage: string;
    context?: string;
    values?: { [name: string]: string | number };
};

const initialState: IAlertReducerState = {
    status: undefined,
    type: undefined,
    defaultMessage: '',
    context: undefined,
    values: undefined
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert: (
            state,
            action: PayloadAction<{
                status: number;
                defaultMessage: string;
                context?: string;
                values?: { [name: string]: string | number };
            }>
        ) => {
            state.type = 'error';
            state.status = action.payload.status;
            state.defaultMessage = action.payload.defaultMessage;
            state.context = action.payload.context;
            state.values = action.payload.values;
        },
        setInfoAlert: (
            state,
            action: PayloadAction<{
                context: string;
                defaultMessage: string;
                values?: { [name: string]: string | number };
            }>
        ) => {
            state.type = 'info';
            state.defaultMessage = action.payload.defaultMessage;
            state.context = action.payload.context;
            state.values = action.payload.values;
        },
        unsetAlert: (state) => {
            state.type = initialState.type;
            state.status = initialState.status;
            state.defaultMessage = initialState.defaultMessage;
            state.context = initialState.context;
            state.values = initialState.values;
        }
    }
});

export default alertSlice;

export const alertItem = (state: IRootState) => state[alertSlice.name];
export const { setInfoAlert, setAlert, unsetAlert } = alertSlice.actions;
