import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import DateHelper from '@/helpers/date/DateHelper';
import useDebounce from '@/hooks/useDebounce';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';
import DateTimePicker, { IDateTimePickerProps } from '@/wrappers/DateTimePicker';

export type IValueType<T> = {
    start: T;
    end: T;
};

const StyledWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

const StyledDateTimePicker = styled(DateTimePicker)`
    flex: 1;
`;

const StyledSign = styled(Box, { shouldForwardProp: (propName) => 'size' !== propName })<{
    size: IDateRangePickerProps['size'];
}>(({ theme, size }) => ({
    margin: `${theme.spacing(1)} ${theme.spacing(1.7)} 0`,
    ...(size === 'small' && { fontSize: '1rem' })
}));

export type IDateTimeRangePickerProps = Omit<
    IDateTimePickerProps,
    'label' | 'defaultValue' | 'minDate' | 'maxDate' | 'error' | 'helperText' | 'onError' | 'onChange' | 'value'
> & {
    label?: IValueType<string>;
    value?: IValueType<IDateTimePickerProps['value']>;
    hidden?: boolean;
    minDate?: IDateTimePickerProps['value'];
    maxDate?: IDateTimePickerProps['value'];
    optionalEnd?: boolean;
    minutesStep?: number;
    error?: IValueType<boolean>;
    helperText?: IValueType<string>;
    onChange?: (value: IValueType<IDateTimePickerProps['value']>) => void;
};

const DateTimeRangePicker = ({
    name,
    label,
    value,
    disableMaskedInput,
    helperText,
    onChange,
    minDate,
    maxDate,
    minutesStep,
    optionalEnd = false,
    error,
    ...rest
}: IDateTimeRangePickerProps) => {
    const [start, setStart] = useState(value?.start ?? null);
    const [end, setEnd] = useState(value?.end ?? null);

    const debouncedStart = useDebounce(start);
    const debouncedEnd = useDebounce(end);

    useEffect(() => {
        if (
            onChange &&
            (!DateHelper.isEqual(start, value?.start ?? null) || !DateHelper.isEqual(end, value?.end ?? null))
        ) {
            onChange({
                start,
                end
            });
        }
    }, [debouncedStart, debouncedEnd]);

    useEffect(() => {
        if (value) {
            setStart(value.start ?? null);
            setEnd(value.end ?? null);
        }
    }, [value]);

    return (
        <StyledWrapper>
            <StyledDateTimePicker
                {...rest}
                name={`${name}.start`}
                disableMaskedInput={disableMaskedInput}
                label={label?.start ?? ''}
                value={start}
                minutesStep={minutesStep}
                minDate={minDate ?? undefined}
                maxDate={end ?? maxDate ?? undefined}
                error={!!error?.start}
                helperText={helperText?.start}
                onChange={(newValue) => setStart(newValue ?? null)}
            />
            <StyledSign size={rest.size}> - </StyledSign>
            <StyledDateTimePicker
                {...rest}
                name={`${name}.end`}
                disableMaskedInput={disableMaskedInput}
                label={label?.end ?? ''}
                value={end}
                minutesStep={minutesStep}
                minDate={start ?? minDate ?? undefined}
                maxDate={maxDate ?? undefined}
                error={!!error?.end}
                helperText={helperText?.end}
                required={!optionalEnd && rest.required}
                onChange={(newValue) => setEnd(newValue ?? null)}
            />
        </StyledWrapper>
    );
};

export default DateTimeRangePicker;
