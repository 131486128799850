import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getGoogleSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'disabled'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const Google = ({ disabled, ...rest }: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getGoogleSettingsItems);
    const { t } = useAppTranslation();

    return (
        <FieldsGenerator
            {...rest}
            name="google"
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'subject',
                        label: t('label.googleSubject', 'Google Subject'),
                        value: getSettingsItemValueByKey(data, 'subject'),
                        disabled,
                        required: !disabled
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'client_id',
                        label: t('label.googleClientId', 'Google Client ID'),
                        value: getSettingsItemValueByKey(data, 'client_id'),
                        disabled,
                        required: !disabled
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'client_email',
                        label: t('label.googleClientEmail', 'Google Client Email'),
                        value: getSettingsItemValueByKey(data, 'client_email'),
                        disabled,
                        required: !disabled
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        name: 'signing_key',
                        label: t('label.googleSigningKey', 'Google Signing Key'),
                        value: getSettingsItemValueByKey(data, 'signing_key'),
                        disabled,
                        required: !disabled
                    }
                }
            ]}
        />
    );
};

export default Google;
