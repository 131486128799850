import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import { createRole, fetchRoleById, fetchRoles, fetchRolesForSelect, removeRole, updateRole } from './RoleActions';
import { IRoleModel, IRoleSelectModel } from './RoleModels';

type IRoleReducerState = {
    selectItems: IRoleSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IRoleReducerState = {
    selectItems: [],
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IRoleModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const roleSlice = createSlice({
    name: 'roles',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
            adapter.addMany(state, action.payload.roles);
        });
        // todo .addCase(assignShiftIntoDay.fulfilled, (state, action) => {
        //     adapter.upsertMany(
        //         state,
        //         action.payload.schedule_plan_day_shift_skills.map(({ skill }) => skill)
        //     );
        // });
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchRoles.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchRolesForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchRolesForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchRolesForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchRoleById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchRoleById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchRoleById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createRole.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name
                    } as IRoleSelectModel);
                }
            })
            .addCase(createRole.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeRole.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeRole.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg.id);
                }
            })
            .addCase(removeRole.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateRole.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? ({
                                  id: action.payload.id,
                                  name: action.payload.name
                              } as IRoleSelectModel)
                            : item
                    );
                }
            })
            .addCase(updateRole.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[roleSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default roleSlice;
export const isRoleListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const rolePaging = (state: IRootState) => getState(state).paging;
export const roleAll = adapterSelectors.selectAll;
export const roleEntities = adapterSelectors.selectEntities;
export const roleById = (state: IRootState, id?: number) => (id ? adapterSelectors.selectById(state, id) : undefined);
export const rolesForSelect = (state: IRootState) => getState(state).selectItems;
export const roleSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const roleCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const roleUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const roleRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = roleSlice.actions;
