import { MouseEvent, PropsWithChildren, ReactNode } from 'react';
import Button from '@/wrappers/Button';

type IOpenButtonProps = {
    render?: (onClick: (event?: MouseEvent) => void) => ReactNode;
    onClick: (event?: MouseEvent) => void;
    value?: ReactNode;
    name?: string;
};

const DialogOpenButton = ({ name = '', render, value, ...rest }: PropsWithChildren<IOpenButtonProps>) => {
    const openButton =
        render ||
        function (onClick: (event: MouseEvent) => void) {
            return (
                <Button {...rest} name={name || ''} onClick={onClick} variant="contained" data-testid="openButton">
                    {value}
                </Button>
            );
        };

    return <>{openButton(rest.onClick)}</>;
};

export default DialogOpenButton;
