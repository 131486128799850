enum ValidationEnum {
    SUBSIDY_VALIDATOR_DAY = 'SUBSIDY_VALIDATOR_DAY',
    SUBSIDY_VALIDATOR_WEEK = 'SUBSIDY_VALIDATOR_WEEK',
    SUBSIDY_VALIDATOR_MONTH = 'SUBSIDY_VALIDATOR_MONTH',

    BREAKS_VALIDATOR_REST_PER_SHIFT = 'BREAKS_VALIDATOR_REST_PER_SHIFT',
    BREAKS_VALIDATOR_REST_PER_WEEK = 'BREAKS_VALIDATOR_REST_PER_WEEK',

    CONTRACT_VALIDATOR_CONTRACT_DAYS = 'CONTRACT_VALIDATOR_CONTRACT_DAYS',
    CONTRACT_VALIDATOR_CONTRACT_HOLIDAYS = 'CONTRACT_VALIDATOR_CONTRACT_HOLIDAYS',
    CONTRACT_VALIDATOR_NIGHT_SHIFTS = 'CONTRACT_VALIDATOR_NIGHT_SHIFTS',
    CONTRACT_VALIDATOR_CONTRACT_ACTIVE = 'CONTRACT_VALIDATOR_CONTRACT_ACTIVE',
    CONTRACT_VALIDATOR_CONTRACT_INTERVAL = 'CONTRACT_VALIDATOR_CONTRACT_INTERVAL',

    SHIFT_VALIDATOR_SHIFT_INTERVAL = 'SHIFT_VALIDATOR_SHIFT_INTERVAL',

    DISTANCE_VALIDATOR_BETWEEN_SHIFTS = 'DISTANCE_VALIDATOR_BETWEEN_SHIFTS',

    VACATION_VALIDATOR_VACATION_DAYS = 'VACATION_VALIDATOR_VACATION_DAYS',

    EMPLOYEE_VALIDATOR_MISSING_EMPLOYEE = 'EMPLOYEE_VALIDATOR_MISSING_EMPLOYEE'
}

export default ValidationEnum;
