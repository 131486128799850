import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getSendGridSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import useAppTranslation from '@/hooks/useAppTranslation';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const SendGrid = ({ ...rest }: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getSendGridSettingsItems);
    const { t } = useAppTranslation();

    return (
        <FieldsGenerator
            {...rest}
            name="sendgrid"
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'api_key',
                        label: t('label.apiKey', 'Api Key'),
                        value: data.find(({ key }) => key === 'api_key')?.value
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'response_email',
                        label: t('label.responseEmail', 'Response Email'),
                        value: data.find(({ key }) => key === 'response_email')?.value,
                        type: 'email'
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'response_name',
                        label: t('label.responseName', 'Response Name'),
                        value: data.find(({ key }) => key === 'response_name')?.value
                    }
                }
            ]}
        />
    );
};

export default SendGrid;
