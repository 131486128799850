import { IUserInformation } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import StackInformation from '@/wrappers/StackInformations';

type IInformation = {
    data: IUserInformation;
};

const InformationTab = ({ data }: IInformation) => {
    const { t } = useAppTranslation();

    return (
        <StackInformation
            items={[
                {
                    label: t('label.apiKey', 'ApiKey'),
                    value: data.token ?? '',
                    allowCopy: true
                }
            ]}
        />
    );
};

export default InformationTab;
