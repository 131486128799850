import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './SkillApi';
import {
    ISkillCUModel,
    SelectSkillListValidationModel,
    SkillListValidationModel,
    SkillValidationModel
} from './SkillModels';
import { skillPaging, skillSelectValuesStatus, skillsForSelect } from './SkillSlice';

export const fetchSkills = createAsyncThunk('skills/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = skillPaging(thunkAPI.getState() as IRootState);

    const response = await fetchList({ ...currentPaging, ...args });

    return {
        ...response,
        data: SkillListValidationModel.parse(response.data)
    };
});

export const fetchSkillsForSelect = createAsyncThunk(
    'skills/selectList',
    async (args: { search: string; fields?: string[] }) => {
        const response = await fetchSelectList(args.search, args.fields || []);

        return {
            data: SelectSkillListValidationModel.parse(response.data)
        };
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (skillSelectValuesStatus(state) !== 'idle' || skillsForSelect(state).length) {
                return false;
            }
        }
    }
);

export const fetchSkillById = createAsyncThunk('skills/byId', async (args: number) => {
    const response = await fetchById(args);

    return SkillValidationModel.parse(response);
});

export const createSkill = createAsyncThunk('skills/create', async (args: ISkillCUModel) => {
    const response = await create(args);

    return SkillValidationModel.parse(response);
});

export const updateSkill = createAsyncThunk('skills/update', async (args: { id: number; data: ISkillCUModel }) => {
    const response = await update(args.id, args.data);

    return SkillValidationModel.parse(response);
});

export const removeSkill = createAsyncThunk('skills/remove', async (args: { id: number; onSuccess?: () => void }) => {
    await remove(args.id).then(() => args.onSuccess && args.onSuccess());

    return args.id;
});
