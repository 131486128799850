import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputBaseProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { forwardRef, useState } from 'react';

export type IPasswordProps = {
    id?: string;
    name: string;
    label?: string;
    value?: string;
    required?: boolean;
    readOnly?: boolean;
    error?: boolean;
    helperText?: string;
    size?: 'small';
    isShowable?: boolean;
    color?: InputBaseProps['color'];
    onBlur?: () => void;
    endAdornment?: React.ReactNode;
    onChange?: (value: string) => void;
};

const Password = forwardRef<HTMLDivElement, IPasswordProps>(function Inner(
    {
        id,
        name,
        label,
        value,
        required,
        readOnly,
        endAdornment,
        error,
        helperText,
        size,
        isShowable = true,
        onBlur,
        color,
        onChange
    },
    ref
) {
    const [displayedPasswords, setDisplayedPasswords] = useState<string[]>([]);
    const shouldDisplayPassword = (identifier?: string): boolean =>
        !!displayedPasswords.find((fieldId) => fieldId === identifier);
    const handleToggleDisplayPassword = (identifier?: string) => {
        if (!identifier) return;

        if (shouldDisplayPassword(identifier)) {
            setDisplayedPasswords(displayedPasswords.filter((fieldName) => fieldName !== identifier));
        } else {
            setDisplayedPasswords([...displayedPasswords, identifier]);
        }
    };

    return (
        <FormControl variant="outlined">
            {label && (
                <InputLabel htmlFor={name} required={required} error={error} size={size}>
                    {label}
                </InputLabel>
            )}
            <OutlinedInput
                ref={ref}
                autoComplete={name}
                error={error}
                color={color}
                id={id || name}
                name={name}
                label={label}
                value={value}
                size={size}
                readOnly={readOnly}
                type={shouldDisplayPassword(name) ? 'text' : 'password'}
                required={false}
                onBlur={onBlur}
                onChange={(e) => onChange && onChange(e.target.value)}
                endAdornment={
                    isShowable && (
                        <>
                            {endAdornment}
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleToggleDisplayPassword(name)}
                                    edge="end"
                                >
                                    {shouldDisplayPassword(name) ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        </>
                    )
                }
            />
            {error && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
    );
});

export default Password;
