import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchTimeoffs, removeTimeoff, updateTimeoff } from '@/data/Timeoffs/TimeoffActions';
import { ITimeoffModel } from '@/data/Timeoffs/TimeoffModels';
import {
    isTimeoffsListInProgress,
    timeoffList,
    timeoffPaging,
    timeoffRemovingStatus
} from '@/data/Timeoffs/TimeoffReducer';
import TimeoffForm from '@/forms/TimeoffForm';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import PageHeading from '@/wrappers/PageHeading';
import Switch from '@/wrappers/Switch';

export default function TimeoffsListPage() {
    const dispatch = useAppDispatch();
    const inProgress = useAppSelector(isTimeoffsListInProgress);
    const data = useAppSelector(timeoffList);

    return (
        <>
            <PageHeading>Time offs</PageHeading>
            <CrudDatatable<ITimeoffModel>
                name="timeoff"
                resource={PermissionsEnum.Timeoffs}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue) => (
                    <TimeoffForm displayAsSidebar justIcon={justIcon} id={id} openButtonValue={openButtonValue} />
                )}
                header={[
                    { title: 'Time off name', field: 'name' },
                    {
                        title: 'Paid',
                        field: 'paid',
                        render: (rowData: ITimeoffModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.paid}
                                onChange={(value) => {
                                    dispatch(
                                        updateTimeoff({
                                            id: rowData.id,
                                            data: {
                                                paid: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    },
                    {
                        title: 'Active',
                        field: 'active',
                        render: (rowData: ITimeoffModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.active}
                                onChange={(value) => {
                                    dispatch(
                                        updateTimeoff({
                                            id: rowData.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(timeoffPaging)}
                removingStatus={useAppSelector(timeoffRemovingStatus)}
                onFetchList={fetchTimeoffs}
                onRemove={removeTimeoff}
            />
        </>
    );
}
