import AddIcon from '@mui/icons-material/Add';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MuiIcon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import { ICuProps } from '@/components/CuForm';
import UserPermission, { Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRolesForSelect } from '@/data/Roles/RoleActions';
import { rolesForSelect } from '@/data/Roles/RoleSlice';
import { ISchedulePlanDayShiftModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { assignUsersToSchedulePlan, endOfPlan, startOfPlan } from '@/data/SchedulePlans/SchedulePlanSlice';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { IUserModel } from '@/data/Users/UserModels';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import { SchedulerContext } from '@/modules/Scheduler/SchedulerContext';
import { compactHeightOfRow } from '@/modules/Scheduler/StyledParts';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import handleIconReplaces from '@/utils/icons/handleIconReplaces';
import { serializeUser } from '@/utils/UserHelper';
import Button from '@/wrappers/Button';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';
import OutlinedInput from '@/wrappers/OutlinedInput';
import Select from '@/wrappers/Select';
import TransferList from '@/wrappers/TransferList';
import UserSkillWrapper from '@/wrappers/UserSkillWrapper';

type IProps = Omit<ICuProps<ISchedulePlanDayShiftModel>, 'resource'> & {
    justIcon?: boolean;
    schedulePlanId: number | null;
    availableUsers: Omit<
        IUserModel,
        'user_to_contracts' | 'user_to_requests' | 'user_to_vacation_fund' | 'user_to_workplaces'
    >[];
    alreadyAssigned: number[] | null;
    selectedRequirements: number[] | null;
    skillsData: ISkillModel[] | null;
};

const StyledTitle = styled(Box)`
    text-align: center;
`;

const StyledFormFilterItem = styled(FormControl)(
    ({ theme }) => `
        width: 25ch;
        margin: ${theme.spacing(1)};
    `
);
const StyledFormFilterContainer = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const StyledSubTitlesSkillsBox = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const StyledSubTitlesBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const StyledSkill = styled(Box)`
    display: flex;
    align-items: center;
`;

const StyledSubtitle = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.26);

    p > svg {
        margin-bottom: -6px;
    }

    .MuiDivider-root {
        height: 50px;
        margin-right: 20px;
        margin-left: 20px;
    }
`;

const StyledButtonBox = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const StyledButton = styled(Button, { shouldForwardProp: (propName) => propName !== 'isCompact' })<{
    isCompact: boolean;
}>(
    ({ theme, isCompact }) => `
    min-height: ${isCompact ? `${compactHeightOfRow}px` : undefined};
        padding-inline: ${theme.spacing(isCompact ? 1 : 2)};
        background: ${theme.palette.grey[300]};
        color: ${theme.palette.text.primary};
    `
);

export default function AssignEmployeesToPlanForm({
    alreadyAssigned,
    availableUsers,
    justIcon,
    selectedRequirements,
    schedulePlanId,
    skillsData
}: IProps) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const rolesData = useAppSelector(rolesForSelect);
    const start = useAppSelector((state) => startOfPlan(state, schedulePlanId));
    const end = useAppSelector((state) => endOfPlan(state, schedulePlanId));
    const { compactMode } = useContext(SchedulerContext);
    const formatter = useLocalizeDateFormatter({});
    const [inProgress, setInProgress] = useState(false);
    const [selected, setSelected] = useState(alreadyAssigned?.map((item) => item.toString()) ?? null);
    const [searched, setSearched] = useState('');
    const [searchedSkill, setSearchedSkill] = useState<string | null>(null);
    const [searchedRole, setSearchedRole] = useState<string | null>(null);
    let selectedSkills = selectedRequirements?.filter((id) => id >= 0) ?? [];
    const selectedRoles = selectedRequirements?.filter((id) => id <= 0).map((id) => id * -1) ?? [];

    const dialogProps = justIcon
        ? {
              openButton: (onClick: (event: MouseEvent) => void) => (
                  <UserPermission id={PermissionsEnum.SchedulePlans} mode={Mode.CREATE}>
                      <IconButton
                          onClick={onClick}
                          name="add-employee"
                          title={t('label.addEmployee', 'Add Employee')}
                          data-testid="assignEmployeesToPlanForm_openButton"
                      >
                          <AddIcon />
                      </IconButton>
                  </UserPermission>
              ),
              openButtonValue: undefined
          }
        : {
              openButton: (onClick: (event: MouseEvent) => void) => (
                  <UserPermission id={PermissionsEnum.SchedulePlans} mode={Mode.CREATE}>
                      <StyledButtonBox>
                          <StyledButton
                              startIcon={<AddIcon />}
                              isCompact={compactMode}
                              name="add-employee"
                              size={compactMode ? 'small' : undefined}
                              onClick={onClick}
                              data-testid="assignEmployeesToPlanForm_openButton"
                          >
                              {t('label.addEmployee', 'Add Employee')}
                          </StyledButton>
                      </StyledButtonBox>
                  </UserPermission>
              ),
              openButtonValue: undefined
          };

    useEffect(() => {
        setSelected(alreadyAssigned?.map((item) => item.toString()) ?? null);
    }, [alreadyAssigned]);

    useEffect(() => {
        dispatch(fetchRolesForSelect({ search: '' }));
    }, []);

    const handleOnChange = useCallback((values: string[]) => setSelected(values), []);
    const handleChangeSearch = useCallback((value: number | string) => setSearched(`${value}`), []);
    const handleChangeSearchSkill = useCallback((value: string | null) => setSearchedSkill(value), []);
    const handleChangeSearchRoll = useCallback((value: string | null) => setSearchedRole(value), []);
    const handleSortBySkill = useCallback(
        (
            prev: Omit<
                IUserModel,
                'user_to_contracts' | 'user_to_requests' | 'user_to_vacation_fund' | 'user_to_workplaces'
            >,
            next: Omit<
                IUserModel,
                'user_to_contracts' | 'user_to_requests' | 'user_to_vacation_fund' | 'user_to_workplaces'
            >
        ) => {
            const prevMaxLevelOfCompetence = Math.max.apply(
                null,
                prev?.user_to_skills?.map((skill) => {
                    return skill.level_of_competence;
                }) ?? []
            );
            const nextMaxLevelOfCompetence = Math.max.apply(
                null,
                next?.user_to_skills?.map((skill) => {
                    return skill.level_of_competence;
                }) ?? []
            );

            return prevMaxLevelOfCompetence > nextMaxLevelOfCompetence ? -1 : 1;
        },
        []
    );
    const handleClick = useCallback(
        (onClose: () => void) => {
            setInProgress(true);
            if (schedulePlanId) {
                dispatch(
                    assignUsersToSchedulePlan({
                        schedulePlanId,
                        selected: selected?.map((item) => parseInt(item)) ?? []
                    })
                );
            }

            setInProgress(false);
            onClose();
        },
        [schedulePlanId, selected]
    );
    const filteredUsers = availableUsers
        .filter((user) => serializeUser(user).includes(searched))
        .filter(
            (user) =>
                !searchedSkill || user?.user_to_skills?.find(({ skill_id }) => skill_id.toString() == searchedSkill)
        )
        .filter(
            (user) => !searchedRole || user.user_to_roles?.find(({ role_id }) => role_id.toString() == searchedRole)
        );

    let usersWithSkillsAndRole: typeof availableUsers = [];
    let usersWithoutSkillsAndRole: typeof availableUsers = [];

    if (selectedSkills.length === 0 && selectedRoles.length === 0) {
        usersWithSkillsAndRole = filteredUsers.sort(handleSortBySkill);
        selectedSkills = skillsData?.map((skill) => skill.id) ?? [];
    } else {
        usersWithSkillsAndRole = filteredUsers
            .filter((user) =>
                user?.user_to_skills?.length !== 0
                    ? user.user_to_skills?.some(({ skill_id }) =>
                          selectedSkills.length === 0 ? true : selectedSkills.includes(skill_id)
                      ) ?? selectedSkills.length === 0
                    : selectedSkills.length === 0
            )
            .filter((user) =>
                user.user_to_roles?.length !== 0
                    ? user.user_to_roles?.some(({ role_id }) =>
                          selectedRoles.length === 0 ? true : selectedRoles.includes(role_id)
                      )
                    : selectedRoles.length === 0
            )
            .sort(handleSortBySkill);
        usersWithoutSkillsAndRole = filteredUsers
            .filter((user) => {
                return !usersWithSkillsAndRole.includes(user);
            })
            .sort(handleSortBySkill);
    }

    const users = [
        ...usersWithSkillsAndRole
            .map((user) => ({
                id: user.id.toString(),
                content: <UserSkillWrapper user={user} />,
                sortableValue: serializeUser(user, true)
            }))
            .sort((a, b) => a.sortableValue.localeCompare(b.sortableValue)),
        {
            id: 'divider',
            isDivider: true,
            shouldDisplay: (ids: string[]) =>
                usersWithoutSkillsAndRole.filter(({ id }) => {
                    return !ids.includes(id.toString());
                }).length !== 0 &&
                usersWithSkillsAndRole.filter(({ id }) => {
                    return !ids.includes(id.toString());
                }).length !== 0,
            content: <Divider />,
            sortableValue: ''
        },
        ...usersWithoutSkillsAndRole
            .map((user) => ({
                id: user.id.toString(),
                content: <UserSkillWrapper user={user} />,
                sortableValue: serializeUser(user, true)
            }))
            .sort((a, b) => a.sortableValue.localeCompare(b.sortableValue))
    ];

    return (
        <Dialog
            {...dialogProps}
            name="assignEmployees"
            title={<StyledTitle>Assign Employees to Plan</StyledTitle>}
            subTitle={
                <StyledSubtitle>
                    <StyledSubTitlesBox>
                        <Typography>
                            {availableUsers.length} Agents <PersonOutlineOutlinedIcon />
                        </Typography>
                    </StyledSubTitlesBox>
                    <Divider orientation="vertical" />
                    <StyledSubTitlesSkillsBox>
                        {selectedSkills.length === 0 ? (
                            <Typography>No skills were selected</Typography>
                        ) : (
                            skillsData
                                ?.filter((skill) => selectedSkills.includes(skill.id))
                                .map((skill) => (
                                    <StyledSkill key={skill.id}>
                                        {skill.icon && (
                                            <MuiIcon
                                                title={skill.name}
                                                sx={{ marginRight: 1, color: skill.color + ' !important' }}
                                            >
                                                {handleIconReplaces(skill?.icon)}
                                            </MuiIcon>
                                        )}
                                    </StyledSkill>
                                ))
                        )}
                    </StyledSubTitlesSkillsBox>
                    <Divider orientation="vertical" />
                    <StyledSubTitlesBox>
                        <Typography>
                            {formatter.format(DateHelper.fromOptionalDateString(start)?.toDate())}
                            {' - '}
                            {formatter.format(DateHelper.fromOptionalDateString(end)?.toDate())}
                        </Typography>
                    </StyledSubTitlesBox>
                </StyledSubtitle>
            }
            fullWidth
            maxWidth="md"
            actions={(onClose) => (
                <>
                    <Button name="no" variant="text" onClick={onClose}>
                        {t('label.cancel', 'Cancel')}
                    </Button>
                    <LoadingButton
                        name="add"
                        variant="contained"
                        loading={inProgress}
                        onClick={() => handleClick(onClose)}
                        data-testid="assign-update"
                    >
                        {t('label.assignOrUpdate', 'Assign / Update')}
                    </LoadingButton>
                </>
            )}
        >
            <Box className="assignEmployeesToPlanForm">
                <StyledFormFilterContainer>
                    <StyledFormFilterItem variant="outlined">
                        <InputLabel size="small">Search</InputLabel>
                        <OutlinedInput
                            data-testid="search-users"
                            size="small"
                            name="search"
                            label="Search"
                            onChange={handleChangeSearch}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </StyledFormFilterItem>
                    <StyledFormFilterItem variant="outlined">
                        <Select
                            name="searchSkill"
                            label="Search skill"
                            options={
                                skillsData?.map((skill) => ({
                                    id: skill.id.toString(),
                                    label: skill.name
                                })) ?? []
                            }
                            value={searchedSkill ?? undefined}
                            onChange={handleChangeSearchSkill}
                        />
                    </StyledFormFilterItem>
                    <StyledFormFilterItem variant="outlined">
                        <Select
                            name="searchRole"
                            label="Search role"
                            options={rolesData.map((role) => ({
                                id: role.id.toString(),
                                label: role.name
                            }))}
                            value={searchedRole ?? undefined}
                            onChange={handleChangeSearchRoll}
                        />
                    </StyledFormFilterItem>
                </StyledFormFilterContainer>
                <Box sx={{ p: 1 }}>
                    <TransferList
                        filteredItems
                        externalSorting={true}
                        items={users}
                        values={selected?.map((item) => item.toString()) ?? []}
                        onChange={handleOnChange}
                    />
                </Box>
            </Box>
        </Dialog>
    );
}
