import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { isQueueLocked } from '@/data/Queues/QueueEnum';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    aggregate,
    create,
    fetchById,
    fetchList,
    fetchSelectList,
    pullDataFromExternalSource,
    remove,
    removeAggregatedData,
    train,
    update,
    uploadCsvFilesTempleData,
    uploadCsvFilesTempleFile
} from './QueueApi';
import { IQueueAggregateModel, IQueueCUModel, IQueuePullDataFromExternalModel, IQueueTrainModel } from './QueueModels';
import { isQueueByIdInProgress, queueById, queuePaging, queueSelectValuesStatus, queuesForSelect } from './QueueSlice';

export const fetchQueues = createAsyncThunk('queues/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = queuePaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchQueueById = createAsyncThunk(
    'queues/byId',
    async (args: number | null) => {
        return await fetchById(args!);
    },
    {
        condition(id, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (id === null || !!queueById(state, id) || isQueueByIdInProgress(state)) {
                return false;
            }
        }
    }
);

export const trainDaktelaQueue = createAsyncThunk(
    'queues/predictionTrain',
    async (args: IQueueTrainModel & { queue_name?: string }, { dispatch }) => {
        dispatch(
            addNotification({
                context: 'message.info.queuesPredictionIsTraining',
                defaultMessage: 'Queues {{name}} prediction is training',
                variant: 'info',
                values: {
                    name: args.queue_name ?? args.queue_id
                }
            })
        );

        return await train(args)
            .then(() => {
                dispatch(
                    addNotification({
                        context: 'message.info.queuesPredictionIsTrained',
                        defaultMessage: 'Queues {{name}} prediction is trained',
                        variant: 'success',
                        values: {
                            name: args.queue_name ?? args.queue_id
                        }
                    })
                );
            })
            .catch((error) =>
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: error.message
                    })
                )
            );
    }
);

export const aggregateDaktelaQueue = createAsyncThunk(
    'queues/aggregate',
    async (args: IQueueAggregateModel & { queue_name: string }, { dispatch }) => {
        return await aggregate(args)
            .then(() => {
                dispatch(
                    addNotification({
                        variant: 'info',
                        context: 'message.info.queuesIsAggregatingData',
                        defaultMessage: 'Queue {{name}} is aggregating data',
                        values: { name: args.queue_name }
                    })
                );
            })
            .catch((error) =>
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: error.message
                    })
                )
            );
    }
);

export const fetchUploadCsvFilesTempleData = createAsyncThunk(
    'queues/csvFileTempleData',
    async () => await uploadCsvFilesTempleData()
);

export const fetchUploadCsvFilesTempleFile = createAsyncThunk(
    'queues/csvFileTempleFile',
    async () => await uploadCsvFilesTempleFile()
);

export const removeQueueAggregatedData = createAsyncThunk(
    'queues/removeAggregatedData',
    async (
        { queueName, ...args }: { queueId: number; queueName: string; dateFrom: string; dateTo: string },
        { dispatch }
    ) =>
        await removeAggregatedData(args)
            .then(() => {
                dispatch(
                    addNotification({
                        variant: 'success',
                        context: 'message.success.aggregatedDataWasDeleted',
                        defaultMessage: 'The aggregated data was successfully deleted from queue {{queueName}}',
                        values: { queueName: queueName }
                    })
                );
            })
            .catch((error) =>
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: error.message
                    })
                )
            )
);

export const pullDataFromExternalSourceDaktelaQueue = createAsyncThunk(
    'queues/pullData',
    async (args: IQueuePullDataFromExternalModel, { dispatch }) => {
        return await pullDataFromExternalSource(args)
            .then(() => {
                dispatch(
                    addNotification({
                        variant: 'info',
                        context: 'message.info.queuesIsDownloadingData',
                        defaultMessage: 'Queues is downloading data'
                    })
                );
            })
            .catch((error) =>
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: error.message
                    })
                )
            );
    }
);

export const createQueue = createAsyncThunk('queues/create', async (args: IQueueCUModel) => {
    return await create(args);
});

export const fetchQueuesForSelect = createAsyncThunk(
    'queues/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (queueSelectValuesStatus(state) !== 'idle' || queuesForSelect(state)) {
                return false;
            }
        }
    }
);

export const updateQueue = createAsyncThunk(
    'queues/update',
    async (args: { id: number; data: IQueueCUModel }) => await update(args.id, args.data),
    {
        condition({ id }, { getState }) {
            const state = getState() as IRootState;

            const queue = queueById(state, id);

            return queue && !isQueueLocked(queue?.state);
        }
    }
);

//TODO: čeká na refactor backandu
// export const partialQueueUpdate = createAsyncThunk(
//     'queues/update',
//     async (args: { id: number; data: Partial<IQueueCUModel> }) => {
//         return await partialUpdate(args.id, args.data);
//     }
// );

export const removeQueue = createAsyncThunk('queues/remove', async (args: { id: number; onSuccess?: () => void }) => {
    await remove(args.id).then(() => args.onSuccess && args.onSuccess());

    return args.id;
});
