import { Grow, MenuList, Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { cloneElement, memo, PropsWithChildren, ReactElement, useCallback, useRef, useState } from 'react';

export type IDropDownMenuProps = PropsWithChildren<{
    children: ReactElement<{ onClick: () => void }>;
    options: { id: string; label: ReactElement | string }[];
    onClick?: (id: string) => void;
}>;

const DropDownMenu = ({ children, options, onClick, ...rest }: IDropDownMenuProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = useCallback(
        (index: string) => {
            setOpen(false);
            if (onClick && index !== null) {
                onClick(index);
            }
        },
        [onClick]
    );

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            {cloneElement(children, { ref: anchorRef, onClick: handleToggle })}
            <Popper
                {...rest}
                sx={{
                    zIndex: 10
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem key={option.id} onClick={() => handleMenuItemClick(option.id)}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default memo(DropDownMenu);
