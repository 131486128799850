import { useCallback, useEffect } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { createAgentGroup, updateAgentGroup } from '@/data/AgentGroups/AgentGroupActions';
import { IAgentGroupModel } from '@/data/AgentGroups/AgentGroupModels';
import {
    agentGroupById,
    agentGroupCreatingStatus,
    agentGroupPaging,
    agentGroupUpdatingStatus,
    updatePaging
} from '@/data/AgentGroups/AgentGroupReducer';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { usersForSelect } from '@/data/Users/UserSlice';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import { workplacesForSelect } from '@/data/Workplaces/WorkplaceSlice';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { serializeUser } from '@/utils/UserHelper';
import { message, regex } from '@/utils/validations';

type IProps = Omit<ICuProps<IAgentGroupModel>, 'resource'>;

const AgentGroupForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => agentGroupById(state, id));
    const usersData = useAppSelector(usersForSelect);
    const workplacesData = useAppSelector(workplacesForSelect);
    const paging = useAppSelector(agentGroupPaging);
    const handleData = (values: IOutputValueType) => ({
        name: values.name as string,
        team_leader_id: values.team_leader as number,
        workplace_id: values.workplace_id as number
    });
    const handleCreate = useCallback((values: IOutputValueType) => createAgentGroup(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateAgentGroup({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    useEffect(() => {
        dispatch(fetchWorkplacesForSelect({ search: '' }));
        dispatch(fetchUsersForSelect({ search: '' }));
    }, []);

    return (
        <CuForm
            {...props}
            id={id}
            name="agentGroup"
            resource={PermissionsEnum.AgentGroups}
            creatingStatus={useAppSelector(agentGroupCreatingStatus)}
            updatingStatus={useAppSelector(agentGroupUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: 'Name',
                        value: loadedData?.name,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'team_leader',
                        label: 'Team Leader',
                        value: `${loadedData?.team_leader_id || ''}`,
                        options: usersData.map((user) => ({
                            id: user.id.toString(),
                            label: serializeUser(user)
                        }))
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'workplace_id',
                        label: 'Workplace',
                        value: `${loadedData?.workplace_id || ''}`,
                        options: workplacesData.map((workplace) => ({
                            id: workplace.id.toString(),
                            label: workplace.name
                        }))
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default AgentGroupForm;
