import {
    DateTimePicker as DesktopDateTimePicker,
    DateTimePickerProps as DesktopDateTimePickerProps
} from '@mui/x-date-pickers/DateTimePicker';
import { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import { Dayjs } from 'dayjs';
import { forwardRef, useCallback } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import DateTimePickerErrorMessages from '@/wrappers/DateTimePicker/DateTimePickerErrorMessages';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';

export type IDateTimePickerProps = Omit<
    DesktopDateTimePickerProps<Dayjs, Dayjs>,
    'label' | 'name' | 'onError' | 'onChange' | 'renderInput'
> &
    Pick<ITextFieldProps, 'name' | 'required' | 'size'> & {
        label?: string;
        error?: boolean;
        helperText?: string;
        onError?: (message: string) => void;
        onChange?: (value: Dayjs | null) => void;
    };

const DateTimePicker = forwardRef<HTMLDivElement, IDateTimePickerProps>(function Inner(
    { name, value = null, required, label, disabled, readOnly, size, error, helperText, onError, onChange, ...rest },
    ref
) {
    const handleError = (reason: DateTimeValidationError) => {
        if (!value) return;

        if (onError) onError(DateTimePickerErrorMessages(reason));
    };

    const handleOnChange = useCallback(
        (newValue: DateTimeType | null) => {
            if (onChange && (newValue === null || DateHelper.isValid(newValue))) {
                onChange(newValue === null ? null : DateHelper.floorSeconds(newValue));
            }
        },
        [onChange]
    );

    return (
        <DesktopDateTimePicker
            {...rest}
            ref={ref}
            disabled={disabled || readOnly}
            label={label}
            value={value}
            onError={handleError}
            onChange={handleOnChange}
            renderInput={({ onChange: onChangeValue, ...restField }) => (
                <TextField
                    {...restField}
                    disabled={disabled}
                    readOnly
                    data-testid={`date_time_picker_${name}`}
                    required={required}
                    label={label}
                    size={size}
                    name={name}
                    error={error}
                    helperText={helperText}
                    onChange={(e) => e && onChangeValue && onChangeValue(e)}
                />
            )}
        />
    );
});

export default DateTimePicker;
